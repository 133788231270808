import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { AppId, AppThunkAPIType } from "../../app/appTypes";
import { RootState } from "../../app/store";
import { deleteWithAuth, getWithAuth, postWithAuth } from "../../http";
import { logout } from "../user/userSlice";
import { ValidationStage } from "../validations/validationsSlice";

export type ProcessId = string;

export type StageStatus = "P" | "R" | "A" | "T" | "F" | "V" | "N" | "S" | "D"
export type ProcessStatus = "D" | "I" | "F" | "R" | "C"

export interface Process {
   id: ProcessId, 
   companyId: string,
   validationForCompanyId: string
   processNo: string, 
   createDate: string, 
   finishDate: string,
   status: ProcessStatus, 
   deviceId: AppId,
   structureId: AppId,
   deviceType: string, 
   deviceName: string,
   deviceYear: number,
   deviceNo: string,
   deviceModel: string,
   deviceDescription: string,
   deviceProducerName: string,
   processOwner: string,   
   processOwnerFullName: string,     
   companyName: string,
   purpose: string,
   ursId: string,
   stages: Stage[],
}

export interface Stage {
    id: AppId
    approverFullName: string
    approverUserName: string
    plannerFullName: string
    plannerUserName: string
    finalApproverUserName: string
    finalApproverFullName: string
    purpose: string
    stageSummary: string
    stage: ValidationStage
    status: StageStatus,
    planningEndDate: Date,
    approvalDate: Date,
    rejectDate: Date,
    verifiedDate: Date,
    processId: string
}

const adapter = createEntityAdapter<Process>({
    selectId: (process) => process.id,
})


export type ProcessesState = EntityState<Process> & { loaded: boolean }

const initialState: ProcessesState = adapter.getInitialState({ loaded: false })


export const loadProcesses = createAsyncThunk<Process[], AppId, AppThunkAPIType>(
    "process/load", async (moduleId, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ 
            url: `api/process/overview?moduleId=${moduleId}` 
        }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as Process[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const loadProcess = 
    createAsyncThunk<Process, AppId, AppThunkAPIType>("process/loadById", async (id, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/process/overview/${id}` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as Process
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

interface NewProcData {
    deviceId: string
    structureId: string
    ursId: string
    documentId: string
    processNo: string
    description: string
    purpose: string
    validationForCompanyId: string
    iqPlannerUserName: string
    iqAproverUserName: string
    iqFinalAproverUserName: string
    oqPlannerUserName: string
    oqAproverUserName: string
    oqFinalAproverUserName: string
    pqPlannerUserName: string
    pqAproverUserName: string
    pqFinalAproverUserName: string
    dqPlannerUserName: string
    dqAproverUserName: string
    dqFinalAproverUserName: string
    dqSkipped: boolean
    iqSkipped: boolean
    oqSkipped: boolean
    pqSkipped: boolean
}

export const createProcess = 
    createAsyncThunk<Process, NewProcData, AppThunkAPIType>("process/create", async (newProcess: NewProcData, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: "api/process/draft",
            payload: newProcess
        }))
        const { payload } = result 
        if (postWithAuth.fulfilled.match(result)) {
            return payload as Process
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const deleteProcess = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "process/delete",
    async (processId, { dispatch, rejectWithValue }) => {
        const result = await dispatch(deleteWithAuth({
            url: `api/process/${processId}`,
        }))
        const { payload } = result
        if (deleteWithAuth.fulfilled.match(result)) {
            return processId
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const startProcess = createAsyncThunk<Process, AppId, AppThunkAPIType>(
    "process/start",
    async (processId , { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/process/start/${processId}`,
            payload: {},
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as Process
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const sendStageToApprove = createAsyncThunk<Stage, AppId, AppThunkAPIType>(
    "stage/send-to-approve",
    async (stageId , { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/processstage/${stageId}/sent-to-approval`,
            payload: {},
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as Stage
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const approveStage = createAsyncThunk<Stage, { stageId: AppId, password: string }, AppThunkAPIType>(
    "stage/approve",
    async ({ stageId, password }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/processstage/${stageId}/approve`,
            payload: {
                password,
            },
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as Stage
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const rejectStage = createAsyncThunk<Stage, AppId, AppThunkAPIType>(
    "stage/reject",
    async (stageId , { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/processstage/${stageId}/reject-planning`,
            payload: {},
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as Stage
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const finishStage = createAsyncThunk<Stage, { stageId: AppId, password: string, processId: AppId }, AppThunkAPIType>(
    "stage/finish",
    async ({ stageId, password, processId } , { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/processstage/${stageId}/finish`,
            payload: {
                password,
            },
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            await dispatch(loadProcess(processId))
            return payload as Stage
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateStagePurpose = createAsyncThunk<Stage, {stageId: string, purpose: string}, AppThunkAPIType>(
    "stage/change-purpose",
    async ({ stageId, purpose } , { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/processstage/${stageId}/change-purpose`,
            payload: {
                purpose,
            }
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as Stage
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateStageSummary = createAsyncThunk<Stage, {stageId: string, stageSummary: string}, AppThunkAPIType>(
    "stage/change-summary",
    async ({ stageId, stageSummary } , { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/processstage/${stageId}/change-summary`,
            payload: {
                text: stageSummary,
            }
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as Stage
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const activateStage = createAsyncThunk<Stage, AppId, AppThunkAPIType>(
    "stage/activate",
    async (stageId , { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/processstage/activate-stage/${stageId}`,
            payload: {},
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as Stage
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const skipStage = createAsyncThunk<Stage, AppId, AppThunkAPIType>(
    "stage/skip",
    async (stageId , { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/processstage/skip-stage/${stageId}`,
            payload: {},
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as Stage
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export interface ChangeUser {    
    stageId: AppId
    userName: string
}

export const updateStagePlanner = createAsyncThunk<Stage, ChangeUser, AppThunkAPIType>(
    "stage/change-planner",
    async ({ stageId, userName } , { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/processstage/${stageId}/change-planner`,
            payload: {
                userName,
            },
        }))
        const { payload } = result 
        if (postWithAuth.fulfilled.match(result)) {
            return payload as Stage
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateStagePlanApprover = createAsyncThunk<Stage, ChangeUser, AppThunkAPIType>(
    "stage/change-plan-approver",
    async ({ stageId, userName } , { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/processstage/${stageId}/change-approver`,
            payload: {
                userName,
            },
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as Stage
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)


export const updateStageFinalApprover = createAsyncThunk<Stage, ChangeUser, AppThunkAPIType>(
    "stage/change-final-approver",
    async ({ stageId, userName } , { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/processstage/${stageId}/change-final-approver`,
            payload: {
                userName,
            },
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as Stage
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const processSlice = createSlice({
    name: "process",
    initialState,

    reducers: {
        clearProcessSlice: (state) => {
            adapter.removeAll(state)
            state.loaded = false
        } 
    },
    extraReducers: (builder) => {
        builder.addCase(startProcess.fulfilled, (state, action) => {
            adapter.upsertOne(state, action.payload)
        })

        builder.addCase(deleteProcess.fulfilled, (state, action) => {
            adapter.removeOne(state, action.payload)
        })
        
        builder.addCase(loadProcesses.fulfilled, (state, action) => {
            adapter.setAll(state, action.payload)
            state.loaded = true
        })
        builder.addCase(loadProcess.fulfilled, (state, action) => {
            adapter.upsertOne(state, action.payload)
        })

        builder.addCase(createProcess.fulfilled, (state, action) => {
            adapter.addOne(state, action.payload)
        })

        builder.addCase(approveStage.fulfilled, (state, action) => {
            updateStage(state, action.payload);
        })

        builder.addCase(sendStageToApprove.fulfilled, (state, action) => {
            updateStage(state, action.payload);       
        })

        builder.addCase(rejectStage.fulfilled, (state, action) => {
            updateStage(state, action.payload);       
        })

        builder.addCase(finishStage.fulfilled, (state, action) => {
            updateStage(state, action.payload);       
        })
        
        builder.addCase(updateStagePurpose.fulfilled, (state, action) => {
            updateStage(state, action.payload);      
        })
        builder.addCase(updateStageSummary.fulfilled, (state, action) => {
            updateStage(state, action.payload);      
        })

        
        builder.addCase(activateStage.fulfilled, (state, action) => {
            updateStage(state, action.payload);      
        })

        builder.addCase(skipStage.fulfilled, (state, action) => {
            updateStage(state, action.payload);      
        })        

        builder.addCase(updateStagePlanner.fulfilled, (state, action) => {
            updateStage(state, action.payload);      
        })
        
        builder.addCase(updateStagePlanApprover.fulfilled, (state, action) => {
            updateStage(state, action.payload);      
        })

        builder.addCase(updateStageFinalApprover.fulfilled, (state, action) => {
            updateStage(state, action.payload);      
        })
        builder.addCase(logout.fulfilled, state => {
            adapter.removeAll(state)
            state.loaded = false
        })
    },
})

function updateStage(state: ProcessesState , payload: Stage) {
    let process = state.entities[payload.processId];
    if (process) {
        let stageId = process?.stages.findIndex(x => x.id === payload.id);
        if (stageId >= 0) {
            process.stages[stageId] = payload;
            adapter.upsertOne(state, process);
        }
    }
}

export const selectProcesses = (state: RootState): ProcessesState => state.processes

export const { clearProcessSlice } = processSlice.actions

export const 
    { selectAll: selectAllProcesses
    , selectById: selectProcessById 
    } = adapter.getSelectors<RootState>(selectProcesses)

export default processSlice.reducer;

