import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { useCallback, useState } from "react"
import { useAppDispatch } from "../../../app/hooks"
import { AppId, AppTextFieldHandler } from "../../../app/appTypes"
import { DocumentActions, DocumentWorkflowActions, performDocumentAction } from "../documentsApi"
import { showError, showSuccess } from "../../notifications/notificationsSlice"
import { ActionContent, ActionTitle } from "./WorkflowActionUtils"

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

export interface WorkflowActionRunnerProps {
    documentId: AppId
    action: DocumentWorkflowActions
    onSuccess: (actions: DocumentActions) => void
    onCancel: () => void
}

export const WorkflowActionRunner = ({ documentId, action, onSuccess, onCancel }: WorkflowActionRunnerProps) => {
    const dispatch = useAppDispatch()
    const today = getLocalISODate(new Date().setDate(new Date().getDate() + 7));
    const [deadline, setDeadline] = useState(today)
    const [comment, setComment] = useState("")
    const [password, setPassword] = useState("")
    const [running, setRunning] = useState(false)
    const [serverError, setServerError] = useState("")
    const [validationErrors, setValidationErrors] = useState(false);

    const actionsWithParams: DocumentWorkflowActions[] = [
        "SendToOpinion",
        "SendToApprove"
    ];

    const actionsWithComment: DocumentWorkflowActions[] = [
        "Reject"
    ];

    const hasDeadline = (action: DocumentWorkflowActions): boolean => {
        return actionsWithParams.includes(action);
    };

    const hasComment = (action: DocumentWorkflowActions): boolean => {
        return actionsWithComment.includes(action);
    };

    const actionHasDeadline = hasDeadline(action);
    const actionHasComment = hasComment(action);

    const passwordError = validationErrors && (password === "" || password === null)
    const commentError = validationErrors && actionHasComment && (comment === "" || comment === null)

    const run = async () => {
        setValidationErrors(true);

        if (
            action &&
            password &&
            (!actionHasComment || (actionHasComment && comment)) &&
            (!actionHasDeadline || (actionHasDeadline && deadline))
        ) {
            try {

                setRunning(true)

                const result = await dispatch(performDocumentAction({
                    documentId: documentId,
                    actionPayload: {
                        action: action,
                        password: password,
                        deadline: deadline,
                        comment: comment
                    }
                }))
                if (performDocumentAction.fulfilled.match(result)) {
                    const actions = result.payload as DocumentActions;
                    dispatch(showSuccess("sent"))
                    onSuccess(actions)
                }
                else {
                    let errorMsg = "error"
                    if (result.payload && result.payload.kind === 'http') {
                        const problem = result.payload.problem
                        if (problem) {
                            errorMsg = problem.title

                        }
                    }
                    setServerError(errorMsg)
                }
            }
            catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setRunning(false)
                setValidationErrors(false);
            }
        }
    }

    const handleCommentFieldChange: AppTextFieldHandler = useCallback(e => {
        setComment(e.target.value)
    }, [])

    const handlePasswordFieldChange: AppTextFieldHandler = useCallback(e => {
        setPassword(e.target.value)
    }, [])

    const handleDeadlineFieldChange: AppTextFieldHandler = useCallback(e => {
        setDeadline(e.target.value)
    }, [])

    function getLocalISODate(date) {
        const tzOffset = (new Date()).getTimezoneOffset() * 60000;
        const localISOTime = (new Date(date - tzOffset)).toISOString().slice(0, -1);
        return localISOTime.split('T')[0];
    }

    return <Dialog open={true} maxWidth="xs" fullWidth>
        <DialogTitle>
            <ActionTitle action={action} />
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <ActionContent action={action} />
            </DialogContentText>
            {actionHasComment && <TextField
                sx={{
                    marginTop: 2
                }}
                required={true}
                helperText={commentError && <RequiredError />}
                error={commentError}
                fullWidth
                value={comment}
                InputLabelProps={{ shrink: true }}
                onChange={handleCommentFieldChange}
                label={<Localized id="document-action-reason"><span>_Przyczyna</span></Localized>}
            />}
            {actionHasDeadline && <TextField
                sx={{
                    marginTop: 2
                }}
                type="date"
                fullWidth
                value={deadline}
                InputLabelProps={{ shrink: true }}
                required={true}
                inputProps={{
                    min: getLocalISODate(new Date())
                }}
                onChange={handleDeadlineFieldChange}
                label={<Localized id="deadline"><span>_Termin</span></Localized>}
            />}
            <TextField
                sx={{
                    marginTop: 2
                }}
                fullWidth
                error={passwordError}
                autoFocus
                type="password"
                required={true}
                InputLabelProps={{ shrink: true }}
                value={password}
                onChange={handlePasswordFieldChange}
                label={<Localized id="password"><span>_Hasło</span></Localized>}
                helperText={<Localized id="autorization-password-required"><span>_Wymagana autoryzacja hasłem</span></Localized>}
                autoComplete="off"
            />
            {
                serverError && <Alert sx={{ marginTop: 1, }} onClose={() => { setServerError("") }} severity="error">
                    <Localized id={serverError}>
                        <span>Server error</span>
                    </Localized>
                </Alert>
            }
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={running} onClick={run}>
                <Localized id="ok">
                    <span>_OK</span>
                </Localized>
            </LoadingButton>
            <LoadingButton loading={running} onClick={onCancel}>
                <Localized id="cancel">
                    <span>_Anuluj</span>
                </Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}

