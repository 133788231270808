import { AppId, AppTextFieldHandler } from "../../app/appTypes"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material"
import { Localized } from "@fluent/react"
import { If } from "../../app/If"
import { LoadingButton } from "@mui/lab"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { useCallback, useState } from "react"
import { createMailTemplate, createNewCompanyMailTemplate, selectMailTemplateById, updateMailTemplate } from "./mailTemplatesSlice"


export interface MailTemplateDialogProps {
    id: AppId | undefined
    editable: boolean
    onSaveSuccess: () => void
    onCancel: () => void
}

export const MailTemplateDialog = ({ id, editable,  onSaveSuccess, onCancel }: MailTemplateDialogProps) => {
    const dispatch = useAppDispatch()

    const entity = useAppSelector(state => selectMailTemplateById(state, id ?? ""));
    const [type, setType] = useState<string>(entity?.type ?? "");
    const [context, setContext] = useState<string>(entity?.context ?? "DOCUMENT");
    const [body, setBody] = useState<string>(entity?.body ?? "");
    const [bodyEN, setBodyEN] = useState<string>(entity?.bodyEN ?? "");
    const [subject, setSubject] = useState<string>(entity?.subject ?? "");
    const [subjectEN, setSubjectEN] = useState<string>(entity?.subjectEN ?? "");
    const [saving, setSaving] = useState(false)

    const handleBodyChange: AppTextFieldHandler = useCallback(e => setBody(e.target.value), [setBody]);
    const handleBodyENChange: AppTextFieldHandler = useCallback(e => setBodyEN(e.target.value), [setBodyEN]);
    const handleSubjectChange: AppTextFieldHandler = useCallback(e => setSubject(e.target.value), [setSubject]);
    const handleSubjectENChange: AppTextFieldHandler = useCallback(e => setSubjectEN(e.target.value), [setSubjectEN]);
    const handleTypeChange: AppTextFieldHandler = useCallback(e => setType(e.target.value), [setType]);
    const handleContextChange = (e) => setContext(e.target.value);

    const handleSaveClick = async () => {
        setSaving(true)
        try {
            if(id === undefined && editable) {
                let template = {
                    type, context, body, subject, bodyEN, subjectEN
                };

                await dispatch(createMailTemplate(template)).unwrap()

                dispatch(showSuccess("saved"));
                onSaveSuccess()
            } else if (editable && entity) {
                let template = {
                    ...entity,
                    context, body, subject, bodyEN, subjectEN
                };

                await dispatch(updateMailTemplate(template)).unwrap()

                dispatch(showSuccess("saved"));
                onSaveSuccess()
            } else if(entity) {
                let template = {
                    ...entity,
                    body, subject, bodyEN, subjectEN
                };

                await dispatch(createNewCompanyMailTemplate(template)).unwrap()

                dispatch(showSuccess("saved"));
                onSaveSuccess()
            }
            
        }
        catch (error) {
            dispatch(showError("error"));
        }
        finally {
            setSaving(false)
        }
    }

    return <>
        <DialogTitle>
            <If condition={id !== undefined} otherwise={
                <Localized id="new-mail-template">
                    <span>Nowy szablon</span>
                </Localized>
            }>
                <Localized id="edit-mail-template">
                    <span>Edycja szablonu</span>
                </Localized>
            </If>
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2} padding={2}>
                <Grid item xs={editable ? 6 : 12}>
                    <TextField
                        fullWidth
                        disabled={id !== undefined || !editable}
                        value={type}
                        inputProps={{ maxLength: 100 }}
                        onChange={handleTypeChange}
                        label={<Localized id="mail-template-type">Typ</Localized>}>
                    </TextField>
                </Grid>
                <If condition={editable}>
                    <Grid item xs={6}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="select-mail-conxtext-label"><Localized id="mail-context">Kontekst</Localized></InputLabel>
                            <Select
                                labelId="select-mail-context-label"
                                value={context}
                                onChange={handleContextChange}
                                label={<Localized id="mail-context">Kontekst</Localized>}
                                sx={{ width: '100%' }}
                                disabled={!editable}
                            >
                                <MenuItem value={'DOCUMENT'}>
                                    <Localized id="mail-document">Dokument</Localized>
                                </MenuItem>
                                <MenuItem value={'SYSTEM'}>System</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </If>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        // disabled={!editable}
                        value={subject}
                        inputProps={{ maxLength: 100 }}
                        onChange={handleSubjectChange}
                        label={<Localized id="mail-subject">Temat PL</Localized>}>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        // disabled={!editable}
                        value={subjectEN}
                        inputProps={{ maxLength: 100 }}
                        onChange={handleSubjectENChange}
                        label={<Localized id="mail-subject-en">Temat EN</Localized>}>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        value={body}
                        // disabled={!editable}
                        minRows={3}
                        maxRows={12}
                        multiline
                        inputProps={{ maxLength: 10000 }}
                        onChange={handleBodyChange}
                        label={<Localized id="mail-body">Treść PL</Localized>}>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        value={bodyEN}
                        // disabled={!editable}
                        minRows={3}
                        maxRows={12}
                        multiline
                        inputProps={{ maxLength: 10000 }}
                        onChange={handleBodyENChange}
                        label={<Localized id="mail-body-en">Treść EN</Localized>}>
                    </TextField>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <LoadingButton onClick={handleSaveClick} loading={saving} /*disabled={!editable}*/>
                <Localized id="save">
                    <span>Zapisz</span>
                </Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={onCancel}>
                <Localized id="cancel">
                    <span>Anuluj</span>
                </Localized>
            </LoadingButton>
        </DialogActions>
    </>
}