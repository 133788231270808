import { Box, Button, ButtonGroup, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, useTheme } from "@mui/material"
import { AppId } from "../../../app/appTypes"
import { useAppDispatch } from "../../../app/hooks"
import { useCallback, useEffect, useMemo, useState } from "react"
import { DocumentTeamMember, loadDocumentTeamMembers } from "./teamMemberApi"
import { unwrapResult } from "@reduxjs/toolkit"
import { ErrorContainer } from "../../../app/ErrorContainer"
import { LoadingContainer } from "../../../app/LoadingContainer"
import { Localized } from "@fluent/react"
import { If } from "../../../app/If"
import { format, parseJSON } from "date-fns";
import DeleteIcon from '@mui/icons-material/Delete';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ShareIcon from '@mui/icons-material/Share';
import AddIcon from '@mui/icons-material/Add';
import produce from "immer"
import { TeamActionStatusLabel } from "./teamMembers/TeamActionStatusLabel"
import { TeamMemberRoleLabel } from "./teamMembers/TeamMemberRoleLabel"
import { InviteTeamMemberDialog } from "./teamMembers/InviteTeamMemberDialog"
import { DeleteTeamMemberDialog } from "./teamMembers/DeleteTeamMemberDialog"
import { AddEditTeamMemberDialog } from "./teamMembers/AddEditTeamMemberDialog"
import { DocumentContentActions } from "../documentsApi"

type DialogState
    = "none"
    | "addEditTeamMember"
    | "changeTMDeadline"
    | "substituteTM"
    | "inviteTeamMember"
    | "sendReminder"
    | "deleteTeamMember"
    | { tag: "confirmTeamMemberDelete", documentId: AppId, teamMemberId: AppId, user: string }


export interface TeamMemberPanelProps {
    documentId: AppId
    mode: "create" | "edit" | "substitute"
}

export const TeamMemberPanel = ({ documentId, mode }: TeamMemberPanelProps) => {
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);
    const [teamMembers, setTeamMembers] = useState<DocumentTeamMember[]>();
    const teamMembersSelection = useMemo(() => new Set(teamMembers?.map(u => u.userName)), [teamMembers])

    const [dialog, setDialog] = useState<DialogState>("none")
    const save = true
    const tempactions: DocumentContentActions[] = [
        "Changedeadline",
        "SubstituteTeamMember",
        "DeleteTeamMember"
    ];

    useEffect(() => {
        setIsLoading(true)
        dispatch(loadDocumentTeamMembers(documentId))
            .then(unwrapResult)
            .then(setTeamMembers)
            .catch((error) => { console.error(error); setIsError(true); setError(error); })
            .finally(() => setIsLoading(false))
    }, [])

    const handleChangeTMDeadlineClick = useCallback((entity: DocumentTeamMember) => {

    }, [])

    const handleSubstituteTMClick = useCallback((entity: DocumentTeamMember) => {

    }, [])

    const handleDeleteTeamMemberClick = useCallback((entity: DocumentTeamMember, user: string) => {
        setDialog({ tag: "confirmTeamMemberDelete", documentId: documentId, teamMemberId: entity.id, user })

    }, [])

    const handleDeleteTMClick = useCallback((entity: DocumentTeamMember, user: string) => {
    }, [])

    const handleAddTeamMemberClick = useCallback(() => {
        setDialog("addEditTeamMember")
    }, [])

    const handleInviteUser = useCallback(() => {
        setDialog("inviteTeamMember")
    }, [])

    const handleDialogCancelClick = useCallback(() => {
        setDialog("none")
    }, [])

    const onTeamMemberSuccessfulSave = (teamMember: DocumentTeamMember) => {
        setTeamMembers(
            produce(draft => {
                if (draft !== undefined) {
                    draft.push(teamMember)
                }
            })
        )
        setDialog("none")
    };
 
    const onTeamMemberSuccessfulDelete = (teamMemberId: AppId) => {
        setTeamMembers(
            produce(draft => {
                if (draft !== undefined) {
                    const index = draft.findIndex(t => t.id === teamMemberId)
                    if (index >= 0) {
                        draft.splice(index, 1)
                    }
                }
            })
        )
        setDialog("none")
    };


    if (isError) {
        return <>
            <ErrorContainer error={error} />
        </>
    }

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }

    return <>
        <TableContainer component={Paper} sx={{
            maxHeight: 'calc(100vh - 400px)',
        }}>

            <Table>
                <TableHead>
                    <TableRow >
                        <TableCell sx={{
                            minWidth: 200,
                        }}>
                            <Localized id="user-first-name">
                                <span>Imię</span>
                            </Localized>
                        </TableCell>
                        <TableCell sx={{
                            minWidth: 200,
                        }}>
                            <Localized id="user-last-name">
                                <span>Nazwisko</span>
                            </Localized>
                        </TableCell>
                        <TableCell>
                            <Localized id="user-position">
                                <span>Stanowisko</span>
                            </Localized>
                        </TableCell>
                        <TableCell>
                            <Localized id="user-company">Firma</Localized>
                        </TableCell>
                        <TableCell>
                            <Localized id="user-role">
                                <span>Rola</span>
                            </Localized>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", width: 200 }}>
                            <Localized id="opinion-status">
                                <span>Status</span>
                            </Localized>
                        </TableCell>
                        <TableCell sx={{ width: 160, textAlign: "center" }}>
                            <Localized id="edition-date">
                                <span>Data edycji</span>
                            </Localized>
                        </TableCell>
                        <TableCell sx={{ width: 160, textAlign: "center" }}>
                            <Localized id="opinion-date">
                                <span>Data zaopiniowania</span>
                            </Localized>
                        </TableCell>
                        <TableCell sx={{ width: 160, textAlign: "center" }}>
                            <Localized id="approvement-date">
                                <span>Data zatwierdzenia</span>
                            </Localized>
                        </TableCell>
                        <TableCell sx={{
                            width: 100,
                        }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{teamMembers?.map(tm => <TeamMemberRow
                    editable={true}
                    actions={tempactions}
                    entity={tm}
                    key={tm.userName}
                    onChangeDeadlineClick={handleChangeTMDeadlineClick}
                    onSubstituteTMClick={handleSubstituteTMClick}
                    onDeleteClick={handleDeleteTeamMemberClick}
                />)}</TableBody>
            </Table>

        </TableContainer>
        <Box sx={{
            padding: 1,
        }}>
            <If condition={save !== undefined}>
                <Button variant="contained" color="secondary" size="small" startIcon={<AddIcon />} onClick={handleAddTeamMemberClick}>
                    <Localized id="add">
                        <span>_Dodaj</span>
                    </Localized>
                </Button>
                <Button sx={{ marginLeft: "5px", marginTop: "1px" }} size="small"  variant="outlined" startIcon={<ShareIcon />} onClick={handleInviteUser} >
                    <Localized id="invite">
                        <span>_Zaproś</span>
                    </Localized>
                </Button>

            </If>
        </Box>
        {
            dialog === "addEditTeamMember" &&
            <AddEditTeamMemberDialog
                documentId={documentId}
                mode={"create"}
                excludedUsers={teamMembersSelection}
                userToSubstitute={""}
                onCancelClick={handleDialogCancelClick}
                onSuccessfulSave={onTeamMemberSuccessfulSave}
            />
        }
        {dialog === "inviteTeamMember" &&
            <InviteTeamMemberDialog
                documentId={documentId}
                onCancelClick={handleDialogCancelClick}
                onSuccessfulSave={onTeamMemberSuccessfulSave}
            />
        }
        {typeof dialog === 'object' && dialog.tag === "confirmTeamMemberDelete" && <DeleteTeamMemberDialog
            documentId={dialog.documentId}
            teamMemberId={dialog.teamMemberId}
            userFullName={dialog.user}
            onSuccessfulSave={onTeamMemberSuccessfulDelete}
            onCancel={handleDialogCancelClick}
        />
        }
    </>
}

interface TeamMemberRowProps {
    entity: DocumentTeamMember
    actions: DocumentContentActions[]
    onChangeDeadlineClick: (entity: DocumentTeamMember) => void
    onSubstituteTMClick: (entity: DocumentTeamMember) => void
    onDeleteClick: (entity: DocumentTeamMember, user: string) => void
    editable: boolean
}
const TeamMemberRow = ({ entity, onChangeDeadlineClick, onSubstituteTMClick, onDeleteClick, editable, actions }: TeamMemberRowProps) => {
    const { userName, approvementDate, finishEditionAction, finishEditionDate, opinionDate, opinionAction, approveAction, opinionClosed } = entity
    const { firstName, surname, position, companyName } = entity

    const approvementFormatedDate = approvementDate ? format(parseJSON(approvementDate), "yyyy-MM-dd HH:mm") : ""
    const opinionDateFormatedDate = opinionDate ? format(parseJSON(opinionDate), "yyyy-MM-dd HH:mm") : ""
    const finishEditionFormatedDate = finishEditionDate ? format(parseJSON(finishEditionDate), "yyyy-MM-dd HH:mm") : ""
    const approveDeadline = approveAction?.deadline ? format(parseJSON(approveAction?.deadline), "yyyy-MM-dd") : ""
    const opinionDeadline = opinionAction?.deadline ? format(parseJSON(opinionAction?.deadline), "yyyy-MM-dd") : ""
    const today = new Date();
    const hasSubstitution = entity.substitutionName !== null && entity.substitutionName !== ""
    const hasActiveAction = approveAction !== null || opinionAction !== null || finishEditionAction !== null

    const changedeadlineActive = actions.includes("Changedeadline") !== undefined && hasActiveAction && !hasSubstitution
    const substitutionActive = actions.includes("SubstituteTeamMember") !== undefined && hasActiveAction && !hasSubstitution
    const deleteActive = actions.includes("DeleteTeamMember") === undefined && actions.includes("SubstituteTeamMember") === undefined && editable
    const deleteMemberActive = actions.includes("DeleteTeamMember") !== undefined

    let textStyle = {};
    const theme = useTheme();
    let substitutionRowColor = {};

    if (hasSubstitution) {
        substitutionRowColor = { backgroundColor: theme.palette.grey[200] }
    }

    if (opinionAction?.deadline) {
        const deadlineDate = new Date(opinionAction.deadline);
        today.setDate(today.getDate() + 2);
        if (today > deadlineDate) {
            textStyle = { color: 'red' };
        }
    }

    if (approveAction?.deadline) {
        const deadlineDate = new Date(approveAction.deadline);
        today.setDate(today.getDate() + 2);
        if (today > deadlineDate) {
            textStyle = { color: 'red' };
        }
    }

    const handleDeleteClick = useCallback(() => {
        onDeleteClick(entity, `${firstName} ${surname}`)
    }, [entity, onDeleteClick])

    return <TableRow style={substitutionRowColor}>
        <TableCell>{firstName}</TableCell>
        <TableCell>{surname}</TableCell>
        <TableCell>{position}</TableCell>
        <TableCell>{companyName}</TableCell>
        <TableCell><TeamMemberRoleLabel role={entity.role}></TeamMemberRoleLabel></TableCell>
        <TableCell>
            <If condition={hasSubstitution}>
                <Box display="flex" style={{ textAlign: "center" }} flexDirection="column">
                    <Localized id="urs-team-substituted-by">
                        <span>Zastąpiony przez </span>
                    </Localized> entity.substitutionName
                </Box>
            </If>
            <If condition={opinionAction != null}>
                <Box display="flex" style={{ textAlign: "center" }} flexDirection="column">
                    <div>
                        <TeamActionStatusLabel status={opinionAction?.status}></TeamActionStatusLabel>
                    </div>
                    <div style={textStyle}>
                        <Localized id="urs-action-deadline">
                            <span>Termin</span>
                        </Localized> {opinionDeadline}
                    </div>
                </Box>
            </If>
            <If condition={approveAction != null}>
                <Box display="flex" style={{ textAlign: "center" }} flexDirection="column">
                    <div>
                        <TeamActionStatusLabel status={approveAction?.status}></TeamActionStatusLabel>
                    </div>
                    <div style={textStyle}>
                        <Localized id="urs-action-deadline">
                            <span>Termin</span>
                        </Localized> {approveDeadline}
                    </div>
                </Box>
            </If>
            <If condition={opinionClosed != null}>
                <Box display="flex" style={{ textAlign: "center" }} flexDirection="column">
                    <div>
                        <Localized id="urs-action-no-opinion">
                            <span>brak opiniowania</span>
                        </Localized>
                    </div>
                </Box>
            </If>
            <If condition={finishEditionAction != null}>
                <Box display="flex" style={{ textAlign: "center" }} flexDirection="column">
                    <div>
                        <TeamActionStatusLabel status={finishEditionAction?.status}></TeamActionStatusLabel>
                    </div>
                </Box>
            </If>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
            <If condition={finishEditionFormatedDate !== ""} otherwise={<span>-</span>}>
                {finishEditionFormatedDate}
            </If>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
            <If condition={opinionDateFormatedDate !== ""} otherwise={<span>-</span>}>
                {opinionDateFormatedDate}
            </If>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
            <If condition={approvementFormatedDate !== ""} otherwise={<span>-</span>}>
                {approvementFormatedDate}
            </If>
        </TableCell>
        <TableCell>
            <ButtonGroup>
                <If condition={changedeadlineActive}>
                    <Tooltip title={<Localized id="urs-change-deadline">Zmień deadline</Localized>}>
                        <IconButton size="small" onClick={() => onChangeDeadlineClick(entity)}>
                            <MoreTimeIcon />
                        </IconButton>
                    </Tooltip>
                </If>
                <If condition={substitutionActive}>
                    <Tooltip title={<Localized id="urs-appoint-substitution">Wyznacz zastępstwo</Localized>}>
                        <IconButton size="small" onClick={() => onSubstituteTMClick(entity)}>
                            <SwapVertIcon />
                        </IconButton>
                    </Tooltip>
                </If>
                <If condition={deleteActive}>
                    <Tooltip title={<Localized id="delete">Usuń</Localized>}>
                        <IconButton size="small" onClick={handleDeleteClick}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </If>
                <If condition={deleteMemberActive}>
                    <Tooltip title={<Localized id="delete">Usuń</Localized>}>
                        <IconButton size="small" onClick={handleDeleteClick}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </If>
            </ButtonGroup>
        </TableCell>
    </TableRow>
}

