import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PrintIcon from '@mui/icons-material/Print';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AppId } from '../../../app/appTypes';
import { Localized } from '@fluent/react';
import { Link } from 'react-router-dom';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import { useAppSelector } from '../../../app/hooks';
import { selectSettings } from '../../settings/settingsSlice';

export interface UrsRequestToolbarMenuProps {
    usrRequestId: AppId
    usrId: AppId
}

export const UrsRequestToolbarMenu = ({ usrRequestId, usrId }: UrsRequestToolbarMenuProps) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { locale } = useAppSelector(selectSettings);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePrintUrsClick = async () => {
        setAnchorEl(null);
        if (usrRequestId) {
            window.open(`${process.env.REACT_APP_REST_API}api/reports/urs/${usrId}?language=${locale}`, '_blank')
        }
    }

    const handlePrintCommentedUrsClick = async () => {
        setAnchorEl(null);
        if (usrRequestId) {
            window.open(`${process.env.REACT_APP_REST_API}api/reports/urs-offer/${usrRequestId}?language=${locale}`, '_blank')
        }
    }

    return (
        <div>
            <Button
                aria-controls="menu"
                aria-haspopup="true"
                onClick={handleClick}
                variant="contained"
                color="primary"
                endIcon={<ArrowDropDownIcon />}
            >
                <Localized id="menu">
                    <span>Menu</span>
                </Localized>
            </Button>
            <Menu
                id="menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handlePrintCommentedUrsClick()}>
                    <PrintIcon sx={{ mr: 1 }} />
                    <Localized id="urs-offer-action-print">
                        <span>Wydruk</span>
                    </Localized>
                </MenuItem>
                <MenuItem onClick={() => handlePrintUrsClick()}>
                    <PrintIcon sx={{ mr: 1 }} />
                    <Localized id="urs-offer-action-print-original">
                        <span>Wydruk</span>
                    </Localized>
                </MenuItem>
                <MenuItem component={Link}
                    to={`/supplierOffers/edit/1`}
                >
                    <HistoryEduIcon sx={{ mr: 1 }} />
                        <span>Create offer (In progress)</span>
                </MenuItem>
            </Menu>
        </div>
    );
};