// designSpecData.ts

import { cupraImage, cupramotor } from "./images";

export interface Device {
    id: string;
    name: string;
    productionDate: string;
    type: string;
    version: string;
    createdBy: string;
    imageBase64?: string; // Dodano pole na obraz base64
  }
  
  export interface TreeNode {
    id: string;
    label: string;
    description: string;
    details?: { name: string; value: string }[];
    tests: { name: string; type: string }[];
    imageBase64?: string; // Dodano pole na obraz base64
    children?: TreeNode[];
  }
  
  // Dane urządzenia (Cupra Formentor) dla specyfikacji DS
  export const device: Device = {
    id: 'DS002',
    name: 'Cupra Formentor Design Specification',
    productionDate: '2024-01-15',
    type: 'SUV',
    version: '1.0',
    createdBy: 'John Smith',
    imageBase64: cupraImage
  };
  
  // Struktura specyfikacji DS w formacie TreeNode z testami i obrazami
  export const treeData: TreeNode[] = [
    {
      id: '1',
      label: 'Exterior Design',
      description: 'This module covers the exterior features and styling of the Cupra Formentor.',
      details: [
        { name: 'Body Type', value: 'SUV' },
        { name: 'Color Options', value: 'Matte Black, Midnight Blue, Metallic Gray' },
        { name: 'Wheels', value: '19-inch Alloy Wheels, Optional 20-inch' }
      ],
      tests: [
        { name: 'Aerodynamic Test', type: 'FAT' },
        { name: 'Weather Resistance Test', type: 'FAT' },
        { name: 'UV Exposure Test', type: 'FAT' }
      ],
      imageBase64: 'data:image/png;base64,...', // Wstaw dowolny obrazek w formacie base64
      children: [
        {
          id: '1.1',
          label: 'Front Bumper',
          description: 'Aggressive front bumper with air intakes and Cupra logo.',
          details: [
            { name: 'Material', value: 'High-strength plastic' },
            { name: 'Features', value: 'Aerodynamic design, integrated LED fog lights' }
          ],
          tests: [
            { name: 'Impact Resistance Test', type: 'FAT' },
            { name: 'Fog Light Function Test', type: 'FAT' },
            { name: 'Paint Durability Test', type: 'FAT' }
          ],
          imageBase64: 'data:image/png;base64,...' // Wstaw dowolny obrazek w formacie base64
        },
        {
          id: '1.2',
          label: 'Rear Spoiler',
          description: 'Stylish rear spoiler enhancing the car’s aerodynamics and sportiness.',
          details: [
            { name: 'Material', value: 'Carbon fiber' },
            { name: 'Color', value: 'Body-color matching' }
          ],
          tests: [
            { name: 'Strength Test', type: 'FAT' },
            { name: 'Vibration Test', type: 'FAT' },
            { name: 'Paint Adhesion Test', type: 'FAT' }
          ],
          imageBase64: 'data:image/png;base64,...' // Wstaw dowolny obrazek w formacie base64
        }
      ]
    },
    {
      id: '2',
      label: 'Interior Design',
      description: 'Detailed design of the interior, including materials, colors, and features.',
      details: [
        { name: 'Seat Material', value: 'Leather, Alcantara, and fabric options' },
        { name: 'Dashboard', value: 'Digital cockpit with customizable display' },
        { name: 'Infotainment Screen', value: '10-inch touchscreen with navigation' }
      ],
      tests: [
        { name: 'Wear and Tear Test', type: 'FAT' },
        { name: 'Temperature Resistance Test', type: 'FAT' },
        { name: 'Ergonomic Assessment', type: 'FAT' }
      ],
      imageBase64: 'data:image/png;base64,...', // Wstaw dowolny obrazek w formacie base64
      children: [
        {
          id: '2.1',
          label: 'Steering Wheel',
          description: 'Sport steering wheel with integrated controls and paddle shifters.',
          details: [
            { name: 'Material', value: 'Leather-wrapped with Cupra logo' },
            { name: 'Features', value: 'Multi-function controls, heated' }
          ],
          tests: [
            { name: 'Grip Test', type: 'FAT' },
            { name: 'Control Function Test', type: 'FAT' },
            { name: 'Heated Functionality Test', type: 'FAT' }
          ],
          imageBase64: 'data:image/png;base64,...' // Wstaw dowolny obrazek w formacie base64
        },
        {
          id: '2.2',
          label: 'Seats',
          description: 'Comfortable sports seats with various upholstery options.',
          details: [
            { name: 'Upholstery Options', value: 'Black leather, blue Alcantara' },
            { name: 'Adjustability', value: '8-way power adjustable' }
          ],
          tests: [
            { name: 'Comfort Test', type: 'FAT' },
            { name: 'Adjustability Test', type: 'FAT' },
            { name: 'Fabric Durability Test', type: 'FAT' }
          ],
          imageBase64: 'data:image/png;base64,...' // Wstaw dowolny obrazek w formacie base64
        }
      ]
    }
  ];
  