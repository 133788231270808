import { Localized } from "@fluent/react";
import { Box, Chip, Typography } from "@mui/material";

export const MeetingMinutesItemStatusLabel = ({ status }: { status: string }) => {
    switch (status) {
        case "NEW":
            return (
                <Box
                    sx={{
                        marginRight: 1, // odstęp między kołem a tekstem
                        width: 10, // szerokość i wysokość koła
                        height: 10,
                        borderRadius: '50%', // zaokrąglenie do formy koła
                        backgroundColor: 'lightblue', // kolor tła koła
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                />
            );
        case "ACTIVE":
            return (
                <Box
                    sx={{
                        marginRight: 1, // odstęp między kołem a tekstem
                        width: 10, // szerokość i wysokość koła
                        height: 10,
                        borderRadius: '50%', // zaokrąglenie do formy koła
                        backgroundColor: 'green', // kolor tła koła
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                />
            );
        case "FINISHED":
            return (
                <Box
                    sx={{
                        marginRight: 1, // odstęp między kołem a tekstem
                        width: 10, // szerokość i wysokość koła
                        height: 10,
                        borderRadius: '50%', // zaokrąglenie do formy koła
                        backgroundColor: 'grey', // kolor tła koła
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                />
            );
        case "CANCELED":
            return (
                <Box
                    sx={{
                        marginRight: 1, // odstęp między kołem a tekstem
                        width: 10, // szerokość i wysokość koła
                        height: 10,
                        borderRadius: '50%', // zaokrąglenie do formy koła
                        backgroundColor: 'red', // kolor tła koła
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                />
            );
        default:
            return <span></span>
    }
}