import { useCallback, useEffect, useState } from "react"
import { LoadingContainer } from "../../../app/LoadingContainer"
import { Button, ButtonGroup, Checkbox, Fab, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Tab, TableContainer } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import { Localized, useLocalization } from "@fluent/react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectSettings } from "../../settings/settingsSlice"
import { ErrorContainer } from "../../../app/ErrorContainer"
import { APIError, AppId } from "../../../app/appTypes"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { FlexDiv } from "../../../app/Utils"
import AddIcon from '@mui/icons-material/Add'
import { CompanySetting, CompanySupplierAssigmentSetting, loadCompanySettings, loadSupplierAssigmentsSettings } from "./companySettingsAPI"
import { unwrapResult } from "@reduxjs/toolkit"
import { MailingGroupList } from "../mailingGroups/MailingGroupList"

type FormTabs = "MainSettings" | "UrsRequestSettings" | "MailingGroups"

type DialogState =
    | { type: "none" }
    | { type: "createUpdateSetting", settingId: AppId | undefined }
    | { type: "createUpdateSupplierAssigmentSetting", supplierAssigmentSettingId: AppId | undefined }


export const CompanySettings = () => {
    const dispatch = useAppDispatch();

    const [params, setParams] = useSearchParams()
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const tab = params.get('tab') as FormTabs || 'MainSettings'
    const [loadingError, setError] = useState<APIError | undefined>(undefined)
    const [loadingErrorOccured, setLoadingErrorOccured] = useState<boolean>(false)
    const [companySettings, setCompanySettings] = useState<CompanySetting[]>([]);
    const [supplierAssigmentSettings, setSupplierAssigmentSettings] = useState<CompanySupplierAssigmentSetting[]>([]);
    const [dialog, setDialog] = useState<DialogState>({ type: "none" })

    const setTab = (tab: FormTabs) => {
        params.set('tab', tab)
        setParams(params, { replace: true })
    }

    const handleTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [])

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadCompanySettings())
            .then(unwrapResult)
            .then(setCompanySettings)
            .catch((error) => console.error(error))
            .finally(() => setIsLoading(false))
        dispatch(loadSupplierAssigmentsSettings())
            .then(unwrapResult)
            .then(result => {
                setSupplierAssigmentSettings(result);
            }).catch((error) => console.error(error))
    }, [dispatch]);


    const handleEditSetting = useCallback((settingId: AppId) => {
        setDialog({
            type: "createUpdateSetting",
            settingId
        })
    }, [setDialog])


    const columns: GridColDef<CompanySetting, any, any>[] = [
        {
            field: 'settingType',
            headerName: l10n.getString("", null, "Setting Type"),
            width: 300
        },
        {
            field: 'value',
            headerName: l10n.getString("", null, "Value"),
            flex: 1,
        },
        {
            field: 'modifyDate',
            headerName: l10n.getString("", null, "Modify Date"),
            flex: 1,
            valueGetter: (_, row) => {
                return new Date(row.modifyDate).toLocaleString();
            }
        },
        {
            field: 'actions', type: 'actions', width: 300,
            renderCell: (params) => {
                return (
                    <ButtonGroup>
                        <Button color="primary" onClick={() => handleEditSetting(params.row.id)} >
                            <Localized id="edit">
                                <span>Edytuj</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ]

    const supplierAssigmentcolumns: GridColDef<CompanySupplierAssigmentSetting, any, any>[] = [
        {
            field: 'event',
            headerName: l10n.getString("", null, "Event"),
            width: 300
        },

        {
            field: 'user',
            headerName: l10n.getString("", null, "User"),
            flex: 1,
        },
        {
            field: 'role',
            headerName: l10n.getString("", null, "Role"),
            width: 200
        },
        {
            field: 'notifyUser',
            headerName: 'Notify user?',
            width: 100,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value}
                    disabled
                    inputProps={{ 'aria-label': 'Is Active Checkbox' }}
                />
            ),
        },
        {
            field: 'notificationMethod',
            headerName: l10n.getString("", null, "Notification method"),
            width: 300
        },
        {
            field: 'modifyDate',
            headerName: l10n.getString("", null, "Modify Date"),
            flex: 1,
            valueGetter: (_, row) => {
                return new Date(row.modifyDate).toLocaleString();
            }
        },
        {
            field: 'actions', type: 'actions', width: 300,
            renderCell: (params) => {
                return (
                    <ButtonGroup size="small">
                        <Button
                            style={{ width: 100 }}
                            onClick={() => { }}
                            color="secondary"
                            variant="outlined" >
                            <Localized id="edit">
                                <span>Edytuj</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ]

    if (loadingErrorOccured) {
        return <>
            <ErrorContainer error={loadingError} />
        </>
    }

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TabContext value={tab}>
                        <TabList onChange={handleTabChange}>
                            <Tab
                                key={0}
                                value={"MainSettings"}
                                label={<Localized id="">Main Settings</Localized>} />
                            <Tab
                                key={1}
                                value={"MailingGroups"}
                                label={<Localized id="">Mailing groups</Localized>} />
                            <Tab
                                key={2}
                                value={"UrsRequestSettings"}
                                label={<Localized id="">URS Request settings</Localized>} />
                        </TabList>
                        <TabPanel value="MainSettings">
                            <TableContainer component={Paper}>
                                <DataGrid localeText={getCustomLocaleText()}
                                    style={{ minHeight: 100, height: 'calc(100vh - 360px)' }}
                                    rows={companySettings}
                                    columns={columns}
                                />
                            </TableContainer>
                            <FlexDiv>
                                <Fab sx={{
                                    marginTop: 2,
                                }}
                                    onClick={() => { }}
                                    color="secondary"
                                >
                                    <AddIcon />
                                </Fab>
                            </FlexDiv>
                        </TabPanel>
                        <TabPanel value="MailingGroups">
                            <MailingGroupList/>
                        </TabPanel>
                        <TabPanel value="UrsRequestSettings">
                            <TableContainer component={Paper}>
                                <DataGrid localeText={getCustomLocaleText()}
                                    style={{ minHeight: 100, height: 'calc(100vh - 360px)' }}
                                    rows={supplierAssigmentSettings}
                                    columns={supplierAssigmentcolumns}
                                />
                            </TableContainer>
                            <FlexDiv>
                                <Fab sx={{
                                    marginTop: 2,
                                }}
                                    onClick={() => { }}
                                    color="secondary"
                                >
                                    <AddIcon />
                                </Fab>
                            </FlexDiv>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
        </>
    )
}
