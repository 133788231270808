import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppThunkAPIType, AppId } from "../../../app/appTypes";
import { deleteWithAuth, getWithAuth, postWithAuth } from "../../../http";

export interface DocumentSettings {
    id: string;
    name: string;
    value: string;
    companyId: number | null;
    documentCode: string;
}

export interface DocumentSettingsForClient {
    name: string;
    value: string;
}

export type DraftDocumentSettings = Omit<DocumentSettings, "id">

interface LoadSystemDocumentSettingsParams {
    companyId: AppId,
    query: string
}

export const loadSystemDocumentSettings = createAsyncThunk<DocumentSettings[], string, AppThunkAPIType>(
    "system-document-settings/load",
    async (query, { dispatch, rejectWithValue }) => {

        const result = await dispatch(getWithAuth({ url: `api/system-document-settings${query}` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as DocumentSettings[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const loadSystemDocumentSettingsForCompany = createAsyncThunk<DocumentSettings[], LoadSystemDocumentSettingsParams, AppThunkAPIType>(
    "system-document-settings/load",
    async ({ companyId, query }, { dispatch, rejectWithValue }) => {

        const result = await dispatch(getWithAuth({ url: `api/system-document-settings/${companyId}${query}` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as DocumentSettings[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const loadSettingsForCompany = createAsyncThunk<DocumentSettingsForClient[], string, AppThunkAPIType>(
    "system-document-settings/load",
    async (query, { dispatch, rejectWithValue }) => {

        const result = await dispatch(getWithAuth({ url: `api/system-document-settings/settings${query}` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as DocumentSettingsForClient[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const createSystemDocumentSettings = createAsyncThunk<DocumentSettings, DraftDocumentSettings, AppThunkAPIType>(
    "system-document-settings/create",
    async (documentSettings, { dispatch, rejectWithValue }) => {

        const result = await dispatch(postWithAuth({ url: `api/system-document-settings`, payload: documentSettings }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as DocumentSettings
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteSystemDocumentSettings = createAsyncThunk<string, string, AppThunkAPIType>(
    "system-document-settings/delete",
    async (id, { dispatch, rejectWithValue }) => {

        const result = await dispatch(deleteWithAuth({ url: `api/system-document-settings/${id}` }))
        const { payload } = result
        if (deleteWithAuth.fulfilled.match(result)) {
            return payload as string
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)