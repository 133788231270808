import { Localized } from "@fluent/react";
import { ManagementRoleType } from "../../utils/managedObject";

export const OfferTeamMemberRoleLabel = ({ role }: { role: ManagementRoleType }) => {
    switch (role) {
        case "PROCESS_OWNER":
            return <Localized id="team-role-process-owner">
                <span>Process owner</span>
            </Localized>
        case "EDITOR":
            return <Localized id="team-role-editor">
                <span>Editor</span>
            </Localized>
        default:
            return <span></span>
    }
}