import { Localized, useLocalization } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { unwrapResult } from "@reduxjs/toolkit"
import { useEffect, useState } from "react"
import { AppId } from "../../../app/appTypes"
import { linkDocumentToProcess,  ProjectProcess } from "./ProjectProcessApiClient"
import { useAppDispatch } from "../../../app/hooks"
import { DocumentHeader, loadAllDocuments } from "../../documents/documentsApi"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { DocumentStatusLabel } from "../../documents/DocumentStatusLabel"
import { QuickSearchToolbar } from "../../../app/QuickSearchToolbar"


export interface AssingDocumentDialogProps {
    processId: AppId
    onSave: (project: ProjectProcess) => void
    onCancel: () => void
}


export const AssingDocumentDialog = (props: AssingDocumentDialogProps) => {
    const dispatch = useAppDispatch()
    const { processId } = props
    const [saving, setSaving] = useState(false)
    const { l10n } = useLocalization()

    const [documents, setDocuments] = useState<DocumentHeader[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [selectedRow, setSelectedRow] = useState(null);

    const handleRowSelection = (params) => {
      setSelectedRow(params);
      console.log(params)

    };

    const handleOkClick = () => {
        setSaving(true)
        if(selectedRow)
        {
            const document = documents.find(row => row.id === selectedRow[0]);
            console.log(document)
            if (document){
                dispatch(linkDocumentToProcess({
                    processId: processId,
                    documentId: document?.id?.toString() ?? ""
                }))
                    .then(unwrapResult)
                    .then(project => props.onSave(project))
                    .catch(err => console.error(err))
                    .finally(() => setSaving(false))
            }
          
        }
        setSaving(false)
    }

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadAllDocuments())
            .then(unwrapResult)
            .then(setDocuments)
            .catch((error) => console.error(error))
            .finally(() => setIsLoading(false));
    }, [])

    const columns: GridColDef[] = [
        {
            field: 'no',
            headerName: l10n.getString('', null, 'Numer'),
            width: 200,
        },
        {
            field: 'title',
            headerName: l10n.getString('', null, 'Title'),
            width: 300,
        },
        {
            field: 'createDate',
            headerName: l10n.getString('', null, 'Data'),
            width: 150,
            valueGetter: (_, row) => {
                return row.createDate ? new Date(row.createDate).toISOString().split('T')[0] : "";
            }
        },
        {
            field: 'type',
            headerName: l10n.getString('', null, 'Type'),
            width: 150,
        },
        
        {
            field: 'status',
            headerName: l10n.getString('project-status', null, 'Status'),
            width: 150,
            renderCell: (params) => (
                <div>
                    <DocumentStatusLabel status={params.row.status ?? ""} />
                </div>
            )
        }
    ];

    return <Dialog open={true} fullWidth maxWidth='lg'>
        <DialogTitle>
            <Localized id=''>Przypnij dokument do procesu</Localized>
        </DialogTitle>
        <DialogContent>
            <DataGrid
                style={{ minHeight: 100, height: 'calc(100vh - 300px)' }}
                rows={documents}
                columns={columns}
                getRowId={(row) => row.id} 
                slots={{ toolbar: QuickSearchToolbar }}
                onRowSelectionModelChange={handleRowSelection}
                checkboxSelection
                disableMultipleRowSelection 
            />
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleOkClick}>
                <Localized id='ok'>OK</Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={props.onCancel}>
                <Localized id='cancel'>Anuluj</Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}
