import { unwrapResult } from "@reduxjs/toolkit"
import { ReactElement, useCallback, useEffect, useState } from "react"
import { BlobMeta, loadBlobs, uploadBlobs } from "../features/urs/ursAttachementsSlice"
import { useAppDispatch } from "./hooks"

export interface BlobsProps {
    guid: string
    blobs: BlobMeta[]
    upload: (files: File[]) => Promise<void>
    uploading: boolean
}

export interface AppBlobsProviderProps<T> {
    guid: string | null
    component: (props: T & BlobsProps) => ReactElement
    args : T
}

export function AppBlobsProvider<T>(props: AppBlobsProviderProps<T>) {
    const { guid, args } = props
    const dispatch = useAppDispatch()

    const [blobs, setBlobs] = useState<BlobMeta[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [uploading, setUploading] = useState<boolean>(false)

    const upload = useCallback((files: File[]) => {
        setUploading(true)
        return dispatch(uploadBlobs([guid ?? '', files]))
            .then(unwrapResult)
            .then(bs => setBlobs(bs))
            .finally(() => setUploading(false))
    }, [guid])

    useEffect(() => {
        if (guid) {
            setBlobs([])
            setLoading(true)
            dispatch(loadBlobs(guid))
                .then(unwrapResult)
                .then(bs => setBlobs(bs))
                .finally(() => setLoading(false))
        }
    }, [guid])

    const Component = props.component

    if (!guid) return null

    if (loading) return <>Loading blobs...</>

    return <Component {...args} blobs={blobs} upload={upload} uploading={uploading} guid={guid} />
}
