import { Localized } from "@fluent/react";
import { MinuteMeetingStatus } from "./meetingMinutesSlice";

export const MeetingStatusLabel = ({ status }: { status: MinuteMeetingStatus }) => {
    switch (status) {
        case "INMEETING":
            return <Localized id="meeting-minutes-status-inmeeting">
                <span>W trakcie spotkania</span>
            </Localized>
        case "SUSPENDED":
            return <Localized id="meeting-minutes-status-suspended">
                <span>Zawieszone</span>
            </Localized>
        case "CLOSED":
            return <Localized id="meeting-minutes-status-closed">
                <span>Ostatnie spotkanie</span>
            </Localized>
        case "NEW":
            return <Localized id="meeting-minutes-status-new">
                <span></span>
            </Localized>
        default:
            return <span></span>
    }
}