import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, isAnyOf } from "@reduxjs/toolkit";
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../app/appTypes";
import { RootState } from "../../app/store";
import type { PayloadAction } from "@reduxjs/toolkit"
import { deleteWithAuth, postWithAuth, putWithAuth } from "../../http";
import { setCurrentModule } from "../modules/moduleSlice";
import { isSameRequestId, load } from "../../app/crud";

export interface Structure {
    id: AppId
    code: string
    name: string
    name_EN: string
    description: string
    description_EN: string
    parentStructureId: AppId | null
    subStructureIds: AppId[]
}

export const loadStructure = load<string, Structure>(
    "user_structure/load",
    moduleCode => `api/user-access/structures/${moduleCode}`,
    state => selectStructure(state).state,
)

export const loadAllStructure = load<void, Structure>(
    "structure/load",
    () => `api/sys/structure`,
    state => selectStructure(state).state,
)

export const createStructure = 
    createAsyncThunk<Structure, Omit<Structure, "id">, AppThunkAPIType>("structure/create", async (structure, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: "api/sys/Structure",
            payload: structure,
        }))
        const { payload } = result 
        if (postWithAuth.fulfilled.match(result)) {
            return payload as Structure
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const updateStructure = 
    createAsyncThunk<Structure, Structure, AppThunkAPIType>("structure/update", async (structure, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({
            url: `api/sys/Structure/${structure.id}`,
            payload: structure,
        }))
        if (putWithAuth.fulfilled.match(result)) {
            return structure 
        } else {
            const { payload } = result 
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const deleteStructure = 
    createAsyncThunk<AppId, AppId, AppThunkAPIType>("structure/delete", async (id, { dispatch, rejectWithValue }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/sys/Structure/${id}`}))
        if (deleteWithAuth.fulfilled.match(result)) {
            return id
        } else {
            const { payload } = result
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

const structureAdapter = createEntityAdapter<Structure>({
    selectId: node => node.id,
})

export type NoCommand = { kind: 'noCommand' }
export type AddRootStructureCommand = { kind: 'addRootStructure' }
export type AddStructureCommand = { kind: 'addStructure', parentId: AppId }
export type EditStructureCommand = { kind: 'editStructure', nodeId: AppId }
export type DeleteStructureCommand = { kind: 'deleteStructure', nodeId: AppId }

export type StructureCommand
    = NoCommand
    | AddRootStructureCommand
    | AddStructureCommand
    | EditStructureCommand
    | DeleteStructureCommand

export interface TreeState {
    selected: AppId | undefined
    multiselection: {
        [structureId: string]: boolean | "indeterminate"
    }
}

export type StructureState 
    = EntityState<Structure> 
    & { state: AppDataState } 
    & { command: StructureCommand }
    & { tree: TreeState }

const initialState: StructureState = structureAdapter.getInitialState({
    state: { type: "empty" },
    command: { kind: 'noCommand' },
    tree: {
        selected: undefined,
        multiselection: {},
    },
})

export const structureSlice = createSlice({
    name: "structure",
    initialState,
    reducers: {
        idle: (state) => {
            state.command = {
                kind: "noCommand",
            }
        },
        addRootStructure: (state) => {
            state.command = {
                kind: "addRootStructure",
            }
        },
        addStructure: (state, action: PayloadAction<AppId>) => {
            state.command = {
                kind: "addStructure",
                parentId: action.payload,
            }
        },
        editStructure: (state, action: PayloadAction<AppId>) => {
            state.command = {
                kind: "editStructure",
                nodeId: action.payload,
            }
        },
        delStructure: (state, action: PayloadAction<AppId>) => {
            state.command = {
                kind: "deleteStructure",
                nodeId: action.payload,
            }
        },
        setTreeSelectedNode: (state, action: PayloadAction<AppId>) => {
            state.tree.selected = action.payload
        },
        checkStructureNodes: (state, action: PayloadAction<AppId[]>) => {
            action.payload.forEach(element => {
                state.tree.multiselection[element] = true
            })
        },
        uncheckStructureNodes: (state, action: PayloadAction<AppId[]>) => {
            action.payload.forEach(element => {
                delete state.tree.multiselection[element]
            })
        },
        uncheckAllStructureNodes: (state, _: PayloadAction<void>) => {
            state.tree.multiselection = {}
        },
    },
    extraReducers: builder => {
        builder.addCase(createStructure.fulfilled, (state, action) => {
            const { parentStructureId, id } = action.payload
            structureAdapter.upsertOne(state, action.payload)
            if (parentStructureId) {
                const parent = structureAdapter.getSelectors().selectById(state, parentStructureId)
                if (parent) {
                    structureAdapter.updateOne(state, {
                        id: parentStructureId,
                        changes: {
                            subStructureIds: [...parent.subStructureIds, id]
                        }
                    })
                }
            }
        })
        builder.addCase(updateStructure.fulfilled, (state, action) => {
            structureAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(deleteStructure.fulfilled, (state, action) => {
            const { selectById } = structureAdapter.getSelectors()
            const structureToRemove = selectById(state, action.payload)
            if (structureToRemove) {
                const { parentStructureId } = structureToRemove
                if (parentStructureId) {
                    const parent = selectById(state, parentStructureId)
                    if (parent) {
                        structureAdapter.updateOne(state, {
                            id: parentStructureId,
                            changes: {
                                subStructureIds: parent.subStructureIds.filter(x => x !== structureToRemove.id),
                            },
                        })
                    }
                }
                structureAdapter.removeOne(state, action.payload)
            }
        })
        builder.addCase(setCurrentModule, (state, action) => {
            state.state = { type: "empty" }
            structureAdapter.removeAll(state)
        })
        builder.addMatcher(isAnyOf(loadStructure.pending, loadAllStructure.pending), (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addMatcher(isAnyOf(loadStructure.fulfilled, loadAllStructure.fulfilled), (state, action) => {
            if (isSameRequestId(state.state, action.meta.requestId)) {
                structureAdapter.setAll(state, action.payload)
                state.state = { type: "loaded" }
            }
        })
        builder.addMatcher(isAnyOf(loadStructure.rejected, loadAllStructure.rejected), (state, action) => {
            if (isSameRequestId(state.state, action.meta.requestId)) {
                state.state = { 
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
    },
})

export const selectStructure = (state: RootState): StructureState => state.structure

export const 
    { selectAll: selectAllStructureNodes
    , selectById: selectStructureNodeById
    } = structureAdapter.getSelectors(selectStructure)

export const
    { idle
    , addRootStructure
    , addStructure
    , editStructure
    , delStructure
    , setTreeSelectedNode
    , checkStructureNodes 
    , uncheckStructureNodes
    , uncheckAllStructureNodes
    } = structureSlice.actions

export default structureSlice.reducer
