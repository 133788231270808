import { useEffect, useState } from 'react';
import { Paper, TableContainer, ButtonGroup, Button } from '@mui/material';
import { DocumentHeader, loadDocumentVersions } from '../documentsApi';
import { AppId } from '../../../app/appTypes';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { LoadingContainer } from '../../../app/LoadingContainer';
import { ErrorContainer } from '../../../app/ErrorContainer';
import { unwrapResult } from '@reduxjs/toolkit';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { plPL, enUS } from '@mui/x-data-grid/locales'
import { selectSettings } from '../../settings/settingsSlice';
import { Localized, useLocalization } from '@fluent/react';
import { useNavigate } from 'react-router-dom'
import { If } from '../../../app/If';
import { getLink } from './DocumentLinkProvider';


export const DocumentVersionsPanel = ({ documentId }: { documentId: AppId }) => {
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);
    const [documentVersions, setDocumentVersions] = useState<DocumentHeader[]>([]);
    const { locale } = useAppSelector(selectSettings);
    const { l10n } = useLocalization()
    const navigate = useNavigate()

    useEffect(() => {
        setIsLoading(true)
        dispatch(loadDocumentVersions({ documentId: documentId }))
            .then(unwrapResult)
            .then(setDocumentVersions)
            .catch((error) => { console.error(error); setIsError(true); setError(error); })
            .finally(() => setIsLoading(false))
    }, [])


    const handleGoToClick = (type: string, id: string | number) => {
        debugger
        const link = getLink(type, id);
        navigate(link);
    };


    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const columns: GridColDef<DocumentHeader, any, any>[] = [
        {
            field: 'no',
            headerName: l10n.getString("document-number", null, "_number"),
            width: 300
        },
        {
            field: 'version',
            headerName: l10n.getString("document-version", null, "_version"),
            width: 300
        },

        {
            field: 'createdByFullName',
            headerName: l10n.getString("document-created-by", null, "_Autor"),
            width: 200
        },

        {
            field: 'status',
            headerName: l10n.getString("document-status", null, "_Status"),
            width: 200,
            valueGetter: (_, row) => {
                var status = row.status.toLowerCase();
                const description = l10n.getString("document-status-" + status, null, "");
                return description;
            }
        },
        {
            field: 'actions', type: 'actions', width: 150,
            renderCell: (params) => {
                return (
                    <If condition={params.id != documentId}
                        otherwise={
                            <span
                                className="ml-1 px-1 py-0.5 rounded bg-green-200 text-green-800 font-semibold text-sm relative"
                                style={{ top: '-5px', fontSize: '0.7rem' }}
                            >
                                <Localized id="current">
                                    <span>_current</span>
                                </Localized>
                            </span>}
                    >
                        <ButtonGroup size="small">
                            <Button
                                style={{ width: 75 }}
                                color="secondary"
                                variant="outlined"
                                onClick={() => handleGoToClick(params.row.type, params.row.id)}
                            >
                                <Localized id="go-to">
                                    <span>_goto</span>
                                </Localized>
                            </Button>
                        </ButtonGroup>
                    </If>
                )
            }
        }
    ]

    if (isError) {
        return <>
            <ErrorContainer error={error} />
        </>
    }

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }

    return <>
        <TableContainer component={Paper}>
            <DataGrid
                localeText={getCustomLocaleText()}
                style={{ minHeight: 100, maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
                rows={documentVersions}
                columns={columns}
                initialState={{
                    sorting: { sortModel: [{ field: 'version', sort: 'desc' }] }
                }}
            />
        </TableContainer>
    </>
};