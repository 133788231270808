import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { ButtonGroup, CircularProgress, debounce, IconButton, Menu, MenuItem, Dialog, DialogContent, DialogActions, Popper, Box, ClickAwayListener, Button, Alert, TextField, DialogTitle, DialogContentText } from "@mui/material"
import { unwrapResult } from "@reduxjs/toolkit"
import clsx from "clsx"
import { createContext, MutableRefObject, ReactNode, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { Link, Location, useLocation } from "react-router-dom"
import { APIError, AppId } from "../../app/appTypes"
import { useAppDispatch, useAppSelector, useQueryStructureId } from "../../app/hooks"
import { createConsequence, createDefect, createFunction, createReason, createRiskAnalysisGroup, deleteConsequence, deleteDefect, deleteFunction, deleteGroup, deleteReason, genConsequenceAI, genDefectAI, genReasonAI, RiskAnalysisConsequence, RiskAnalysisDefect, RiskAnalysisDocument, RiskAnalysisFunction, RiskAnalysisGroup, RiskAnalysisReason, updateConsequence, updateDefect, updateFunction, updateGroup, updateReason } from "../documents/riskAnalysis/riskAnalysisApi"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import './riskAnalysis.css'
import { findConsequence, findDefect, findFunction, findReason, isEditable } from "./utils"
import { DataSetterContext } from "../documents/riskAnalysis/RiskAnalysisForm"
import { JustText, useJustText } from "../../app/RichTextEditor"
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Editor } from "@tiptap/react"
import { CommentsSection } from "./CommentsSection"
import { DocumentActions } from "../documents/documentsApi"
import { DocComment } from "../documents/commentsAPI"
import { ValidationsBrowser } from "../validations/ValidationsBrowser"
import { selectValidationById } from "../validations/validationsSlice"
import { CreateValidationForm } from "../validations/CreateValidation"
import { EditValidationForm } from "../validations/EditValidation"
import { ServerErrorMsg } from "../../app/ServerErrorMsg"
import MoreVertIcon from '@mui/icons-material/MoreVert'

const headerBgColor = 'bg-gray-100'
const borderColor = 'border-gray-300'

const itemWrapperClass = "my-2 border-solid rounded-3xl border-2 border-gray-500 p-4"

const FnContext = createContext<AppId>('')

const DocIdContext = createContext<AppId>('')

interface Limit{
    good : number
    warning: number
}
const LimitContext = createContext<Limit>({good:0, warning:0})

type CommentsSettings
    = { tag: 'no' }
    | { tag: 'yes', canAdd: boolean }

const CommentsContext = createContext<CommentsSettings>({ tag: 'no' })

const testSettings: CommentsSettings = {tag:'yes',canAdd:true}

interface WithComments {
    comments: DocComment[]
}

interface Editable {
    editable : boolean
}

type Entity
    = { type: 'group', data: RiskAnalysisGroup }
    | { type: 'function', data: RiskAnalysisFunction }

type DialogState
    = { tag: 'none' }
    | { tag: 'assoc-test', fn: RiskAnalysisFunction }
    | { tag: 'create-test', fn: RiskAnalysisFunction }
    | { tag: 'edit-test', testId: AppId }
    | { tag: 'add-group', parentGroupId: AppId | undefined }
    | { tag: 'edit-group', group: RiskAnalysisGroup, parentGroupId: AppId | undefined }
    | { tag: 'delete', item: Entity, parentGroupId?: AppId }

const DialogContext = createContext<(st: DialogState) => void>(() => {})

const empty = new Set([])

interface RiskAnalysisContentProps {
    doc : RiskAnalysisDocument
    actions: DocumentActions
}
export const RiskAnalysisContent = (props : RiskAnalysisContentProps & WithComments) => {
    const { doc, actions, comments } = props
    const dispatch = useAppDispatch()
    const updateData = useContext(DataSetterContext)

    const [dialog, setDialog] = useState<DialogState>({ tag: 'none' })
    const [running, setRunning] = useState(false)
    const [testId, setTestId] = useState<AppId | undefined>(undefined)

    const selection: Set<AppId> = useMemo(() => testId ? new Set([testId]) : new Set(), [testId])
    
    const [goodThreshold, setGoodThreshold] = useState<number>(
        () => {
            const setting = doc?.settings.find(x => x.name === 'GOOD_THRESHOLD');
            return setting ? Number(setting.value) : 0;
        }
    );

    const [warningThreshold, setWarningThreshold] = useState<number>(
        () => {
            const setting = doc?.settings.find(x => x.name === 'WARNING_THRESHOLD');
            return setting ? Number(setting.value) : 0;
        }
    );

    const limit = useMemo( () => {
        return {good: goodThreshold, warning: warningThreshold}
    },[goodThreshold, warningThreshold ])

    const commentsCfg = useMemo(() => {
        if (actions.documentContentActions.findIndex(act => act === 'Comment') >= 0) {
            return {
                tag: 'yes',
                canAdd: true
            } as CommentsSettings
        } else {
            return {
                tag: 'no'
            } as CommentsSettings
        }
    }, [actions])

    const openDialog = useCallback((st: DialogState) => {
        setDialog(st)
    }, [])

    const handleAssocTestClick = async () => {
        if (testId && dialog.tag === 'assoc-test') {
            setRunning(true)
            const fn = dialog.fn
            const { defects, ...data } = fn
            try {
                const newFn = await dispatch(updateFunction({
                    ...data,
                    docId: doc.id,
                    testDefinitionId: testId,
                    isTestable: true,
                })).unwrap()
                updateData(draft => {
                    const someFn = findFunction(draft, fn.id)
                    if (someFn) {
                        someFn.testDefinitionId = newFn.testDefinitionId
                        someFn.isTestable = newFn.isTestable
                    }
                })
            } catch (error) {
                
            } finally {
                setRunning(false)
                setDialog({ tag: 'none' })
                setTestId(undefined)
            }
        }
    }

    const handleCloseDialogClick = () => {
        setDialog({ tag: 'none' })
        setTestId(undefined)
    }

    return <DocIdContext.Provider value={doc.id}>
            <LimitContext.Provider value={limit}>
                <CommentsContext.Provider value={commentsCfg}>
                    <DialogContext.Provider value={openDialog}>
                        <div className="flex flex-row gap-x-4">
                            <NavTree groups={doc.groups} />
                            <div className="basis-full">
                                <Content data={doc} comments={comments} actions={actions} />
                            </div>
                        </div>
                        {dialog.tag === 'create-test' && <CreateTestDialog fn={dialog.fn} />}
                        {dialog.tag === 'edit-test' && <EditTestDialog testId={dialog.testId} />}
                        {dialog.tag === 'add-group' && <CreateGroup parentGroupId={dialog.parentGroupId} />}
                        {dialog.tag === 'edit-group' && <EditGroup group={dialog.group} parentGroupId={dialog.parentGroupId} />}
                        {dialog.tag === 'delete' && <DeleteItem item={dialog.item} parentGroupId={dialog.parentGroupId} />}
                        <Dialog fullWidth maxWidth='lg' open={dialog.tag === 'assoc-test'}>
                            <DialogContent>
                                <ValidationsBrowser excluded={empty} selection={selection} itemChecked={id => setTestId(id)} itemUnchecked={id => setTestId(undefined)}></ValidationsBrowser> 
                            </DialogContent>
                            <DialogActions>
                                <LoadingButton loading={running} onClick={handleAssocTestClick}>
                                    <Localized id="ok"><span>OK</span></Localized>
                                </LoadingButton>
                                <LoadingButton loading={running} onClick={handleCloseDialogClick}>
                                    <Localized id="cancel"><span>Anuluj</span></Localized>
                                </LoadingButton>
                            </DialogActions>
                        </Dialog>
                    </DialogContext.Provider>
                </CommentsContext.Provider>
        </LimitContext.Provider>
    </DocIdContext.Provider>
}

interface ContentProps {
    data: RiskAnalysisDocument
    comments: DocComment[]
    actions: DocumentActions
}
function Content(props: ContentProps) {
    const { data, comments, actions } = props
    const { groups, } = data
    const { hash } = useLocation()
    const dialog = useContext(DialogContext)

    const groupRefs = useRef<Map<string, HTMLDivElement>>(new Map())
    const gridRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (hash && gridRef.current) {
            const someId = hash.substring(1)
            if (groupRefs.current.has(someId)) {
                const div = groupRefs.current.get(someId) as HTMLDivElement
                const rect = div.getBoundingClientRect()
                gridRef.current.scrollBy({
                    top: rect.top - 208,
                    behavior: 'smooth',
                })
            }
        }
    }, [hash])

    const handleAddGroupClick = useCallback(() => {
        dialog({
            tag: 'add-group',
            parentGroupId: undefined,
        })
    }, [])

    const editable = isEditable(actions)

    const addBtn = editable ? <Button variant='outlined' onClick={handleAddGroupClick}>Add new group</Button> : null

    if (groups.length === 0) {
        return <Box sx={{
            display: 'flex',
            gap: 2,
        }}>
            {addBtn }
            <Alert sx={{ minWidth: '50%' }} variant="outlined" color="warning">Dokument jest pusty.</Alert>
        </Box>
    } else {
        return  <>
            <div ref={gridRef} id='risk-analysis-grid' className="border-solid border-gray-300 border">
                {groups.map((item) => <Group key={item.guid} editable={editable} groupRefs={groupRefs} gr={item} comments={comments} />)}
            </div>
            <Box sx={{ mt: 2 }}>
                {addBtn}
            </Box>
        </>
    }
}


interface GroupProps {
    gr : RiskAnalysisGroup
    groupRefs : MutableRefObject<Map<string, HTMLDivElement>>
}

const Group = (props : GroupProps & WithComments & Editable) => {
    const { gr, groupRefs, comments, editable } = props
    const dialog = useContext(DialogContext)
    const ref = useRef<HTMLDivElement | null>(null)

    useLayoutEffect(() => {
        if (ref.current) {
            groupRefs.current.set(gr.id, ref.current)
        }
    }, [ref, gr])

    const handleAddSubgroup = () => {
        dialog({ tag: 'add-group', parentGroupId: gr.id })
    }

    return <>
        <div
            ref={ref}
            id={gr.id}
            className={`border-b ${borderColor} border-solid border-x-0 border-t-0 col-span-2 px-2 py-2 text-lg font-bold ${headerBgColor} flex`}
        >
            <div className='grow'>
                {`${gr.numeration}. ${gr.name}`}
            </div>
            {editable && <Button size='small' variant='outlined' onClick={handleAddSubgroup}>+ Dodaj podgrupę</Button>}
            {editable && <GroupMenu group={gr} />}
        </div>
        {gr.functions.map((item) => <Function key={item.id} editable={editable} fn={item} comments={comments} />)}
        {gr.groups.map((item) => <SubGroup groupRefs={groupRefs} key={item.guid} editable={editable} gr={item} comments={comments} parentGroupId={gr.id} />)}
    </>
}

const SubGroup = (props : GroupProps & WithComments & Editable & { parentGroupId: string }) => {
    const dispatch = useAppDispatch()
    const { gr, groupRefs, comments, editable, parentGroupId } = props
    const docId = useContext(DocIdContext)
    const setter = useContext(DataSetterContext)
    const ref = useRef<HTMLDivElement | null>(null)

    const [saving, setSaving] = useState(false)

    useLayoutEffect(() => {
        if (ref.current) {
            groupRefs.current.set(gr.id, ref.current)
        }
    }, [ref, gr])

    const handleCreateFunctionClick = () => {
        setSaving(true)
        dispatch(createFunction({
            documentId: docId,
            name: '',
            criticality: '',
            isTestable: false,
            currentControlMethod: '',
            testDefinitionId: null,
            parentSectionGuid: gr.id,
        })).then(unwrapResult)
            .then(newFn => {
                setter(draft => {
                    const grIdx = draft.groups.findIndex(g => g.id === parentGroupId)
                    if (grIdx >= 0) {
                        const idx = draft.groups[grIdx].groups.findIndex(s => s.id === gr.id)
                        if (idx >= 0) {
                            draft.groups[grIdx].groups[idx].functions.push(newFn)
                        }
                    }
                })
            })
            .finally(() => setSaving(false))
    }

    return <>
        <div
            ref={ref}
            id={gr.guid}
            className={`border-b ${borderColor} border-solid border-x-0 border-t-0 col-span-2 px-2 py-2 font-bold ${headerBgColor} flex`}
        >
            <div className="grow">
                {`${gr.numeration}. ${gr.name}`}
            </div>
            {editable && <GroupMenu group={gr} parentGroupId={parentGroupId} />}
        </div>
        {gr.functions.map((item) => <Function key={item.id} editable={editable} fn={item} comments={comments} />)}
        {editable && <Box sx={{
            my: 2,
            mx: 2,
        }}>
            <LoadingButton onClick={handleCreateFunctionClick} loading={saving} size='small' variant='outlined'>+ Dodaj funkcję</LoadingButton>
        </Box>}
    </>
}

function GroupMenu(props: { group: RiskAnalysisGroup, parentGroupId?: AppId }) {
    const { group, parentGroupId } = props
    const dialog = useContext(DialogContext)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return <>
        <IconButton onClick={e => { e.stopPropagation(); handleClick(e); }} sx={{ p: 0, mx: 1 }}><MoreVertIcon fontSize='small' /></IconButton>
        <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
            paper: {
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
        <MenuItem onClick={e => {
                e.stopPropagation();
                setAnchorEl(null);
                dialog({ tag: 'edit-group', group, parentGroupId });
            } }>Edit</MenuItem>
        <MenuItem onClick={e => {
                e.stopPropagation();
                setAnchorEl(null);
                dialog({ tag: 'delete', item: { type: 'group', data: group }, parentGroupId });
            } }>Delete</MenuItem>
    </Menu>
    </>
}

interface FunctionProps {
    fn : RiskAnalysisFunction
}
const Function = (props : FunctionProps & WithComments & Editable) => {
    const { fn, comments, editable } = props
    const dispatch = useAppDispatch()
    const setter = useContext(DataSetterContext)
    const docId = useContext(DocIdContext)
    const dialog = useContext(DialogContext)

    const handleChange = () => {
        save(fnName, ccm, fn)
    }

    const fnName = useJustText({
        defaultValue: fn.name,
        onChange: handleChange,
        editable,
    })

    const ccm = useJustText({
        defaultValue: fn.currentControlMethod,
        onChange: handleChange,
        editable,
    })

    const save = useCallback(debounce((fnName: Editor | null, ccmEditor: Editor | null, fn: RiskAnalysisFunction) => {
        const ccm = ccmEditor?.getHTML() ?? ''
        const name = fnName?.getHTML() ?? ''
        const { defects, ...data } = fn
        dispatch(updateFunction({
            ...data,
            name,
            currentControlMethod: ccm,
            docId,
        }))
            .then(unwrapResult)
            .then(() => {
                dispatch(showSuccess('saved'))
                setter(draft => {
                    const someFn = findFunction(draft, fn.id)
                    if (someFn) {
                        someFn.currentControlMethod = ccm
                        someFn.name = name
                    }
                })
            })
            .catch(() => dispatch(showError('error')))
    }, 400), [])

    const handleDeleteClick = () => {
        dialog({ tag: 'delete', item: { type: 'function', data: fn } })
        setContextMenu(null)
    }

    // Context menu
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null)

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault()
        if (editable) {
            setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY - 6,
                    }
                    : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                    // Other native context menus might behave different.
                    // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                    null,
            )
        }
    }

    const handleClose = () => {
        setContextMenu(null)
    }
    // Context menu

    return <>
        <div className="flex flex-row">
            <div className="ra-basis-20 border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 px-2 py-1 text-xs font-bold flex justify-center items-center">Funkcja</div>
            <div className="ra-basis-65 ra-fn-details">
                <Header />
            </div>
            <div className="ra-basis-15 border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 px-2 py-1 text-xs font-bold flex justify-center items-center">Test</div>
        </div>
        <Spacer />
        <div className="flex flex-row items-start">
            <div onContextMenu={handleContextMenu} className={clsx("ra-basis-20 my-2 border-solid rounded-3xl border-2 border-gray-500 p-4 cursor-context-menu")}>
                <div className='flex space-x-2'>
                    <div className='text-xs bg-gray-300 py-1 px-2 grow-0 rounded-lg text-sm'>
                        {fn.numeration }
                    </div>
                </div>
                <div className="text-xs overflow-y-auto">
                    <JustText editor={fnName} />
                </div>
            </div>
            <div className="ra-basis-65 ra-fn-details">
                <FnContext.Provider value={fn.id}>
                    {fn.defects.length > 0 ? fn.defects.map((item, idx) => <>
                        <Defect key={item.id} editable={editable} defect={item} idx={idx} total={fn.defects.length} comments={comments} />
                        </>) : <NoDefect editable={editable} fnId={fn.id} />}
                </FnContext.Provider>
            </div>
            <div className="ra-basis-15 flex-column">
                <TestSection fn={fn} editable={editable} />
            </div>
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined }
            >
                <MenuItem disabled={false} onClick={handleDeleteClick}><Localized id='delete'>Usuń</Localized></MenuItem>
            </Menu>
        </div>
        <HLine />
    </>
}

const NoDefect = ({ fnId, editable } : { fnId : AppId } & Editable) => {
    const dispatch = useAppDispatch()
    const setter = useContext(DataSetterContext)
    const docId = useContext(DocIdContext)

    const [saving, setSaving] = useState(false)

    const create = async () => {
        setSaving(true)
        try {
            const newDefect = await dispatch(createDefect({
                docId,
                name: '',
                functionId: fnId,
                value: 0,
            })).unwrap()
            const newConsequence = await dispatch(createConsequence({
                docId,
                name: '',
                defectId: newDefect.id,
                value: 0
            })).unwrap()
            newDefect.consequences.push(newConsequence)
            const newReason = await dispatch(createReason({
                docId,
                name: '',
                consequenceId: newConsequence.id,
                value: 0,
                currentControlMethod: ''
            })).unwrap()
            newConsequence.reasons = [newReason]
            setter(draft => {
                const someFn = findFunction(draft, fnId)
                if (someFn) {
                    someFn.defects.push(newDefect)
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        } finally {
            setSaving(false)
        }
    }
    const genAI = async () => {
        setSaving(true)
        try {
            const defects = await dispatch(genDefectAI({ docId, fnId })).unwrap()
            setter(draft => {
                const someFn = findFunction(draft, fnId)
                if (someFn) {
                    for (let i = 0; i < defects.length; i++) {
                        someFn.defects.push(defects[i])
                    }
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        } finally {
            setSaving(false)
        }
    }

    return <>
        <div></div>
        <div className="place-self-center">
            <ButtonGroup>
                <LoadingButton disabled={!editable} loading={saving} variant='outlined' size='small' onClick={create}>+</LoadingButton>
                <LoadingButton disabled={!editable} loading={saving} variant='outlined' size='small' onClick={genAI}>AI</LoadingButton>
            </ButtonGroup>
        </div>
        <div className="col-span-6"></div>
    </>
}

const NoConsequence = ({ defectId, fnId }: { defectId: AppId, fnId: AppId }) => {
    const dispatch = useAppDispatch()
    const setter = useContext(DataSetterContext)
    const docId = useContext(DocIdContext)

    const [saving, setSaving] = useState(false)

    const create = async () => {
        setSaving(true)
        try {
            const newConsequence = await dispatch(createConsequence({
                docId,
                name: '',
                defectId,
                value: 0,
            })).unwrap()
            const newReason = await dispatch(createReason({
                docId,
                name: '',
                consequenceId: newConsequence.id,
                value: 0,
                currentControlMethod: '',
            })).unwrap()
            newConsequence.reasons = [newReason]
            setter(draft => {
                const someFn = findFunction(draft, fnId)
                if (someFn) {
                    const someDefectIdx = someFn.defects.findIndex(d => d.id === defectId)
                    if (someDefectIdx >= 0) {
                        someFn.defects[someDefectIdx].consequences.push(newConsequence)
                    }
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        } finally {
            setSaving(false)
        }
    }

    return <>
        <div></div>
        <div className="place-self-center">
            <LoadingButton loading={saving} variant='outlined' size='small' onClick={create}>+</LoadingButton>
        </div>
        <div className="col-span-4"></div>
    </>
}

interface DefectProps {
    defect : RiskAnalysisDefect
    idx : number
    total : number
}
const Defect = (props: DefectProps & WithComments & Editable) => {
    const { defect, idx, total, comments, editable } = props
    const dispatch = useAppDispatch()
    const setter = useContext(DataSetterContext)
    const functionId = useContext(FnContext)
    const docId = useContext(DocIdContext)
    const commentsSetting = useContext(CommentsContext)
    const showComments = commentsSetting.tag === 'yes'

    const [defaultName] = useState(defect.name)
    const [generated, setGenerated] = useState(defect.generated)

    const [generating, setGenerating] = useState(false)

    const save = useCallback(debounce((nameEditor: Editor | null) => {
        const name = nameEditor?.getHTML() ?? ''
        dispatch(updateDefect({ docId, id: defect.id, functionId, name }))
            .then(unwrapResult)
            .then(() => {
                dispatch(showSuccess('saved'))
                setter(draft => {
                    const fn = findFunction(draft, functionId)
                    if (fn) {
                        const someDefect = findDefect(fn, defect.id)
                        if (someDefect) {
                            someDefect.name = name
                        }
                    }
                })
            })
            .catch(() => dispatch(showError('error')))
    }, 400), [])

    const handleDefectChange = () => {
        save(name)
        setGenerated(false)
    }

    const name = useJustText({
        defaultValue: defaultName,
        onChange: handleDefectChange,
        editable,
    })

    // Context menu
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null)

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault()
        if (editable) {
            setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY - 6,
                    }
                    : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                    // Other native context menus might behave different.
                    // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                    null,
            )
        }
    }

    const handleClose = () => {
        setContextMenu(null)
    }
    // Context menu

    const handleAddNextDefect = async () => {
        setContextMenu(null)
        try {
            const newDefect = await dispatch(createDefect({
                docId,
                name: '',
                functionId,
                value: 0,
            })).unwrap()
            const newConsequence = await dispatch(createConsequence({
                docId,
                name: '',
                defectId: newDefect.id,
                value: 0,
            })).unwrap()
            newDefect.consequences.push(newConsequence)
            const newReason = await dispatch(createReason({
                docId,
                name: '',
                consequenceId: newConsequence.id,
                value: 0,
                currentControlMethod: '',

            })).unwrap()
            newConsequence.reasons = [newReason]
            setter(draft => {
                const someFn = findFunction(draft, functionId)
                if (someFn) {
                    someFn.defects.push(newDefect)
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        } finally { }
    }

    const handleGenConsequenceAI = async () => {
        setContextMenu(null)
        setGenerating(true)
        try {
            const newConsequences = await dispatch(genConsequenceAI({
                docId,
                defectId: defect.id,
            })).unwrap()
            setter(draft => {
                const someFn = findFunction(draft, functionId)
                if (someFn) {
                    const someDefectIdx = someFn.defects.findIndex(d => d.id === defect.id)
                    if (someDefectIdx >= 0) {
                        someFn.defects[someDefectIdx].consequences = newConsequences
                    }
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        } finally {
            setGenerating(false)
        }
    }

    const handleDelete = async () => {
        setContextMenu(null)
        try {
            await dispatch(deleteDefect({ docId, defectId: defect.id }))
            setter(draft => {
                const someFn = findFunction(draft, functionId)
                if (someFn) {
                    const i = someFn.defects.findIndex(d => d.id === defect.id)
                    if (i >= 0) {
                        someFn.defects.splice(i, 1)
                    }
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        }
    }

    const content = <div onContextMenu={handleContextMenu} className={clsx(itemWrapperClass, 'cursor-context-menu relative')}>
            <div className="text-xs">
                <JustText editor={name} />
            </div>
            {generated && <span className="ra-ai-badge">AI</span>}
            {showComments && <CommentsSection comments={comments} documentId={docId} sectionGuid={defect.guid} />}
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined }
            >
                <MenuItem disabled={false} onClick={handleDelete}><Localized id='delete'>Usuń</Localized></MenuItem>
                <MenuItem onClick={handleAddNextDefect}><Localized id='risk-analysis-add-defect'>Dodaj kolejną wadę</Localized></MenuItem>
                <MenuItem onClick={handleGenConsequenceAI}><Localized id='risk-analysis-ai-add-defect'>Zapytaj o skutki AI</Localized></MenuItem>
            </Menu>
        </div>
    const consequences = defect.consequences.length > 0
        ? defect.consequences.map((item, i) => 
            <Consequence
                key={item.id}
                editable={editable}
                consequence={item}
                defectId={defect.id}
                idx={i}
                total={defect.consequences.length}
                isLastDefect={idx === total - 1}
                generatingConsequence={generating}
                comments={comments}
            />)
        : <NoConsequence defectId={defect.id} fnId={functionId} />

    switch (idx) {
        case 0:
            return <>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    {total > 1 ? <TLine /> : <Line />}
                </div>
                {content}
                {consequences}
            </>
        case total - 1:
            return <>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    <LLine />
                </div>
                {content}
                {consequences}
            </>
        default:
            return <>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    <LeftTLine />
                </div>
                {content}
                {consequences}
            </>
    }
}

interface ConsequenceProps {
    consequence : RiskAnalysisConsequence
    defectId: AppId
    idx : number
    total : number
    isLastDefect : boolean
    generatingConsequence: boolean
}
const Consequence = (props: ConsequenceProps & WithComments & Editable) => {
    const { consequence, defectId, idx, total, isLastDefect, generatingConsequence, comments, editable } = props
    const dispatch = useAppDispatch()
    const setter = useContext(DataSetterContext)
    const fnId = useContext(FnContext)
    const docId = useContext(DocIdContext)
    const commentsSetting = useContext(CommentsContext)
    const showComments = commentsSetting.tag === 'yes'

    const [defaultName] = useState(consequence.name)
    const [severity, setSeverity] = useState<number>(consequence.severity)
    const [generated, setGenerated] = useState(consequence.generated)

    const [generatingReason, setGeneratingReason] = useState(false)

    const save = useCallback(
      debounce((nameEditor: Editor | null, s: number) => {
        const name = nameEditor?.getHTML() ?? ''
        dispatch(updateConsequence({ docId, id: consequence.id, defectId, severity: s, name }))
            .then(unwrapResult)
            .then(() => {
                dispatch(showSuccess('saved'))
                setter(draft => {
                    const fn = findFunction(draft, fnId)
                    if (fn) {
                        const someConsequence = findConsequence(fn, consequence.id)
                        if (someConsequence) {
                            someConsequence.name = name
                            someConsequence.severity = s
                        }
                    }
                })
            })
            .catch(() => dispatch(showError('error')))
        }, 400), [])

    const handleConsequenceChange = () => {
        save(name, severity)
        setGenerated(false)
    }
    const handleSeverityChange = (newVal: number) => {
        setSeverity(newVal)
        save(name, newVal)
    }

    const name = useJustText({ defaultValue: defaultName, onChange: handleConsequenceChange, editable })

    // Context menu
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null)

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault()
        if (editable) {
            setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY - 6,
                    }
                    : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                    // Other native context menus might behave different.
                    // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                    null,
            )
        }
    }

    const handleClose = () => {
        setContextMenu(null)
    }
    // Context menu

    const handleAddNextConsequence = async () => {
        setContextMenu(null)
        try {
            const newConsequence = await dispatch(createConsequence({
                docId,
                name: '',
                defectId,
                value: 0,
            })).unwrap()
            const newReason = await dispatch(createReason({
                docId,
                name: '',
                consequenceId: newConsequence.id,
                value: 0,
                currentControlMethod: '',
            })).unwrap()
            newConsequence.reasons = [newReason]
            setter(draft => {
                const someFn = findFunction(draft, fnId)
                if (someFn) {
                    const someDefectIdx = someFn.defects.findIndex(d => d.id === defectId)
                    if (someDefectIdx >= 0) {
                        someFn.defects[someDefectIdx].consequences.push(newConsequence)
                    }
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        } finally { }
    }

    const handleGenReasonsAI = async () => {
        setContextMenu(null)
        setGeneratingReason(true)
        try {
            const newReasons = await dispatch(genReasonAI({
                docId,
                consequenceId: consequence.id,
            })).unwrap()
            setter(draft => {
                const someFn = findFunction(draft, fnId)
                if (someFn) {
                    const someConsequence = findConsequence(someFn, consequence.id)
                    if (someConsequence) {
                        someConsequence.reasons = newReasons
                    }
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        } finally {
            setGeneratingReason(false)
        }
    }

    const handleDelete = async () => {
        setContextMenu(null)
        try {
            await dispatch(deleteConsequence({ docId, id: consequence.id }))
            setter(draft => {
                const someFn = findFunction(draft, fnId)
                if (someFn) {
                    const someDefect = findDefect(someFn, defectId)
                    if (someDefect) {
                        const i = someDefect.consequences.findIndex(d => d.id === consequence.id)
                        if (i >= 0) {
                            someDefect.consequences.splice(i, 1)
                        }
                    }
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        }
    }

    const Severity = <Num editable={editable} value={severity} onChange={handleSeverityChange}/>

    const content = <div onContextMenu={handleContextMenu} className={clsx(itemWrapperClass, 'cursor-context-menu relative ')}>
            <div className="text-xs">
                <JustText editor={name} />
            </div>
            {Severity}
            {generated && <span className="ra-ai-badge">AI</span>}
            {showComments && <CommentsSection comments={comments} documentId={docId} sectionGuid={consequence.guid} />}
            {generatingConsequence && <CircularProgress sx={{
                position: 'absolute',
                top: 'calc(50% - 20px)',
                left: 'calc(50% - 20px)',
            }} />}
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined }
            >
                <MenuItem disabled={idx === 0} onClick={handleDelete}><Localized id='delete'>Usuń</Localized></MenuItem>
                <MenuItem onClick={handleAddNextConsequence}><Localized id=''>Dodaj kolejny skutek</Localized></MenuItem>
                <MenuItem onClick={handleGenReasonsAI}><Localized id='risk-analysis-ai-add-defect'>Zapytaj o przyczyny AI</Localized></MenuItem>
            </Menu>
        </div>
    const reasons = consequence.reasons.map((item, i) => 
        <Reason
            key={item.id}
            editable={editable}
            reason={item}
            consequenceId={consequence.id}
            idx={i}
            total={consequence.reasons.length}
            isLastDefect={isLastDefect}
            isLastReason={idx === total - 1}
            severity={severity}
            generating={generatingConsequence || generatingReason}
            comments={comments}
        />
    )

    switch (idx) {
        case 0:
            return <>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    {total > 1 ? <TLine /> : <Line />}
                </div>
                {content}
                {reasons}
            </>
        case total - 1:
            return <>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    {isLastDefect ? null : <VLine />}
                </div>
                <div className=""></div>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    <LLine />
                </div>
                {content}
                {reasons}
            </>
        default:
            return <>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    {isLastDefect ? null : <VLine />}
                </div>
                <div className=""></div>
                <div className=" grid grid-cols-2 gap-0 h-full">
                        <LeftTLine />
                </div>
                {content}
                {reasons}
            </>
    }
}

interface ReasonProps {
    reason: RiskAnalysisReason
    consequenceId: AppId
    idx: number
    total : number
    isLastDefect : boolean
    isLastReason : boolean
    severity : number
    generating : boolean
}
const Reason = (props: ReasonProps & WithComments & Editable) => {
    const { reason, consequenceId, idx, total, isLastDefect, isLastReason, severity, generating, comments, editable } = props
    const dispatch = useAppDispatch()
    const setter = useContext(DataSetterContext)
    const fnId = useContext(FnContext)
    const docId = useContext(DocIdContext)
    const commentsSetting = useContext(CommentsContext)
    const showComments = commentsSetting.tag === 'yes'

    const [defaultName] = useState(reason.name)
    const [occurence, setOccurence] = useState<number>(reason.occurrenceProbability)
    const [detection, setDetection] = useState<number>(reason.detectionProbability)
    const [generated, setGenerated] = useState(reason.generated)

    const handleCcmChange = () => {
        save(name, ccm, occurence, detection)
    }

    const ccm = useJustText({
        defaultValue: reason.currentControlMethod,
        onChange: handleCcmChange,
        editable,
    })

    const save = useCallback(debounce((nameEditor: Editor | null, ccmEditor: Editor | null, occurence: number, detection: number) => {
        const name = nameEditor?.getHTML() ?? ''
        const currentControlMethod = ccmEditor?.getHTML() ?? ''
        dispatch(updateReason({
            docId,
            id: reason.id,
            consequenceId,
            detectionProbability: detection,
            occurrenceProbability: occurence,
            name,
            currentControlMethod,
        }))
            .then(unwrapResult)
            .then(() => {
                dispatch(showSuccess('saved'))
                setter(draft => {
                    const fn = findFunction(draft, fnId)
                    if (fn) {
                        const someR = findReason(fn, reason.id)
                        if (someR) {
                            someR.currentControlMethod = currentControlMethod
                            someR.name = name
                            someR.detectionProbability = detection
                            someR.occurrenceProbability = occurence
                        }
                    }
                })
            })
            .catch(() => dispatch(showError('error')))
    }, 400), [])

    const handleReasonChange = () => {
        save(name, ccm, occurence, detection)
        setGenerated(false)
    }
    const handleOccurenceChange = (newVal: number) => {
        setOccurence(newVal)
        save(name, ccm, newVal, detection)
    }
    const handleDetectionChange = (newVal: number) => {
        setDetection(newVal)
        save(name, ccm, occurence, newVal)
    }

    const name = useJustText({ defaultValue: defaultName, onChange: handleReasonChange, editable })

    // Context menu
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null)

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault()
        if (editable) {
            setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY - 6,
                    }
                    : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                    // Other native context menus might behave different.
                    // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                    null,
            )
        }
    }

    const handleClose = () => {
        setContextMenu(null)
    }
    // Context menu
    
    const handleAddNextReason  = async () => {
        setContextMenu(null)
        try {
            const newReason = await dispatch(createReason({
                docId,
                name: '',
                consequenceId,
                value: 0,
                currentControlMethod: ''
            })).unwrap()
            setter(draft => {
                const someFn = findFunction(draft, fnId)
                if (someFn) {
                    const someConsequence = findConsequence(someFn, consequenceId)
                    if (someConsequence) {
                        someConsequence.reasons.push(newReason)
                    }
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        }
    }

    const handleDelete = async () => {
        setContextMenu(null)
        try {
            await dispatch(deleteReason({ docId, id: reason.id }))
            setter(draft => {
                const someFn = findFunction(draft, fnId)
                if (someFn) {
                    const someConsequence = findConsequence(someFn, consequenceId)
                    if (someConsequence) {
                        const i = someConsequence.reasons.findIndex(c => c.id === reason.id)
                        if (i >= 0) {
                            someConsequence.reasons.splice(i, 1)
                        }
                    }
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        }
    }

    const content = <div onContextMenu={handleContextMenu} className={clsx(itemWrapperClass, 'cursor-context-menu relative ')}>
            <div className="text-xs">
                <JustText editor={name} />
            </div>
            {generated && <span className="ra-ai-badge">AI</span>}
            {showComments && <CommentsSection comments={comments} documentId={docId} sectionGuid={reason.guid} />}
            {generating && <CircularProgress sx={{
                position: 'absolute',
                top: 'calc(50% - 20px)',
                left: 'calc(50% - 20px)',
            }} />}
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined }
            >
                <MenuItem disabled={idx === 0} onClick={handleDelete}><Localized id='delete'>Usuń</Localized></MenuItem>
                <MenuItem onClick={handleAddNextReason}><Localized id=''>Dodaj kolejna przyczyne</Localized></MenuItem>
            </Menu>
        </div>

    const occurenceCss = ' w-7 cursor-pointer border border-solid rounded border-gray-200 shadow px-2'
    const Occurence = <Num editable={editable} value={occurence} onChange={handleOccurenceChange} css={occurenceCss} />

    const detectionCss = ' w-7 cursor-pointer border border-solid rounded border-gray-200 shadow px-2'
    const Detection = <Num editable={editable} value={detection} onChange={handleDetectionChange} css={detectionCss} />

    // const Severity = <Num editable={editable} value={detection} onChange={handleSeverityChange} />
    const p = <P f={occurence} d={detection} s={severity} />

    switch (idx) {
        case 0:
            return <>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    {total > 1 ? <TLine /> : <Line />}
                </div>
                {content}
                <div className="flex items-center justify-center">
                    {Occurence}
                </div>
                <div className={itemWrapperClass}>
                    <div className="text-xs">
                        <JustText editor={ccm} />
                    </div>
                </div>
                <div className="flex items-center justify-center">
                    {Detection}
                </div>
                {p}
                {/* {comment} */}
            </>
        case total - 1:
            return <>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    {isLastDefect ? null : <VLine />}
                </div>
                <div className=""></div>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    {isLastReason ? null : <VLine />}
                </div>
                <div className=""></div>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    <LLine />
                </div>
                {content}
                <div className="flex items-center justify-center">
                    {Occurence}
                </div>
                <div className={itemWrapperClass}>
                    <div className="text-xs">
                        <JustText editor={ccm} />
                    </div>
                </div>
                <div className="flex items-center justify-center">
                    {Detection}
                </div>
                {p}
                {/* {comment} */}
            </>
        default:
            return <>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    {isLastDefect ? null : <VLine />}
                </div>
                <div className=""></div>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    {isLastReason ? null : <VLine />}
                </div>
                <div className=""></div>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    <LeftTLine />
                </div>
                {content}
                <div className="flex items-center justify-center">
                    {Occurence}
                </div>
                <div className={itemWrapperClass}>
                    <div className="text-xs">
                        <JustText editor={ccm} />
                    </div>
                </div>
                <div className="flex items-center justify-center">
                    {Detection}
                </div>
                {p}
                {/* {comment} */}
            </>
    }
}

const Header = () => {
    return <>
        <div className=" border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 text-xs font-bold flex justify-end items-center"></div>
        <div className=" border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 px-2 py-1 text-xs font-bold flex justify-center items-center">Potencjalna wada</div>
        <div className=" border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 px-2 text-xs font-bold flex justify-end items-center"></div>
        <div className=" border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 px-2 text-xs font-bold flex justify-center items-center">Skutek</div>
        <div className=" border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 text-xs font-bold flex justify-start items-center">Dotkliwość</div>
        <div className=" border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 px-2 text-xs font-bold flex justify-center items-center">Przyczyna</div>
        <div className=" border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 px-2 text-xs font-bold flex justify-center items-center">P wyst.</div>
        <div className=" border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 px-2 text-xs font-bold flex justify-center items-center">Ob. śr. kontroli</div>
        <div className=" border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 px-2 text-xs font-bold flex justify-center items-center">P wykr.</div>
        <div className=" border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 px-2 text-xs font-bold flex justify-center items-center">P</div>
    </>
}

interface LineProps {
    label? : ReactNode
}

const Line = (props: LineProps) => {
    return <>
        <div className="border-gray-500 border-b-2 border-x-0 border-t-0 border-dashed"></div>
        <div className="border-gray-500 border-b-2 border-x-0 border-t-0 border-dashed relative">
            {props.label}
        </div>
        <div></div>
        <div></div>
    </>
}

const LLine = (props: LineProps) => {
    return <>
        <div className="border-gray-500 border-r-2 border-y-0 border-l-0 border-dashed"></div>
        <div className="relative">
            {props.label}
        </div>
        <div></div>
        <div className="border-gray-500 border-t-2 border-x-0 border-b-0 border-dashed"></div>
    </>
}

const TLine = (props: LineProps) => {
    return <>
        <div className="border-gray-500 border-b-2 border-x-0 border-t-0 border-dashed"></div>
        <div className="border-gray-500 border-b-2 border-x-0 border-t-0 border-dashed relative">
            {props.label}
        </div>
        <div className="border-gray-500 border-r-2 border-l-0 border-dashed border-y-0"></div>
        <div></div>
    </>
}

const LeftTLine = (props: LineProps) => {
    return <>
        <div className="border-gray-500 border-r-2 border-y-0 border-l-0 border-dashed"></div>
        <div className="relative">
            {props.label}
        </div>
        <div className="border-gray-500 border-dashed border-y-0 border-r-2 border-l-0"></div>
        <div className="border-gray-500 border-t-2 border-x-0 border-b-0 border-dashed"></div>
    </>
}

const VLine = () => {
    return <>
        <div className="border-gray-500 border-r-2 border-y-0 border-l-0 border-dashed"></div>
        <div className=""></div>
        <div className="border-gray-500 border-r-2 border-l-0 border-dashed border-y-0"></div>
        <div></div>
    </>
}

const HLine = () => {
    return <div className="col-span-2 h-4 border-gray-300 border-solid border-x-0 border-t-0 border-b"></div>
}

const Spacer = () => {
    return <div className="h-4"></div>
}

interface PProps {
    f : number
    d : number
    s : number
}
const P = (props: PProps) => {
    const { f, d, s} = props
    const {good, warning } = useContext(LimitContext)
    const value = f * d * s
    const cls = clsx(' place-self-center', 
        value <= good && 'text-green-600 font-bold bg-green-100 py-1 px-2 rounded',
        value <= warning && value > good && 'text-orange-600 font-bold bg-orange-100 py-1 px-2 rounded',
        value > warning && 'text-red-600 font-bold bg-red-100 py-1 px-2 rounded',
    )
    return <div className={cls}>{value}</div>
}

interface NavTreeProps {
    groups: RiskAnalysisGroup[]
}
const NavTree = (props: NavTreeProps) => {
    const { groups } = props
    const location = useLocation()

    const [collapsed, setCollapsed] = useState(true)

    const width = collapsed ? 'basis-8' : 'basis-96'

    return <div className={clsx(width)}>
        <nav className=" border-solid border-gray-300 border">
            <div className="border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 py-1 px-2 text-xs font-bold flex justify-start items-center relative">
                <span className={clsx(collapsed && 'ra-groups-collapsed my-8')}>Grupy</span>
                {collapsed === false && <IconButton sx={{
                        height: '16px',
                        width: '16px',
                        position: 'absolute',
                        right: 'calc(0% + 4px)',
                    }}
                    onClick={() => setCollapsed(true)}
                ><ChevronLeftIcon /></IconButton>}
                {collapsed === true && <IconButton sx={{
                        height: '16px',
                        width: '16px',
                        position: 'absolute',
                        right: 'calc(0% + 8px)',
                        top: 'calc(0% + 4px)',
                    }}
                    onClick={() => setCollapsed(false)}
                ><ChevronRightIcon /></IconButton>}
            </div>
            {collapsed === false && <ol className="list-none pl-4">
                {groups.map((item) => <>
                    <li>
                        <DocLink to={{...location, hash: item.id}} >{`${item.numeration}. ${item.name}`}</DocLink>
                    </li>
                    <li>
                        <ol className="list-none pl-4">
                            {item.groups.map((subItem) => <>
                                <li>
                                    <DocLink to={{...location, hash: subItem.id}} >{`${subItem.numeration}. ${subItem.name}`}</DocLink>
                                </li>
                            </>)}
                        </ol>
                    </li>
                </>)}
            </ol>}
        </nav>
    </div>
}

interface DocLinkProps {
    to: Location<any>
    children : ReactNode
}
const DocLink = ({ children, to }: DocLinkProps) => {
    return <Link className="no-underline text-black" replace={true} to={to}>{children}</Link>
}

interface NumProps {
    value: number
    onChange : (newVal: number) => void
    css? : string
}
const Num = (props: NumProps & Editable) => {
    const { editable } = props
    const { value, onChange } = props
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLInputElement>(null)

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget)
        setOpen(st => !st)
    }
    const css = props.css ?? 'absolute ra-severity w-6 cursor-pointer border border-solid rounded border-gray-200 shadow px-2'

    if (editable) {
        return <ClickAwayListener onClickAway={() => { setOpen(false); }}>
            <div className={css} onClick={handleClick}>
                <span>{props.value}</span>
                <Popper open={open} anchorEl={anchorEl}>
                    <Box sx={{
                        fontSize: '0.75rem',
                        p: 0,
                        bgcolor: 'var(--gray-50)',
                        border: '1px solid var(--gray-100)',
                        boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
                        borderRadius: 0.5,
                        ['& ul']: {
                            m: 0,
                            p: 0,
                            listStyleType: 'none',
                            ['& li']: {
                                px: 3,
                                cursor: 'pointer',
                                position: 'relative',
                            },
                            ['& li:hover']: {
                                bgcolor: 'var(--gray-200)',
                            },
                            ['& li.current::before']: {
                                content: '"✓"',
                                position: 'absolute',
                                left: '5px',
                            },
                        },
                    }}>
                        <ul>
                            <li className={clsx(value === 1 && 'current')} onClick={() => onChange(1)}>1</li>
                            <li className={clsx(value === 2 && 'current')} onClick={() => onChange(2)}>2</li>
                            <li className={clsx(value === 3 && 'current')} onClick={() => onChange(3)}>3</li>
                            <li className={clsx(value === 4 && 'current')} onClick={() => onChange(4)}>4</li>
                            <li className={clsx(value === 5 && 'current')} onClick={() => onChange(5)}>5</li>
                        </ul>
                    </Box>
                </Popper>
            </div>
        </ClickAwayListener>
    } else {
        return <div className={css}>
            <span>{props.value}</span>
        </div>
    }

}

interface TestSectionProps {
    fn: RiskAnalysisFunction
}
const TestSection = (props : TestSectionProps & Editable) => {
    const { fn, editable } = props
    const dispatch = useAppDispatch()
    const docId = useContext(DocIdContext)
    const setter = useContext(DataSetterContext)
    const dialog = useContext(DialogContext)

    // Context menu
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null)

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault()
        if (editable) {
            setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY - 6,
                    }
                    : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                    // Other native context menus might behave different.
                    // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                    null,
            )
        }
    }

    const handleClose = () => {
        setContextMenu(null)
    }
    // Context menu
    
    const handleUntestableClick = async () => {
        const { defects, ...data } = fn
        try {
            setContextMenu(null)
            const updated = await dispatch(updateFunction({
                ...data,
                isTestable: false,
                docId,
            })).unwrap()
            setter(draft => {
             const someFn = findFunction(draft, fn.id)
                if (someFn) {
                    someFn.isTestable = updated.isTestable
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        } finally {
        }
    }

    const handleAssocTestClick = () => {
        setContextMenu(null)
        dialog({
            tag: 'assoc-test',
            fn,
        })
    }

    const handleCreateNewTestClick = () => {
        setContextMenu(null)
        dialog({ tag: 'create-test', fn })
    }

    const handleTestOpenClick = useCallback(() => {
        setContextMenu(null)
        dialog({ tag: 'edit-test', testId: fn.testDefinitionId ?? '' })
    }, [])

    const empty = fn.isTestable && fn.testDefinitionId === null
    let content = <></>
    if (empty) {
        content = <EmptyTest fn={fn} />
    } else if (!fn.isTestable) {
        content = <FnNotTestable />
    } else {
        content = <TestSelected testId={fn.testDefinitionId ?? ''} />
    }
    return <div onContextMenu={handleContextMenu} className={clsx(itemWrapperClass, "text-xs flex justify-center", editable && 'cursor-context-menu')}>
        {content}
        <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
            contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined }
        >
            {fn.testDefinitionId !== null && <MenuItem onClick={handleTestOpenClick}><Localized id=''>Otwórz</Localized></MenuItem>}
            <MenuItem onClick={handleCreateNewTestClick}><Localized id=''>Dodaj nowy test</Localized></MenuItem>
            <MenuItem onClick={handleAssocTestClick}><Localized id=''>Skojarz z testem</Localized></MenuItem>
            <MenuItem onClick={handleUntestableClick}><Localized id=''>Funkcja nietestowalna</Localized></MenuItem>
        </Menu>
    </div>
}

const EmptyTest = (props: TestSectionProps) => {
    return <>
        <span className='text-red-600 font-bold mr-1'>&#33;</span>
        <span className='text-gray-400'>Brak testu.</span>
    </>
}
const FnNotTestable = () => {
    return <>n/d</>
}
const TestSelected = ({ testId }: { testId: AppId }) => {
    const test = useAppSelector(st => selectValidationById(st, testId))
    const dialog = useContext(DialogContext)

    const handleTestDbClick = useCallback(() => {
        dialog({ tag: 'edit-test', testId })
    }, [])

    return <div className='flex flex-col gap-2 items-start w-full'>
        <div onDoubleClick={handleTestDbClick}>{test?.description ?? ''}</div>
        <div className='bg-gray-300 py-1 px-2 grow-0 rounded-lg'>{test?.stage ?? ''}</div>
    </div>
}

const CreateTestDialog = (props: { fn: RiskAnalysisFunction }) => {
    const { fn } = props
    const docId = useContext(DocIdContext)
    const dispatch = useAppDispatch()
    const setter = useContext(DataSetterContext)
    const dialog = useContext(DialogContext)
    const structureId = useQueryStructureId()

    const [newTestId, setNewTestId] = useState<AppId | undefined>(undefined)

    const close = useCallback(() => dialog({ tag: 'none' }), [])
    const handleTestCreated = useCallback((testId: AppId) => {
        // close()
        dispatch(updateFunction({
            ...fn,
            docId,
            testDefinitionId: testId,
            isTestable: true,
        })).then(() => {
            setter(draft => {
                const someFn = findFunction(draft, fn.id)
                if (someFn) {
                    someFn.testDefinitionId = testId
                    someFn.isTestable = true
                }
            })
            setNewTestId(testId)
        })
    }, [])

    return <Dialog fullScreen open={true}>
        <DialogContent>
            {newTestId ?
                <EditValidationForm id={newTestId} onClose={close} onDeleted={() => {}} /> :
                <CreateValidationForm onSuccess={handleTestCreated} onCancel={close} defName={fn.name} defStructureId={structureId} />}
        </DialogContent>
    </Dialog>
}

const EditTestDialog = (props: { testId: AppId }) => {
    const { testId } = props
    const dialog = useContext(DialogContext)

    const close = useCallback(() => dialog({ tag: 'none' }), [])
    const deleted = useCallback(() => {
        dialog({ tag: 'none' })
    }, [])

    return <Dialog fullScreen open={true}>
        <DialogContent>
            <EditValidationForm id={testId} onDeleted={deleted} onClose={close} />
        </DialogContent>
    </Dialog>
}

function CreateGroup(props: { parentGroupId: AppId | undefined }) {
    const { parentGroupId } = props
    const dispatch = useAppDispatch()
    const docId = useContext(DocIdContext)
    const dialog = useContext(DialogContext)
    const setter = useContext(DataSetterContext)
    const [name, setName] = useState('')
    const [code, setCode] = useState('')
    const [running, setRunning] = useState(false)
    const [error, setError] = useState<APIError | null>(null)

    const handleCreateGroupClick = async () => {
        if (name && code) {
            setRunning(true)
            try {
                const newGroup = await dispatch(createRiskAnalysisGroup({
                    name,
                    code,
                    documentId: docId.toString(),
                    systemTypeId: null,
                    parentGroupGuid: parentGroupId ?? null,
                })).unwrap()
                setter(draft => {
                    if (parentGroupId) {
                        const grIdx = draft.groups.findIndex(g => g.id === parentGroupId)
                        if (grIdx >= 0) {
                            draft.groups[grIdx].groups.push(newGroup)
                        }
                    } else {
                        draft.groups.push(newGroup)
                    }
                })
                dialog({ tag: 'none' })
            } catch (err) {
                setError(err as APIError)
            } finally {
                setRunning(false)
            }
        }
    }

    const title = parentGroupId === undefined ? 'Utwórz nową grupę' : 'Utwórz nową podgrupę'

    return <Dialog fullWidth maxWidth='lg' open={true}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <TextField
                sx={{ mt: 1 }}
                value={code}
                onChange={e => setCode(e.target.value)}
                required
                label={'Kod grupy'}
                helperText={!name ? 'To pole jest wymagane' : 'OK'}
                error={code === ''}
            />
            <TextField
                sx={{ mt: 1 }}
                value={name}
                onChange={e => setName(e.target.value)}
                fullWidth
                required
                label={'Nazwa grupy'}
                helperText={!name ? 'To pole jest wymagane' : 'OK'}
                error={name === ''}
            />
            <ServerErrorMsg err={error} />
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={running} onClick={handleCreateGroupClick}>
                <Localized id="ok"><span>OK</span></Localized>
            </LoadingButton>
            <LoadingButton loading={running} onClick={() => dialog({ tag: 'none' })}>
                <Localized id="cancel"><span>Anuluj</span></Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}

function EditGroup(props: { group: RiskAnalysisGroup, parentGroupId: AppId | undefined }) {
    const { group, parentGroupId } = props
    const dispatch = useAppDispatch()
    const docId = useContext(DocIdContext)
    const dialog = useContext(DialogContext)
    const setter = useContext(DataSetterContext)
    const [name, setName] = useState(group.name)
    const [running, setRunning] = useState(false)
    const [error, setError] = useState<APIError | null>(null)

    const handleEditGroupClick = async () => {
        if (name) {
            setRunning(true)
            try {
                await dispatch(updateGroup({
                    id: group.id,
                    name,
                    code: group.code,
                    docId: docId.toString(),
                })).unwrap()
                setter(draft => {
                    if (parentGroupId) {
                        const grIdx = draft.groups.findIndex(g => g.id === parentGroupId)
                        if (grIdx >= 0) {
                            const idx = draft.groups[grIdx].groups.findIndex(s => s.id === group.id)
                            if (idx >= 0) {
                                const g = draft.groups[grIdx].groups[idx]
                                g.name = name
                            }
                        }
                    } else {
                        const idx = draft.groups.findIndex(s => s.id === group.id)
                        if (idx >= 0) {
                            const g = draft.groups[idx]
                            g.name = name
                        }
                    }
                })
                dialog({ tag: 'none' })
            } catch (err) {
                setError(err as APIError)
            } finally {
                setRunning(false)
            }
        }
    }

    const title = 'Edycja grupy'

    return <Dialog fullWidth maxWidth='lg' open={true}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            {/* <TextField */}
            {/*     sx={{ mt: 1 }} */}
            {/*     value={code} */}
            {/*     onChange={e => setCode(e.target.value)} */}
            {/*     required */}
            {/*     label={'Kod grupy'} */}
            {/*     helperText={!code ? 'To pole jest wymagane' : 'OK'} */}
            {/*     error={code === ''} */}
            {/* /> */}
            <TextField
                sx={{ mt: 1 }}
                value={name}
                onChange={e => setName(e.target.value)}
                fullWidth
                required
                label={'Nazwa grupy'}
                helperText={!name ? 'To pole jest wymagane' : 'OK'}
                error={name === ''}
            />
            <ServerErrorMsg err={error} />
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={running} onClick={handleEditGroupClick}>
                <Localized id="ok"><span>OK</span></Localized>
            </LoadingButton>
            <LoadingButton loading={running} onClick={() => dialog({ tag: 'none' })}>
                <Localized id="cancel"><span>Anuluj</span></Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}

function DeleteItem(props: { item: Entity, parentGroupId?: AppId }) {
    const { item, parentGroupId } = props
    const dispatch = useAppDispatch()
    const documentId = useContext(DocIdContext)
    const setter = useContext(DataSetterContext)
    const dialog = useContext(DialogContext)
    const [deleting, setDeleting] = useState(false)

    const handleCancelDelClick = () => {
        dialog({ tag: 'none' })
    }

    const deleteGr = async (group: RiskAnalysisGroup) => {
        setDeleting(true)
        try {
            await dispatch(deleteGroup({
                docId: documentId,
                groupId: group.id,
            })).unwrap()
            setter(draft => {
                if (parentGroupId) {
                    const grIdx = draft.groups.findIndex(g => g.id === parentGroupId)
                    if (grIdx >= 0) {
                        const idx = draft.groups[grIdx].groups.findIndex(s => s.id === item.data.id)
                        if (idx >= 0) {
                            draft.groups[grIdx].groups.splice(idx, 1)
                        }
                    }
                } else {
                    const idx = draft.groups.findIndex(s => s.id === item.data.id)
                    if (idx >= 0) {
                        draft.groups.splice(idx, 1)
                    }
                }
            })
            handleCancelDelClick()
        } catch (error) {
            console.log(error)
        } finally {
            setDeleting(false)
        }
    }

    const deleteFun = async (fn: RiskAnalysisFunction) => {
        setDeleting(true)
        try {
            await dispatch(deleteFunction({
                docId: documentId,
                functionId: fn.id,
            })).unwrap()
            setter(draft => {
                for (let i = 0; i < draft.groups.length; i++) {
                    const g = draft.groups[i]
                    const idx = g.functions.findIndex(x => x.id === fn.id)
                    if (idx >= 0) {
                        g.functions.splice(idx, 1)
                    }

                    for (let k = 0; k < g.groups.length; k++) {
                        const sg = g.groups[k]
                        const idx = sg.functions.findIndex(x => x.id === fn.id)
                        if (idx >= 0) {
                            sg.functions.splice(idx, 1)
                        }
                    }
                }
            })
            handleCancelDelClick()
        } catch (error) {
            console.log(error)
        } finally {
            setDeleting(false)
        }

    }

    const handleConfirmDeleteClick = () => {
        switch (props.item.type) {
            case 'group':
                deleteGr(props.item.data)
                break
            case 'function':
                deleteFun(props.item.data)
                break
        }
    }

    return <Dialog open={true}>
        <DialogTitle>Usuwanie</DialogTitle>
        <DialogContent>
            <DialogContentText>
                <Localized id="confirm-delete">
                    <span>Czy napewno chcesz usunąć?</span>
                </Localized>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={deleting} onClick={handleConfirmDeleteClick}>
                <Localized id="yes"><span>Tak</span></Localized>
            </LoadingButton>
            <LoadingButton loading={deleting} onClick={handleCancelDelClick}>
                <Localized id="no"><span>Nie</span></Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}
