import { Localized, useLocalization } from "@fluent/react"
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead,  TableRow } from "@mui/material"
import { Link } from "react-router-dom"
import { UrsOfferStatusLabel } from "../ursOffer/UrsOfferStatusLabel"
import PrintIcon from '@mui/icons-material/Print';
import IconButton from '@mui/material/IconButton';
import PageviewIcon from '@mui/icons-material/Pageview';
import Divider from '@mui/material/Divider';
import { AppId } from "../../app/appTypes"
import { selectSettings } from '../settings/settingsSlice';
import {  useAppDispatch, useAppSelector } from "../../app/hooks"
import { loadAllUrsOffers, Offers } from "./UrsSlice"
import { useCallback, useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";

export const UrsOffers = (props: { ursId: AppId, offerSelected: (id: AppId) => void,  handleNewVersion: () => void, handleWithoutNewVersion : () => void}) => 
{
    const { ursId, offerSelected, handleNewVersion, handleWithoutNewVersion } = props
    const dispatch = useAppDispatch()
    const { locale } = useAppSelector(selectSettings);
    const { l10n } = useLocalization()
    const [ursOffers, setUrsOffers] = useState<Offers[] | null>(null)

    useEffect(() => {
        dispatch(loadAllUrsOffers(ursId))
            .then(unwrapResult)
            .then(setUrsOffers)
            .catch((error) => console.error(error))
    }, [])

    const DateFormater = ({ date }: { date: string }) => {
        let parsedDate = Date.parse(date);
        if (parsedDate){
            
            let date = new Date(parsedDate);
            return <span>{date.toLocaleDateString()}</span>;
        }        
        
        return <> - </>
    }

    const [showSelectOfferMessage, setShowSelectOfferMessage] = useState(false);  
    const [message, setMessage] = useState("");  
    

    const handleConfirmNewVersionClick = async () => {
        
        setShowSelectOfferMessage(false)        
        handleNewVersion()
    }

    const handleConfirmWithoutNewVersionClick = async () => {
        
        setShowSelectOfferMessage(false)        
        handleWithoutNewVersion()
    }    


    const handleCancelSelectOfferClick = useCallback(() => {
        setShowSelectOfferMessage(false)        
    }, [setShowSelectOfferMessage])
    


    const handleSelectOfferClick  = async (id: AppId) =>  {        
        if (ursOffers !== null) {
            let result = ursOffers.find( x => x.id === id);
            if (result) {
                if (result.hasUnfunfilledRequirements){    

                    setMessage(l10n.getString("urs-offer-all-requirements-not-ok-message", null, "Wybrana oferta zawiera wymagania, które nie zostały spełnione przez producenta. Wskazne jest utworzenie nowej wersji URS."));

                }
                else {                
                    setMessage(l10n.getString("urs-offer-all-requirements-ok-message", null, "Wybrana oferta spełnia wszystkie wymagania"));
                }
            }
            offerSelected(id);
            setShowSelectOfferMessage(true)
        }
        
    }      

    const handlePrintUrsClick  = async (id: AppId) =>  {
        if (id){                    
            window.open(`${process.env.REACT_APP_REST_API}api/reports/urs-offer/${id}?language=${locale}`, '_blank')              
        }
      }

    if (ursOffers == null) return <Skeleton />

    return <>
    <TableContainer component={Paper}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell sx={{minWidth: 200}}>
                        <Localized id="urs-offer-supplier">
                            <span>Dostawca</span>
                        </Localized>
                    </TableCell>
                    <TableCell sx = {{minWidth:150, width:150, maxWidth:150}}>
                        <Localized id="urs-offer-send-date">
                            <span>Data wysłania</span>
                        </Localized>
                    </TableCell>                    
                    <TableCell sx = {{minWidth:150, width:150, maxWidth:150}}>
                        <Localized id="urs-offer-response-date">
                            <span>Data odpowiedzi</span>
                        </Localized>
                    </TableCell>
                    <TableCell sx = {{minWidth:150, width:150, maxWidth:150}}>
                        <Localized id="urs-offer-status">
                            <span>Status</span>
                        </Localized>
                    </TableCell>
                    <TableCell sx={{minWidth: 200}}>
                        <Localized id="urs-offer-comment">
                            <span>Komentarz</span>
                        </Localized>
                    </TableCell>                   
                    <TableCell sx={{width: 100}}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                        {ursOffers.map(( { id, supplierName, createDate, responseDate, status, comment }) =>
                            <TableRow key={id}>                        
                                <TableCell>{supplierName}</TableCell>                                   
                                <TableCell><DateFormater date={createDate}/></TableCell>                                                                                                                               
                                <TableCell><DateFormater date={responseDate}/></TableCell>                               
                                <TableCell><UrsOfferStatusLabel status ={status}/></TableCell>                                             
                                <TableCell>{comment}</TableCell>                                                                       
                                <TableCell align="right">
                                    <ButtonGroup>
                                       <Button  color="secondary" variant="outlined" onClick={() => handleSelectOfferClick(id)} style={{ display: status==="A" ? "inline-flex" : "none" }}>
                                            <Localized id="urs-offer-action-approve">Zatwierdź</Localized>
                                        </Button>
                                        <Divider orientation="vertical" flexItem />
                                        <IconButton aria-label="print" onClick={() => handlePrintUrsClick(id)}  style={{ display: status !== "N" ? "inline-flex" : "none" }}>
                                            <PrintIcon />
                                        </IconButton>                                       
                                        <IconButton aria-label="open" component={Link} to={`/offer-preview/${id}`}>
                                            <PageviewIcon/>                                         
                                        </IconButton>                                            
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>          
        </Table>
    </TableContainer>
    <Dialog open={showSelectOfferMessage}>
        <DialogTitle>
            <Localized id="urs-offer-action-approve-question">Czy zatwierdzić wskazaną ofertę?</Localized>
        </DialogTitle>
        <DialogContent>
            <DialogContentText>                   
                <span>{message}</span>                       
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleConfirmNewVersionClick} variant="outlined"  color="secondary" >
                <Localized id="urs-offer-action-approve-new-version"><span>Zatwierdź z nową wersją URS</span></Localized>
            </Button>
            <Button onClick={handleConfirmWithoutNewVersionClick} variant="outlined"  color="secondary" >
                <Localized id="urs-offer-action-approve"><span>Zatwierdź</span></Localized> 
            </Button>
            <Button onClick={handleCancelSelectOfferClick} variant="outlined">
                <Localized id="cancel"><span>Anuluj</span></Localized>
            </Button>
        </DialogActions>
    </Dialog>
</>
}
