import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppId, AppThunkAPIType } from "../../app/appTypes"
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../http"

export interface DocComment {
    id: AppId
    comment: string
    context: string
    documentId: AppId
    sectionGuid: string
    sectionType: string
    parentCommentId: string | null
    createdBy: string
    createdByFullName: string
    createDate: string
    status: "NEW" | "RESOLVED" | "CLOSED" | "WONTFIX"
    type: string
    replies: DocComment[]
}

export interface AddCommentArg {
    comment: string
    context: string
    documentId: AppId
    sectionGuid: string
    parentCommentId: string | null
    sectionType: string
    type: string
}


export const loadDocComments = createAsyncThunk<DocComment[], AppId, AppThunkAPIType>('document-comments/load', async (docId, api) => {
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(getWithAuth({ url: `api/document-comments/${docId}`}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as DocComment[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const createComment = createAsyncThunk<DocComment, AddCommentArg, AppThunkAPIType>('document-comments/create', async (arg, api) => {
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(postWithAuth({
        url: `api/document-comments/${arg.documentId}/comments`,
        payload: arg
    }))
    const { payload } = result
    if (postWithAuth.fulfilled.match(result)) {
        return payload as DocComment
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const updateComment = createAsyncThunk<DocComment, DocComment, AppThunkAPIType>('document-comments/update', async (arg, api) => {
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(putWithAuth({
        url: `api/document-comments/${arg.documentId}/comments/${arg.id}`,
        payload: arg
    }))
    const { payload } = result
    if (putWithAuth.fulfilled.match(result)) {
        return payload as DocComment
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const deleteDocComment = createAsyncThunk<AppId, DocComment, AppThunkAPIType>('document-comments/delete', async (data, api) => {
    const { dispatch, rejectWithValue } = api
    const { id, documentId } = data
    const result = await dispatch(deleteWithAuth({
        url: `api/document-comments/${documentId}/comments/${id}`,
    }))
    if (deleteWithAuth.fulfilled.match(result)) {
        return id
    } else {
        return rejectWithValue(result.payload ?? { kind: 'unknown' })
    }
})

export const changeDocCommentStatus = createAsyncThunk<DocComment, Pick<DocComment, 'id' | 'status' | 'documentId'>, AppThunkAPIType>('document-comments/create', async (arg, api) => {
    const { dispatch, rejectWithValue } = api
    const { documentId, status, id: commentId } = arg
    const result = await dispatch(postWithAuth({
        url: `/api/document-comments/${documentId}/comments/${commentId}/action`,
        payload: {
            documentId,
            commentId,
            action: status,
        },
    }))
    const { payload } = result
    if (postWithAuth.fulfilled.match(result)) {
        return payload as DocComment
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})
