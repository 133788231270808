import { Localized } from "@fluent/react";
import {
    Button,
    Card,
    CardContent,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel, Paper,
    Radio,
    RadioGroup,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    TextField,
    Typography
} from "@mui/material";
import { Autocomplete, Skeleton } from '@mui/material';
import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { LoadDevices, LoadStructure, LoadUsers } from "../../app/AppDataLoader";
import { AppLoader } from "../../app/AppLoader";
import { AppId, AppTextFieldHandler } from "../../app/appTypes";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Company, selectAllCompanies } from "../company/companiesSlice";
import { Device, selectAllDevices, selectDeviceById } from "../device/deviceSlice";
import { showError } from "../notifications/notificationsSlice";
import { selectAllUsers, User } from "../users/usersSlice";
import { createProcess } from "./processSlice";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

type StepId = 1 | 2 | 3 | 4 | 5 | 6 | 7

export const CreateProcess = () => {
    const { state } = useLocation()
    const { deviceId, structureId, ursId, documentId } = (state ?? {}) as { deviceId?: AppId , structureId?: AppId , ursId?:  AppId , documentId?:  AppId }
    const device: Device | undefined = useAppSelector(state => selectDeviceById(state, deviceId ?? ""))

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const allCompanies = useAppSelector(selectAllCompanies)
    const allDevices = useAppSelector(selectAllDevices)
    const allUsers = useAppSelector(selectAllUsers)

    const [activeStep, setActiveStep] = useState<StepId>(1)

    const [company, setCompany] = useState<Company | undefined>(undefined)
    const [newDevice, setNewDevice] = useState<Device | undefined>(undefined)
    
    const [description, setDescription] = useState("")
    const [processNo, setProcessNo] = useState("")
    
    const [iqPlanner, setIqPlanner] = useState<User | undefined>(undefined)
    const [dqPlanner, setDqPlanner] = useState<User | undefined>(undefined)
    const [oqPlanner, setOqPlanner] = useState<User | undefined>(undefined)
    const [pqPlanner, setPqPlanner] = useState<User | undefined>(undefined)

    const [iqApprover, setIqApprover] = useState<User | undefined>(undefined)           
    const [dqApprover, setDqApprover] = useState<User | undefined>(undefined)   
    const [oqApprover, setOqApprover] = useState<User | undefined>(undefined)    
    const [pqApprover, setPqApprover] = useState<User | undefined>(undefined)

    const [dqFinalApprover, setDqFinalApprover] = useState<User | undefined>(undefined)   
    const [iqFinalApprover, setIqFinalApprover] = useState<User | undefined>(undefined)    
    const [oqFinalApprover, setOqFinalApprover] = useState<User | undefined>(undefined)
    const [pqFinalApprover, setPqFinalApprover] = useState<User | undefined>(undefined)

    
    const [newId, setNewId] = useState<AppId | undefined>(undefined)
    const [creating, setCreating] = useState(false)

    const [dqSkipped, setDqSkipped] = React.useState(false);
    const [iqSkipped, setIqSkipped] = React.useState(false);
    const [oqSkipped, setOqSkipped] = React.useState(false);
    const [pqSkipped, setPqSkipped] = React.useState(false);

    const [selectedDeviceRadio, setSelectedDeviceRadio] = useState('');
    const [selectValue1, setSelectValue1] = useState('');
    const [selectValue2, setSelectValue2] = useState('');
  
    useEffect(() => {
        if (deviceId) {
          setSelectedDeviceRadio('hasdevice');
        } else {
          setSelectedDeviceRadio('nodevice');
        }
      }, [deviceId]);
      
    const handleDeviceRadioChange = (event) => {
        setSelectedDeviceRadio(event.target.value);
    };
  
    const handleSelectChange1 = (event) => {
      setSelectValue1(event.target.value);
    };
  
    const handleSelectChange2 = (event) => {
      setSelectValue2(event.target.value);
    };

    const handleSkipDqChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDqSkipped(event.target.checked);
    };
    
    const handleSkipIqChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIqSkipped(event.target.checked);
    };

    const handleSkipOqChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOqSkipped(event.target.checked);
    };

    const handleSkipPqChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPqSkipped(event.target.checked);
    };

    const handleDeviceChange = useCallback((_: any, newDevice: Device | null) => {
        if (newDevice) {
            setNewDevice(newDevice)
        }
    }, [])
    const handleCompanyChange = useCallback((_: any, newCompany: Company | null) => {
        if (newCompany) {
            setCompany(newCompany)
        }
    }, [])
    const handleProcessNoChange: AppTextFieldHandler = useCallback((e) => {
        setProcessNo(e.target.value)
    }, [])
    const handleDescriptionChange: AppTextFieldHandler = useCallback((e) => {
        setDescription(e.target.value)
    }, [])
    const handleIqPlannerChange = useCallback((_: any, newUser: User | null) => {
        if (newUser) {
            setIqPlanner(newUser)
        }
    }, [])
    const handleIqApproverChange = useCallback((_: any, newUser: User | null) => {
        if (newUser) {
            setIqApprover(newUser)
        }
    }, [])

    const handleIqFinalApproverChange = useCallback((_: any, newUser: User | null) => {
        if (newUser) {
            setIqFinalApprover(newUser)
        }
    }, [])

    
    const handleDqPlannerChange = useCallback((_: any, newUser: User | null) => {
        if (newUser) {
            setDqPlanner(newUser)
        }
    }, [])
    const handleDqApproverChange = useCallback((_: any, newUser: User | null) => {
        if (newUser) {
            setDqApprover(newUser)
        }
    }, [])

    const handleDqFinalApproverChange = useCallback((_: any, newUser: User | null) => {
        if (newUser) {
            setDqFinalApprover(newUser)
        }
    }, [])

    
    const handleOqPlannerChange = useCallback((_: any, newUser: User | null) => {
        if (newUser) {
            setOqPlanner(newUser)
        }
    }, [])
    const handleOqApproverChange = useCallback((_: any, newUser: User | null) => {
        if (newUser) {
            setOqApprover(newUser)
        }
    }, [])

    const handleOqFinalApproverChange = useCallback((_: any, newUser: User | null) => {
        if (newUser) {
            setOqFinalApprover(newUser)
        }
    }, [])

    
    const handlePqPlannerChange = useCallback((_: any, newUser: User | null) => {
        if (newUser) {
            setPqPlanner(newUser)
        }
    }, [])
    const handlePqApproverChange = useCallback((_: any, newUser: User | null) => {
        if (newUser) {
            setPqApprover(newUser)
        }
    }, [])

    const handlePqFinalApproverChange = useCallback((_: any, newUser: User | null) => {
        if (newUser) {
            setPqFinalApprover(newUser)
        }
    }, [])

    const handleNextClick = async () => {
        if (activeStep === 7) {
            // save process
            setCreating(true)
            
            try {
                const { id } = unwrapResult(
                    await dispatch(createProcess({
                        deviceId: deviceId ?  deviceId : (newDevice?.id ?? ""),
                        structureId: structureId ? structureId : "",
                        processNo: processNo,
                        ursId: ursId ?? "",
                        documentId: documentId ? String(documentId) : "",
                        description: description,
                        purpose: "",
                        validationForCompanyId: company?.id ?? "",
                        iqPlannerUserName: iqPlanner?.userName ?? "",
                        iqAproverUserName: iqApprover?.userName ?? "",
                        iqFinalAproverUserName: iqFinalApprover?.userName ?? "",
                        oqPlannerUserName: oqPlanner?.userName ?? "",
                        oqAproverUserName: oqApprover?.userName ?? "",
                        oqFinalAproverUserName: oqFinalApprover?.userName ?? "",
                        pqPlannerUserName: pqPlanner?.userName ?? "",
                        pqAproverUserName: pqApprover?.userName ?? "",
                        pqFinalAproverUserName: pqFinalApprover?.userName ?? "",
                        dqPlannerUserName: dqPlanner?.userName ?? "",
                        dqAproverUserName: dqApprover?.userName ?? "",
                        dqFinalAproverUserName: dqFinalApprover?.userName ?? "",
                        dqSkipped: dqSkipped,
                        iqSkipped: iqSkipped,
                        oqSkipped: oqSkipped,
                        pqSkipped: pqSkipped
                    }))
                )
                navigate(`/process/dashboard/${id}`, { replace: true })
            } catch (error) {   
                dispatch(showError("error"))
            }
            setCreating(false)
        } else {
            setActiveStep(activeStep + 1 as StepId)
        }
    }
    const handleBackClick = () => {
        setActiveStep(activeStep - 1 as StepId)
    }

    const handleBackButtonClick = useCallback(() => {
        navigate(-1)
    }, [navigate])

    const DeviceLabel = useMemo(() => {
        if (device) {
            return () => <div>
                    <b><span>{device?.deviceTypeId}</span></b><br />
                    <span>
                        <Localized id="devices-model"> Model</Localized>: &nbsp;{device?.model}
                    </span>
                                   
                </div>
        } else {
            return () => <></>
        }
    }, [device])

    const Buttons = () => <div>
        <Button disabled={activeStep === 1} 
                onClick={handleBackClick}
                sx={{
                    marginTop: 2,
                    marginRight: 1,
                }}
        >
            <Localized id="back">
                <span>Powrót</span>
            </Localized>
        </Button>
        <Button variant="contained"
                color="secondary"
                onClick={handleNextClick}
                sx={{
                    marginTop: 2,
                    marginRight: 1,
                }}
        >
            {activeStep === 7 ? 
                <Localized id="process-create">
                    <span>Twórz proces</span>
                </Localized> : 
                <Localized id="next">
                    <span>Dalej</span>
                </Localized>    
            }
        </Button>
    </div>

    return (
        <Container component={Paper} maxWidth="lg">
            <Stepper sx={{
                width: "600px",
            }} orientation="vertical">
                <Step key={1} active={activeStep === 1} >
                    <StepLabel optional={activeStep !== 1 ? <DeviceLabel /> : null}>
                        <Localized id="step-device-name">
                            <span>Urządzenie</span>
                        </Localized>
                    </StepLabel>
                    <StepContent>
                        {deviceId &&
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>
                                    <p>
                                        <b><span>{device?.deviceTypeName}</span></b><br />
                                        <span><Localized id="devices-model"> Model</Localized>:</span>&nbsp;<span>{device?.model}</span><br />
                                        <span><Localized id="devices-serial-number"> Nr seryjny</Localized>:</span>&nbsp;<span>{device?.serialNo}</span>
                                    </p>
                                </Typography>
                            </CardContent>
                        </Card>
                        }
                        {!deviceId &&                        
                            <LoadDevices component={<Skeleton variant="rectangular" height={100} />}>
                                <LoadStructure component={<Skeleton variant="text" />}>

                                <FormControl>
                                    <RadioGroup
                                        value={selectedDeviceRadio} onChange={handleDeviceRadioChange}
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="nodevice"
                                        name="radio-buttons-group"
                                    >
                                        <FormControlLabel value="nodevice" control={<Radio />} label="Nie mam urządzenia (urządzenie zostanie wskazane w dalszym etapie procesu walidacyjnego)" />
                                        <FormControlLabel value="hasdevice" control={<Radio />} label="Mam już urządzenie (wybierz z listy)" />
                                    </RadioGroup>

                                    {selectedDeviceRadio === 'hasdevice' && (
                                        <Autocomplete 
                                            sx={{
                                                width: "400px",
                                            }}
                                            fullWidth={false}
                                            options={allDevices}
                                            getOptionLabel={({ name }) => name}
                                            value={newDevice ?? null}
                                            onChange={handleDeviceChange}
                                            renderInput={params =>
                                                <TextField {...params} />
                                        }
                                        />
                                    )}
                                </FormControl>
                               
                                </LoadStructure>
                            </LoadDevices>
                            
                        }
                        <Buttons />                        
                    </StepContent>
                </Step>
                <Step key={2} active={activeStep === 2} >
                    <StepLabel optional={activeStep !== 2 ? <></> : null}>
                        <Localized id="step-number">
                            <span>Numer procesu</span>
                        </Localized>
                    </StepLabel>
                    <StepContent>
                        <TextField 
                            value={processNo}
                            inputProps={{ maxLength: 1000 }}         
                            onChange={handleProcessNoChange}
                            fullWidth
                        />
                        <Buttons />
                    </StepContent>
                </Step>
                <Step key={3} active={activeStep === 3} >
                    <StepLabel optional={activeStep !== 3 ? <></> : null}>
                        <Localized id="step-proces-description">
                            <span>Opis</span>
                        </Localized>
                    </StepLabel>
                    <StepContent>
                        <TextField 
                            multiline={true} 
                            value={description}
                            inputProps={{ maxLength: 1000 }}         
                            onChange={handleDescriptionChange}
                            fullWidth
                        />
                        <Buttons />
                    </StepContent>
                </Step>
                <Step key={4} active={activeStep === 4} >
                    <StepLabel optional={activeStep !== 4 ? <></> : null}>
                        <Localized id="step-team">
                            <span>Zespól DQ </span>
                        </Localized>
                        <span> DQ</span>
                    </StepLabel>
                    <StepContent>
                        <div>
                            <FormControlLabel
                                control={
                                <Checkbox checked={dqSkipped} onChange={handleSkipDqChange}/>
                                }
                                label={
                                    <Localized id="step-skip">
                                        <span>Pomiń etap</span>
                                    </Localized>
                                }
                            />
                            <LoadUsers component={<Skeleton variant="rectangular" />}>
                                <Autocomplete 
                                    disabled= {dqSkipped}
                                    sx={{
                                        width: "400px",
                                    }}
                                    options={allUsers}
                                    value={dqPlanner}
                                    onChange={handleDqPlannerChange}
                                    getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                                    renderInput={params =>
                                        <TextField {...params}
                                            label={
                                                <Localized id="step-planning">
                                                    <span>Planowanie</span>
                                                </Localized>
                                            }
                                        />
                                    }
                                />
                                <Autocomplete 
                                    disabled= {dqSkipped}
                                    sx={{
                                        width: "400px",
                                        marginTop: "10px"
                                    }}
                                    options={allUsers}
                                    value={dqApprover}
                                    onChange={handleDqApproverChange}
                                    getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                                    renderInput={params =>
                                        <TextField {...params}
                                            label={
                                                <Localized id="step-verification">
                                                    <span>Weryfikacja</span>
                                                </Localized>
                                            }
                                        />
                                    }
                                />
                                <Autocomplete 
                                    disabled= {dqSkipped}
                                    sx={{
                                        width: "400px",
                                        marginTop: "10px"
                                    }}
                                    options={allUsers}
                                    value={dqFinalApprover}
                                    onChange={handleDqFinalApproverChange}
                                    getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                                    renderInput={params =>
                                        <TextField {...params}
                                            label={
                                                <Localized id="step-process-approval">
                                                    <span>Zatwierdzanie procesu</span>
                                                </Localized>
                                            }
                                        />
                                    }
                                />                                
                            </LoadUsers>
                        </div>
                        <Buttons />
                    </StepContent>
                </Step>
                <Step key={5} active={activeStep === 5} >
                    <StepLabel optional={activeStep !== 5 ? <></> : null}>
                        <Localized id="step-team">
                            <span>Zespól IQ</span>
                        </Localized>
                        <span> IQ</span>
                    </StepLabel>
                    <StepContent>
                        <div>
                            <FormControlLabel
                                control={
                                <Checkbox checked={iqSkipped} onChange={handleSkipIqChange}/>
                                }
                                label={
                                    <Localized id="step-skip">
                                        <span>Pomiń etap</span>
                                    </Localized>
                                }
                            />
                            <LoadUsers component={<Skeleton variant="rectangular" />}>
                                <Autocomplete 
                                    sx={{
                                        width: "400px",
                                    }}
                                    disabled= {iqSkipped}
                                    options={allUsers}
                                    value={iqPlanner}
                                    onChange={handleIqPlannerChange}
                                    getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                                    renderInput={params =>
                                        <TextField {...params}
                                            label={
                                                <Localized id="step-planning">
                                                    <span>Planowanie</span>
                                                </Localized>
                                            }
                                        />
                                    }
                                />
                                <Autocomplete 
                                    sx={{
                                        width: "400px",
                                        marginTop: "10px"
                                    }}
                                    disabled= {iqSkipped}
                                    options={allUsers}
                                    value={iqApprover}
                                    onChange={handleIqApproverChange}
                                    getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                                    renderInput={params =>
                                        <TextField {...params}
                                            label={
                                                <Localized id="step-verification">
                                                    <span>Weryfikacja</span>
                                                </Localized>
                                            }
                                        />
                                    }
                                />
                                <Autocomplete 
                                    sx={{
                                        width: "400px",
                                        marginTop: "10px"
                                    }}
                                    disabled= {iqSkipped}
                                    options={allUsers}
                                    value={iqFinalApprover}
                                    onChange={handleIqFinalApproverChange}
                                    getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                                    renderInput={params =>
                                        <TextField {...params}
                                            label={
                                                <Localized id="step-process-approval">
                                                    <span>Zatwierdzanie procesu</span>
                                                </Localized>
                                            }
                                        />
                                    }
                                />      
                            </LoadUsers>
                        </div>
                        <Buttons />
                    </StepContent>
                </Step>
                <Step key={6} active={activeStep === 6} >
                    <StepLabel optional={activeStep !== 6 ? <></> : null}>
                        <Localized id="step-team">
                            <span>Zespól OQ</span>
                        </Localized>
                        <span> OQ</span>
                    </StepLabel>
                    <StepContent>
                        <div>
                            <FormControlLabel
                                control={
                                <Checkbox checked={oqSkipped} onChange={handleSkipOqChange}/>
                                }
                                label={
                                    <Localized id="step-skip">
                                        <span>Pomiń etap</span>
                                    </Localized>
                                }
                            />
                            <LoadUsers component={<Skeleton variant="rectangular" />}>
                                <Autocomplete 
                                    sx={{
                                        width: "400px",
                                    }}
                                    disabled= {oqSkipped}
                                    options={allUsers}
                                    value={oqPlanner}
                                    onChange={handleOqPlannerChange}
                                    getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                                    renderInput={params =>
                                        <TextField {...params}
                                            label={
                                                <Localized id="step-planning">
                                                    <span>Planowanie</span>
                                                </Localized>
                                            }
                                        />
                                    }
                                />
                                <Autocomplete 
                                    sx={{
                                        width: "400px",
                                        marginTop: "10px"
                                    }}
                                    disabled= {oqSkipped}
                                    options={allUsers}
                                    value={oqApprover}
                                    onChange={handleOqApproverChange}
                                    getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                                    renderInput={params =>
                                        <TextField {...params}
                                            label={
                                                <Localized id="step-verification">
                                                    <span>Weryfikacja</span>
                                                </Localized>
                                            }
                                        />
                                    }
                                />
                                <Autocomplete 
                                    sx={{
                                        width: "400px",
                                        marginTop: "10px"
                                    }}
                                    disabled= {oqSkipped}
                                    options={allUsers}
                                    value={oqFinalApprover}
                                    onChange={handleOqFinalApproverChange}
                                    getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                                    renderInput={params =>
                                        <TextField {...params}
                                            label={
                                                <Localized id="step-process-approval">
                                                    <span>Zatwierdzanie procesu</span>
                                                </Localized>
                                            }
                                        />
                                    }
                                />      
                            </LoadUsers>
                        </div>
                        <Buttons />
                    </StepContent>
                </Step>
                <Step key={7} active={activeStep === 7} >
                    <StepLabel optional={activeStep !== 7 ? <></> : null}>
                        <Localized id="step-team">
                            <span>Zespól PQ</span>
                        </Localized>
                        <span> PQ</span>
                    </StepLabel>
                    <StepContent>
                        <div>
                            <FormControlLabel
                                control={
                                <Checkbox checked={pqSkipped} onChange={handleSkipPqChange}/>
                                }
                                label={
                                    <Localized id="step-skip">
                                        <span>Pomiń etap</span>
                                    </Localized>
                                }
                            />
                            <LoadUsers component={<Skeleton variant="rectangular" />}>
                                <Autocomplete 
                                    sx={{
                                        width: "400px",
                                    }}
                                    disabled= {pqSkipped}
                                    options={allUsers}
                                    value={pqPlanner}
                                    onChange={handlePqPlannerChange}
                                    getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                                    renderInput={params =>
                                        <TextField {...params}
                                            label={
                                                <Localized id="step-planning">
                                                    <span>Planowanie</span>
                                                </Localized>
                                            }
                                        />
                                    }
                                />
                                <Autocomplete 
                                    sx={{
                                        width: "400px",
                                        marginTop: "10px"
                                    }}
                                    disabled= {pqSkipped}
                                    options={allUsers}
                                    value={pqApprover}
                                    onChange={handlePqApproverChange}
                                    getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                                    renderInput={params =>
                                        <TextField {...params}
                                            label={
                                                <Localized id="step-verification">
                                                    <span>Weryfikacja</span>
                                                </Localized>
                                            }
                                        />
                                    }
                                />
                                <Autocomplete 
                                    sx={{
                                        width: "400px",
                                        marginTop: "10px"
                                    }}
                                    disabled= {pqSkipped}
                                    options={allUsers}
                                    value={pqFinalApprover}
                                    onChange={handlePqFinalApproverChange}
                                    getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                                    renderInput={params =>
                                        <TextField {...params}
                                            label={
                                                <Localized id="step-process-approval">
                                                    <span>Zatwierdzanie procesu</span>
                                                </Localized>
                                            }   
                                        />
                                    }
                                />      
                            </LoadUsers>
                        </div>
                        <Buttons />
                    </StepContent>
                </Step>
            </Stepper>
            <Button sx={{marginTop:5, marginBottom:2}} onClick={handleBackButtonClick} color="secondary" variant="outlined">
                <Localized id="cancel">Anuluj</Localized>
            </Button>
            <AppLoader open={creating} />
        </Container>
    );
}
