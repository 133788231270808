import { Localized, useLocalization } from "@fluent/react";
import { Alert, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, TextField } from "@mui/material"
import { AppId, AppTextFieldHandler } from "../../../app/appTypes";
import { addMailingGroup, MailingGroup, MailingGroupUser, updateMailingGroup } from "./mailingGroupApi";
import { If } from "../../../app/If";
import { LoadingButton } from "@mui/lab";
import { useCallback, useState, KeyboardEvent } from "react";
import { showError, showSuccess } from "../../notifications/notificationsSlice";
import { addDocumentTeamMember } from "../../documents/components/teamMemberApi";
import { useAppDispatch } from "../../../app/hooks";
import AddIcon from '@mui/icons-material/Add';


type AddEditMailingGroupDialogMode = 'create' | 'edit'

export interface AddEditMailingGroupDialogProps {
  mailingGroup: MailingGroup | undefined
  mode: AddEditMailingGroupDialogMode;
  onSuccessfullySaved: (updatedGroup: MailingGroup) => void;
  onCancel: () => void
}

export const AddEditMailingGroupDialog: React.FC<AddEditMailingGroupDialogProps> = ({ mailingGroup, mode, onSuccessfullySaved, onCancel }) => {
  const { l10n } = useLocalization()
  const dispatch = useAppDispatch()
  const [saving, setSaving] = useState(false)
  const [name, setName] = useState(mailingGroup?.name)
  const [groupEmail, setGroupEmail] = useState(mailingGroup?.email)
  const [serverError, setServerError] = useState("")
  const [emails, setEmails] = useState<string[]>(
    mailingGroup?.users?.map((user) => user.email).filter((email) => !!email) || []
  );
  const [emailInput, setEmailInput] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  const handleMailingGroupNameChange: AppTextFieldHandler = useCallback(e => {
    setName(e.target.value)
  }, [])

  const handleEmailChange: AppTextFieldHandler = useCallback(e => {
    setGroupEmail(e.target.value)
  }, [])


  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleAddEmail = () => {
    if (emailInput.trim() !== '') {
      if (!validateEmail(emailInput)) {
        setEmailError(l10n.getString("email-incorrect", null, "email-incorrect"),);
      } else if (emails.includes(emailInput)) {
        setEmailError(l10n.getString("email-already-on-list", null, "email-already-on-list"),);
      } else {
        setEmails([...emails, emailInput]);
        setEmailInput('');
        setEmailError('');
      }
    }
  };

  const handleDelete = (emailToDelete: string) => () => {
    setEmails(emails.filter(email => email !== emailToDelete));
  };


  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleAddEmail();
    }
  };

  const handleSaveClick = async () => {
    setSaving(true)

    const generatedUsers: MailingGroupUser[] = emails.map((email, index) => ({
      id: "0",
      firstName: "",
      lastName: "",
      email: email,
    }))

    try {
      switch (mode) {
        case "create":
          if (groupEmail && name) {

            const newGroup: MailingGroup = {
              name: name,
              email: groupEmail,
              id: "0",
              users: generatedUsers
            };

            const result = await dispatch(addMailingGroup(newGroup))
            if (addMailingGroup.fulfilled.match(result)) {
              const newMailingGroup = result.payload as MailingGroup;
              dispatch(showSuccess("saved"))
              onSuccessfullySaved(newMailingGroup)
            } else {
              let errorMsg = "error"
              if (result.payload && result.payload.kind === 'http') {
                const problem = result.payload.problem
                if (problem) {
                  errorMsg = problem.title

                }
              }
              setServerError(errorMsg)
            }

          }

          break
        case "edit":

          if (groupEmail && name) {

            const updatedGroup: MailingGroup = {
              name: name,
              email: groupEmail,
              id: mailingGroup?.id || "0",
              users: generatedUsers
            };

            const result = await dispatch(updateMailingGroup(updatedGroup))
            if (updateMailingGroup.fulfilled.match(result)) {
              const newMailingGroup = result.payload as MailingGroup;
              dispatch(showSuccess("saved"))
              onSuccessfullySaved(newMailingGroup)
            } else {
              let errorMsg = "error"
              if (result.payload && result.payload.kind === 'http') {
                const problem = result.payload.problem
                if (problem) {
                  errorMsg = problem.title

                }
              }
              setServerError(errorMsg)
            }

          }


          break
      }

    }
    catch (error) {
      dispatch(showError("error"));
    }
    finally {
      setSaving(false)
    }
  }

  return <>
    <Dialog open={true} maxWidth="xs" fullWidth>
      <DialogContent>
        <Stack direction="column" spacing={2} padding={2}>
          <TextField
            fullWidth
            value={name}
            inputProps={{ maxLength: 20 }}
            onChange={handleMailingGroupNameChange}
            label={<Localized id="">_name</Localized>}>
          </TextField>
          <TextField
            fullWidth
            value={groupEmail}
            inputProps={{ maxLength: 20 }}
            onChange={handleEmailChange}
            label={<Localized id="">_email</Localized>}>
          </TextField>

        </Stack>
        <Paper
          component="div"
          style={{ padding: '10px', marginBottom: '10px', maxHeight: '500px', overflowY: 'auto' }}
        >
          {emails.sort((a, b) => a.localeCompare(b)).map((email) => (
            <div key={email}>
              <Chip
                label={email}
                onDelete={handleDelete(email)}
                color="primary"
                style={{ marginLeft: '10px', marginTop: '1px', flex: 1 }}
              />
            </div>
          ))}
        </Paper>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <TextField
            value={emailInput}
            size='small'
            onChange={(e) => setEmailInput(e.target.value)}
            onKeyDown={handleKeyDown}
            label={<Localized id="add-email">Dodaj email</Localized>}
            variant="standard"
            error={!!emailError}
            helperText={emailError}
          />
          <IconButton onClick={handleAddEmail} color="primary" aria-label="add email">
            <AddIcon />
          </IconButton>
        </div>
        {
          serverError && <Alert sx={{ marginTop: 1, }} onClose={() => { setServerError("") }} severity="error">
            <Localized id={serverError}>
              <span>Server error</span>
            </Localized>
          </Alert>
        }
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handleSaveClick} loading={saving}>
          <Localized id="save">
            <span>Zapisz</span>
          </Localized>
        </LoadingButton>
        <LoadingButton loading={saving} onClick={onCancel}>
          <Localized id="cancel">
            <span>Anuluj</span>
          </Localized>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  </>
}

