import Container from '@mui/material/Container'
import TextField from "@mui/material/TextField"
import { Localized } from "@fluent/react"
import { Button, ButtonGroup, Grid, Paper } from "@mui/material"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { unwrapResult } from "@reduxjs/toolkit"
import { ChangeEvent, useCallback, useMemo, useState } from "react"
import SaveIcon from '@mui/icons-material/Save'
import { useAppDispatch } from '../../app/hooks'
import { AppLoader } from '../../app/AppLoader'
import { createSupplierDevice } from './supplierDeviceSlice'
import { useNavigate } from 'react-router-dom'
import { DeviceTypeCombobox } from '../device/DeviceTypeCombobox'

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

export const CreateSupplierDevice = () => {
    const [name, setName] = useState<string>("");    
    const [description, setDescription] = useState<string>("");
    const [structureId, setDeviceTypeId] = useState<string>("");
    const [saving, setSaving] = useState(false)
    const [validationErrors, setValidationErrors] = useState(false)

    const dispatch = useAppDispatch();
    const navigate = useNavigate(); 
          
    const handleBackClick = useCallback(() => {
        navigate(-1)
    }, [navigate])
    
    const handleSaveClick = async () => {
        setSaving(true)
        setValidationErrors(true)

        if(name && structureId){
                try {
                    const actionResult = await dispatch(createSupplierDevice({                     
                        name,  
                        serialNo: "", 
                        productionYear: 0, 
                        description, 
                        structureId
                    }));
                    const { id: newId } = unwrapResult(actionResult);

                    dispatch(showSuccess("saved"));

                    navigate(`/supplierdevices/edit/${newId}`, { replace: true });
                } catch (error) {
                    dispatch(showError("error"));
                }
            } 
        setSaving(false)
    }

    const handleNameChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setName(e.target.value), [setName]);

    const handleDescriptionChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setDescription(e.target.value), [setDescription])

    const handleTypeChange = useCallback(structureId => setDeviceTypeId(structureId), [setDeviceTypeId])

    const nameError = validationErrors && name === ""
    const deviceTypeIdError = validationErrors && structureId === ""

    return (
        <>
            <Container maxWidth="md" sx={{paddingTop: 2, paddingBottom: 2}}>
                <Paper >
                <Grid container spacing={2} sx={{padding:2}}>
                    <Grid item xs={12}>
                        <TextField      
                            fullWidth    
                            required
                            error={nameError}
                            helperText={nameError && <RequiredError />}
                            multiline={true}       
                            inputProps={{ maxLength: 200 }}                                 
                            value={name}   
                            onChange={handleNameChange}                        
                            label={<Localized id="devices-name">Nazwa urzadzenia</Localized>}>
                        </TextField>  
                    </Grid>
                    <Grid item xs={12}>
                        <DeviceTypeCombobox
                            onlyLeafs={true}
                            required
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                            error={deviceTypeIdError}
                            helperText={deviceTypeIdError && <RequiredError />}
                            value={structureId}
                            onChange={handleTypeChange}
                            label={<Localized id="urs-device-type">Typ</Localized>}
                        />
                    </Grid>
                    <Grid item xs={12}> 
                        <TextField               
                            fullWidth              
                            value={description}                 
                            onChange={handleDescriptionChange}                 
                            multiline     
                            minRows={4}                     
                            label={<Localized id="devices-description">Opis</Localized>}>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}> 
                        <ButtonGroup color="secondary" variant="contained">
                            <Button startIcon={<SaveIcon />} onClick={handleSaveClick}>
                                <Localized id="save">Zapisz</Localized>
                            </Button>
                            <Button  onClick={handleBackClick}  color="secondary" variant="outlined">
                                <Localized id="back">Wróć</Localized>
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>                    
                </Paper>
            </Container>
            <AppLoader open={saving} />
        </>
    );
}
