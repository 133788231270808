import { AppId, AppTextFieldHandler } from "../../app/appTypes";
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import Button from '@mui/material/Button'
import { useCallback, useEffect, useMemo, useState } from "react"
import { Localized } from "@fluent/react"
import { useAppSelector } from "../../app/hooks"
import { Autocomplete, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import { Requirement, selectAllRequirements, selectRequirements } from "../requirements/RequirementsDictSlice"
import { Skeleton } from '@mui/material';
import { selectRequirementsTypes, selectRequirementTypeById, selectAllRequirementsTypes, RequirementType } from "../requirements/RequirementsTypesSlice"
import { store } from "../../app/store"
import { useLocalStorage } from "../../useLocalStorage";
import { selectAllStructure, selectAllStructureFamily } from "../device/structureRelationshipSlice";
import { StructureName } from "../device/Structure";
import ClearIcon from '@mui/icons-material/Clear';
import produce from "immer";
import { LoadingButton } from "@mui/lab";

export interface RequirementDialogBrowserProps {
    performAction: (selection: Set<AppId>) => void
    cancel: () => void
    deviceTypeId: AppId | null
    disabled: Set<AppId>
    lang: string
    defaultTypeId?: AppId
}

export const RequirementDialogBrowser = ({ performAction, cancel, ...browserProps }: RequirementDialogBrowserProps) => {
    const [selection, setSelection] = useState<Set<AppId>>(new Set())
    const [pending, setPending] = useState(false)
    const itemChecked = useCallback((id: AppId) => { 
        setSelection(produce(draft => draft.add(id)))
    },[])
    const itemUnchecked = useCallback((id: AppId) => {
        setSelection(produce(draft => { draft.delete(id) } ))
    },[])
    const handleOkClick = useCallback((selection: Set<AppId>) => {
        setPending(true)
        performAction(selection)
    }, [])
    return <Dialog
        open={true}
        maxWidth="lg"
        fullWidth={true}
        PaperProps={{
            sx: {
                height: "80%"
            }
        }}>
        <DialogTitle>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom >
                <Localized id="requirements-catalogue">
                    <span>Katalog wymagań</span>
                </Localized>
            </Typography>
        </DialogTitle>
        <DialogContent>
            <RequirementsBrowser selection={selection} itemChecked={itemChecked} itemUnchecked={itemUnchecked} {...browserProps} />
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={pending} onClick={() => handleOkClick(selection)} color="secondary" variant="contained">
                <Localized id="ok">
                    <span>OK</span>
                </Localized>
            </LoadingButton>
            <LoadingButton loading={pending} onClick={cancel}>
                <Localized id="cancel"><span>Anuluj</span></Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}

export interface RequirementsBrowserProps {
    selection: Set<AppId>
    itemChecked: (id: AppId) => void
    itemUnchecked: (id: AppId) => void
    deviceTypeId: AppId | null
    disabled: Set<AppId>
    lang: string
    defaultTypeId?: AppId
}

export const RequirementsBrowser = (props: RequirementsBrowserProps) => {
    const { selection: selection, itemChecked, itemUnchecked, deviceTypeId, disabled, lang } = props
    const { state: reqState } = useAppSelector(selectRequirements)
    const { state: requirementsTypesState } = useAppSelector(selectRequirementsTypes)
    const relationship = useAppSelector(state => {
        if (deviceTypeId) {
            return selectAllStructureFamily(state, deviceTypeId)
        } else {
            return selectAllStructure(state)
        }
    })

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useLocalStorage<number>("ReqDialog_RowPerPage", 100);

    const allRequirements = useAppSelector(selectAllRequirements)
    const allRequirementsTypes = useAppSelector(selectAllRequirementsTypes)
    const [storedType, setStoredType] = useLocalStorage<RequirementType | null>("ReqDialog_TypeFilter", null)
    const [type, setType] = useState<RequirementType | null>(() => {
        if (props.defaultTypeId) {
            return allRequirementsTypes.find(t => t.id === props.defaultTypeId) ?? null
        } else {
            return storedType
        }
    })
    const [search, setSearch] = useState<string>("")

    const rows = useMemo(() => {
        const filterByType: (e: Requirement) => boolean =
            type
                ? e => e.typeId === type.id
                : _ => true
        return allRequirements.filter((row) => {
            const searchableName = lang === 'PL' ? row.name : row.name_EN
            return filterByType(row) && searchableName.toLowerCase().includes(search.toLowerCase()) && relationship.has(row.structureId)
        })
    }, [allRequirements, type, relationship, search, lang])

    const requirementsCount = rows.length
    const requirementsPage = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    const handleRequirementTypeChange = useCallback((_: any, newtype: RequirementType | null) => {
        setType(newtype)
        setStoredType(newtype)
    }, [setType])

    const handleSearchChange: AppTextFieldHandler = useCallback(e => setSearch(e.target.value), [setSearch]);

    const handleClearSearchClick = () => {
        setSearch("");
    };

    const handleChangePage = useCallback((_: unknown, newPage: number) => {
        setPage(newPage)
    }, [setPage])

    const handleChangeRowsPerPage: AppTextFieldHandler = useCallback((event) => {
        let val = parseInt(event.target.value, 10);
        setRowsPerPage(val)
        setPage(0)
    }, [setPage, setRowsPerPage])

    const handleCheckboxChange = (reqId: AppId, checked: boolean) => {
        if (checked) {
            itemChecked(reqId)
        } else {
            itemUnchecked(reqId)
        }
    }

    const ReqTypeName = ({ reqTypeId }: { reqTypeId: AppId }) => {
        if (requirementsTypesState.type !== "loaded") {
            return <Skeleton animation="wave" variant="text" ></Skeleton>
        }
        const entity = selectRequirementTypeById(store.getState(), reqTypeId)

        if (!entity) {
            return <Skeleton animation="wave" variant="text" ></Skeleton>
        } else {
            return <span>{entity.code} </span>
        }
    }

    const getReqName = ({ code, name }: RequirementType) => `${name}`

    switch (reqState.type) {
        case "empty":
        case "error":
        case "loading":
            return <Skeleton animation="wave" variant="rectangular" height="64vh" ></Skeleton>;
        case "loaded":
            return <>
                <Autocomplete
                    sx={{
                        marginTop: 2,
                        marginBottom: 2
                    }}
                    disabled={props.defaultTypeId !== undefined}
                    options={allRequirementsTypes}
                    value={type as RequirementType}
                    onChange={handleRequirementTypeChange}
                    getOptionLabel={getReqName}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={params => <TextField {...params}
                        fullWidth
                        size="small"
                        label={<Localized id="requirements-filter"><span>Filtruj wymagania</span></Localized>} />}
                />
                <TextField
                    fullWidth
                    value={search}
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton size="small" onClick={handleClearSearchClick}>
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{ marginBottom: 2 }}
                    size="small"
                    onChange={handleSearchChange}
                    label={<Localized id="search">Wyszukaj</Localized>}>
                </TextField>
                <TableContainer
                    component={Paper}
                    sx={{ display: 'flex', height: 'calc(100vh - 550px)' }}
                >
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        <Localized id="requirements-name">
                                            <span>Nazwa </span>
                                        </Localized>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        <Localized id="device-type-name">
                                            <span>Nazwa typu rządzenia</span>
                                        </Localized>
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ minWidth: 80, width: 80, maxWidth: 80 }}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        <Localized id="requirements-type">
                                            <span>Typ</span>
                                        </Localized>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Localized id="system-item">
                                        <span>Systemowy</span>
                                    </Localized>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {requirementsPage.map(({ id, code, name, name_EN, typeId, structureId, isSystem }) => {
                                const disable = disabled.has(id)
                                const displayedName = lang === 'PL' ? name : name_EN
                                return <TableRow key={id}>
                                    <TableCell padding="checkbox">
                                        <Checkbox disabled={disable} checked={selection.has(id)} onChange={(_, checked) => handleCheckboxChange(id, checked)} />
                                    </TableCell>
                                    <TableCell>
                                        <Typography component="span" color={disable ? "text.secondary" : "text.primary"}>
                                            <div dangerouslySetInnerHTML={{ __html: displayedName }} />
                                        </Typography>
                                    </TableCell>
                                    <TableCell><StructureName structureId={structureId} /></TableCell>
                                    <TableCell>
                                        <ReqTypeName reqTypeId={typeId} />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox defaultChecked={isSystem} disabled />
                                    </TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 50, 100]}
                    component="div"
                    count={requirementsCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>
    }
}

