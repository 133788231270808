import { Localized } from "@fluent/react"
import { Alert, Box, Button, Card, CardActions, CardContent, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Divider, IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { Container, Stack } from "@mui/system"
import { useParams } from "react-router-dom"
import { LoadActivities, LoadValidations } from "../../app/AppDataLoader"
import { AppId } from "../../app/appTypes"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { AcceptanceCriteria, Activity, createActivity, createSysActivity, deleteActivity, deleteStepCriteria, deleteSysActivity, deleteSysStepCriteria, selectAllActivities, updateActivity, updateSysActivity } from "./activitiesSlice"
import { deleteSysValidation, deleteValidation, selectValidationById, updateSysValidation, updateValidation, Validation } from "./validationsSlice"
import EditIcon from '@mui/icons-material/Edit'
import { useCallback, useState } from "react"
import { selectModule } from "../modules/moduleSlice"
import { CreateStepAction, UpdateStepAction, UpdateTestAction } from "./types"
import { UpdateStepDialogForm } from "./form/UpdateStepDialogForm"
import { CreateCriteriaDialogForm } from "./form/CreateCriteriaDialogForm"
import { CreateStepDialogForm } from "./form/CreateStepDialogForm"
import { UpdateTestDialogForm } from "./form/UpdateTestDialogForm"
import { UpdateCriteriaDialogForm } from "./form/UpdateCriteriaDialogForm"
import ButtonGroup from '@mui/material/ButtonGroup'
import { LoadingButton } from "@mui/lab"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from "react-router-dom"
import { postWithAuth } from "../../http"
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import { If } from "../../app/If"

export const EditValidation = () => {
    const { id: paramId } = useParams(); const id = paramId ?? ''
    const navigate = useNavigate()
    const module = useAppSelector(state => selectModule(state).currentModule?.code)

    const onDeleted = useCallback(() => {
        const editURL = module === "super"? `/sys/validations/` : `/validations`
        navigate(editURL);
    }, [])
    const onClose = useCallback(() => {
        navigate(-1)
    }, [])

    return <LoadValidations component={<Skeleton />}>
        <LoadActivities component={<Skeleton />}>
            <EditValidationForm id={id} onDeleted={onDeleted} onClose={onClose} />
        </LoadActivities> 
    </LoadValidations>
}

export interface EditValidationProps {
    id : AppId
    onDeleted : () => void
    onClose   : () => void
}
export const EditValidationForm = (props: EditValidationProps) => {
    const { id, onDeleted, onClose } = props
    const test = useAppSelector(state => selectValidationById(state, id))
    const steps = useAppSelector(state => selectAllActivities(state).filter(({ validationId }) => validationId === id))
    const module = useAppSelector(state => selectModule(state).currentModule?.code)

    if (module === undefined) return null

    if (!test) return <Alert severity="warning">
        <Localized id="error-entity-not-exists">
            <div>Obiekt nie istnieje</div>
        </Localized>
    </Alert>

    const [update, createStep, updateStep] 
        = module === "super" 
        ? [updateSysValidation, createSysActivity, updateSysActivity]
        : [updateValidation, createActivity, updateActivity]

    return <TestView 
        test={test} 
        steps={steps} 
        updateAction={update}
        createStepAction={createStep}
        updateStepAction={updateStep}
        onDeleted={onDeleted}
        onClose={onClose}
    />
}

interface ValidationFormProps {
    test: Validation
    steps: Activity[]
    updateAction: UpdateTestAction
    createStepAction: CreateStepAction 
    updateStepAction: UpdateStepAction
    onDeleted : () => void
    onClose   : () => void
}

type DialogState =
    | { type: "none"}
    | { type: "editTest", data: Validation }
    | { type: "editStep", stepId: AppId }
    | { type: "addStep", data: Pick<Activity, "validationId">}
    | { type: "addCriteria", stepId: AppId }
    | { type: "editCriteria", stepId: AppId, id: AppId }

const FormDialogDispatcher = 
    ({ payload, update, createStep, updateStep, close }
        : { payload: DialogState
          , update: UpdateTestAction
          , createStep: CreateStepAction
          , updateStep: UpdateStepAction 
          , close: () => void
        }) => {
    switch (payload.type) {
        case "none":
            return null
        case "addCriteria":
            return <CreateCriteriaDialogForm stepId={payload.stepId} close={close} update={updateStep} />
        case "editCriteria":
            return <UpdateCriteriaDialogForm stepId={payload.stepId} id={payload.id} close={close} update={updateStep} />
        case "editTest":
            return <UpdateTestDialogForm data={payload.data} close={close} udpate={update} />
        case "addStep":
            return <CreateStepDialogForm data={payload.data} close={close} create={createStep} />
        case "editStep":
            return <UpdateStepDialogForm stepId={payload.stepId} close={close} update={updateStep} />
    }
}

const TestView = (props: ValidationFormProps) => {
    const { test, createStepAction, updateStepAction, updateAction, onClose, onDeleted } = props
    const { id, name, description, stage, isSystem} = test
    const steps = useAppSelector(state => selectAllActivities(state).filter(({ validationId }) => validationId === id))

    const [dialog, setDialog] = useState<DialogState>({ type: "none" })

    const closeDialog = useCallback(() => setDialog({ type: "none" }), [])
    const dispatch = useAppDispatch()

    const module = useAppSelector(state => selectModule(state).currentModule?.code)
    const readOnly = useCallback((system: boolean): boolean => module === "super" ? false : system, [module])
    const [itemToDelete, setItemToDelete] = useState<AppId | undefined>(undefined)
    const [deleting, setDeleting] = useState(false)
    const [waitingForAnswer, setWaitingForAnswer] = useState<boolean>(false);

    const isSuper = module === "super";

    const handleGenerateDescriptionClick = async () => {
        setWaitingForAnswer(true)
        try{
            const result = await dispatch(postWithAuth({
                url: `api/ai/ask`,
                payload: {
                    Type : 'validation-steps',
                    Question: "Nazwa: " + name + " Opis: " + description
                }
            }))
            if (postWithAuth.fulfilled.match(result)) {
                const { payload } = result
                
                try {
                    const parseAnswer = JSON.parse(payload.answer);

                    if(!Array.isArray(parseAnswer)) {
                        dispatch(showError("validation-ai-error"));
                    }

                    for(let i of parseAnswer) {
                        if(typeof i.description !== "string" || !Array.isArray(i.acceptanceCriteria) || !i.acceptanceCriteria.every(c => typeof c === "string")) {
                            dispatch(showError("validation-ai-error"));
                        }

                        if(isSuper) {
                            await dispatch(createSysActivity({ validationId: id, name: i.description, acceptanceCriteria: i.acceptanceCriteria.map(f => {
                                return { description: f }
                            }) })).unwrap()
                        } else {
                            await dispatch(createActivity({ validationId: id, name: i.description, acceptanceCriteria: i.acceptanceCriteria.map(f => {
                                return { description: f }
                            }) })).unwrap()
                        }
                    }
                } catch {
                    dispatch(showError("validation-ai-error"))
                }
            } else {
                if(result && result.payload && result.payload.kind === "http") {
                    dispatch(showError(result.payload.problem?.title))
                }
            }
        }
        finally {
            setWaitingForAnswer(false)
        }
    }

    const handleEditTestClick = useCallback(() => {
        setDialog({
            type: "editTest",
            data: test,
        })
    }, [test])
    const handleAddStepClick = useCallback(() => {
        setDialog({
            type: "addStep",
            data: {
                validationId: id
            },
        })
    }, [id])
    const handleEditStepClick = useCallback((stepId: AppId) => {
        setDialog({
            type: "editStep",
            stepId,
        })
    }, [])

    const handleDeleteStepClick = useCallback( async (stepId: AppId) => {
        if (stepId) {
            setDeleting(true)
            try {
                if(module === "super"){
                    await dispatch(deleteSysActivity(stepId))
                }else{
                    await dispatch(deleteActivity(stepId))
                }
                
                dispatch(showSuccess("deleted"))
            } catch (error) {
                dispatch(showError("error"))
            }
         
            setDeleting(false)
        }
    }, [])
    
    const handleAddCriteriaClick = useCallback((stepId: AppId) => {
        setDialog({
            type: "addCriteria",
            stepId,
        })
    }, [])
    const handleEditCriteriaClisk = useCallback((stepId: AppId, id: AppId) => {
        setDialog({
            type: "editCriteria",
            stepId,
            id,
        })
    }, [])


    const handleDeleteCriteriaClick = useCallback(async (stepId: AppId, id: AppId) => {
        if (stepId && id) {
            setDeleting(true)
            try {
                if(module === "super"){
                    await dispatch(deleteSysStepCriteria({stepId, id}))
                }else{
                    await dispatch(deleteStepCriteria({stepId, id}))
                }
                
                dispatch(showSuccess("deleted"))
            } catch (error) {
                dispatch(showError("error"))
            }
         
            setDeleting(false)
        }
    }, [])

    const handleBackClick = onClose

    const handleDeleteClick = useCallback((id: AppId) => {
        setItemToDelete(id)
    }, [])
    const handleCancelDelClick = useCallback(() => {
        setItemToDelete(undefined)
    }, [])

    const handleConfirmDelClick = async () => {
        if (itemToDelete) {
            setDeleting(true)
            try {
                if(module === "super"){
                    await dispatch(deleteSysValidation(itemToDelete))
                }else{
                    await dispatch(deleteValidation(itemToDelete))
                }
                
                dispatch(showSuccess("deleted"))

                onDeleted()
            } catch (error) {
                dispatch(showError("error"))
            }
            setItemToDelete(undefined)
            setDeleting(false) 
        }
    }

    return <Container maxWidth="lg">
        <Stack spacing={2}>
            <Card>
                <CardContent>
                    <Typography component="div" color="text.secondary" gutterBottom>
                        Nazwa testu&nbsp;<Chip size="small" color="primary" variant="outlined" label={stage} />
                    </Typography>
                    <Typography variant="h5"><div dangerouslySetInnerHTML={{ __html: name ?? "" }}></div></Typography>
                    <Divider sx={{
                        marginTop: 1,
                    }}>Opis</Divider>
                    <Typography variant="body2">{description}</Typography>
                </CardContent>
                <CardActions>
                    <Button onClick={handleEditTestClick} variant="text">
                        <Localized id="edit">Edytuj</Localized>
                    </Button>
                </CardActions>
            </Card>
            <Card>
                <CardContent>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Typography color="text.secondary" gutterBottom>
                            Kroki testu
                        </Typography>
                        <If condition={waitingForAnswer} otherwise={
                            <IconButton onClick={handleGenerateDescriptionClick}>
                                <AutoFixHighIcon />
                            </IconButton>
                        }>
                            <CircularProgress />
                        </If>
                    </Box>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: "60px" }}>L.p.</TableCell>
                                    <TableCell>Opis</TableCell>
                                    <TableCell>Kryteria akceptacji</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{steps.map((step, i) => <TestStepView 
                                key={step.id}
                                seq={i + 1} 
                                step={step} 
                                onStepEditClick={handleEditStepClick} 
                                onStepDeleteClick={handleDeleteStepClick} 
                                onCriteriaEditClick={handleEditCriteriaClisk} 
                                onCriteriaDeleteClick={handleDeleteCriteriaClick} 
                                onCriteriaAddClcik={handleAddCriteriaClick} />)}</TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <CardActions>
                    <Button onClick={handleAddStepClick} variant="text">
                        <Localized id="validation-add-step">Dodaj krok</Localized>
                    </Button>
                </CardActions>
            </Card>
            <Card>
                <CardContent>
                    <ButtonGroup>

                        <Button disabled={readOnly(isSystem)} onClick={() => handleDeleteClick(id)} >
                            <Localized id="delete">
                                <span>Usuń</span>
                            </Localized>
                        </Button>
                        <Button onClick={handleBackClick} color="secondary" variant="outlined">
                            <Localized id="back">Wróć</Localized>
                        </Button>
                    </ButtonGroup>
                </CardContent>
            </Card>
        </Stack>
        <Dialog maxWidth="md" fullWidth open={dialog.type !== "none"}>
            <FormDialogDispatcher 
                payload={dialog} 
                createStep={createStepAction} 
                updateStep={updateStepAction} 
                update={updateAction}
                close={closeDialog}
            />
        </Dialog>
        <Dialog open={itemToDelete !== undefined}>
            <DialogContent>
                <DialogContentText>
                    <Localized id="confirm-delete">
                        <span>Czy napewno chcesz usunąć?</span>
                    </Localized>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={deleting} onClick={handleConfirmDelClick}>
                    <Localized id="yes"><span>Tak</span></Localized>
                </LoadingButton>
                <LoadingButton loading={deleting} onClick={handleCancelDelClick}>
                    <Localized id="no"><span>Nie</span></Localized>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </Container>
    
}

interface TestStepViewProps {
    step: Activity
    seq: number
    onStepEditClick: (stepId: AppId) => void
    onStepDeleteClick: (stepId: AppId) => void
    onCriteriaEditClick: (stepId: AppId, id: AppId) => void
    onCriteriaDeleteClick: (stepId: AppId, id: AppId) => void
    onCriteriaAddClcik: (stepId: AppId) => void
}

const TestStepView = ({ step, seq, onStepEditClick, onStepDeleteClick, onCriteriaEditClick, onCriteriaDeleteClick, onCriteriaAddClcik }: TestStepViewProps) => {
    const { id, name, acceptanceCriteria } = step
    const rowsNum = acceptanceCriteria.length === 0 ? 1 : acceptanceCriteria.length
    const [fst, ...rest] = acceptanceCriteria

    const handleStepEditClick = useCallback(() => { onStepEditClick(id) }, [id, onStepEditClick])
    const handleStepDeleteClick = useCallback(() => { onStepDeleteClick(id) }, [id, onStepDeleteClick])
    const handleCriteriaAddClick = useCallback(() => { onCriteriaAddClcik(id) }, [id, onCriteriaAddClcik])
  
    return <>
        <TableRow key={`${id}_a`}>
            <TableCell rowSpan={rowsNum}>{seq}.</TableCell>
            <TableCell rowSpan={rowsNum}>{name}&nbsp;
                <IconButton onClick={handleStepEditClick} size="small"><EditIcon /></IconButton>
                <IconButton onClick={handleStepDeleteClick} size="small"><DeleteIcon /></IconButton>
            </TableCell>
            <TableCell>{fst === undefined ? <EmptyCriteria /> : <CriteriaCellView stepId={id} criterion={fst} onEditClick={onCriteriaEditClick} onDeleteClick={onCriteriaDeleteClick}/>}</TableCell>
        </TableRow>
        {rest.map((x) => <TableRow key={`${id}_${x.id}`}><TableCell><CriteriaCellView stepId={id} criterion={x} onEditClick={onCriteriaEditClick} onDeleteClick={onCriteriaDeleteClick}/></TableCell></TableRow>)}
        <TableRow key={`${id}_z`}>
            <TableCell sx={{
                textAlign: "right"
            }} colSpan={3}><Button onClick={handleCriteriaAddClick} variant="text">Dodaj kryterium</Button></TableCell>
        </TableRow>
    </>
}

interface CriteriaCellViewProps {
    stepId: AppId
    criterion: AcceptanceCriteria
    onEditClick: (stepId: AppId, id: AppId) => void
    onDeleteClick: (stepId: AppId, id: AppId) => void
}
const CriteriaCellView = ({criterion, onEditClick, onDeleteClick, stepId }: CriteriaCellViewProps) => {
    const { id, description } = criterion
    const handleEditClick = useCallback(() => {
        if (id !== undefined) {
            onEditClick(stepId, id)
        }
    }, [stepId, id, onEditClick])

    const handleStepDeleteClick = useCallback(() => {
        if (id !== undefined) {
            onDeleteClick(stepId, id)
        }
    }, [stepId, id, onDeleteClick])

    return <>{description}&nbsp;
        <IconButton onClick={handleEditClick} size="small"><EditIcon /></IconButton>
        <IconButton onClick={handleStepDeleteClick} size="small"><DeleteIcon /></IconButton>
        </>
}

const EmptyCriteria = () => <Typography color="text.secondary"><span>N\A</span></Typography>
    
