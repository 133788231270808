import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, nanoid } from "@reduxjs/toolkit";
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../app/appTypes";
import { RootState } from "../../app/store";
import { getWithAuth, postWithAuth, putWithAuth, deleteWithAuth } from "../../http"

export interface MailTemplate {
    id: AppId,
    type: string,
    context: string,
    body: string,
    bodyEN: string,
    subject: string,
    subjectEN: string,
    companyId: number
}

export type DraftMailTemplate = Omit<MailTemplate, "id" | "companyId">;

export interface ExtendedMailTemplate extends MailTemplate {
    originalId: AppId;
}

const adapter = createEntityAdapter<MailTemplate>({
    selectId: (mailTemplate) => mailTemplate.id,
})

export type MailTemplatesState = EntityState<MailTemplate> & { state: AppDataState }

const initialState: MailTemplatesState = adapter.getInitialState({
    state: { type: "empty" },
})

export const loadMailTemplates = createAsyncThunk<MailTemplate[], string | undefined, AppThunkAPIType>("mailTemplates/load", async (context, { getState, requestId, dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({ url: `api/mail-templates${context ? '?context=' + context : ''}` }))
    const { payload } = result

    if (getWithAuth.fulfilled.match(result)) {
        return payload as MailTemplate[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }

})

export const createNewCompanyMailTemplate = createAsyncThunk<ExtendedMailTemplate, MailTemplate, AppThunkAPIType>(
    "mailTemplates/new-company-mail-template",
    async (mailTemplate, { getState, requestId, dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ url: "api/mail-templates", payload: mailTemplate }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            payload.originalId = mailTemplate.id;
            return payload as ExtendedMailTemplate
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const createMailTemplate = createAsyncThunk<MailTemplate, DraftMailTemplate, AppThunkAPIType>(
    "mailTemplate/new",
    async (mailTemplate: DraftMailTemplate, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ url: "api/sys-mail-templates", payload: mailTemplate }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as MailTemplate
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateMailTemplate = createAsyncThunk<MailTemplate, MailTemplate, AppThunkAPIType>(
    "mailTemplate/edit",
    async (mailTemplates: MailTemplate, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ url: `api/sys-mail-templates/${mailTemplates.id}`, payload: mailTemplates }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return mailTemplates
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteMailTemplate = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "mailTemplate/delete",
    async (mailTemplateId, { rejectWithValue, dispatch }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/mail-templates/${mailTemplateId}` }))
        const { payload } = result
        if (deleteWithAuth.fulfilled.match(result)) {
            return mailTemplateId
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const mailTemplatesSlice = createSlice({
    name: "mailTemplates",
    initialState,
    reducers: {
        clearRequirementsTypesSlice: (state) => {
            adapter.removeAll(state)
            state.state = { type: "empty" }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createMailTemplate.fulfilled, (state, action) => {
            state = adapter.addOne(state, action.payload)
        });

        builder.addCase(createNewCompanyMailTemplate.fulfilled, (state, action) => {
            const { originalId } = action.payload
            const changes: Omit<MailTemplate, "id"> = action.payload
            state = adapter.updateOne(state, {
                id: originalId,
                changes,
            });
        });

        builder.addCase(updateMailTemplate.fulfilled, (state, action) => {
            const { id } = action.payload
            const changes: Omit<MailTemplate, "id"> = action.payload
            state = adapter.updateOne(state, {
                id,
                changes,
            });
        });

        builder.addCase(deleteMailTemplate.fulfilled, (state, action) => {
            const id = action.payload

            state = adapter.removeOne(state, id);
        });


        builder.addCase(loadMailTemplates.rejected, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                state.state = {
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })

        builder.addCase(loadMailTemplates.pending, (state, action) => {
            if (state.state.type === "empty" || state.state.type === "loaded") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })


        builder.addCase(loadMailTemplates.fulfilled, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                adapter.setAll(state, action.payload)
                state.state = { type: "loaded" }
            }
        })
    },
})

export const selectMailTemplates = (state: RootState): MailTemplatesState => state.mailTemplates

export const
    { selectAll: selectAllMailTemplates
        , selectById: selectMailTemplateById
        , selectTotal: selectTotalMailTemplates
        , selectEntities: selectMailTemplatesEntities
    } = adapter.getSelectors<RootState>(selectMailTemplates)

export default mailTemplatesSlice.reducer;
