import { Alert, AlertTitle } from "@mui/material"

export const AlertFeatureInProgress = () => {
    return <>
        <Alert severity="info">
            <AlertTitle>Update on Feature Development</AlertTitle>
            We’re pleased to let you know that the requested functionality is currently <strong>in progress</strong>, and our team is actively working to bring it to completion.
            We expect it to be ready soon and appreciate your patience as we fine-tune every detail to ensure it meets your expectations.<br /><br />
            Please <strong>stay tuned</strong> for updates—exciting developments are on the way!<br /><br />
            Warm regards,<br />
            V-Chainer team
        </Alert>
    </>

}