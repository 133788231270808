import React, { useEffect, useState } from 'react';
import {
  Box,
  Badge,
  IconButton,
  Menu,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Button,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { loadUserNotifications, UserNotification } from './userNotificationAPI';
import { useAppDispatch } from '../../app/hooks';

export const NotificationPanel = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<UserNotification[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch()

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const fetchNotifications = async () => {
    try {
      const userNotifications = await dispatch(loadUserNotifications()).unwrap();
      setNotifications(userNotifications);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  useEffect(() => {
    fetchNotifications();

    const interval = setInterval(() => {
      console.log('Sprawdzam nowe powiadomienia...');
      fetchNotifications();
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  const handleNotificationClick = async (notification: UserNotification) => {
    try {
      setNotifications((prev) =>
        prev.map((n) =>
          n.id === notification.id ? { ...n, seen: true } : n
        )
      );
      navigate(notification.link);
      handleCloseMenu();
    }
    catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const handleDeleteNotification = async (id: string) => {

  };

  const unreadCount = notifications.filter((n) => !n.isRead).length;

  return (
    <Box>
      {/* Ikona powiadomień */}
      <IconButton color="inherit" onClick={handleOpenMenu}>
        <Badge badgeContent={unreadCount} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      {/* Panel powiadomień */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: { maxHeight: 400, width: 350 },
        }}
      >
        <Typography variant="h6" sx={{ px: 2, py: 1 }}>
          Notifications ({notifications.length})
        </Typography>
        {notifications.length === 0 ? (
          <Typography variant="body2" sx={{ px: 2, py: 1 }}>
            No notifications
          </Typography>
        ) : (
          <List>
            {notifications.map((notification) => (
              <ListItem
                key={notification.id}
                button
                onClick={() => handleNotificationClick(notification)}
                sx={{
                  backgroundColor: notification.isRead
                    ? 'inherit'
                    : 'rgba(25, 118, 210, 0.1)',
                }}
              >
                <ListItemText
                  primary={notification.message}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(e) => {
                      e.stopPropagation(); // Zapobiega kliknięciu notyfikacji
                      handleDeleteNotification(notification.id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
        <Box sx={{ textAlign: 'center', p: 1 }}>
          <Button size="small" onClick={() => setNotifications([])}>
            Clear All
          </Button>
        </Box>
      </Menu>
    </Box>
  );
};

