import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useCallback, useEffect, useState } from 'react';
import { AppId } from "../../app/appTypes";
import { unwrapResult } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../notifications/notificationsSlice";
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { Box, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { Localized, useLocalization } from '@fluent/react';
import { AppLoader } from '../../app/AppLoader';
import { FlexDiv } from '../../app/Utils';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { deleteSupplierDevice, loadSupplierDevices, selectAllSupplierDevices, selectSupplierDevices } from "./supplierDeviceSlice";
import { getStructureName } from '../device/Structure';

export const CostEstimationComponents = () => {
    const { loaded } = useAppSelector(selectSupplierDevices)
    const { l10n } = useLocalization()
    const dispatch = useAppDispatch()
    const rows = useAppSelector(state => selectAllSupplierDevices(state))
    const [deleting, setDeleting] = useState(false)
    const [itemToDelete, setItemToDelete] = useState<AppId | undefined>(undefined)
    
    const handleDeleteClick = useCallback((id: AppId) => {
        setItemToDelete(id)
    }, [setItemToDelete])

    const handleCancelDelClick = useCallback(() => {
        setItemToDelete(undefined)
    }, [setItemToDelete])
    
    const handleConfirmDelClick = async () => {
        if (itemToDelete) { 
            setDeleting(true)           
            try {
                unwrapResult(await dispatch(deleteSupplierDevice(itemToDelete)))
                dispatch(showSuccess("deleted"))
            } catch (error) {
                dispatch(showError("error"))
            }
            setItemToDelete(undefined)
            setDeleting(false)
        }
    }

    useEffect(() => {
        if (!loaded) {
            dispatch(loadSupplierDevices())
        }
    }, [dispatch, loaded])

    const columns: GridColDef[] = 
    [
        { 
            field: 'name', 
            headerName: l10n.getString('devices-name',null,'Nazwa'),   
            flex:1 
        },
        {
            field: 'structureId', 
            flex:1,
            headerName: l10n.getString('devices-type', null, 'Typ urządzenia'), 
            valueGetter: (_, row) => {
                return getStructureName(row.structureId)
            }
        },
        { 
            field: 'actions', type: 'actions', width: 200,
            renderCell: (params) => {
                return(
                <ButtonGroup>
                    <Button component={Link} to={`/costestimationcomponents/edit/${params.id}`} color="secondary" variant="outlined">
                            <Localized id="edit">
                            <span>Edytuj</span> 
                        </Localized>
                    </Button>
                    <Button color="primary" onClick={() => handleDeleteClick(params.row.id)}>
                        <Localized id="delete">
                            <span>Usuń</span>
                        </Localized>
                    </Button>
                </ButtonGroup>
                )
            }
        }
    ];
        return (
            <>
            <Box sx={{ width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                <DataGrid autoHeight rows={rows} columns={columns} />
                </div>
            </div>
                <FlexDiv>
                    <Fab component={Link} to="/costestimationcomponents/create" color="secondary" sx={{marginTop:2}}>
                        <AddIcon />
                    </Fab>
                </FlexDiv>
                <Dialog open={itemToDelete !== undefined}>
                    <DialogContent>
                        <DialogContentText>
                            <Localized id="confirm-delete">
                                <span>Czy napewno chcesz usunąć?</span>
                            </Localized>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirmDelClick}>
                            <Localized id="yes"><span>Tak</span></Localized>
                        </Button>
                        <Button onClick={handleCancelDelClick}>
                            <Localized id="no"><span>Nie</span></Localized>
                        </Button>
                    </DialogActions>
                </Dialog>
                <AppLoader open={deleting} />
                </Box>
            </>
        );
}
