import { Localized, useLocalization } from "@fluent/react"
import { useCallback, useEffect, useState } from "react"
import { Box, Button, ButtonGroup, Dialog, Fab } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import AddIcon from '@mui/icons-material/Add'
import { unwrapResult } from "@reduxjs/toolkit"
import { useNavigate } from "react-router-dom"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectSettings } from "../../settings/settingsSlice"
import { LoadingContainer } from "../../../app/LoadingContainer"
import { AdminDocumentEdit } from "./AdminDocumentEdit"
import { DocumentStatusLabel } from "../DocumentStatusLabel"
import { DocumentHeader, loadAllDocuments } from "../documentsApi"
import { QuickSearchToolbar } from "../../../app/QuickSearchToolbar"

export const DocumentsOverview = () => {
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [documents, setDocuments] = useState<DocumentHeader[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<DocumentHeader>();

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadAllDocuments())
            .then(unwrapResult)
            .then(setDocuments)
            .catch((error) => console.error(error))
            .finally(() => setIsLoading(false));
    }, [])


    const handleOpenEditDialog = useCallback(() => {
        setEditDialogOpen(true)
    }, [setEditDialogOpen])


    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const handleEditRow = useCallback((entity: DocumentHeader) => {
        setSelectedRow(entity)
        setEditDialogOpen(true)
    }, [])

    const columns: GridColDef[] = [
        {
            field: 'no',
            headerName: l10n.getString('', null, 'Numer'),
            width: 200,
        },
        {
            field: 'title',
            headerName: l10n.getString('', null, 'Title'),
            width: 400,
        },
        {
            field: 'companyName',
            headerName: l10n.getString('', null, 'Firma'),
            width: 300,
        },
        {
            field: 'createdByFullName',
            headerName: l10n.getString('', null, 'Utworzył'),
            width: 300,
        },
        {
            field: 'createDate',
            headerName: l10n.getString('project-create-date', null, 'Data Utworzenia'),
            width: 200,
            valueGetter: (_, row) => {
                return row.createDate ? new Date(row.createDate).toISOString().split('T')[0] : "";
            }
        },

        {
            field: 'status',
            headerName: l10n.getString('project-status', null, 'Status'),
            width: 150,
            renderCell: (params) => (
                <div>
                    <DocumentStatusLabel status={params.row.status ?? ""} />
                </div>
            )
        },
        {
            field: 'actions', type: 'actions', width: 200,
            renderCell: (params) => {
                return (
                    <ButtonGroup>
                        <Button onClick={() => handleEditRow(params.row)} color="secondary" variant="outlined">
                            <Localized id="edit">
                                <span>Edytuj</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ];

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            style={{ minHeight: 100, height: 'calc(100vh - 200px)' }}
                            localeText={getCustomLocaleText()}
                            rows={documents}
                            columns={columns}
                            slots={{ toolbar: QuickSearchToolbar }}
                        />
                    </div>
                </div>
            </Box>
            {
                editDialogOpen && selectedRow &&
                <AdminDocumentEdit document={selectedRow} onSucces={function (item: DocumentHeader): void {
                    throw new Error("Function not implemented.")
                }} onCancel={function (): void {
                    throw new Error("Function not implemented.")
                }}></AdminDocumentEdit>
            }

        </>
    )
}
