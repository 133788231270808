import { Alert, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Paper, Select, Skeleton, Stack, TableContainer, TextField } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { Localized, useLocalization } from "@fluent/react"
import { LoadCompanies } from "../../../app/AppDataLoader"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { selectSettings } from "../../settings/settingsSlice"
import { unwrapResult } from "@reduxjs/toolkit"
import SyncIcon from '@mui/icons-material/Sync';
import { If } from "../../../app/If"
import { Company, loadCompanies, selectCompanyById } from "../../company/companiesSlice"
import { store } from "../../../app/store"
import { LoadingContainer } from "../../../app/LoadingContainer"
import { FlexDiv } from "../../../app/Utils"
import { LoadingButton } from "@mui/lab"
import AddIcon from '@mui/icons-material/Add'
import { showError, showSuccess } from "../../notifications/notificationsSlice"
import produce from "immer";
import { createSystemDocumentSettings, deleteSystemDocumentSettings, DocumentSettings, loadSystemDocumentSettings, loadSystemDocumentSettingsForCompany } from "./SystemDocumentSettingsApi"

type DialogState =
    | { type: "none" }
    | { type: "create" }

export const SystemDocumentSettings = () => {
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const dispatch = useAppDispatch();
    const [systemDocumentSettings, setSystemDocumentSettings] = useState<DocumentSettings[]>([]);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [company, setCompany] = useState('all');
    const [documentCode, setDocumentCode] = useState('all');
    const [saving, setSaving] = useState(false);
    const documentCodes = ["URS", "RiskAnalysis"];
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false)
    const [dialog, setDialog] = useState<DialogState>({ type: "none" })
    const [itemToDelete, setItemToDelete] = useState<string | undefined>(undefined)
    const [deleting, setDeleting] = useState(false)
    const [serverError, setServerError] = useState("")
    const [companyRequired, setCompanyRequired] = useState(false)
    const [nameRequired, setNameRequired] = useState(false)
    const [valueRequired, setValueRequired] = useState(false)
    const [documentCodeRequired, setDocumentCodeRequired] = useState(false)

    const [companyDialog, setCompanyDialog] = useState();
    const [documentCodeDialog, setDocumentCodeDialog] = useState<string>();
    const [nameDialog, setNameDialog] = useState<string>('');
    const [valueDialog, setValueDialog] = useState<string>('');

    const handleCompanyDialogChange = (e) => setCompanyDialog(e.target.value);

    const handleDocumentCodeDialogChange = (e) => {
        setDocumentCodeDialog(e.target.value);
        setNameDialog('');
        setValueDialog('');
    }

    const handleNameDialogChange = (e) => {
        setNameDialog(e.target.value);
        setValueDialog('');
    };

    const handleValueDialogChange = (e) => {
        if(documentCodeDialog === 'RiskAnalysis' && /^\d*$/.test(e.target.value) && Number(e.target.value) >= 1 && Number(e.target.value) <= 1000) setValueDialog(e.target.value);
        else if(documentCode === 'URS') setValueDialog(e.target.value);
    }

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadSystemDocumentSettings(`?documentCode=${documentCode}`))
            .then(unwrapResult)
            .then(setSystemDocumentSettings)
            .catch((error) => console.error(error))
            .finally(() => setIsLoading(false));
        dispatch(loadCompanies())
            .then(unwrapResult)
            .then(setCompanies)
            .catch((error) => console.error(error))
    }, [dispatch])

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const handleEventTypeChange = (event) => {
        setSaving(false);
        setDocumentCode(event.target.value);
    };

    const handleCompanyChange = (event) => {
        setSaving(false);
        setCompany(event.target.value);
    };

    const handleRefreshClick = () => {
        setSaving(true);
        setIsLoadingData(true);
        if(company === 'all') {
            dispatch(loadSystemDocumentSettings(`?documentCode=${documentCode}`))
                .then(unwrapResult)
                .then(setSystemDocumentSettings)
                .catch((error) => console.error(error))
                .finally(() => setIsLoadingData(false))
        } else {
            dispatch(loadSystemDocumentSettingsForCompany({ companyId: company === "super" ? "0" : company, query: `?documentCode=${documentCode}` }))
                .then(unwrapResult)
                .then(setSystemDocumentSettings)
                .catch((error) => console.error(error))
                .finally(() => setIsLoadingData(false))
        }
        setSaving(false);
    }

    const handleAddDocumentSettingsClick = useCallback(() => {
        setDialog({
            type: "create"
        })
    }, [setDialog])

    const handleDeleteClick = useCallback((id: string) => {
        setItemToDelete(id)
    }, [])

    const handleCancelDelClick = useCallback(() => {
        setItemToDelete(undefined)
    }, [])

    const handleDeleteHelpArticleClick = async () => {
        if (itemToDelete) {
            setDeleting(true)
            try {
                setDeleting(true)

                const result = await dispatch(deleteSystemDocumentSettings(itemToDelete))

                if(deleteSystemDocumentSettings.fulfilled.match(result)) {
                    setSystemDocumentSettings(produce(draft => {
                        draft.splice(draft.findIndex(f => f.id === result.payload), 1)
                    }))

                    dispatch(showSuccess("deleted"))
                } else {
                    let errorMsg = "error"
                    if (result.payload && result.payload.kind === 'http') {
                        const problem = result.payload.problem
                        if (problem) {
                            errorMsg = problem.title
                        }
                    }
                    setServerError(errorMsg)
                }
            } catch (error) {
                dispatch(showError("error"))
            } finally {
                setItemToDelete(undefined)
                setDeleting(false)
            }
        }
    }

    const handleSaveClick = async () => {
        companyDialog ? setCompanyRequired(false) : setCompanyRequired(true);
        documentCodeDialog ? setDocumentCodeRequired(false) : setDocumentCodeRequired(true);
        nameDialog !== '' ? setNameRequired(false) : setNameRequired(true);
        valueDialog !== '' ? setValueRequired(false) : setValueRequired(true);
        if(documentCodeDialog !== undefined && nameDialog !== '' && valueDialog !== '' && companyDialog !== undefined) {
            try {
                setSaving(true)

                let documentSettingsToSave ={ 
                    name: nameDialog, value: valueDialog, documentCode: documentCodeDialog, companyId: companyDialog === "super" ? 0 : Number(companyDialog)
                };

                const result = await dispatch(createSystemDocumentSettings(documentSettingsToSave))

                if(createSystemDocumentSettings.fulfilled.match(result)) {
                    setSystemDocumentSettings(produce(draft => {
                        draft.unshift(result.payload)
                    }))

                    dispatch(showSuccess("saved"));
                    setDialog({ type: "none" });
                    setCompanyDialog(undefined);
                    setDocumentCodeDialog(undefined);
                    setNameDialog("");
                    setValueDialog("");
                } else {
                    let errorMsg = "error"
                    if (result.payload && result.payload.kind === 'http') {
                        const problem = result.payload.problem
                        if (problem) {
                            errorMsg = problem.title

                        }
                    }
                    setServerError(errorMsg)
                }
            } catch (error) {
                dispatch(showError("error"));
            } finally {
                setSaving(false)
            }
        }
    }

    const handleCancelClick = () => {
        setDialog({ type: "none" });
        setCompanyDialog(undefined);
        setDocumentCodeDialog(undefined);
        setNameDialog("");
        setValueDialog("");
    }

    const columns: GridColDef<DocumentSettings, any, any>[] = [
        {
            field: 'documentCode',
            headerName: l10n.getString("system-feature-flags-flag", null, "Flaga"),
            flex: 1,
        },
        {
            field: 'companyId',
            headerName: l10n.getString("company", null, "Firma"),
            flex: 1,
            valueGetter: (_, row) => {
                if(row.companyId === null) return "Super";
                else {
                    const entity = selectCompanyById(store.getState(), row.companyId)

                    if (!entity) {
                        return ""
                    } else {
                        return entity.shortName
                    }
                }
            }
        },
        {
            field: 'name',
            headerName: l10n.getString("description", null, "Opis"),
            flex: 1,
        },
        {
            field: 'value',
            headerName: l10n.getString("description", null, "Opis"),
            flex: 1,
        },
        {
            field: 'actions', type: 'actions', width: 250,
            renderCell: (params) => {
                return (
                    <ButtonGroup size="small">
                        <Button
                            style={{ width: 100 }}
                            color="error"
                            variant="outlined"
                            onClick={() => handleDeleteClick(params.row.id)}>
                            <Localized id="delete">
                                <span>Usuń</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ]

    if (isLoading) {
        return <>
            <LoadingContainer/>
        </>
    }

    return (
        <>
            <div>
                <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                    <FormControl>
                        <InputLabel id="select-company-label"><Localized id="company">Firma</Localized></InputLabel>
                        <Select
                            labelId="select-company-label"
                            value={company}
                            onChange={handleCompanyChange}
                            label={<Localized id="company">Firma</Localized>}
                            sx={{ width: '200px' }}
                        >
                            <MenuItem value={'all'}>
                                <Localized id="all">All</Localized>
                            </MenuItem>
                            {companies.map((option) =>
                                <MenuItem value={option.id}>{option.name}</MenuItem>
                            )}
                            <MenuItem value={'super'}>
                                <span>Super</span>
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="select-document-code-label"><Localized id="system-document-settings-document-code">Kod dokumentu</Localized></InputLabel>
                        <Select
                            labelId="select-document-code-label"
                            value={documentCode}
                            onChange={handleEventTypeChange}
                            label={<Localized id="system-document-settings-document-code">Kod dokumentu</Localized>}
                            sx={{ width: '200px' }}
                        >
                            <MenuItem value={'all'}>
                                <Localized id="all">All</Localized>
                            </MenuItem>
                            {documentCodes.map((option) =>
                                <MenuItem value={option}>{option}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <IconButton
                        onClick={handleRefreshClick}
                        disabled={saving}
                        >
                            <SyncIcon />
                    </IconButton>
                </Stack>
            </div >
            <LoadCompanies component={<Skeleton variant="rectangular" />}>
                <TableContainer component={Paper}>
                {isLoadingData ? (<LoadingContainer/>) : (
                    <DataGrid localeText={getCustomLocaleText()}
                        style={{ minHeight: 100, height: 'calc(100vh - 360px)' }}
                        rows={systemDocumentSettings}
                        columns={columns}
                        initialState={{
                            sorting: { sortModel: [{ field: 'eventDate', sort: 'desc' }] }
                        }}
                    />
                )}
                </TableContainer>
            </LoadCompanies >
            <FlexDiv>
                <Fab sx={{
                    marginTop: 2,
                }}
                    onClick={() => handleAddDocumentSettingsClick()}
                    color="secondary"
                >
                    <AddIcon />
                </Fab>
            </FlexDiv>
            <Dialog open={dialog.type !== "none"} fullWidth maxWidth="sm" keepMounted={false}>
                <DialogTitle>
                    <If condition={dialog.type === "create"}>
                        <Localized id="system-document-settings-new">
                            <span>Nowe ustawienia dokumentu</span>
                        </Localized>
                    </If>
                </DialogTitle>
                <DialogContent>
                    <Stack direction="column" spacing={2} padding={2}>
                        <FormControl>
                            <InputLabel id="select-company-label"><Localized id="company">Firma</Localized></InputLabel>
                            <Select
                                labelId="select-company-label"
                                value={companyDialog}
                                onChange={handleCompanyDialogChange}
                                label={<Localized id="company">Firma</Localized>}
                            >
                                {companies.map((option) =>
                                    <MenuItem value={option.id}>{option.name}</MenuItem>
                                )}
                                <MenuItem value={'super'}>
                                    <span>Super</span>
                                </MenuItem>
                            </Select>
                            {companyRequired && <FormHelperText error><Localized id='field-is-required'>To pole jest wymagane</Localized></FormHelperText>}
                        </FormControl>
                        <FormControl>
                            <InputLabel id="select-document-code-label"><Localized id="system-document-settings-document-code">Kod dokumentu</Localized></InputLabel>
                            <Select
                                labelId="select-document-code-label"
                                value={documentCodeDialog}
                                onChange={handleDocumentCodeDialogChange}
                                label={<Localized id="system-document-settings-document-code">Kod dokumentu</Localized>}
                            >
                                {documentCodes.map((option) =>
                                    <MenuItem value={option}>{option}</MenuItem>
                                )}
                            </Select>
                            {documentCodeRequired && <FormHelperText error><Localized id='field-is-required'>To pole jest wymagane</Localized></FormHelperText>}
                        </FormControl>
                        <If condition={documentCodeDialog === "URS"}>
                            <FormControl>
                                <InputLabel id="select-document-name-label"><Localized id="system-document-settings-name">Nazwa</Localized></InputLabel>
                                <Select
                                    labelId="select-document-name-label"
                                    value={nameDialog}
                                    onChange={handleNameDialogChange}
                                    label={<Localized id="system-document-settings-name">Nazwa</Localized>}
                                >
                                    {['NUMBERING_MODE'].map((option) =>
                                        <MenuItem value={option}>{option}</MenuItem>
                                    )}
                                </Select>
                                {documentCodeRequired && <FormHelperText error><Localized id='field-is-required'>To pole jest wymagane</Localized></FormHelperText>}
                            </FormControl>
                        </If>
                        <If condition={documentCodeDialog === "RiskAnalysis"}>
                            <FormControl>
                                <InputLabel id="select-document-name-label"><Localized id="system-document-settings-name">Nazwa</Localized></InputLabel>
                                <Select
                                    labelId="select-document-name-label"
                                    value={nameDialog}
                                    onChange={handleNameDialogChange}
                                    label={<Localized id="system-document-settings-name">Nazwa</Localized>}
                                >
                                    {['WARNING_THRESHOLD', 'GOOD_THRESHOLD'].map((option) =>
                                        <MenuItem value={option}>{option}</MenuItem>
                                    )}
                                </Select>
                                {documentCodeRequired && <FormHelperText error><Localized id='field-is-required'>To pole jest wymagane</Localized></FormHelperText>}
                            </FormControl>
                        </If>
                        <If condition={documentCodeDialog === "URS" && nameDialog === 'NUMBERING_MODE'}>
                            <FormControl>
                                <InputLabel id="select-document-value-label"><Localized id="system-document-settings-value">Wartość</Localized></InputLabel>
                                <Select
                                    labelId="select-document-value-label"
                                    value={valueDialog}
                                    onChange={handleValueDialogChange}
                                    label={<Localized id="system-document-settings-value">Wartość</Localized>}
                                >
                                    {['DECIMAL_HIERARCHICAL', 'UNIQUE_REQ', 'ONLY_SIMPLE_REQ'].map((option) =>
                                        <MenuItem value={option}>{option}</MenuItem>
                                    )}
                                </Select>
                                {nameRequired && <FormHelperText error><Localized id='field-is-required'>To pole jest wymagane</Localized></FormHelperText>}
                            </FormControl>
                        </If>
                        <If condition={documentCodeDialog === "RiskAnalysis" && (nameDialog === "GOOD_THRESHOLD" || nameDialog === "WARNING_THRESHOLD")}>
                            <TextField 
                                fullWidth
                                value={valueDialog}
                                inputProps={{ inputMode: 'numeric', pattern : '[0-9]*' }}
                                onChange={handleValueDialogChange}
                                label={<Localized id="system-document-settings-value">Wartość</Localized>}
                                error={valueRequired === true}
                                helperText={valueRequired && <Localized id='field-is-required'>To pole jest wymagane</Localized>}>
                            </TextField>
                        </If>
                    </Stack>
                    {
                        serverError && <Alert sx={{ marginTop: 1, }} onClose={() => { setServerError("") }} severity="error">
                            <Localized id={serverError}>
                                <span>Server error</span>
                            </Localized>
                        </Alert>
                    }
                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={handleSaveClick} loading={saving}>
                        <Localized id="save">
                            <span>Zapisz</span>
                        </Localized>
                    </LoadingButton>
                    <LoadingButton loading={saving} onClick={handleCancelClick}>
                        <Localized id="cancel">
                            <span>Anuluj</span>
                        </Localized>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog open={itemToDelete !== undefined}>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="confirm-delete">
                            <span>Czy napewno chcesz usunąć?</span>
                        </Localized>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={deleting} onClick={handleDeleteHelpArticleClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </LoadingButton>
                    <LoadingButton loading={deleting} onClick={handleCancelDelClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
