import { Localized } from "@fluent/react"
import { Alert, Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, Skeleton, TextField } from "@mui/material"
import { useCallback, useState } from "react"
import { AppId, AppTextFieldHandler } from "../../../app/appTypes"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectAllUsers, User } from "../../users/usersSlice"
import { ManagedTeamMember } from "../../utils/managedObject"
import { showError, showSuccess } from "../../notifications/notificationsSlice"
import { LoadUsers } from "../../../app/AppDataLoader"
import { LoadingButton } from "@mui/lab"
import { changeOfferOwner, UrsOffer } from "../ursOfferSlice"

export interface ChangeOfferOwnerDialogProps {
    offerId: AppId
    teamMembers: ManagedTeamMember[]
    onSuccessfulSave: (updatedOffer: UrsOffer) => void
    onCancelClick: () => void,
}
export const ChangeOfferOwnerDialog = ({ offerId, teamMembers, onCancelClick, onSuccessfulSave }: ChangeOfferOwnerDialogProps) => {
    const dispatch = useAppDispatch()
    const allUsers = useAppSelector(selectAllUsers)
    const [user, setUser] = useState<User | null>(null)
    const [saving, setSaving] = useState(false)
    const [serverError, setServerError] = useState("")
    const [validationErrors, setValidationErrors] = useState(false);
    const [password, setPassword] = useState("")
    const passwordError = validationErrors && (password === "" || password === null)

    const filteredUsers = allUsers.filter(
        user => !teamMembers.some(member => member.userName === user.userName)
    );

    const handleUserChange = useCallback((_, newUser) => {
        setUser(newUser)
    }, [])

    const handlePasswordFieldChange: AppTextFieldHandler = useCallback(e => {
        setPassword(e.target.value)
    }, [])

    const handleOkClick = async () => {
        setValidationErrors(true);

        if (offerId && user && password) {
            setSaving(true)
            try {

                const result = await dispatch(changeOfferOwner({
                    offerId: offerId,
                    actionPayload: {
                        password: password,
                        userName: user.userName
                    }
                }))
                if (changeOfferOwner.fulfilled.match(result)) {
                    const updatedOffer = result.payload as UrsOffer;
                    dispatch(showSuccess("saved"))
                    onSuccessfulSave(updatedOffer)
                } else {
                    let errorMsg = "error"
                    if (result.payload && result.payload.kind === 'http') {
                        const problem = result.payload.problem
                        if (problem) {
                            errorMsg = problem.title

                        }
                    }
                    setServerError(errorMsg)
                }

            }
            catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setSaving(false)
                setValidationErrors(false);
            }

        }
        setSaving(false)
    }

    return <>
        <Dialog key="addEditTeamMemberDialog" open={true} maxWidth="md" fullWidth keepMounted={false}>
            <DialogTitle>
                <Localized id="document-appoint-changeowner">
                    <span>_Change process owner</span>
                </Localized>
            </DialogTitle>
            <DialogContent sx={{
                padding: 2,
            }}>
                <LoadUsers component={<Skeleton />}>
                    <Autocomplete
                        sx={{
                            marginTop: 1,
                        }}
                        options={filteredUsers}
                        value={user}
                        onChange={handleUserChange}
                        getOptionLabel={({ firstName, lastName, email }) => `${firstName} ${lastName} (${email}))`}
                        renderInput={params =>
                            <TextField {...params}
                                helperText={user?.position ?? " "}
                                label={
                                    <Localized id="username">
                                        <span>Nazwa użytkownika</span>
                                    </Localized>
                                }
                            />
                        }
                    />
                </LoadUsers>
                <TextField
                    sx={{
                        marginTop: 2
                    }}
                    fullWidth
                    error={passwordError}
                    autoFocus
                    type="password"
                    required={true}
                    InputLabelProps={{ shrink: true }}
                    value={password}
                    onChange={handlePasswordFieldChange}
                    label={<Localized id="password"><span>_Hasło</span></Localized>}
                    helperText={<Localized id="autorization-password-required"><span>_Wymagana autoryzacja hasłem</span></Localized>}
                    autoComplete="off"
                />
                {
                    serverError && <Alert sx={{ marginTop: 1, }} onClose={() => { setServerError("") }} severity="error">
                        <Localized id={serverError}>
                            <span>Server error</span>
                        </Localized>
                    </Alert>
                }
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={saving}
                    color="secondary"
                    variant="contained"
                    loadingIndicator={<Localized id="saving" />}
                    onClick={handleOkClick}>
                    <Localized id="save">
                        <span>save</span>
                    </Localized>
                </LoadingButton>
                <LoadingButton
                    color="primary"
                    variant="outlined"
                    loading={saving}
                    onClick={onCancelClick}>
                    <Localized id="cancel">
                        <span>Anuluj</span>
                    </Localized>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </>
}