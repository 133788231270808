import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { Idable } from "../../app/AppDataProvider";
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../app/appTypes";
import { isSameRequestId } from "../../app/crud";
import { RootState } from "../../app/store";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../http";

export type SpecStatus = "NEW" | "IMPORTED"

export interface Spec {
    id: number
    createdBy: string
    createDate: string
    modifiedBy: string
    modifyDate: string
    name: string
    description: string
    guid: string
    companyId: number
    structureId: AppId | null
    status: SpecStatus
}

export const defaultSpec: SpecWithDetails = {
    id: 0,
    createdBy: '',
    createDate: '',
    modifiedBy: '',
    modifyDate: '',
    name: '',
    description: '',
    guid: '',
    companyId: 0,
    components: [],
    structureId: null,
    status: "NEW"
}

export interface SpecComponent {
    id: number
    createdBy: string
    createDate: string
    modifiedBy: string
    modifyDate: string
    guid: string
    name: string
    functionalSpecification: string
    designSpecification: string
    parentComponentId: number
    parentComponent: string
    childComponents: SpecComponent[]
    deviceSpecificationId: number
    deviceSpecification: string
    offerDescription: string | null
    numeration: string | null
}

export interface SpecDetails {
    components: SpecComponent[]
}

export type SpecWithDetails = Spec & SpecDetails

const adapter = createEntityAdapter<Spec>({
    selectId: spec => spec.id,
})

export type SpecState = EntityState<Spec> & { state: AppDataState }

const initialState: SpecState = adapter.getInitialState({
    state: { type: "empty" }
})

export const findSpecComponent = (cs: SpecComponent[], id: number): SpecComponent | null => {
    for (let i = 0; i < cs.length; i++) {
        if (cs[i].id === id) {
            return cs[i]
        } else {
            const child = findSpecComponent(cs[i].childComponents, id)
            if (child) {
                return child
            }
        }
    }
    return null
}

// GET api/DeviceSpecifications
export const loadSpecs = createAsyncThunk<Spec[], void, AppThunkAPIType>('spec/load', async (_, api) => {
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(getWithAuth({ url: "api/DeviceSpecifications"}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as Spec[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const loadSpec = createAsyncThunk<SpecWithDetails, number, AppThunkAPIType>('spec/single-load', async (id, api) => {
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(getWithAuth({ url: `api/DeviceSpecifications/${id}`}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as SpecWithDetails
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
    
})

export const createSpec = createAsyncThunk<SpecWithDetails, SpecWithDetails, AppThunkAPIType>('spec/create', async (args, api) => {
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(postWithAuth({
        url: "api/DeviceSpecifications",
        payload: args
    }))
    const { payload } = result
    if (postWithAuth.fulfilled.match(result)) {
        return payload as SpecWithDetails
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const updateSpec = createAsyncThunk<SpecWithDetails, SpecWithDetails, AppThunkAPIType>('spec/update', async (spec, api) => {
    const { dispatch, rejectWithValue } = api
    const { id, name, description, structureId } = spec
    const result = await dispatch(putWithAuth({
        url: `api/DeviceSpecifications/${id}`,
        payload: {
            name,
            description,
            structureId,
        }
    }))
    const { payload } = result
    if (putWithAuth.fulfilled.match(result)) {
        return payload as SpecWithDetails
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const deleteSpec = createAsyncThunk<void, number, AppThunkAPIType>('spec/delete', async (id, api) => {
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(deleteWithAuth({
        url: `api/spec/${id}`,
    }))
    if (deleteWithAuth.fulfilled.match(result)) {
        return
    } else {
        return rejectWithValue(result.payload ?? { kind: 'unknown' })
    }
})

export interface CreateSpecComponentArg {
    name: string
    functionalSpecification: string
    designSpecification: string
    parentComponentId: number | null
    deviceSpecificationId: number
    offerDescription: string | null
    numeration : string | null
}
export const createSpecComponent = createAsyncThunk<SpecComponent, CreateSpecComponentArg, AppThunkAPIType>('spec/create-component', async (args, api) => {
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(postWithAuth({
        url: `api/DeviceSpecifications/${args.deviceSpecificationId}/components`,
        payload: args,
    }))
    const { payload } = result
    if (postWithAuth.fulfilled.match(result)) {
        return payload as SpecComponent
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export interface DeleteSpecComponentArgs {
    specId: number
    componentId: number
}
export const deleteSpecComponent = createAsyncThunk<number, DeleteSpecComponentArgs, AppThunkAPIType>('spec/delete-spec-component', async (args, api) => {
    const { dispatch, rejectWithValue } = api
    const { specId, componentId } = args
    const result = await dispatch(deleteWithAuth({
        url: `api/DeviceSpecifications/${specId}/components/${componentId}`,
    }))
    if (deleteWithAuth.fulfilled.match(result)) {
        return componentId
    } else {
        return rejectWithValue(result.payload ?? { kind: 'unknown' })
    }
})

export type UpdateSpecComponentArg = CreateSpecComponentArg & Idable<number>
export const updateSpecComponent = createAsyncThunk<SpecComponent, UpdateSpecComponentArg, AppThunkAPIType>('spec/update-component', async (args, api) => {
    const { id, ...data } = args
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(putWithAuth({
        url: `api/DeviceSpecifications/${args.deviceSpecificationId}/components/${id}`,
        payload: data,
    }))
    const { payload } = result
    if (putWithAuth.fulfilled.match(result)) {
        return payload as SpecComponent
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})


export const specSlice = createSlice({
    name: "specs",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loadSpecs.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addCase(loadSpecs.fulfilled, (state, action) => {
            if (isSameRequestId(state.state, action.meta.requestId)) {
                adapter.setAll(state, action.payload)
                state.state = { type: "loaded" }
            }
        })
        builder.addCase(loadSpecs.rejected, (state, action) => {
            if (isSameRequestId(state.state, action.meta.requestId)) {
                state.state = { 
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
        builder.addCase(createSpec.fulfilled, (state, action) => {
            adapter.addOne(state, action.payload)
        })
        builder.addCase(updateSpec.fulfilled, (state, action) => {
            adapter.upsertOne(state, action.payload)
        })
        builder.addCase(deleteSpec.fulfilled, (state, action) => {
            adapter.removeOne(state, action.meta.arg)
        })
    }
})

export const selectSpecs = (state: RootState) => state.specs

export const
    { selectAll: selectAllSpecs
    , selectById: selectSpecById
    } = adapter.getSelectors(selectSpecs)

export default specSlice.reducer
