import { Localized } from "@fluent/react"
import { Alert, Box, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import { unwrapResult } from "@reduxjs/toolkit"
import clsx from "clsx"
import produce from "immer"
import { useState } from "react"
import { NumberFormatValues, NumericFormat } from "react-number-format"
import { useAppDispatch } from "../../app/hooks"
import { If } from "../../app/If"
import { BCCurrency, BCPrecision, BusinessCaseWithDetails, Cashflow, NPVMode } from "./businessCaseSlice"
import { calculateNPV, calculateNPVManually } from "./npv"
import React from "react"

function ccyFormat(num: number, currency: BCCurrency = 'PLN', precision: BCPrecision = 'full') {
    if (precision === '6' && num > 999999) {
        return new Intl.NumberFormat('pl-PL', {
            style: 'currency',
            currency,
            currencyDisplay: 'code',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            maximumSignificantDigits: 6,
        }).format(num)

    } else if (precision === '4' && num > 999) {
        return new Intl.NumberFormat('pl-PL', {
            style: 'currency',
            currency,
            currencyDisplay: 'code',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            maximumSignificantDigits: 4,
        }).format(num)
    } else if (precision === '5' && num > 999) {
        return new Intl.NumberFormat('pl-PL', {
            style: 'currency',
            currency,
            currencyDisplay: 'code',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            maximumSignificantDigits: 5,
        }).format(num)
    } else if (precision === '3' && num > 999) {
        return new Intl.NumberFormat('pl-PL', {
            style: 'currency',
            currency,
            currencyDisplay: 'code',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            maximumSignificantDigits: 3,
        }).format(num)
    } else {
        return new Intl.NumberFormat('pl-PL', {
            style: 'currency',
            currency,
            currencyDisplay: 'code',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        }).format(num)
    }
}

const calcNPV = (cashflow: number[], discountRate: number) => cashflow
  .reduce((acc, c, i) => acc + c / Math.pow((1 + discountRate), i), 0)

type Poly = number[]

function evalPoly(p: Poly, x: number): number {
    return p.reduce((acc, c, i) => acc + c * Math.pow(x, i), 0)
}

function derivative(p: Poly): Poly {
    return p.slice(1).map((c, i) => c * (i + 1))
}

function newtonRaphson(p: Poly, x0: number, eps: number, maxIter: number): number | undefined {
    let x = x0
    for (let i = 0; i < maxIter; i++) {
        let f = evalPoly(p, x)
        let df = evalPoly(derivative(p), x)
        x = x - f / df
        if (Math.abs(f) < eps) {
            return x
        }
    }

    return undefined
}

function irr(cashflow: number[], initialGuess: number = 2.1): number | undefined {
    const poly = cashflow.slice().reverse()
    const g = newtonRaphson(poly, initialGuess, 1e-6, 1000)
    if (g === undefined) {
        return undefined
    } else {
        return g - 1
    }
}

function calcPayback(cashflow: number[]): number {
    const monthlyCfs: number[] = []
    cashflow.forEach((cf) => {
        for (let j = 0; j < 12; j++) {
            monthlyCfs.push(cf / 12)
        }
    })
    let payback = 0
    let cumulative = 0
    for (let i = 0; i < monthlyCfs.length; i++) {
        cumulative += monthlyCfs[i]
        if (cumulative >= 0) {
            payback = i + 1
            break
        }
    }
    return payback
}

export interface NPVProps {
    businessCaseId: number
    investementCost: number | null
    forcastedYearlySaving: number | null
    growthYearlyFactor: number | null
    discountRate: number | null
    numberOfYears: number | null
    cashflows: Cashflow[]
    pv: number
    npv: number
    irr: number | undefined
    payback: number
    mode: NPVMode
    currency: BCCurrency | null
    precision?: BCPrecision
    updateDataLocally: (f: (data: BusinessCaseWithDetails) => void) => void
}

export const NPV = (props: NPVProps) => {
    const dispatch = useAppDispatch()
    const [forcastedYearSavingInput, setForcastedYearSavingInput] = useState<number | undefined>(props.forcastedYearlySaving ?? 0)
    const [discountRateInput, setDiscountRateInput] = useState<number | undefined>(props.discountRate ?? 1)
    const [investmentInput, setInvestmentInput] = useState<number | undefined>(props.investementCost ?? 0)
    const [growthFactorInput, setGrowthFactorInput] = useState<number | undefined>(props.growthYearlyFactor ?? 0)
    const [yearsInput, setYearsInput] = useState<number | undefined>(props.numberOfYears ?? 1)
    //const [endOfLifeValue, setEndOfLifeValue] = useState(0)
    const [startingYear, setStartingYear] = useState(2024)
    const [initialGuessInput, setInitialGuessInput] = useState<number | undefined>(2.1)
    const [mode, setMode] = useState<NPVMode>(props.mode)
    const [currency, setCurrency] = useState<BCCurrency>(props.currency ?? 'PLN')
    const [precision, setPrecision] = useState<BCPrecision>(props.precision ?? 'full')

    const discountRate: number = discountRateInput !== undefined ? discountRateInput : 0
    const investment: number = investmentInput !== undefined ? investmentInput : 0
    const growthFactor: number = growthFactorInput !== undefined ? growthFactorInput : 0
    const forcastedYearSaving: number = forcastedYearSavingInput !== undefined ? forcastedYearSavingInput : 0
    const years: number = yearsInput !== undefined ? yearsInput : 1
    const initialGuess: number = initialGuessInput !== undefined ? initialGuessInput : 2.1

    const [cfs, setCfs] = useState<number[]>(props.cashflows.map(x => x.cfValue))
    const [discountedCfs, setDiscountedCfs] = useState<number[]>(props.cashflows.map(y => y.dcfValue))
    const [npv, setNpv] = useState<number>(props.npv)
    const [pv, setPv] = useState<number>(props.pv)
    const [internalRateOfReturn, setInternalRateOfReturn] = useState<number | undefined>(props.irr)
    const [payback, setPayback] = useState<number>(props.payback)

    const irrFormatted = internalRateOfReturn !== undefined ? `${(internalRateOfReturn*100).toFixed(2)} %` : 'N/A'

    const handleCurrencyChange = (e: SelectChangeEvent) => {
        const newCurrency = e.target.value as BCCurrency
        setCurrency(newCurrency)
        props.updateDataLocally(draft => {
            draft.currency = newCurrency
        })
    }

    const handlePrecisionChange = (e: SelectChangeEvent) => {
        const newPrecision = e.target.value as BCPrecision
        setPrecision(newPrecision)
        props.updateDataLocally(draft => {
            draft.precision = newPrecision
        })
    }

    const handleInvestmentChange = (vals: NumberFormatValues) => {
        setInvestmentInput(vals.floatValue)
        props.updateDataLocally((data) => {
            data.investementCost = vals.floatValue ?? null
        })
        if (mode !== 'Manual') {
            dispatch(calculateNPV({
                investementCost: vals.floatValue ?? 0,
                forcastedYearlySaving: forcastedYearSaving,
                growthYearlyFactor: growthFactor,
                discountRate: discountRate,
                numberOfYears: years,
                initialGuess: initialGuess,
            })).then(unwrapResult)
            .then(({ cfs, dcfs, npv, pv, irr, payback }) => {
                setCfs(cfs)
                setDiscountedCfs(dcfs)
                setNpv(npv)
                setInternalRateOfReturn(irr)
                setPayback(payback)
                setPv(pv)
                props.updateDataLocally((data) => {
                    data.cashflows = cfs.map((cf, i) => ({
                        year: startingYear + i,
                        cfValue: cf,
                        dcfValue: dcfs[i],
                        businessCaseId: props.businessCaseId,
                    }))
                    data.pv = pv
                    data.npv = npv
                    data.irr = irr ?? null
                    data.payback = payback
                })
            })
        }
    }
    const handleYearlySavingChange = (vals: NumberFormatValues) => {
        setForcastedYearSavingInput(vals.floatValue)
        props.updateDataLocally((data) => {
            data.forcastedYearlySaving = vals.floatValue ?? null
        })
        if (mode !== 'Manual') {
            dispatch(calculateNPV({
                investementCost: investment,
                forcastedYearlySaving: vals.floatValue ?? 0,
                growthYearlyFactor: growthFactor,
                discountRate: discountRate,
                numberOfYears: years,
                initialGuess: initialGuess,
            })).then(unwrapResult)
            .then(({ cfs, dcfs, npv, pv, irr, payback }) => {
                setCfs(cfs)
                setDiscountedCfs(dcfs)
                setNpv(npv)
                setInternalRateOfReturn(irr)
                setPayback(payback)
                setPv(pv)
                props.updateDataLocally((data) => {
                    data.cashflows = cfs.map((cf, i) => ({
                        year: startingYear + i,
                        cfValue: cf,
                        dcfValue: dcfs[i],
                        businessCaseId: props.businessCaseId,
                    }))
                    data.pv = pv
                    data.npv = npv
                    data.irr = irr ?? null
                    data.payback = payback
                })
            })
        }
    }
    const handleGrowthFactorChange = (vals: NumberFormatValues) => {
        setGrowthFactorInput(vals.floatValue)
        props.updateDataLocally((data) => {
            data.growthYearlyFactor = vals.floatValue ?? null
        })
        if (mode !== 'Manual') {
            dispatch(calculateNPV({
                investementCost: investment,
                forcastedYearlySaving: forcastedYearSaving,
                growthYearlyFactor: vals.floatValue ?? 0,
                discountRate: discountRate,
                numberOfYears: years,
                initialGuess: initialGuess,
            })).then(unwrapResult)
            .then(({ cfs, dcfs, npv, pv, irr, payback }) => {
                setCfs(cfs)
                setDiscountedCfs(dcfs)
                setNpv(npv)
                setInternalRateOfReturn(irr)
                setPayback(payback)
                setPv(pv)
                props.updateDataLocally((data) => {
                    data.cashflows = cfs.map((cf, i) => ({
                        year: startingYear + i,
                        cfValue: cf,
                        dcfValue: dcfs[i],
                        businessCaseId: props.businessCaseId,
                    }))
                    data.pv = pv
                    data.npv = npv
                    data.irr = irr ?? null
                    data.payback = payback
                })
            })
        }
    }
    const handleDiscountRateChange = (vals: NumberFormatValues) => {
        setDiscountRateInput(vals.floatValue)
        props.updateDataLocally((data) => {
            data.discountRate = vals.floatValue ?? null
        })
        if (mode !== 'Manual') {
            dispatch(calculateNPV({
                investementCost: investment,
                forcastedYearlySaving: forcastedYearSaving,
                growthYearlyFactor: growthFactor,
                discountRate: vals.floatValue ?? 0,
                numberOfYears: years,
                initialGuess: initialGuess,
            })).then(unwrapResult)
            .then(({ cfs, dcfs, npv, pv, irr, payback }) => {
                setCfs(cfs)
                setDiscountedCfs(dcfs)
                setNpv(npv)
                setInternalRateOfReturn(irr)
                setPayback(payback)
                setPv(pv)
                props.updateDataLocally((data) => {
                    data.cashflows = cfs.map((cf, i) => ({
                        year: startingYear + i,
                        cfValue: cf,
                        dcfValue: dcfs[i],
                        businessCaseId: props.businessCaseId,
                    }))
                    data.pv = pv
                    data.npv = npv
                    data.irr = irr ?? null
                    data.payback = payback
                })
            })
        } else {
            dispatch(calculateNPVManually({
                cfs,
                discountRate,
                initialGuess,
            })).then(unwrapResult)
            .then(({ npv, pv, irr, payback, dcfs }) => {
                setDiscountedCfs(dcfs)
                setNpv(npv)
                setInternalRateOfReturn(irr)
                setPayback(payback)
                setPv(pv)
                props.updateDataLocally((data) => {
                    data.cashflows = cfs.map((cf, i) => ({
                        year: startingYear + i,
                        cfValue: cf,
                        dcfValue: dcfs[i],
                        businessCaseId: props.businessCaseId,
                    }))
                    data.pv = pv
                    data.npv = npv
                    data.irr = irr ?? null
                    data.payback = payback
                })
            })
        }
    }
    const handleYearsChange = (vals: NumberFormatValues) => {
        setYearsInput(vals.floatValue)
        props.updateDataLocally((data) => {
            data.numberOfYears = vals.floatValue ?? null
        })
        if (mode !== 'Manual') {
            dispatch(calculateNPV({
                investementCost: investment,
                forcastedYearlySaving: forcastedYearSaving,
                growthYearlyFactor: growthFactor,
                discountRate: discountRate,
                numberOfYears: vals.floatValue ?? 0,
                initialGuess: initialGuess,
            })).then(unwrapResult)
            .then(({ cfs, dcfs, npv, pv, irr, payback }) => {
                setCfs(cfs)
                setDiscountedCfs(dcfs)
                setNpv(npv)
                setInternalRateOfReturn(irr)
                setPayback(payback)
                setPv(pv)
                props.updateDataLocally((data) => {
                    data.cashflows = cfs.map((cf, i) => ({
                        year: startingYear + i,
                        cfValue: cf,
                        dcfValue: dcfs[i],
                        businessCaseId: props.businessCaseId,
                    }))
                    data.pv = pv
                    data.npv = npv
                    data.irr = irr ?? null
                    data.payback = payback
                })
            })
        } else if (vals.floatValue !== undefined) {
            const newYears = vals.floatValue
            if (newYears + 1 > cfs.length) {
                const newCfs = produce(cfs, draft => {
                    for (let i = 0; i < newYears + 1 - cfs.length; i++) {
                        draft.push(0)
                    }
                })
                setCfs(newCfs)
                const newDcfs = produce(discountedCfs, draft => {
                    for (let i = 0; i < newYears + 1 - discountedCfs.length; i++) {
                        draft.push(0)
                    }
                })
                setDiscountedCfs(newDcfs)
                props.updateDataLocally((data) => {
                    data.cashflows = newCfs.map((cf, i) => ({
                        year: startingYear + i,
                        cfValue: cf,
                        dcfValue: newDcfs[i],
                        businessCaseId: props.businessCaseId,
                    }))
                })
            } else {
                const newCfs = cfs.slice(0, newYears + 1)
                const newDcfs = discountedCfs.slice(0, newYears + 1)
                setCfs(newCfs)
                setDiscountedCfs(newDcfs)
                props.updateDataLocally((data) => {
                    data.cashflows = newCfs.map((cf, i) => ({
                        year: startingYear + i,
                        cfValue: cf,
                        dcfValue: newDcfs[i],
                        businessCaseId: props.businessCaseId,
                    }))
                })
            }
        }
    }
    const handleModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMode(e.target.value as NPVMode)
        props.updateDataLocally((data) => {
            data.mode = e.target.value as NPVMode
        })

        // if (e.target.value === 'Manual') {
        //     props.updateDataLocally((data) => {
        //         data.cashflows = cfs.map((cf, i) => ({
        //             year: startingYear + i,
        //             cfValue: cf,
        //             dcfValue: discountedCfs[i],
        //             businessCaseId: props.businessCaseId,
        //         }))
        //     })
        // }
    }
    const handleCashflowChange = (value: number | undefined, index: number) => {
        const newCfs = produce(cfs, draft => {
            draft[index] = value ?? 0
        })
        setCfs(newCfs)
        dispatch(calculateNPVManually({
            cfs: newCfs,
            discountRate,
            initialGuess,
        })).then(unwrapResult)
        .then(({ npv, pv, irr, payback, dcfs }) => {
            setDiscountedCfs(dcfs)
            setNpv(npv)
            setInternalRateOfReturn(irr)
            setPayback(payback)
            setPv(pv)
            props.updateDataLocally((data) => {
                data.cashflows = newCfs.map((cf, i) => ({
                    year: startingYear + i,
                    cfValue: cf,
                    dcfValue: dcfs[i],
                    businessCaseId: props.businessCaseId,
                }))
                data.pv = pv
                data.npv = npv
                data.irr = irr ?? null
                data.payback = payback
            })
        })
    }
    const currSuffix = ` ${currency}`

    return <div className="space-y-4">
        <div className='flex flex-row gap-5'>
            <NumericFormat disabled={mode === 'Manual'} value={investmentInput} onValueChange={handleInvestmentChange} customInput={TextField} suffix={currSuffix} thousandSeparator=" "
                label={<Localized id='bc-investment-cost'>Investment cost</Localized>}
            />
            <NumericFormat disabled={mode === 'Manual'} value={forcastedYearSavingInput} onValueChange={handleYearlySavingChange} customInput={TextField} suffix={currSuffix} thousandSeparator=" "
                label={<Localized id ='bc-forcasted-yearly-income'>Forcasted yearly income</Localized>}
            />
            <NumericFormat disabled={mode === 'Manual'} value={growthFactorInput} onValueChange={handleGrowthFactorChange} customInput={TextField} suffix=" %"
                label={<Localized id='bc-growth-factor'>Growth factor (yearly)</Localized>}
            />
            <FormControl>
                <FormLabel>
                    <Localized id='bc-mode'>Mode</Localized>
                </FormLabel>
                <RadioGroup row value={mode} onChange={handleModeChange}>
                    <FormControlLabel value="Auto" control={<Radio value="Auto" />} label="Auto" />
                    <FormControlLabel value="Manual" control={<Radio />} label="Manual" />
                </RadioGroup>
            </FormControl>
            {/* <NumericFormat value={externalCost} onValueChange={((vals) => { setExternalCost(vals.floatValue ?? 0) })} customInput={TextField} suffix=" zł " thousandSeparator=" " label='External cost' fullWidth /> */}
            {/* <NumericFormat value={internalCost} onValueChange={(vals) => setInternalCost(vals.floatValue ?? 0) } customInput={TextField} suffix=" zł " thousandSeparator=" " label='InternalCost' fullWidth /> */}
        </div>
        <div className='flex flex-row gap-5'>
            <NumericFormat value={discountRateInput} onValueChange={handleDiscountRateChange} customInput={TextField} suffix=" %"
                label={<Localized id='bc-discount-rate'>Discount rate</Localized>}
            />
            <NumericFormat value={yearsInput} onValueChange={handleYearsChange } customInput={TextField} suffix=" year(s)"
                label={<Localized id='bc-years-count'>No. of years</Localized>}
            />
            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                    <InputLabel id="bc-currency-select-label">
                        <Localized id='currency'>Currency</Localized>
                    </InputLabel>
                    <Select
                    labelId="bc-currency-select-label"
                    id="bc-currency-select"
                    value={currency}
                    label="Currency"
                    onChange={handleCurrencyChange}
                    >
                    <MenuItem value={'PLN'}>PLN</MenuItem>
                    <MenuItem value={'USD'}>USD</MenuItem>
                    <MenuItem value={'EUR'}>EUR</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                    <InputLabel id="bc-precision-select-label">
                        <Localized id='precision'>Precision</Localized>
                    </InputLabel>
                    <Select
                    labelId="bc-precision-select-label"
                    id="bc-precision-select"
                    value={precision}
                    label="Precision"
                    onChange={handlePrecisionChange}
                    >
                    <MenuItem value={'full'}>Full</MenuItem>
                    <MenuItem value={'6'}>6 digits</MenuItem>
                    <MenuItem value={'5'}>5 digits</MenuItem>
                    <MenuItem value={'4'}>4 digits</MenuItem>
                    <MenuItem value={'3'}>3 digits</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            {/* <NumericFormat value={initialGuessInput} onValueChange={(vals) => setInitialGuessInput(vals.floatValue)} customInput={TextField} label='IRR initial guess' /> */}
            {/*<NumericFormat value={endOfLifeValue} onValueChange={((vals) => { setEndOfLifeValue(vals.floatValue ?? 0) })} customInput={TextField} suffix=" zł " thousandSeparator=" " label='End of life value' fullWidth />*/}
        </div>
        <div className='grid grid-cols-2 gap-4'>
            <TableContainer component={Paper}>
                <Table>
                    <caption className="caption-top">
                        <Localized id='bc-cashflow'>Cashflow</Localized>
                    </caption>
                    <TableHead>
                        <TableRow>
                            <TableCell><Localized id='bc-year'>Year</Localized></TableCell>
                            <TableCell align={'left'}><Localized id='bc-cf'>CF</Localized></TableCell>
                            <TableCell align='right'><Localized id='bc-dcf'>DCF</Localized></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cfs.map((value, index) => index > 10 ? null : <TableRow key={index}>
                            <TableCell sx={{ p: 1 }}>{startingYear + index}</TableCell>
                            <TableCell sx={{ p: 1 }} align={'left'}>
                                <If condition={mode === 'Manual'} otherwise={<span className={clsx(value < 0 && 'text-red-500')}>{ccyFormat(value, currency, precision)}</span>}>
                                    <NumericFormat className="w-32 rounded border border-slate-200 p-1 outline-none"
                                        value={value}
                                        onValueChange={(e) => handleCashflowChange(e.floatValue,index)}
                                        decimalScale={0}
                                        suffix={currSuffix}
                                        thousandSeparator=' '
                                    />
                                </If>
                            </TableCell>
                            <TableCell sx={{ p: 1 }} align='right'><span className={clsx(value < 0 && 'text-red-500')}>{ccyFormat(discountedCfs[index], currency, precision)}</span></TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="flex flex-col gap-4">
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow className="bg-slate-100">
                                <TableCell><span className="font-bold text-lg"><Localized id='bc-pv'>PV</Localized></span></TableCell>
                                <TableCell align='right'><span className="font-bold text-lg">{ccyFormat(pv, currency, precision)}</span></TableCell>
                            </TableRow>
                            <TableRow className="bg-slate-200">
                                <TableCell><span className="font-bold text-lg"><Localized id='bc-npv'>NPV</Localized></span></TableCell>
                                <TableCell align='right'><span className={clsx("font-bold text-lg", npv < 0 && 'text-red-500' )}>{ccyFormat(npv, currency, precision)}</span></TableCell>
                            </TableRow>
                            <TableRow className="bg-slate-200">
                                <TableCell><span className="font-bold text-lg"><Localized id='bc-irr'>IRR</Localized></span></TableCell>
                                <TableCell align='right'><span className={clsx("font-bold text-lg", internalRateOfReturn !== undefined && internalRateOfReturn < 0 && 'text-red-500')}>{irrFormatted}</span></TableCell>
                            </TableRow>
                            <TableRow className="bg-slate-300">
                                <TableCell><span className="font-bold text-lg"><Localized id='bc-payback'>Payback</Localized></span></TableCell>
                                <TableCell align='right'>
                                    <span className={clsx("font-bold text-lg", payback === 0 && 'text-red-500')}>
                                        {payback}&nbsp;<Localized id='bc-payback-unit'>months</Localized>
                                    </span>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Alert severity="info">NPV(IRR) = {calcNPV(cfs, internalRateOfReturn ?? 0).toFixed(15)}</Alert>
            </div>
        </div>
    </div>
}
