import { useCallback, useEffect, useState } from "react";
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText, ListSubheader, Paper, Skeleton, Stack, TextField } from "@mui/material";
import { If } from "../../../app/If";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import produce from "immer";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useParams } from "react-router-dom";
import { Attendee, createMeeting, getLastMeeting } from "./meeting";
import { ServerErrorMsg } from "../../../app/ServerErrorMsg";
import { APIError } from "../../../app/appTypes";
import { unwrapResult } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../../notifications/notificationsSlice";
import DeleteIcon from '@mui/icons-material/Delete';
import { Localized } from "@fluent/react";
import { Email, UsersBrowser } from "../../users/UsersBrowser";
import { useGridApiRef } from "@mui/x-data-grid";
import { selectCompanyById } from "../../company/companiesSlice";
import { store } from "../../../app/store";
import { selectLoggedUser, selectUserCompanyId } from "../../user/userSlice";
import { User, selectUserByName } from "../../users/usersSlice";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CloseIcon from '@mui/icons-material/Close';
import { LoadUsers } from "../../../app/AppDataLoader";
import { useNavigate } from "react-router-dom";

const steps = ['meeting-minutes-step-general-info', 'meeting-minutes-step-attendees'];

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

const InvalidDateError = () => <Localized id="field-invalid-date"><span>Data jest nieprawidłowa</span></Localized>

export const CreateMeeting = () => {

  return <LoadUsers component={<Skeleton variant="rectangular" />}>
    <CreateMettingForm/>
  </LoadUsers>
}

const CreateMettingForm = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { meetingMinuteId: paramId } = useParams<{ meetingMinuteId: string }>()
    const meetingMinuteId = paramId ?? ''
    const [title, setTitle] = useState('')
    const [dateAndTime, setDateAndTime] = useState(new Date())
    const [endDateAndTime, setEndDateAndTime] = useState(new Date())
    const [plannedMeetingTime, setPlannedMeetingTime] = useState(60);
    const [location, setLocation] = useState('')
    const [requestedBy, setRequestedBy] = useState('')
    const [company, setCompany] = useState('')
    const [email, setEmail] = useState('')
    const [attendees, setAttendees] = useState<Attendee[]>([])

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());

    const [person, setPerson] = useState('')
    const [lastName, setLastName] = useState('')

    const firstNameRef = React.useRef<HTMLInputElement>(null)

    const [error, setError] = useState<APIError | null>(null)
    const [emailError, setEmailError] = useState("");

    const [loadingLastMeeting, setLoadingLastMeeting] = useState(false)

    const [validationErrors, setValidationErrors] = useState(false);
    const [saving, setSaving] = useState(false)

    const meetingTitleError = validationErrors && (title === "" || title === null)
    const meetingLocationError = validationErrors && (location === "" || location === null)
    const meetingDateAndTimeError = validationErrors && (isNaN(dateAndTime.getTime()) || dateAndTime === null)
    const attendeesPersonError = validationErrors && (person === "" || person === null)
    const attendeesCompanyError = validationErrors && (company === "" || company === null)

    useEffect(() => {
        if (meetingMinuteId) {
            setLoadingLastMeeting(true)
            dispatch(getLastMeeting(meetingMinuteId))
            .then(unwrapResult)
            .then(lastMeeting => {
                    if (lastMeeting) {
                        setTitle(lastMeeting.name)
                        setLocation(lastMeeting.place)
                        setRequestedBy(lastMeeting.onDemand)
                        setAttendees(lastMeeting.attendanceList)
                        if (lastMeeting.plannedMeetingTime > 0) {
                          setPlannedMeetingTime(lastMeeting.plannedMeetingTime);
                        } else {
                          setPlannedMeetingTime(60); 
                        }
                    }
                })
            .catch(err => setError(err))
            .finally(() => setLoadingLastMeeting(false))
        }
    }, [])

    const [appUsersDialogOpen, setAppUsersDialogOpen] = useState(false)

    const usersGridRef = useGridApiRef()

    const handleGoBackclick = useCallback(() => navigate(-1), [navigate])

    const handlePlannedMeetingTime = (e) => {
      const time = parseInt(e.target.value, 10);
      setPlannedMeetingTime(time);
    };

    const handleAddAttendee = () => {
      setValidationErrors(true);
      setSaving(true);
      if(person && company && !emailError) {
        if((email && attendees.find(f => f.email === email) || !email && attendees.find(f => f.person === person && f.company === company))) {
          dispatch(showError("meeting-minutes-error-user-already-exists"));
        } else {
          setAttendees(produce(draft => {
              draft.push({ id:0, person, company, email, meetingId: 0 })
          }))
          setPerson('')
          setLastName('')
          setCompany('')
          setEmail('')
          if (firstNameRef.current) {
              firstNameRef.current.focus()
          }
        }
      }

      setValidationErrors(false);
      setSaving(false);
    }

    const handleCreateMeetingClick = () => {
      if(attendees.length === 0) {
        dispatch(showError("meeting-minutes-error-empty-list"));
      } else {
        dispatch(createMeeting({
            meetingMinuteId,
            meeting: {
                id: 0,
                name: title,
                startDate: dateAndTime.toISOString(),
                endDate: endDateAndTime.toISOString(),
                place: location,
                onDemand: requestedBy,
                meetingMinuteId,
                createdBy: "",
                plannedMeetingTime: plannedMeetingTime,
                attendanceList: attendees,
            },
        })).then(unwrapResult)
            .then((newId) => {
                dispatch(showSuccess('saved'))
                navigate(`/meetingMinutes/edit/${newId}`, { replace: true })
            })
            .catch(err => setError(err))
      }
    }

  const isStepOptional = (step: number) => {
    return false
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const loggedUser = useAppSelector(selectLoggedUser)

  //TODO - musimy zmienic sposob doczytywania danych o firmach userow, nie mozemy ladowac wszystkich danych firm
  const loggedUserCompanyId = useAppSelector(selectUserCompanyId) as string
  const loggedUserCompany =  useAppSelector(state => selectCompanyById(state, loggedUserCompanyId))
  const [user, setUser] = useState<User | null>(() => selectUserByName(store.getState(), loggedUser) ?? null)

  const handleNext = () => {
    setValidationErrors(true);
    setSaving(true);
    if(title && location && !isNaN(dateAndTime.getTime())) {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      if(user && !attendees.find(f => f.email === user.email)) {
        setAttendees(produce(draft => {
          draft.push({
            id: 0,
            person: user?.firstName + ' ' + user?.lastName,
            company: loggedUserCompany?.name ?? 'not found',
            email: user.email,
            meetingId: 0
          })
        }))
      }
      
      setValidationErrors(false);
      setSaving(false);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setSaving(false);
    setValidationErrors(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

    const deleteAttendee = (index: number) => {
    setAttendees(produce(draft => {
        draft.splice(index, 1)
    }))}

    const handleAddAppUSersClick = () => {
        setAttendees(produce(draft => {
            if (usersGridRef.current) {
                usersGridRef.current.getSelectedRows().forEach((row) => {
                  const company = selectCompanyById(store.getState(), row.companyId)?.name ?? 'not found';
                    draft.push({
                        id: 0,
                        person: row.firstName + ' ' + row.lastName,
                        company: company,
                        email: row.email,
                        meetingId: 0
                    })
                })
            }
        }))
        setAppUsersDialogOpen(false)
    }

    const validateEmail = () => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if(email === ""){
        setEmailError("");
      }
      else if (!emailRegex.test(email)) {
          setEmailError("Nieprawidłowy adres e-mail");
      }
      else {
        setEmailError("");
      }
  };

    const emails: Set<Email> = new Set(attendees.map(a => a.email))

  return (
    <Container fixed>
      <ServerErrorMsg err={error} />
    {loadingLastMeeting && <Box sx={{ display: 'flex', p: 2 }}>Wczytywanie danych z poprzedniego spotkania... </Box>}
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{<Localized id={label}>{label}</Localized>}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment></React.Fragment>
      ) : (
        <React.Fragment>
        <If condition={activeStep === 0}>
            <Stack sx={{ mt: 4 }} spacing={2}>
                <TextField
                  helperText={meetingTitleError && <RequiredError />}
                  required
                  fullWidth
                  label={<Localized id="meeting-minutes-meeting-title">Tytuł spotkania</Localized>}
                  value={title}
                  error={meetingTitleError}
                  onChange={e => { setTitle(e.target.value); setSaving(false); } }
                />
                <TextField
                  fullWidth
                  label={<Localized id="meeting-minutes-on-demand">Na żądanie</Localized>}
                  value={requestedBy}
                  onChange={e => { setRequestedBy(e.target.value); setSaving(false); }}
                />
                <TextField
                  fullWidth
                  required
                  helperText={meetingLocationError && <RequiredError />}
                  label={<Localized id="meeting-minutes-meeting-place">Miejsce spotkania</Localized>}
                  value={location}
                  error={meetingLocationError}
                  onChange={e => { setLocation(e.target.value); setSaving(false); } }
                />
                <TextField
                  label={<Localized id="meeting-minutes-meeting-planned-time">Planowany czas (minuty)</Localized>}
                  sx={{maxWidth:"200px"}}
                  type="number"
                  value={plannedMeetingTime}
                  onChange={handlePlannedMeetingTime}
                  defaultValue={60}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    step: 1, 
                    min: 0,  
                    max: 1440 
                  }}
               />
                <DateTimePicker
                    label={<Localized id="meeting-minutes-meeting-planned-time">Planowany czas (minuty)</Localized>}
                    views={['year', 'day', 'hours', 'minutes']}
                    value={dateAndTime}
                    slotProps={{
                      textField: {
                        helperText: meetingDateAndTimeError ? <InvalidDateError /> : ""
                      }
                    }}
                    onChange={(newVal) => { newVal && setDateAndTime(newVal); newVal && setSaving(false); } }
                />
                <If condition={meetingDateAndTimeError}>
                  <InvalidDateError />
                </If>
            </Stack>
        </If>
        <If condition={activeStep === 1}>
            <Stack sx={{ mt: 4 }} spacing={4}>
                <Paper sx={{ p: 2 }}>
                    <Stack direction="column" spacing={2}>
                        <TextField
                          required
                          inputRef={firstNameRef}
                          helperText={attendeesPersonError && <RequiredError />}
                          fullWidth
                          label={<Localized id="meeting-minutes-attendee-fullname">Imię i nazwisko</Localized>}
                          value={person}
                          error={attendeesPersonError}
                          onChange={e => { setPerson(e.target.value); setSaving(false); } }
                        />
                        <Stack direction="row" spacing={2}>
                            <TextField
                              fullWidth
                              helperText={attendeesCompanyError && <RequiredError />}
                              label={<Localized id="meeting-minutes-attendee-company">Firma</Localized>}
                              value={company}
                              error={attendeesCompanyError}
                              required
                              onChange={e => { setCompany(e.target.value); setSaving(false); } }
                            />
                            <TextField fullWidth label="Email" 
                              value={email} 
                              onBlur={validateEmail}
                              error={!!emailError}
                              helperText={emailError}
                              onChange={e => setEmail(e.target.value)} 
                            />
                            <Button onClick={handleAddAttendee} disabled={saving}>
                              <Localized id="add">Dodaj</Localized>
                            </Button>
                        </Stack>
                        <Box><span><Localized id="or">lub</Localized></span> 
                          <Button onClick={() => setAppUsersDialogOpen(true)}>
                            <Localized id="meeting-minutes-attendee-add-from-catalogue">dodaj z listy użytkowników aplikacji</Localized> 
                          </Button>
                        </Box>
                    </Stack>
                </Paper>
                {attendees.length === 0 ? <Typography color='text.secondary'><Localized id="meeting-minutes-no-attendees">Brak uczestników</Localized></Typography> : <List sx={{ maxHeight: 'calc(100vh - 400px)', overflowY: 'auto' }}>
                    <ListSubheader><Localized id="meeting-minutes-attendees">Uczestnicy</Localized>:</ListSubheader>
                    {attendees.map((attendee, index) => <ListItem divider secondaryAction={<IconButton disabled={attendee.email === user?.email} onClick={() => deleteAttendee(index)} edge='end' ><DeleteIcon /></IconButton>} key={index}>
                            <ListItemText primary={`${index + 1}. ${attendee.person}`} secondary={`${attendee.company}, ${attendee.email}`}></ListItemText>
                        </ListItem>)}
                </List>}
            </Stack>
        </If>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mt: 2 }}>
            <Button
                variant="outlined"
                color="error"
                onClick={handleGoBackclick}
                sx={{ mr: 1 }}
              >
              <CloseIcon/> {<Localized id="meeting-minutes-cancel-creating-meeting">Anuluj tworzenie spotkania</Localized>}
              </Button>
           
            
            <Box sx={{ flex: '1 1 auto' }} />
            <If condition={activeStep === 1}>
               <Button
                variant="outlined"
                onClick={handleBack}
                sx={{ mr: 1 }}
            >
              <NavigateBeforeIcon/>
              Wróć
            </Button>
            </If>
                <If condition={activeStep === 0}>
                    <Button variant="outlined" onClick={handleNext} disabled={saving}>
                     
                    {<Localized id="meeting-minutes-next">Dalej</Localized>} <NavigateNextIcon/>
                    </Button>
                </If>
                <If condition={activeStep === steps.length - 1}>
                    <Button color="success" variant="outlined"  disabled={saving} onClick={handleCreateMeetingClick}>Rozpocznij spotkanie</Button>
                </If>
          </Box>
        </React.Fragment>
      )}
        <Dialog open={appUsersDialogOpen} onClose={() => setAppUsersDialogOpen(false) } fullWidth maxWidth='lg'>
                <DialogTitle>Użytkownicy</DialogTitle>
                <DialogContent>
                    <UsersBrowser gridRef={usersGridRef} disabled={emails} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddAppUSersClick}>
                        <Localized id='ok'>OK</Localized>
                    </Button>
                    <Button onClick={() => setAppUsersDialogOpen(false)}>
                        <Localized id='cancel'>Anuluj</Localized>
                    </Button>
                </DialogActions>
        </Dialog>
    </Container>
  )
}
