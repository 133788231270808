import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab"
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Skeleton, Stack, Tab, TableContainer, TextField } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { SystemSettings, createSystemNotification, deleteSystemNotification, editSystemNotification, getSystemNotificationTypes, loadSystemSettings } from "./systemSettingsApi"
import { Localized, useLocalization } from "@fluent/react"
import { LoadCompanies } from "../../app/AppDataLoader"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { selectSettings } from "../settings/settingsSlice"
import { unwrapResult } from "@reduxjs/toolkit"
import SyncIcon from '@mui/icons-material/Sync';
import { FlexDiv } from "../../app/Utils"
import AddIcon from '@mui/icons-material/Add'
import { If } from "../../app/If"
import produce from "immer";
import { LoadingContainer } from "../../app/LoadingContainer"
import { useSearchParams } from "react-router-dom"
import { SystemEvents } from "./systemEvents/SystemEvents"
import { SystemTestSettings } from "./system/SystemTestSettings"
import { HelpArticles } from "./system/HelpArticles"
import { SystemFeatureFlags } from "./systemFeatureFlags/SystemFeatureFlags"
import { SystemDocumentSettings } from "./systemDocumentSettings/SystemDocumentSettings"

type FormTabs = "systemNotifications" | "systemEvents" | "systemTest" | "systemHelp" | "systemFeatureFlags" | "documentSettings"

export const SystemSettingsPage = () => {
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const dispatch = useAppDispatch();
    const [systemSettings, setSystemSettings] = useState<SystemSettings[]>([]);
    const [notificationType, setNotificationType] = useState('all');
    const [saving, setSaving] = useState(false);
    const [systemNotificationsTypes, setSystemNotificationsTypes] = useState<string[]>([]);
    const [dialogVisible, setDialogVisible] = useState<string>('');
    const [id, setId] = useState('');
    const [email, setEmail] = useState('');
    const [notificationTypeDialog, setNotificationTypeDialog] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadSystemSettings(`?notificationType=${notificationType}`))
            .then(unwrapResult)
            .then(setSystemSettings)
            .catch((error) => console.error(error))
            .finally(() => setIsLoading(false))
        dispatch(getSystemNotificationTypes())
            .then(unwrapResult)
            .then(result => {
                setSystemNotificationsTypes(result);
                setNotificationTypeDialog(result[0]);
            }).catch((error) => console.error(error))
    }, [dispatch, notificationType]);

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const [params, setParams] = useSearchParams()
    const tab = params.get('tab') as FormTabs || 'systemNotifications'
    const setTab = (tab: FormTabs) => {
        params.set('tab', tab)
        setParams(params, { replace: true })
    }

    const handleTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [])

    const handleNotificationTypeChange = (event) => {
        setSaving(false);
        setNotificationType(event.target.value);
    };

    const handleNotificationTypeDialogChange = (event) => {
        setSaving(false);
        setNotificationTypeDialog(event.target.value);
    };

    const handleRefreshClick = () => {
        setSaving(true);
        dispatch(loadSystemSettings(`?notificationType=${notificationType}`)) // &dateFrom=${dateFrom}&dateTo=${dateTo}
        .then(unwrapResult)
        .then(result => {
            setSystemSettings(result);
            setSaving(false);
        })
        .catch((error) => console.error(error))
    }

    const handleCancel = useCallback(() => setDialogVisible(''), [])

    const handleSaveClick = async () => {
        if(dialogVisible === 'create') {
            await dispatch(createSystemNotification({
                email: email,
                notificationType: notificationTypeDialog
            }))
            .then(unwrapResult)
            .then((notification) => {
                console.log(notification);
                setDialogVisible('')
                setSystemSettings(produce(draft => {
                    draft.push(notification)
                }))
            })
            .catch((err) => console.log(err))
            .finally(() => setSaving(false))
        } else if(dialogVisible === 'edit') {
            await dispatch(editSystemNotification({
                id: id,
                email: email,
                notificationType: notificationTypeDialog
            }))
            .then(unwrapResult)
            .then((notification) => {
                console.log('test')
                setDialogVisible('')
                setSystemSettings(produce(draft => {
                    let element = draft.find(f => f.id === notification.id);
                    if(element) {
                        element.email = notification.email;
                        element.notificationType = notification.notificationType;
                    }
                }))
            })
            .catch((err) => console.log(err))
            .finally(() => setSaving(false))
        } else if(dialogVisible === 'delete') {
            await dispatch(deleteSystemNotification(id))
            .then(unwrapResult)
            .then((notification) => {
                setDialogVisible('')
                setSystemSettings(produce(draft => {
                    draft.splice(draft.findIndex(f => f.id === notification), 1)
                }))
            })
            .catch((err) => console.log(err))
            .finally(() => setSaving(false))
        }
    }

    const columns: GridColDef<SystemSettings, any, any>[] = [
        {
            field: 'email',
            headerName: l10n.getString("system-notifications-email", null, "E-mail"),
            width: 300
        },
        {
            field: 'notificationType',
            headerName: l10n.getString("system-settings-notification-type", null, "Typ powiadomienia"),
            flex: 1,
        },
        {
            field: 'createDate',
            headerName: l10n.getString("create-date", null, "Data utworzenia"),
            flex: 1,
            valueGetter: (_, row) => {
                return new Date(row.createDate).toLocaleString();
            }
        },
        {
            field: 'actions', type: 'actions', width: 300,
            renderCell: (params) => {
                return (
                    <ButtonGroup size="small">
                        <Button
                            style={{ width: 100 }}
                            onClick={() => { setDialogVisible('edit'); setId(params.row.id); setEmail(params.row.email); setNotificationTypeDialog(params.row.notificationType); }}
                            color="secondary"
                            variant="outlined" >
                            <Localized id="edit">
                                <span>Edytuj</span>
                            </Localized>
                        </Button>
                        <Button
                            style={{ width: 100 }}
                            onClick={() => { setDialogVisible('delete'); setId(params.row.id); }}
                            color="error"
                            variant="outlined" >
                            <Localized id="delete">
                                <span>Usuń</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ]

    if (isLoading) {
        return <>
            <LoadingContainer/>
        </>
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TabContext value={tab}>
                        <TabList onChange={handleTabChange}>
                            <Tab
                                key={0}
                                value={"systemNotifications"}
                                label={<Localized id="system-notifications">Powiadomienia systemowe</Localized>} />
                            <Tab
                                key={1}
                                value={"systemEvents"}
                                label={<Localized id="menu-system-events">Zdarzenia systemowe</Localized>} />
                            <Tab
                                key={2}
                                value={"systemTest"}
                                label={<Localized id="menu-system-test-settings">Testy systemu</Localized>} />
                            <Tab
                                key={3}
                                value={"systemHelp"}
                                label={<Localized id="menu-system-help">Ustawienia pomocy</Localized>} />
                            <Tab
                                key={4}
                                value={"systemFeatureFlags"}
                                label={<Localized id="system-feature-flags">Ustawienia flag</Localized>} />
                            <Tab
                                key={5}
                                value={"documentSettings"}
                                label={<Localized id="system-document-settings">Ustawienia dokumentów</Localized>} />
                        </TabList>
                        <TabPanel value="systemNotifications">
                            <div>
                                <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                                    <FormControl>
                                        <InputLabel id="select-notification-type-label"><Localized id="system-settings-notification-type">Typ powiadomienia</Localized></InputLabel>
                                        <Select
                                            labelId="select-notification-type-label"
                                            value={notificationType}
                                            onChange={handleNotificationTypeChange}
                                            label={<Localized id="system-settings-notification-type">Typ powiadomienia</Localized>}
                                            sx={{ width: '200px' }}
                                        >
                                            <MenuItem value={'all'}>
                                                <Localized id="all">All</Localized>
                                            </MenuItem>
                                            {systemNotificationsTypes.map((option) =>
                                                <MenuItem value={option}>{option}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                    <IconButton
                                        onClick={handleRefreshClick}
                                        disabled={saving}
                                        >
                                            <SyncIcon />
                                    </IconButton>
                                </Stack>
                            </div >
                            <LoadCompanies component={<Skeleton variant="rectangular" />}>
                                <TableContainer component={Paper}>
                                    <DataGrid localeText={getCustomLocaleText()}
                                        style={{ minHeight: 100, height: 'calc(100vh - 360px)' }}
                                        rows={systemSettings}
                                        columns={columns}
                                    />
                                </TableContainer>
                                <FlexDiv>
                                    <Fab sx={{
                                            marginTop: 2,
                                        }} 
                                        onClick={() => { setDialogVisible('create'); setEmail(''); setNotificationTypeDialog(systemNotificationsTypes[0]); } }
                                        color="secondary" 
                                    >
                                        <AddIcon />
                                    </Fab>
                                </FlexDiv>
                            </LoadCompanies >
                        </TabPanel>
                        <TabPanel value="systemEvents">
                            <SystemEvents/>
                        </TabPanel>
                        <TabPanel value="systemTest">
                            <SystemTestSettings/>
                        </TabPanel>
                        <TabPanel value="systemHelp">
                            <HelpArticles/>
                        </TabPanel>
                        <TabPanel value="systemFeatureFlags">
                            <SystemFeatureFlags/>
                        </TabPanel>
                        <TabPanel value="documentSettings">
                            <SystemDocumentSettings/>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
            <Dialog open={dialogVisible !== ''} maxWidth="md" fullWidth keepMounted={false}>
                <If condition={dialogVisible === 'create'}>
                    <DialogTitle>
                        <Localized id='system-settings-notification-add'>Dodaj powiadomienie</Localized>
                    </DialogTitle>
                </If>
                <If condition={dialogVisible === 'edit'}>
                    <DialogTitle>
                        <Localized id='system-settings-notification-edit'>Edytuj powiadomienie</Localized>
                    </DialogTitle>
                </If>
                <If condition={dialogVisible === 'delete'}>
                    <DialogTitle>
                        <Localized id='system-settings-notification-delete'>Usuń powiadomienie</Localized>
                    </DialogTitle>
                </If>
                <If condition={dialogVisible === 'create' || dialogVisible === 'edit'}>
                    <DialogContent>
                        <TextField size="small"
                            InputLabelProps={{
                            shrink: true
                            }}
                            fullWidth
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={saving}
                            sx={{ marginTop: "10px" }}
                            value={email}
                            label={<Localized id='email'>Email</Localized>}>
                        </TextField>
                        <FormControl sx={{ marginTop: '15px' }}>
                            <InputLabel sx={{ marginTop: '10px' }} id="select-notification-type-label"><Localized id="system-settings-notification-type">Typ powiadomienia</Localized></InputLabel>
                            <Select
                                labelId="select-notification-type-label"
                                value={notificationTypeDialog}
                                onChange={handleNotificationTypeDialogChange}
                                label={<Localized id="system-settings-notification-type">Typ powiadomienia</Localized>}
                                fullWidth
                                sx={{ marginTop: "10px" }}
                            >
                                {systemNotificationsTypes.map((option) =>
                                    <MenuItem value={option}>{option}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </DialogContent>
                </If>
                <DialogActions>
                    <LoadingButton onClick={handleSaveClick}>
                    <Localized id='save'>OK</Localized>
                    </LoadingButton>
                    <LoadingButton onClick={handleCancel}><Localized id='cancel'>Anuluj</Localized></LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
