import { Localized, useLocalization } from '@fluent/react'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { useAppSelector } from '../../app/hooks'
import { Skeleton } from '@mui/material'
import { selectAllUrsOffers, UrsOfferHeader } from './ursOfferSlice'
import { LoadURSOffers } from '../../app/AppDataLoader'
import { selectSettings } from '../settings/settingsSlice'
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { useNavigate } from 'react-router-dom'
import { StructureName } from '../device/Structure'

export const UrsOfferList = () => {
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const allOffers = useAppSelector(selectAllUrsOffers);
    const navigate = useNavigate()

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const handleGoToClick = (id) => {
        navigate(`/ursOffers/edit/${id}`)
    };

    const columns: GridColDef<UrsOfferHeader, any, any>[] = [
        {
            field: 'ursNo',
            headerName: l10n.getString("urs-number", null, "_Numer URS"),
            width: 300
        },
        {
            field: 'deviceTypeId',
            headerName: l10n.getString("urs-device-type", null, "_Typ urzadzenia"),
            flex: 1,
            renderCell:(params) =>{
                return (
                    <StructureName structureId={params.row.deviceTypeId} />
                )
            }
        },
        {
            field: 'companyName',
            headerName: l10n.getString("urs-client", null, "_Klient"),
            width: 300
        },
        {
            field: 'createDate',
            headerName: l10n.getString("urs-offer-create-date", null, "_Data przesłania"),
            width: 300,
            valueGetter: (_, row) => new Date(row.createDate).toLocaleDateString()
        },
        {
            field: 'status',
            headerName: l10n.getString("urs-status", null, "_Status"),
            width: 300,
            valueGetter: (_, row) => {
                var status = row.status.toLowerCase();

                return l10n.getString("urs-offer-status-" + status, null, "_Status")
            }
        },
        {
            field: 'actions', type: 'actions', width: 150,
            renderCell: (params) => {
                return (
                    <Button onClick={() => handleGoToClick(params.row.id)} color="secondary" variant="outlined">
                        <Localized id="go-to">
                            <span>go-to</span>
                        </Localized>
                    </Button>
                )
            }
        }
    ]

    return <>
        <LoadURSOffers component={<Skeleton animation="wave" variant="rectangular" height="64vh" />}>
            <TableContainer component={Paper}>
                <DataGrid localeText={getCustomLocaleText()}
                    style={{ minHeight: 100, height: 'calc(100vh - 200px)' }}
                    rows={allOffers}
                    columns={columns}
                    initialState={{
                        sorting: { sortModel: [{ field: 'createDate', sort: 'desc' }] }
                    }}
                    onRowDoubleClick={({ row }) => handleGoToClick(row.id)}
                />
            </TableContainer>
        </LoadURSOffers>
    </>
}
