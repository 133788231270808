import React, { useState } from 'react';
import { Box, Typography, Paper, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, AppBar } from '@mui/material';
import { ExpandMore, ChevronRight, Print as PrintIcon } from '@mui/icons-material';
import { device, treeData, TreeNode } from './dsMockData';
import { TreeItem, TreeView } from '@mui/lab';

const SupplierDSMockup: React.FC = () => {
    const [selectedNode, setSelectedNode] = useState<TreeNode | null>(null);

    const handleNodeSelect = (nodeId: string, nodes: TreeNode[]) => {
        const findNode = (id: string, nodes: TreeNode[]): TreeNode | null => {
            for (const node of nodes) {
                if (node.id === id) return node;
                if (node.children) {
                    const found = findNode(id, node.children);
                    if (found) return found;
                }
            }
            return null;
        };
        setSelectedNode(findNode(nodeId, nodes));
    };

    const renderTree = (nodes: TreeNode[]) => (
        nodes.map((node) => (
            <TreeItem key={node.id} nodeId={node.id} label={node.label}>
                {node.children && renderTree(node.children)}
            </TreeItem>
        ))
    );

    const handlePrint = () => {
        window.print();
    };

    return (
        <Box display="flex" flexDirection="column" sx={{ height: 'calc(100vh - 150px)' }}>
            <Box p={2}>
                <Typography variant="h5" gutterBottom fontWeight="bold">Design Specification</Typography>
                <Typography variant="body2" color="textSecondary">
                    Version: 1 | Created by: John Smith | Date: {new Date().toLocaleDateString()} | Structure: Cars
                </Typography>
                <Divider sx={{ mt: 2 }} />
            </Box>


            <Box display="flex" flexGrow={1} overflow="hidden">
                <Paper elevation={3} sx={{ width: '30%', padding: 2 }}>
                    <Typography variant="h6">Device Structure</Typography>
                    <Divider sx={{ my: 2 }} />
                    <TreeView
                        defaultCollapseIcon={<ExpandMore />}
                        defaultExpandIcon={<ChevronRight />}
                        onNodeSelect={(event, nodeId) => handleNodeSelect(nodeId, treeData)}
                    >
                        {renderTree(treeData)}
                    </TreeView>
                </Paper>

                <Paper elevation={3} sx={{ width: '70%', padding: 2, overflowY: 'auto' }}>
                    <Typography variant="subtitle1">Device Type: {device.type}</Typography>
                    <Typography variant="subtitle1">Device ID: {device.id}</Typography>
                    <Typography variant="subtitle1">Production Date: {device.productionDate}</Typography>
                    <Box mb={2}>
                        <img src={device.imageBase64} style={{ maxWidth: '100%', borderRadius: '8px' }} />
                    </Box>


                    <Divider sx={{ my: 2 }} />

                    {selectedNode ? (
                        <>
                            <Typography variant="h5" color="primary" gutterBottom>
                                {selectedNode.label}
                            </Typography>
                            <Typography variant="body1" gutterBottom>{selectedNode.description}</Typography>

                            <Typography variant="h6">Tests</Typography>
                            <TableContainer component={Paper} sx={{ maxHeight: 200, overflowY: 'auto' }}>
                                <Table size="small" aria-label="tests table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Test Name</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Designation</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedNode.tests.map((test, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{test.name}</TableCell>
                                                <TableCell>{test.type}</TableCell>
                                                <TableCell>{test.type || '-'}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    ) : (
                        <Typography variant="body1" color="textSecondary">
                            Select an item from the structure to view details.
                        </Typography>
                    )}
                </Paper>
            </Box>

            <AppBar position="fixed" color="transparent" sx={{ top: 'auto', bottom: 0, p: 0.5 }}>
                <Box display="flex" justifyContent="center">
                    <Button variant="contained" color="primary" startIcon={<PrintIcon />} onClick={handlePrint}>
                        Print
                    </Button>
                </Box>
            </AppBar>
        </Box>
    );
};

export default SupplierDSMockup;
