import { Localized } from "@fluent/react"
import { Skeleton, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, TablePagination } from "@mui/material"
import { useState, useMemo, useCallback } from "react"
import { AppId, AppTextFieldHandler } from "../../app/appTypes"
import { useAppSelector } from "../../app/hooks"
import { ValidationStage, selectAllValidations } from "./validationsSlice"
import { LoadValidations } from "../../app/AppDataLoader"
import { StructureName } from "../device/Structure"

export interface ValidationsBrowserProps {
    excluded: Set<AppId>
    selection: Set<AppId>
    itemChecked: (id: AppId) => void
    itemUnchecked: (id: AppId) => void
    stage?: ValidationStage | undefined
}

export const ValidationsBrowser = ( { excluded, selection, itemChecked, itemUnchecked, stage }: ValidationsBrowserProps) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const allValidations = useAppSelector(selectAllValidations)
    const validations = 
        useMemo(() => allValidations.filter(({ id, stage: testStage }) => !excluded.has(id) && (stage === testStage || stage === undefined)), [allValidations, excluded, stage])

    const validationsPage = validations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const validationsCount = validations.length
  
    const handleChangePage = useCallback((_: unknown, newPage: number) => {
        setPage(newPage)
    }, [ setPage ])
    
    const handleChangeRowsPerPage: AppTextFieldHandler = useCallback((event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }, [ setPage, setRowsPerPage ])
    const handleCheckboxChange = (validationId: AppId, checked: boolean) => {
        if (checked) {
            itemChecked(validationId)
        } else {
            itemUnchecked(validationId)
        }
    }
    
    return <LoadValidations component={<Skeleton animation="wave" variant="rectangular" height="64vh" ></Skeleton>}>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                            <Localized id="validation-name">
                                <span>Nazwa</span>
                            </Localized>
                        </TableCell>
                        <TableCell sx={{width: "128px"}} >
                            <Localized id="validation-stage">
                                <span>Etap</span>
                            </Localized>
                        </TableCell>
                        <TableCell><Localized id="structure-node-device-type">Typ urządzenia</Localized></TableCell>
                        <TableCell sx={{width: "128px"}} >
                            <Localized id="validation-system">
                                <span>Systemowy</span>
                            </Localized>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {validationsPage.map(({ id, name, stage, structureId, isSystem }) => {
                        return <TableRow key={id}>
                            <TableCell padding="checkbox">
                                <Checkbox checked={selection.has(id)} onChange={(_, checked) => handleCheckboxChange(id, checked)} />
                            </TableCell>
                            <TableCell>{name}</TableCell>
                            <TableCell>{stage}</TableCell>
                            <TableCell><StructureName structureId={structureId} /></TableCell>
                            <TableCell>
                                <Checkbox defaultChecked={isSystem} disabled />
                            </TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={validationsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </LoadValidations>
}
