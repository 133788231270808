import { AsyncThunk, unwrapResult } from "@reduxjs/toolkit";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { APIError, AppId, AppThunkAPIType } from "./appTypes";
import produce from "immer";
import { useAppDispatch } from "./hooks";
import { showError, showSuccess } from "../features/notifications/notificationsSlice";
import { Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { Localized } from "@fluent/react";
import { LoadingButton } from "@mui/lab";
import { ServerErrorMsg } from "./ServerErrorMsg";
import { useNavigate } from "react-router-dom";
import { LoadingContainer } from "./LoadingContainer";

export interface Idable<T> {
    id : T
}

export type CreateAction<Id, T extends Idable<Id>> = AsyncThunk<T, T, AppThunkAPIType>
export type UpdateUction<Id, T extends Idable<Id>> = AsyncThunk<T, T, AppThunkAPIType>
export type LoadAction<Id, T extends Idable<Id>> = AsyncThunk<T, Id, AppThunkAPIType>
export type DeleteAction<Id> = AsyncThunk<void, Id, AppThunkAPIType>

export type SaveResult<T>
    = { tag: 'ok', entity: T }
    | { tag: 'error', error: APIError }

export type DataSetter<T> = (setter: (d: T) => void) => void

export interface AppData<Id, T extends Idable<Id>> {
    data : T
    updateDataLocally: DataSetter<T>
    persisted : boolean
    save : () => Promise<SaveResult<T>>
    del : () => void
    saving: boolean
    unsaved: boolean
}

export interface AppDataProviderProps<Id, T extends Idable<Id>> {
    id : Id | undefined
    def : T
    create : CreateAction<Id, T>
    update : UpdateUction<Id, T>
    load   : LoadAction<Id, T>
    del : DeleteAction<Id>
    form : (state: AppData<Id, T>) => ReactElement
}

export function AppDataProvider<Id, T extends Idable<Id>>(props: AppDataProviderProps<Id, T>) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { id, def, create, update, form, load, del } = props
    const Form = form

    const [data, setData] = useState<T | undefined>(() => {
        return id === undefined ? def : undefined
    })
    const [loading, setLoading] = useState<boolean>(false)
    const [saving, setSaving] = useState<boolean>(false)
    const [unsaved, setUnsaved] = useState<boolean>(false)
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false)
    const [error, setError] = useState<APIError | undefined>(undefined)

    useEffect(() => {
        if (id !== undefined) {
            setLoading(true)
            dispatch(load(id))
                .then(unwrapResult)
                .then(setData)
                .then(() => setUnsaved(false))
                .catch(err => {
                    setError(err as APIError)
                })
                .finally(() => setLoading(false))
        }
    }, [id])

    const updateDataLocally = useCallback((f: (data: T) => void) => {
        setData(produce(draft => {
            f(draft)
        }))
        setUnsaved(true)
    }, [])
    const save = async () => {
        const action = id === undefined ? create : update
        if (data === undefined) {
            throw new Error("Data is undefined")
        } else {
            setSaving(true)
            let result: SaveResult<T>
            try {
                const entity = await dispatch(action(data)).unwrap()
                setData(entity)
                setUnsaved(false)
                dispatch(showSuccess('saved'))
                result = { tag: 'ok', entity }
            } catch (err) {
                dispatch(showError('error'))
                result = { tag: 'error', error: err as APIError }
            } finally {
                setSaving(false)
            }
            return result
        }
    }
    const deleteData = async () => {
        if (data) {
            setSaving(true)
            try {
                await dispatch(del(data.id)).unwrap()
                setData(undefined)
                setUnsaved(false)
                dispatch(showSuccess('deleted'))
                navigate(-1)
            } catch (err) {
                dispatch(showError('error'))
            } finally {
                setSaving(false)
            }
        } else {
            throw new Error("Data is undefined")
        }
    }
    const deleteEntity = useCallback(() => {
        setConfirmDelete(true)
    }, [])

    if (data === undefined && !loading) {
        return <div>No data</div>
    } else if (loading) {
        return <LoadingContainer />
    } else if (error) {
        <ServerErrorMsg err={error} />
    } else if (data) {
        return <>
            <Form data={data} updateDataLocally={updateDataLocally} persisted={id !== undefined} save={save} saving={saving} unsaved={unsaved} del={deleteEntity} />
            <Dialog fullWidth open={confirmDelete} maxWidth="xs">
                <DialogContent>
                    <DialogContentText>
                        <Localized id='confirm-delete'>Czy napewno chcesz usunąć?</Localized>
                    </DialogContentText>
                    <DialogActions>
                        <LoadingButton loading={saving} onClick={deleteData}>
                            <Localized id='yes'>Tak</Localized>
                        </LoadingButton>
                        <LoadingButton loading={saving} onClick={() => setConfirmDelete(false)}>
                            <Localized id='no'>Nie</Localized>
                        </LoadingButton>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    }

    return null
}
