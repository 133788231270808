import React from 'react';
import { selectSettings } from '../settings/settingsSlice';
import { useAppSelector } from '../../app/hooks';
import { Button } from '@mui/material';
import { Localized } from '@fluent/react';
import PrintIcon from '@mui/icons-material/Print';

interface OpenReportProps {
    id: string;
    stage: string;
    reportType: string;
    label: string
}

const OpenReport: React.FC<OpenReportProps> = ({ id, stage, reportType, label  }) => {
    const { locale } = useAppSelector(selectSettings);

    const openReport = () => {
        window.open(
            `${process.env.REACT_APP_REST_API}api/reports/${reportType}/${id}/${stage}?language=${locale}`,
            '_blank'
        );
    };

    return (<>
         <Button color="secondary" variant="outlined" value="report" aria-label="report" onClick={() => openReport()}>
            <PrintIcon />
            <Localized id={label}>Drukuj protokół</Localized>
        </Button>
        </> 
    );
};

export default OpenReport;
