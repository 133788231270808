import { createAsyncThunk } from "@reduxjs/toolkit"
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../../http"
import { AppId, AppThunkAPIType } from "../../../app/appTypes"

export type ProjectProcessStatus = 'D' | 'I' | 'H' | 'F' | 'C'

export interface ProjectProcessHeader {
    id: AppId,
    no: string,
    name: string,
    description: string,
    status: string,
    createdBy: string,
    createdByFullName: string,
    createDate: Date,
    modifiedBy: string,
    modifiedByFullName: string,
    modifyDate: Date,
    projectId: number,
    projectName: string,
    projectNo: string,
    guid: string
}

export interface ProjectProcessTeamMember {
    id: AppId,
    user: string,
    firstName: string,
    lastName: string,
    position: string,
    companyName: Date,
    role: string,
    processId: number
}

export interface ProjectProcessStage {
    id: AppId,
    name: string,
    no: string,
    responsibleFullName: string,
    createDate: Date,
    processId: number,
    status: string,
    type: string
}

export type ProjectProcess = ProjectProcessHeader & {
    processTeamMembers: ProjectProcessTeamMember[]
    projectProcessStages : ProjectProcessStage []
}

export interface CreateProcessTeamMemberPayload {
    user: string,
    processId: string,
    role: string
}

export interface InviteProcessTeamMemberPayload {
    userEmail: string,
    processId: string,
    role: string
}

export interface DescriptionFieldPayload {
    fieldName: string
    text: string
}

export interface StatusResponse {
    status: string
}

export interface ProjectActionArgs {
    id: AppId,
    args: { password: string }
}

export interface ProjectStatusActionArgs {
    id: AppId,
    args: { password: string } & StatusResponse
}


export const loadProjectProcesses = createAsyncThunk<ProjectProcessHeader[], void, AppThunkAPIType>(
    "projectProcesses/loadAll", async (_, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: "api/project-process" }))
        const { payload } = result

        if (getWithAuth.fulfilled.match(result)) {
            return payload as ProjectProcessHeader[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })


export const loadProjectProcess = createAsyncThunk<ProjectProcess, string, AppThunkAPIType>("projectProcesses/loadProcess", async (id, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({
        url: `api/project-process/${id}/`,
    }))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as ProjectProcess
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const loadForProject = createAsyncThunk<ProjectProcessHeader[], string, AppThunkAPIType>("projectProcesses/loadForProject", async (id, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({
        url: `api/project-process/load-for-project/${id}`,
    }))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as ProjectProcessHeader[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})


export const createProcessTeamMember = createAsyncThunk<ProjectProcessTeamMember, CreateProcessTeamMemberPayload, AppThunkAPIType>(
    "projectProcessesTM/create",
    async (createPayload: CreateProcessTeamMemberPayload, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: "api/process-team-member",
            payload: createPayload,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as ProjectProcessTeamMember
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const inviteProcessTeamMember = createAsyncThunk<ProjectProcessTeamMember, InviteProcessTeamMemberPayload, AppThunkAPIType>(
    "projectProcessesTM/invite",
    async (invitePayload: InviteProcessTeamMemberPayload, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: "api/process-team-member/invite",
            payload: invitePayload,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as ProjectProcessTeamMember
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deletepProcessTeamMember = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "process-team-member/delete",
    async (id: AppId, { rejectWithValue, dispatch }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/process-team-member/${id}` }))
        const { payload } = result
        if (deleteWithAuth.fulfilled.match(result)) {
            return id
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deletepProcess = createAsyncThunk<AppId, ProjectActionArgs, AppThunkAPIType>(
    "process/delete",
    async ({ id, args }, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({ 
            url: `api/project-process/${id}`,
            payload: args
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return id
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateProjectProcessName = createAsyncThunk<ProjectProcessHeader, { id: AppId, data: DescriptionFieldPayload }, AppThunkAPIType>(
    "process/update",
    async ({ id, data }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({
            url: `api/project-process/${id}`,
            payload: data,
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload as ProjectProcessHeader
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const linkDocumentToProcess = createAsyncThunk<ProjectProcess, { documentId: AppId, processId: AppId }, AppThunkAPIType>(
    "process/updateDocumentProcess",
    async ({ processId, documentId }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/project-process/${processId}/link`,
            payload: {
                documentId: documentId,
                processId: processId
            },
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as ProjectProcess
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateProjectProcessStatus = createAsyncThunk<StatusResponse, ProjectStatusActionArgs, AppThunkAPIType>(
    "projects/update-status",
    async ({ id, args }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({
            url: `api/project-process/${id}/status`,
            payload: args ?? {},
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload as StatusResponse
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)