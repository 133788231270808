// mockData.ts

import { cupraImage, cupramotor } from "./images";

export interface Device {
    id: string;
    name: string;
    productionDate: string;
    type: string;
    imageBase64?: string;
  }
  
  export interface TreeNode {
    id: string;
    label: string;
    description: string;
    tests: { name: string; type: string; designation?: string }[];
    children?: TreeNode[];
    imageBase64?: string;
  }
  
  export const device: Device = {
    id: '002',
    name: 'Cupra Formentor',
    productionDate: '2024-01-15',
    type: 'SUV',
    imageBase64: cupraImage
  };
  
  export const treeData: TreeNode[] = [
    {
      id: '1',
      label: 'Engine Module',
      description: 'This module includes the main engine variants available for the Cupra Formentor.',
      tests: [
        { name: 'Power Output Test', type: 'IQ', designation: 'FAT' },
        { name: 'Fuel Efficiency Test', type: 'OQ', designation: 'FAT' },
        { name: 'Emission Compliance Check', type: 'DQ', designation: 'SAT' }
      ],
      imageBase64: cupramotor,
      children: [
        {
          id: '1.1',
          label: '2.0 TSI 310 HP',
          description: '2.0-liter turbocharged engine delivering 310 horsepower.',
          tests: [
            { name: 'Turbo Boost Test', type: 'IQ', designation: 'FAT' },
            { name: 'Torque Measurement', type: 'PQ', designation: 'FAT' },
            { name: 'Heat Resistance', type: 'OQ', designation: 'FAT' }
          ],
          children: [
            {
              id: '1.1.1',
              label: 'Cooling System',
              description: 'Ensures optimal engine temperature under all conditions.',
              tests: [
                { name: 'Temperature Regulation', type: 'DQ', designation: 'FAT' },
                { name: 'Coolant Flow Test', type: 'OQ', designation: 'FAT' },
                { name: 'Leakage Test', type: 'SAT', designation: 'FAT' }
              ]
            },
            {
              id: '1.1.2',
              label: 'Exhaust System',
              description: 'Reduces emissions and directs exhaust gases safely.',
              tests: [
                { name: 'Emission Control', type: 'PQ', designation: 'FAT' },
                { name: 'Back Pressure Test', type: 'OQ', designation: 'FAT' },
                { name: 'Durability Test', type: 'FAT', designation: 'FAT' }
              ]
            }
          ]
        },
        {
          id: '1.2',
          label: '1.5 TSI 150 HP',
          description: '1.5-liter engine with 150 horsepower, focused on efficiency.',
          tests: [
            { name: 'Fuel Consumption Test', type: 'OQ', designation: 'FAT' },
            { name: 'Compression Ratio Test', type: 'IQ', designation: 'FAT' },
            { name: 'Combustion Efficiency', type: 'PQ', designation: 'SAT' }
          ],
          children: [
            {
              id: '1.2.1',
              label: 'Fuel Injection System',
              description: 'Precise fuel delivery for optimal combustion.',
              tests: [
                { name: 'Flow Rate Test', type: 'IQ' },
                { name: 'Injection Timing', type: 'PQ' },
                { name: 'Pressure Tolerance', type: 'OQ' }
              ]
            }
          ]
        }
      ]
    },
    {
      id: '2',
      label: 'Interior Module',
      description: 'Focuses on the interior features, comfort, and infotainment options.',
      tests: [
        { name: 'Sound System Calibration', type: 'OQ', designation: 'FAT' },
        { name: 'Seat Comfort Test', type: 'PQ' },
        { name: 'Climate Control Efficiency', type: 'DQ' }
      ],
      children: [
        {
          id: '2.1',
          label: 'Infotainment System',
          description: 'Includes the multimedia system with touchscreen display and navigation.',
          tests: [
            { name: 'Display Quality Test', type: 'OQ' },
            { name: 'Touch Sensitivity', type: 'PQ', designation: 'SAT' },
            { name: 'Response Time', type: 'IQ' }
          ]
        },
        {
          id: '2.2',
          label: 'Seating',
          description: 'Adjustable seats with options for leather upholstery and heating.',
          tests: [
            { name: 'Adjustability Check', type: 'DQ' },
            { name: 'Heating Efficiency', type: 'OQ' },
            { name: 'Durability Test', type: 'PQ', designation: 'FAT' }
          ]
        }
      ]
    }
  ];
  