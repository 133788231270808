import { Box, Container, Paper, Fab, Dialog, DialogTitle, DialogActions, Button, DialogContent, TextField } from "@mui/material"
import { DataGrid, GridColDef, GridToolbarQuickFilter } from "@mui/x-data-grid"
import { useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { loadSpecs, selectAllSpecs, selectSpecs, Spec } from "./specSlice"
import AddIcon from '@mui/icons-material/Add'
import { Localized, useLocalization } from "@fluent/react"
import { LoadingContainer } from "../../app/LoadingContainer"
import { useNavigate, useSearchParams } from "react-router-dom"
import { parseAndFormatISODate, parseIntOrDef } from "../../app/Utils"

const QuickSearchToolbar = () => {
    return <Box sx={{ p: 0.5, pb: 0 }}>
        <GridToolbarQuickFilter />
    </Box>
}

export const SpecList = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { l10n } = useLocalization()
    const [searchParams, setSearchParams] = useSearchParams()
    const { state } = useAppSelector(selectSpecs)
    const allSpecs = useAppSelector(selectAllSpecs)

    const [open, setOpen] = useState<boolean>(false)
    const [title, setTitle] = useState<string>('')

    const handleCreateNewSpecClick = async () => {
        navigate(`/specs/create`)
    }

    useEffect(() => {
        if (state.type === 'empty') {
            dispatch(loadSpecs())
        }
    }, [state])

    const page: number = parseIntOrDef(searchParams.get('page'), 0)
    const pageSize: number = parseIntOrDef(searchParams.get('pageSize'), 20)

    const isToday = (dateString: string) => {
        const date = new Date(dateString);
        const today = new Date();

        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    };

    const cols: GridColDef<Spec, any, any>[] = useMemo(() => {
        return [
            {
                field: 'name'
                , headerName: l10n.getString('spec-name', null, 'Nazwa')
                , flex: 1
            },
            {
                field: 'createDate',
                width: 150,

                headerName: l10n.getString('spec-creation-date', null, 'Data utworzenia'),
                valueGetter: (_, row) => {

                    return row.createDate ? new Date(row.createDate).toISOString().split('T')[0] : "";
                }
            },
            {
                field: 'status',
                width: 200,

                headerName: l10n.getString('status', null, 'Utworzony przez'),
                renderCell: (params) => {
                    const isNew = isToday(params.row.createDate);
                    return (
                        <>
                            <span>{params.row.status}</span>
                            {isNew && (
                                <span
                                    className="ml-1 px-1 py-0.5 rounded bg-green-200 text-green-800 font-semibold text-sm relative"
                                    style={{ top: '-5px', fontSize: '0.7rem' }}
                                >
                                    NEW
                                </span>
                            )}
                        </>

                    );
                },
            },

        ]
    }, [l10n])

    const qfInitialValue: string[] = useMemo(() => searchParams.getAll('qf'), [])

    switch (state.type) {
        case 'empty':
        case 'loading':
            return <LoadingContainer />
        case 'error':
            return <Container component={Paper} maxWidth='lg'>
                <h5>Data not loaded due to error!</h5>
            </Container>
        case 'loaded':
            return <><Container maxWidth='xl'>
                <h5>List of Specs</h5>
                <DataGrid
                    columns={cols}
                    rows={allSpecs}
                    slots={{ toolbar: QuickSearchToolbar }}
                    onRowDoubleClick={({ row }) => navigate(`/specs/edit/${row.id}`)}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                        }
                    }}
                    paginationModel={{ pageSize, page }}
                    onPaginationModelChange={(m) => {
                        searchParams.set('page', m.page.toString())
                        searchParams.set('pageSize', m.pageSize.toString())
                        setSearchParams(searchParams, { replace: true })
                    }}
                    initialState={{
                        filter: {
                            filterModel: {
                                items: [],
                                quickFilterValues: qfInitialValue,
                            },
                        },
                    }}
                    onFilterModelChange={(m) => {
                        searchParams.delete('qf')
                        m.quickFilterValues?.forEach(val => {
                            searchParams.set('qf', val)
                        })
                        setSearchParams(searchParams)
                    }}
                />
                <Fab sx={{ mt: 2 }} color="secondary" onClick={handleCreateNewSpecClick}>
                    <AddIcon />
                </Fab>
            </Container>
                <Dialog open={open} fullWidth maxWidth='lg'>
                    <DialogTitle>
                        <Localized id='new-business-case'>Nowy Business Case</Localized>
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ pt: 2 }}>
                            <TextField
                                label={<Localized id='title'>Tytuł</Localized>}
                                placeholder='Tytuł'
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                fullWidth
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { }}>
                            <Localized id='save'>Zapisz</Localized>
                        </Button>
                        <Button onClick={() => setOpen(false)}>
                            <Localized id='cancel'>Anuluj</Localized>
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
    }
}
