import { AppTextFieldHandler } from "../../../app/appTypes"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { Autocomplete, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material"
import { Localized } from "@fluent/react"
import { If } from "../../../app/If"
import { LoadingButton } from "@mui/lab"
import { showError, showSuccess } from "../../notifications/notificationsSlice"
import { useCallback, useState } from "react"
import { createMapping, selectMailMappingById, updateMapping } from "./mailMappingsSlice"

export interface CreateUpdateMailMappingProps {
    id: number | undefined
    options: string[]
    onSaveSuccess: () => void
    onCancel: () => void
}

export const MailMappingEditDialog = ({ id, options, onSaveSuccess, onCancel }: CreateUpdateMailMappingProps) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)

    const entity = useAppSelector(state => selectMailMappingById(state, id ?? ""));

    const [mail, setMail] = useState<string>(entity?.mail ?? "");    
    const [redirectToMail, setRedirectToMail] = useState<string>(entity?.redirectToMail ?? "");     
    const [group, setGroup] = useState<string>(entity?.group ?? "");     
    
    const handleMailChange: AppTextFieldHandler = useCallback(e => setMail(e.target.value), [setMail]);
    const handleRedirectToMailChange: AppTextFieldHandler = useCallback(e => setRedirectToMail(e.target.value), [setRedirectToMail]); 
    const handleGroupChange = (e) => { 
        if(typeof(e.target.value) === "string") setGroup(e.target.value);
    }
    const handleInputGroupChange = (_, value) => {console.log("value", value); setGroup(value); } 

    const handleSaveClick = async () => {
        setSaving(true)
        try {
            if (entity){
                let mappingToSave ={...entity, 
                    mail, redirectToMail, group
                };
        
                await dispatch(updateMapping(mappingToSave)).unwrap()
                
                dispatch(showSuccess("saved"));
                onSaveSuccess()
            }
            else {
                const id = 0;
                let mappingToSave ={ 
                    id, mail, redirectToMail, group
                };
        
                await dispatch(createMapping(mappingToSave)).unwrap()
                
                dispatch(showSuccess("saved"));
                onSaveSuccess()
            }
        }
        catch (error) {
                dispatch(showError("error"));
            } 
        finally {
                setSaving(false)
            }
    }   

    return <>
        <DialogTitle>
            <If condition={id == undefined} otherwise={
                <Localized id="new-mail-mapping ">
                    <span>Nowe mapowanie</span>
                </Localized>
                }>
                <Localized id="edit-mail-mapping">
                    <span>Edycja mapowania</span>
                </Localized>
            </If>
        </DialogTitle>
        <DialogContent>
            <Stack direction="column" spacing={2} padding={2}>
                <TextField 
                    fullWidth
                    value={mail}
                    inputProps={{ maxLength: 1000 }}
                    onChange={handleMailChange}
                    label={<Localized id="email-address">Mail</Localized>}>
                </TextField>
                <TextField 
                    fullWidth
                    value={redirectToMail}
                    inputProps={{ maxLength: 1000 }}
                    onChange={handleRedirectToMailChange}                   
                    label={<Localized id="redirect-to-address ">Redirect to</Localized>}>
                </TextField>
                <Autocomplete
                    freeSolo
                    options={options}
                    getOptionLabel={(option) => option || ""}
                    value={group}
                    inputValue={group}
                    onChange={handleGroupChange}
                    onInputChange={handleInputGroupChange}
                    renderInput={(params) => <TextField {...params} label="Wybierz lub wpisz własne" />} />
            </Stack>
        </DialogContent>
        <DialogActions>
            <LoadingButton onClick={handleSaveClick} loading={saving}>
                <Localized id="save">
                    <span>Zapisz</span>
                </Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={onCancel}>
                <Localized id="cancel">
                    <span>Anuluj</span>
                </Localized>
            </LoadingButton>
        </DialogActions>
    </>
}