import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import { LoadingContainer } from "../../../app/LoadingContainer"
import { Localized, useLocalization } from "@fluent/react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectSettings } from "../../settings/settingsSlice"
import { ErrorContainer } from "../../../app/ErrorContainer"
import { APIError, AppId } from "../../../app/appTypes"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { FlexDiv } from "../../../app/Utils"
import AddIcon from '@mui/icons-material/Add'
import { unwrapResult } from "@reduxjs/toolkit"
import { loadMailingGroups, MailingGroup, MailingGroupUser } from "./mailingGroupApi"
import { AddEditMailingGroupDialog, AddEditMailingGroupDialogProps } from "./AddEditMailingGroupDialog"
import { Button, ButtonGroup, Chip, Dialog, Fab, Paper, TableContainer } from "@mui/material"
import { showSuccess } from "../../notifications/notificationsSlice"
import { MailTemplateDialog } from "../../mailing/MailTemplateDialog"
import produce from "immer"

type DialogState =
    | { type: "none" }
    | { type: "createMailingGroup", props: AddEditMailingGroupDialogProps }
    | { type: "editMailingGroup", props: AddEditMailingGroupDialogProps }


export const MailingGroupList = () => {
    const dispatch = useAppDispatch();

    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [loadingError, setError] = useState<APIError | undefined>(undefined)
    const [loadingErrorOccured, setLoadingErrorOccured] = useState<boolean>(false)
    const [mailingGroups, setMailingGroups] = useState<MailingGroup[]>([]);
    const [addEditDialogVisible, setAddEditDialogVisible] = useState<boolean>(false)
    const [dialog, setDialog] = useState<DialogState>({ type: "none" })

    const handleAddMailingGroup = useCallback(() => {
        setDialog({
            type: "createMailingGroup",
            props: {
                mailingGroup: undefined,
                mode: "create",
                onSuccessfullySaved: (newGroup: MailingGroup) => {
                    setDialog({ type: "none" });
                },
                onCancel: () => setDialog({ type: "none" })
            }
        });
    }, [setDialog]);

    const handleEditMailingGroup = useCallback((mailingGroup: MailingGroup) => {
        setDialog({
            type: "editMailingGroup",
            props: {
                mailingGroup: mailingGroup,
                mode: "edit",
                onSuccessfullySaved: (updatedGroup: MailingGroup) => {
                    setDialog({ type: "none" });
                },
                onCancel: () => setDialog({ type: "none" })
            }
        });
    }, [setDialog]);

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadMailingGroups())
            .then(unwrapResult)
            .then(setMailingGroups)
            .catch((error) => console.error(error))
            .finally(() => setIsLoading(false))
    }, [dispatch]);



    const DialogContentDispatcher = ({ state, setter }: { state: DialogState, setter: Dispatch<SetStateAction<DialogState>> }) => {
        const dispatch = useAppDispatch()

        const onSuccess = useCallback((mailingGroup: MailingGroup) => {
            setter({ type: "none" })
            setMailingGroups(
                produce((draft) => {
                    if (draft !== undefined) {
                        const existingIndex = draft.findIndex((group) => group.id === mailingGroup.id);

                        if (existingIndex !== -1) {
                            draft[existingIndex] = { ...draft[existingIndex], ...mailingGroup };
                        } else {
                            draft.push(mailingGroup);
                        }
                    }
                })
            );
        }, [setter, dispatch])

        const onCancel = useCallback(() => {
            setter({ type: "none" })
        }, [setter])

        switch (state.type) {
            case "none":
                return null

            case "createMailingGroup":
                return <AddEditMailingGroupDialog
                    mailingGroup={undefined}
                    mode={'create'}
                    onSuccessfullySaved={onSuccess}
                    onCancel={onCancel}
                />

            case "editMailingGroup":
                return <AddEditMailingGroupDialog
                    mailingGroup={state.props.mailingGroup}
                    mode={'edit'}
                    onSuccessfullySaved={onSuccess}
                    onCancel={onCancel}
                />
        }
    }

    const columns: GridColDef<MailingGroup, any, any>[] = [
        {
            field: 'name',
            headerName: l10n.getString("", null, "_name"),
            width: 300
        },
        {
            field: 'email',
            headerName: l10n.getString("", null, "_email"),
            width: 300
        },
        {
            field: "users",
            headerName: "Users",
            flex: 1,
            renderCell: (params) => {
                const users: MailingGroupUser[] = params.value || []; // Pobieramy użytkowników z params
                const emails = users.map((user) => user.email); // Wyciągamy listę e-maili
        
                return (
                  <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
                    {emails
                      .sort((a, b) => a.localeCompare(b)) // Sortujemy e-maile alfabetycznie
                      .map((email) => (
                        <Chip
                          key={email}
                          label={email}
                          color="primary"
                          style={{ marginLeft: "5px", marginTop: "5px" }}
                        />
                      ))}
                  </div>
                );
              }
          },
        {
            field: 'actions', type: 'actions', width: 300,
            renderCell: (params) => {
                return (
                    <ButtonGroup>
                        <Button color="primary" onClick={() => handleEditMailingGroup(params.row)} >
                            <Localized id="edit">
                                <span>Edytuj</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ]

    if (loadingErrorOccured) {
        return <>
            <ErrorContainer error={loadingError} />
        </>
    }

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }
    return (
        <>
            <TableContainer component={Paper}>
                <DataGrid localeText={getCustomLocaleText()}
                    style={{ minHeight: 100, height: 'calc(100vh - 360px)' }}
                    rows={mailingGroups}
                    columns={columns}
                    onRowDoubleClick={({ row }) => handleEditMailingGroup(row)}
                />
            </TableContainer>
            <FlexDiv>
                <Fab
                    sx={{
                        marginTop: 2
                    }}
                    onClick={handleAddMailingGroup}
                    color="secondary"
                >
                    <AddIcon />
                </Fab>
            </FlexDiv>
            <Dialog open={dialog.type !== "none"} fullWidth maxWidth="lg" keepMounted={false}>
                <DialogContentDispatcher state={dialog} setter={setDialog} />
            </Dialog>
        </>
    )
}
