import { ChangeEvent, useEffect, useState } from "react"
import { styled } from '@mui/material/styles';
import { useLocation } from "react-router-dom"
import { useAppDispatch } from "./app/hooks"
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import { Localized, useLocalization } from "@fluent/react"
import { showError, showSuccess } from "./features/notifications/notificationsSlice";
import { confirmAccount } from "./features/user/userSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { setLocalePersistent } from "./features/settings/settingsSlice";


const PREFIX = 'Login';

const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    logo: `${PREFIX}-logo`,
    text: `${PREFIX}-text`,
    backdrop: `${PREFIX}-backdrop`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        textAlign: 'center',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '400px',
        margin: 'auto',
    },

    [`& .${classes.paper}`]: {
        width: "100%",
        paddingTop: 32,
        paddingBottom: 32,
    },

    [`& .${classes.logo}`]: {
        pointerEvents: 'none',
        height: 300,
    },

    [`& .${classes.text}`]: {
        width: '80%',
    },

    [`& .${classes.backdrop}`]: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    }
}));


export interface LoginAPIError {
    success: boolean
    token: null | string
    errors: string[]
}

export const ConfirmAccount = () => {
    const dispatch = useAppDispatch()
    const { l10n } = useLocalization()
    const { search } = useLocation()

    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const [errorPassword, setPasswordError] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [mask, setMask] = useState<boolean>(false);
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(setLocalePersistent('en-US'));
    }, [dispatch]); 
    
    const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)
    const onPasswordConfirmChange = (e: ChangeEvent<HTMLInputElement>) => setPasswordConfirm(e.target.value)

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };


    function timeout(delay: number) {
        return new Promise(res => setTimeout(res, delay));
    }

    const clearErrors = () => {
        setPasswordError("")
    }

    const Validate = () => {
        let valResult = true;

        if (password !== '' && passwordConfirm !== '' && passwordConfirm !== password) {
            valResult = false;
            setPasswordError(l10n.getString("password-do-not-match", null, "_password not match"));
        }
        if (!isPasswordStrong(password)) {
            valResult = false;
            setPasswordError(
                l10n.getString("password-requirements", null, "_Hasło musi zawierać co najmniej 8 znaków, jedną dużą literę i jeden znak specjalny (!, @, #, $ itp.).")
            );
        }
        return valResult;
    }

    const isPasswordStrong = (password) => {
        const passwordPattern = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
        return passwordPattern.test(password);
    };

    const handleSaveClick = async () => {
        clearErrors()
        if (!Validate()) {
            return;
        }
        if (search) {
            const params = new URLSearchParams(search)

            if (params.has("t") && params.has("u")) {
                try {
                    setMask(true)

                    const resultAction = await dispatch(confirmAccount({
                        user: params.get("u") as string,
                        password: password,
                        passwordConfirmation: passwordConfirm,
                        token: params.get("t") as string
                    }))

                    if (confirmAccount.fulfilled.match(resultAction)) {
                        dispatch(showSuccess("account-confirmation-sucesfully"))
                        await timeout(2000)
                        navigate('/login', { replace: true })
                    }

                    if (confirmAccount.rejected.match(resultAction)) {
                        const { payload } = resultAction
                        if (payload) {
                            switch (payload.kind) {
                                case "http":
                                    if (payload.body) {
                                        dispatch(showError(payload.body))
                                    }
                                    else {
                                        dispatch(showError("error"))
                                    } break
                                case "connection":
                                    dispatch(showError("connection-issue"))
                                    break
                            }
                        } else {
                            dispatch(showError("error"))
                        }
                    }

                }
                catch (error) {
                    dispatch(showError(error ?? "error-login-failed"))
                }
                finally {
                    setMask(false)
                }
            }
        }
    }
    return (
        <Root className={classes.root}>
            <Paper variant="elevation" elevation={4} component="div" className={classes.paper}>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                            <Localized id="email-confirmation-message">
                                <span>Dziękujemy za potwierdzenie adresu email!</span>
                            </Localized>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                            <Localized id="set-new-password">
                                <span>Ustal nowe hasło</span>
                            </Localized>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type={showPassword ? 'text' : 'password'}
                            className={classes.text}
                            onBlur={clearErrors}
                            onChange={onPasswordChange}
                            error={!!errorPassword}
                            helperText={errorPassword}
                            label={l10n.getString("password", null, "Nowe hasło")}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton tabIndex={-1} onClick={togglePasswordVisibility}>
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type={showConfirmPassword ? 'text' : 'password'}
                            className={classes.text}
                            onChange={onPasswordConfirmChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton tabIndex={-1} onClick={toggleConfirmPasswordVisibility}>
                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            label={l10n.getString("user-confirm-password", null, "Powtórz hasło")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={handleSaveClick} color="primary" size="large" variant="contained">
                            <Localized id="save">
                                <span>Zapisz</span>
                            </Localized>
                        </Button>
                    </Grid>
                </Grid>
                <Backdrop className={classes.backdrop} open={mask}>
                    <CircularProgress color="inherit"></CircularProgress>
                </Backdrop>
            </Paper>
            <Box sx={{ marginTop: 2, }}>
                <img className={classes.logo} src={process.env.PUBLIC_URL + '/logo.gif'} alt="Logo" />
            </Box>
        </Root>
    );

}
