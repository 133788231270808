import { ReactRenderer } from "@tiptap/react"
import { SuggestionOptions } from "@tiptap/suggestion"
import tippy, { GetReferenceClientRect } from "tippy.js"
import MentionList from "./MentionList"

export interface MentionUser {
    id: string
    label: string
}

export function mkSuggestion(persons: MentionUser[]): Omit<SuggestionOptions<MentionUser>, 'editor'> {
    return {
        ...suggestion,
        items: ({ query }) => {
            return persons
                .filter(person => {
                    const q = query.toLowerCase()
                    return person.label.toLowerCase().includes(q)
                })
                .slice(0, 5)
        },
    }
}

const suggestion: Omit<SuggestionOptions<MentionUser>, 'editor'> = {
    render: () => {
        let component
        let popup

        return {
            onStart: props => {
                component = new ReactRenderer(MentionList, {
                    props,
                    editor: props.editor,
                })

                if (!props.clientRect) {
                    return
                }

                popup = tippy('body', {
                    getReferenceClientRect: props.clientRect as GetReferenceClientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                })
            },

            onUpdate(props) {
                component.updateProps(props)

                if (!props.clientRect) {
                    return
                }

                popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                })
            },

            onKeyDown(props) {
                if (props.event.key === 'Escape') {
                    popup[0].hide()

                    return true
                }

                return component.ref?.onKeyDown(props)
            },

            onExit() {
                popup[0].destroy()
                component.destroy()
            },
        }
    },
}

