import { Alert, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Paper, Select, Skeleton, Stack, TableContainer, TextField } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { Localized, useLocalization } from "@fluent/react"
import { LoadCompanies } from "../../../app/AppDataLoader"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { selectSettings } from "../../settings/settingsSlice"
import { unwrapResult } from "@reduxjs/toolkit"
import SyncIcon from '@mui/icons-material/Sync';
import { If } from "../../../app/If"
import { Company, loadCompanies, selectCompanyById } from "../../company/companiesSlice"
import { store } from "../../../app/store"
import { LoadingContainer } from "../../../app/LoadingContainer"
import { createSystemFeatureFlag, deleteSystemFeatureFlag, getSystemFeatureFlags, loadSystemFeatureFlags, loadSystemFeatureFlagsForCompany, SystemFeatureFlag, updateSystemFeatureFlag } from "./systemFeatureFlagsApi"
import { FlexDiv } from "../../../app/Utils"
import { LoadingButton } from "@mui/lab"
import AddIcon from '@mui/icons-material/Add'
import { showError, showSuccess } from "../../notifications/notificationsSlice"
import { AppTextFieldHandler } from "../../../app/appTypes"
import produce from "immer";

type DialogState =
    | { type: "none" }
    | { type: "create" }
    | { type: "update", featureFlag: SystemFeatureFlag }

export const SystemFeatureFlags = () => {
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const dispatch = useAppDispatch();
    const [systemFeatureFlags, setSystemFeatureFlags] = useState<SystemFeatureFlag[]>([]);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [company, setCompany] = useState('all');
    const [featureFlagType, setFeatureFlagType] = useState('all');
    const [saving, setSaving] = useState(false);
    const [featureFlags, setFeatureFlags] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false)
    const [dialog, setDialog] = useState<DialogState>({ type: "none" })
    const [itemToDelete, setItemToDelete] = useState<string | undefined>(undefined)
    const [deleting, setDeleting] = useState(false)
    const [serverError, setServerError] = useState("")
    const [companyRequired, setCompanyRequired] = useState(false)
    const [flagRequired, setFlagRequired] = useState(false)
    const [descriptionRequired, setDescriptionRequired] = useState(false)

    const [companyDialog, setCompanyDialog] = useState<number>();
    const [featureFlagTypeDialog, setFeatureFlagTypeDialog] = useState<string>('');
    const [descriptionDialog, setDescriptionDialog] = useState<string>('');

    const handleCompanyDialogChange = (e) => setCompanyDialog(Number(e.target.value));
    const handleFeatureFlagTypeDialogChange = (e) => setFeatureFlagTypeDialog(e.target.value);
    const handleDescriptionDialogChange: AppTextFieldHandler = useCallback(e => setDescriptionDialog(e.target.value), [setDescriptionDialog]);

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadSystemFeatureFlags(`?flag=${featureFlagType}`))
            .then(unwrapResult)
            .then(setSystemFeatureFlags)
            .catch((error) => console.error(error))
            .finally(() => setIsLoading(false));
        dispatch(getSystemFeatureFlags())
            .then(unwrapResult)
            .then(setFeatureFlags)
            .catch((error) => console.error(error))
        dispatch(loadCompanies())
            .then(unwrapResult)
            .then(setCompanies)
            .catch((error) => console.error(error))
    }, [dispatch])

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const handleEventTypeChange = (event) => {
        setSaving(false);
        setFeatureFlagType(event.target.value);
    };

    const handleCompanyChange = (event) => {
        setSaving(false);
        setCompany(event.target.value);
    };

    const handleRefreshClick = () => {
        setSaving(true);
        setIsLoadingData(true);
        if(company === 'all') {
            dispatch(loadSystemFeatureFlags(`?flag=${featureFlagType}`))
                .then(unwrapResult)
                .then(setSystemFeatureFlags)
                .catch((error) => console.error(error))
                .finally(() => setIsLoadingData(false))
        } else {
            dispatch(loadSystemFeatureFlagsForCompany({ companyId: company, query: `?flag=${featureFlagType}` }))
                .then(unwrapResult)
                .then(setSystemFeatureFlags)
                .catch((error) => console.error(error))
                .finally(() => setIsLoadingData(false))
        }
        setSaving(false);
    }

    const handleAddFeatureFlagClick = useCallback(() => {
        setDialog({
            type: "create"
        })
    }, [setDialog])

    const handleUpdateFeatureFlagClick = useCallback((featureFlag:SystemFeatureFlag) => {
        setDialog({
            type: "update",
            featureFlag
        })
        setCompanyDialog(featureFlag.companyId);
        setFeatureFlagTypeDialog(featureFlag.code);
        setDescriptionDialog(featureFlag.description);
    }, [setDialog])

    const handleDeleteClick = useCallback((id: string) => {
        setItemToDelete(id)
    }, [])

    const handleCancelDelClick = useCallback(() => {
        setItemToDelete(undefined)
    }, [])

    const handleDeleteHelpArticleClick = async () => {
        if (itemToDelete) {
            try {
                setDeleting(true)
            
                const result = await dispatch(deleteSystemFeatureFlag(itemToDelete))
                
                if(deleteSystemFeatureFlag.fulfilled.match(result)) {
                    setSystemFeatureFlags(produce(draft => {
                        draft.splice(draft.findIndex(f => f.id === result.payload), 1)
                    }))

                    dispatch(showSuccess("deleted"))
                } else {
                    let errorMsg = "error"
                    if (result.payload && result.payload.kind === 'http') {
                        const problem = result.payload.problem
                        if (problem) {
                            errorMsg = problem.title

                        }
                    }
                    setServerError(errorMsg)
                }
            } catch (error) {
                dispatch(showError("error"))
            } finally {
                setItemToDelete(undefined)
                setDeleting(false)
            }
        }
    }

    const handleSaveClick = async () => {
        if (dialog.type === "update") {
            if(descriptionDialog !== '') {
                try {
                    setSaving(true)
                    
                    let featureFlagToSave ={...dialog.featureFlag, 
                        description: descriptionDialog
                    };
            
                    const result = await dispatch(updateSystemFeatureFlag(featureFlagToSave))
                    
                    if(updateSystemFeatureFlag.fulfilled.match(result)) {
                        setSystemFeatureFlags(produce(draft => {
                            let element = draft.find(f => f.id === result.payload.id);
                            if(element) {
                                element.description = result.payload.description;
                            }
                        }))

                        dispatch(showSuccess("saved"));
                        setDialog({ type: "none" });
                        setCompanyDialog(undefined);
                        setFeatureFlagTypeDialog("");
                        setDescriptionDialog("");
                        setDescriptionRequired(false);
                    } else {
                        let errorMsg = "error"
                        if (result.payload && result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title

                            }
                        }
                        setServerError(errorMsg)
                    }
                } catch(error) {
                    dispatch(showError("error"));
                } finally {
                    setSaving(false)
                }
            } else {
                setDescriptionRequired(true);
            }
        } else {
            companyDialog ? setCompanyRequired(false) : setCompanyRequired(true);
            featureFlagTypeDialog ? setFlagRequired(false) : setFlagRequired(true);
            descriptionDialog !== '' ? setDescriptionRequired(false) : setDescriptionRequired(true);
            if(featureFlagTypeDialog !== undefined && descriptionDialog !== '' && companyDialog !== undefined) {
                try {
                    setSaving(true)

                    let featureFlagToSave ={ 
                        code: featureFlagTypeDialog, description: descriptionDialog, companyId: companyDialog
                    };
        
                    const result = await dispatch(createSystemFeatureFlag(featureFlagToSave))
                
                    if(createSystemFeatureFlag.fulfilled.match(result)) {
                        setSystemFeatureFlags(produce(draft => {
                            draft.unshift(result.payload)
                        }))

                        dispatch(showSuccess("saved"));
                        setDialog({ type: "none" });
                        setCompanyDialog(undefined);
                        setFeatureFlagTypeDialog("");
                        setDescriptionDialog("");
                    } else {
                        let errorMsg = "error"
                        if (result.payload && result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
    
                            }
                        }
                        setServerError(errorMsg)
                    }
                } catch (error) {
                    dispatch(showError("error"));
                } finally {
                    setSaving(false)
                }
            }
        }
    }

    const handleCancelClick = () => {
        setDialog({ type: "none" });
        setCompanyDialog(undefined);
        setFeatureFlagTypeDialog("");
        setDescriptionDialog("");
        setCompanyRequired(false);
        setFlagRequired(false);
        setDescriptionRequired(false);
    }

    const columns: GridColDef<SystemFeatureFlag, any, any>[] = [
        {
            field: 'code',
            headerName: l10n.getString("system-feature-flags-flag", null, "Flaga"),
            flex: 1,
        },
        {
            field: 'companyId',
            headerName: l10n.getString("company", null, "Firma"),
            flex: 1,
            valueGetter: (_, row) => {
                const entity = selectCompanyById(store.getState(), row.companyId)

                if (!entity) {
                    return ""
                } else {
                    return entity.shortName
                }
            }
        },
        {
            field: 'description',
            headerName: l10n.getString("description", null, "Opis"),
            flex: 1,
        },
        {
            field: 'actions', type: 'actions', width: 250,
            renderCell: (params) => {
                return (
                    <ButtonGroup size="small">
                        <Button
                            style={{ width: 100 }}
                            color="secondary"
                            variant="outlined"
                            onClick={() => handleUpdateFeatureFlagClick(params.row)}>
                            <Localized id="edit">
                                <span>Edytuj</span>
                            </Localized>
                        </Button>
                        <Button
                            style={{ width: 100 }}
                            color="error"
                            variant="outlined"
                            onClick={() => handleDeleteClick(params.row.id)}>
                            <Localized id="delete">
                                <span>Usuń</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ]

    if (isLoading) {
        return <>
            <LoadingContainer/>
        </>
    }

    return (
        <>
            <div>
                <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                    <FormControl>
                        <InputLabel id="select-company-label"><Localized id="company">Firma</Localized></InputLabel>
                        <Select
                            labelId="select-company-label"
                            value={company}
                            onChange={handleCompanyChange}
                            label={<Localized id="company">Firma</Localized>}
                            sx={{ width: '200px' }}
                        >
                            <MenuItem value={'all'}>
                                <Localized id="all">All</Localized>
                            </MenuItem>
                            {companies.map((option) =>
                                <MenuItem value={option.id}>{option.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="select-flag-label"><Localized id="system-feature-flags-flag">Flaga</Localized></InputLabel>
                        <Select
                            labelId="select-flag-label"
                            value={featureFlagType}
                            onChange={handleEventTypeChange}
                            label={<Localized id="system-feature-flags-flag">Flaga</Localized>}
                            sx={{ width: '200px' }}
                        >
                            <MenuItem value={'all'}>
                                <Localized id="all">All</Localized>
                            </MenuItem>
                            {featureFlags.map((option) =>
                                <MenuItem value={option}>{option}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <IconButton
                        onClick={handleRefreshClick}
                        disabled={saving}
                        >
                            <SyncIcon />
                    </IconButton>
                </Stack>
            </div >
            <LoadCompanies component={<Skeleton variant="rectangular" />}>
                <TableContainer component={Paper}>
                {isLoadingData ? (<LoadingContainer/>) : (
                    <DataGrid localeText={getCustomLocaleText()}
                        style={{ minHeight: 100, height: 'calc(100vh - 360px)' }}
                        rows={systemFeatureFlags}
                        columns={columns}
                        initialState={{
                            sorting: { sortModel: [{ field: 'eventDate', sort: 'desc' }] }
                        }}
                    />
                )}
                </TableContainer>
            </LoadCompanies >
            <FlexDiv>
                <Fab sx={{
                    marginTop: 2,
                }}
                    onClick={() => handleAddFeatureFlagClick()}
                    color="secondary"
                >
                    <AddIcon />
                </Fab>
            </FlexDiv>
            <Dialog open={dialog.type !== "none"} fullWidth maxWidth="sm" keepMounted={false}>
                <DialogTitle>
                    <If condition={dialog.type === "create"} otherwise={
                        <Localized id="system-feature-flags-edit">
                            <span>Edycja flagi</span>
                        </Localized>
                        }>
                        <Localized id="system-feature-flags-new">
                            <span>Nowa flaga</span>
                        </Localized>
                    </If>
                </DialogTitle>
                <DialogContent>
                    <Stack direction="column" spacing={2} padding={2}>
                        <FormControl>
                            <InputLabel id="select-company-label"><Localized id="company">Firma</Localized></InputLabel>
                            <Select
                                labelId="select-company-label"
                                value={companyDialog}
                                onChange={handleCompanyDialogChange}
                                label={<Localized id="company">Firma</Localized>}
                                disabled={dialog.type === "update"}
                                error={companyRequired === true}
                            >
                                {companies.map((option) =>
                                    <MenuItem value={option.id}>{option.name}</MenuItem>
                                )}
                            </Select>
                            {companyRequired && <FormHelperText error><Localized id='field-is-required'>To pole jest wymagane</Localized></FormHelperText>}
                        </FormControl>
                        <FormControl>
                            <InputLabel id="select-flag-label"><Localized id="system-feature-flags-flag">Flaga</Localized></InputLabel>
                            <Select
                                labelId="select-flag-label"
                                value={featureFlagTypeDialog}
                                onChange={handleFeatureFlagTypeDialogChange}
                                label={<Localized id="system-feature-flags-flag">Flaga</Localized>}
                                disabled={dialog.type === "update"}
                                error={flagRequired === true}
                            >
                                {featureFlags.map((option) =>
                                    <MenuItem value={option}>{option}</MenuItem>
                                )}
                            </Select>
                            {flagRequired && <FormHelperText error><Localized id='field-is-required'>To pole jest wymagane</Localized></FormHelperText>}
                        </FormControl>
                        <TextField 
                            fullWidth
                            value={descriptionDialog}
                            inputProps={{ maxLength: 200 }}
                            onChange={handleDescriptionDialogChange}
                            label={<Localized id="description">Description</Localized>}
                            error={descriptionRequired === true}
                            helperText={descriptionRequired && <Localized id='field-is-required'>To pole jest wymagane</Localized>}>
                        </TextField>
                    </Stack>
                    {
                        serverError && <Alert sx={{ marginTop: 1, }} onClose={() => { setServerError("") }} severity="error">
                            <Localized id={serverError}>
                                <span>Server error</span>
                            </Localized>
                        </Alert>
                    }
                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={handleSaveClick} loading={saving}>
                        <Localized id="save">
                            <span>Zapisz</span>
                        </Localized>
                    </LoadingButton>
                    <LoadingButton loading={saving} onClick={handleCancelClick}>
                        <Localized id="cancel">
                            <span>Anuluj</span>
                        </Localized>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog open={itemToDelete !== undefined}>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="confirm-delete">
                            <span>Czy napewno chcesz usunąć?</span>
                        </Localized>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={deleting} onClick={handleDeleteHelpArticleClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </LoadingButton>
                    <LoadingButton loading={deleting} onClick={handleCancelDelClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
