import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppThunkAPIType, AppId } from "../../../app/appTypes";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../../http";

export interface SystemFeatureFlag {
    id: string;
    code: string;
    description: string;
    companyId: number;
}

export type DraftSystemFeatureFlag = Omit<SystemFeatureFlag, "id">

interface LoadSystemFeatureFlagsParams {
    companyId: AppId,
    query: string
}

export const loadSystemFeatureFlags = createAsyncThunk<SystemFeatureFlag[], string, AppThunkAPIType>(
    "system-feature-flags/load",
    async (query, { dispatch, rejectWithValue }) => {

        const result = await dispatch(getWithAuth({ url: `api/system-feature-flags${query}` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as SystemFeatureFlag[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const loadSystemFeatureFlagsForCompany = createAsyncThunk<SystemFeatureFlag[], LoadSystemFeatureFlagsParams, AppThunkAPIType>(
    "system-feature-flags/load",
    async ({ companyId, query }, { dispatch, rejectWithValue }) => {

        const result = await dispatch(getWithAuth({ url: `api/system-feature-flags/${companyId}${query}` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as SystemFeatureFlag[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const getSystemFeatureFlags = createAsyncThunk<string[], void, AppThunkAPIType>(
    "system-feature-flags/load-flags",
    async (_, { dispatch, rejectWithValue }) => {

        const result = await dispatch(getWithAuth({ url: `api/system-feature-flags/flags` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as string[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const createSystemFeatureFlag = createAsyncThunk<SystemFeatureFlag, DraftSystemFeatureFlag, AppThunkAPIType>(
    "system-feature-flags/create-flag",
    async (featureFlag, { dispatch, rejectWithValue }) => {

        const result = await dispatch(postWithAuth({ url: `api/system-feature-flags`, payload: featureFlag }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as SystemFeatureFlag
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateSystemFeatureFlag = createAsyncThunk<SystemFeatureFlag, SystemFeatureFlag, AppThunkAPIType>(
    "system-feature-flags/update-flag",
    async (featureFlag, { dispatch, rejectWithValue }) => {

        const result = await dispatch(putWithAuth({ url: `api/system-feature-flags/${featureFlag.id}`, payload: featureFlag }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload as SystemFeatureFlag
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteSystemFeatureFlag = createAsyncThunk<string, string, AppThunkAPIType>(
    "system-feature-flags/delete-flag",
    async (id, { dispatch, rejectWithValue }) => {

        const result = await dispatch(deleteWithAuth({ url: `api/system-feature-flags/${id}` }))
        const { payload } = result
        if (deleteWithAuth.fulfilled.match(result)) {
            return payload as string
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)