import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { Localized } from '@fluent/react';
import { AppDispatch } from '../../app/store';
import { downloadBCReport, downloadMultiBCReport } from './businessCaseSlice'; // Zakładamy, że ten endpoint istnieje
import { showError } from '../notifications/notificationsSlice';

export type PrinterMode = 'download' | 'open';
export type ReportMode = 'single' | 'multi';

interface BusinessCasePrinterProps {
    businessCaseIds: string[]; // Zmieniono na listę ID
    title: string; // Dodano tytuł prezentacji
    language: string;
    mode: PrinterMode;
    reportMode: ReportMode; // Tryb pracy (single | multi)
    isDialogOpen: boolean;
    closeDialog: () => void;
}

export const BusinessCasePrinter = ({
    businessCaseIds,
    title,
    language,
    mode,
    reportMode,
    isDialogOpen,
    closeDialog,
}: BusinessCasePrinterProps) => {
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const dispatch: AppDispatch = useDispatch();

    const handlePrintDocument = async () => {
        setIsGenerating(true);

        try {
            if (reportMode === 'single') {
                // Wywołaj endpoint dla pojedynczego raportu
                const response = await dispatch(
                    downloadBCReport({ businessCaseId: businessCaseIds[0] })
                ).unwrap();

                handleResponse(response);
            } else if (reportMode === 'multi') {
                // Wywołaj endpoint dla wielu raportów
                const response = await dispatch(
                    downloadMultiBCReport({
                        payload: { ids: businessCaseIds, title }
                    })
                ).unwrap();

                handleResponse(response);
            }
        } catch (error) {
            dispatch(showError('Error generating report.'));
        } finally {
            setIsGenerating(false);
            closeDialog();
        }
    };

    const handleResponse = (response: { blobUrl: string; fileName: string }) => {
        if (mode === 'open') {
            window.open(response.blobUrl);
        } else if (mode === 'download') {
            const link = document.createElement('a');
            link.href = response.blobUrl;
            link.setAttribute('download', response.fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    };

    useEffect(() => {
        if (isDialogOpen) {
            handlePrintDocument();
        }
    }, [isDialogOpen]);

    return (
        <Dialog open={isDialogOpen}>
            <DialogTitle>
                <Localized id="report-generating">
                    <span>Generating Report</span>
                </Localized>
            </DialogTitle>
            <DialogContent>
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                    <CircularProgress />
                    <Localized id="report-generating-description">
                        <span>Your report is being generated. Please wait...</span>
                    </Localized>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} disabled={isGenerating}>
                    <Localized id="cancel">
                        <span>Cancel</span>
                    </Localized>
                </Button>
            </DialogActions>
        </Dialog>
    );
};
