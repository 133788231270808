import { Localized } from "@fluent/react";
import { UrsOfferStatus } from "./ursOfferSlice";


export const UrsOfferStatusLabel = ({ status }: { status: UrsOfferStatus | undefined }) => {
    switch (status) {
        case "N":
            return <Localized id="urs-offer-status-n">
                <span>Komentowanie</span>
            </Localized>
        case "R":
            return <Localized id="urs-offer-status-r">
                <span>Odrzucone</span>
            </Localized>
        case "S":
            return <Localized id="urs-offer-status-s">
                <span>Wybrano</span>
            </Localized>
        case "A":
            return <Localized id="urs-offer-status-a">
                <span>Złożono ofertę</span>
            </Localized>
        case "E":
            return <Localized id="urs-offer-status-e">
                <span>W ocenie</span>
            </Localized>
        case "F":
            return <Localized id="urs-offer-status-f">
                <span>Zakończono</span>
            </Localized>
        case "O":
            return <Localized id="urs-offer-status-o">
                <span>Zakończono</span>
            </Localized>
        case "D":
            return <Localized id="urs-offer-status-d">
                <span>Discussion</span>
            </Localized>
        default:
            return <span>{status}</span>
    }
}