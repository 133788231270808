import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../app/appTypes";
import { isSameRequestId } from "../../app/crud";
import { RootState } from "../../app/store";
import { BlobResponse, deleteWithAuth, getBlobWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../http";

export type BusinessCaseStatus = 'DRAFT' | 'ON_HOLD' | 'APPROVED' | 'REJECTED' | 'READY'
export type NPVMode = 'Auto' | 'Manual' | ''
export type BCCurrency = 'PLN' | 'USD' | 'EUR'
export type BCPrecision = 'full' | '6' | '5' | '4' |'3' 

export interface BusinessCase {
    id: AppId
    guid: string
    title: string
    description: string
    status: BusinessCaseStatus | null
    companyId: number
    projectId: number | null
    projectName: string | null
    currentSituation: string
    goal: string
    successCriteria: string
    alternativeSolutions: string
    calculationComment: string
    privateComment: string
    costCenterCode: string
    deadline: string
    approvalDate: string
    capexDate: string| null
    investementStartDate: string
    projectCategory: string | null
    objectiveCompliancy: boolean
    investementCost: number | null
    forcastedYearlySaving: number | null
    growthYearlyFactor: number | null
    discountRate: number | null
    numberOfYears: number | null
    mode: NPVMode
    pv: number | null
    npv: number | null
    irr: number | null
    createDate: string
    createdBy: string
    modifyDate: string
    modifiedBy: string
    cashflows: Cashflow[]
    payback: number
    currency: BCCurrency | null
    precision?: BCPrecision
}

export interface Cashflow {
    year: number
    businessCaseId: number
    cfValue: number
    dcfValue: number
}

export interface AssesmentItem {
    id: number
    name: string
    businessCaseId: AppId
    clientId: string
}

export interface BusinessCaseDetails {
    risks: AssesmentItem[]
    opportunities: AssesmentItem[]
}

export type BusinessCaseWithDetails = BusinessCase & BusinessCaseDetails

export interface RiskAssesment {
    risks: string[]
    opportunities: string[]
}

const adapter = createEntityAdapter<BusinessCase>({
    selectId: businessCase => businessCase.id,
})

export type BusinessCaseState = EntityState<BusinessCase> & { state: AppDataState }

const initialState: BusinessCaseState = adapter.getInitialState({
    state: { type: "empty" }
})

// GET /api/businessCase
export const loadBusinessCases = createAsyncThunk<BusinessCase[], void, AppThunkAPIType>('businessCase/load', async (_, api) => {
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(getWithAuth({ url: "api/businessCase"}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as BusinessCase[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
    
})

export const loadBusinessCasesForProject = createAsyncThunk<BusinessCase[], string | null, AppThunkAPIType>('businessCase/load-for-project', async (projectId, api) => {
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(getWithAuth({ url: `api/businessCase${projectId ? '?projectId=' + projectId : ''}`}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as BusinessCase[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
    
})


export const downloadBCReport = createAsyncThunk<BlobResponse, { businessCaseId: string }, AppThunkAPIType>(
    "document-report/download",
    async ({ businessCaseId }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getBlobWithAuth({ url: `api/businessCase/generate-presentation/${businessCaseId}` }));

        if (getBlobWithAuth.fulfilled.match(result)) {
            return result.payload as BlobResponse;
        } else {
            return rejectWithValue(result.payload ?? { kind: 'unknown' });
        }
    }
);

export interface PrintMultiBCPayload {
    ids: string[]
    title: string
}
export const downloadMultiBCReport = createAsyncThunk<BlobResponse,{ payload: PrintMultiBCPayload },AppThunkAPIType>(
    'document-report/download',
    async ({ payload }, { dispatch, rejectWithValue }) => {
        const { ids, title } = payload;

        // Funkcja tworząca URL z parametrami zapytania
        const buildQueryString = (params: Record<string, string | string[]>) => {
            const query = new URLSearchParams();
            for (const key in params) {
                const value = params[key];
                if (Array.isArray(value)) {
                    value.forEach(v => query.append(key, v));
                } else {
                    query.append(key, value);
                }
            }
            return query.toString();
        };

        // Tworzenie URL z parametrami
        const queryParams = buildQueryString({ ids, title });
        const url = `api/businessCase/generate-presentation/multi?${queryParams}`;

        // Wywołanie API
        const result = await dispatch(
            getBlobWithAuth({ url })
        );

        if (getBlobWithAuth.fulfilled.match(result)) {
            return result.payload as BlobResponse;
        } else {
            return rejectWithValue(result.payload ?? { kind: 'unknown' });
        }
    }
);



export const loadBusinessCase = createAsyncThunk<BusinessCaseWithDetails, AppId, AppThunkAPIType>('businessCase/single-load', async (id, api) => {
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(getWithAuth({ url: `api/businessCase/${id}`}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as BusinessCaseWithDetails
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
    
})

export const defaultBusinessCase: BusinessCaseWithDetails = {
    id: '',
    guid: '',
    title: '',
    description: '',
    status: 'DRAFT',
    companyId: 0,
    projectId: null,
    projectName: null,
    currentSituation: '',
    goal: '',
    successCriteria: '',
    alternativeSolutions: '',
    calculationComment: '',
    privateComment: '',
    costCenterCode: '',
    deadline: '',
    approvalDate: '',
    capexDate: '',
    investementStartDate: '',
    projectCategory: '',
    objectiveCompliancy: false,
    investementCost: 0,
    forcastedYearlySaving: 0,
    growthYearlyFactor: 0,
    discountRate: 0,
    numberOfYears: 0,
    mode: 'Auto',
    pv: 0,
    npv: 0,
    irr: 0,
    risks: [],
    opportunities: [],
    createDate: '',
    createdBy: '',
    modifyDate: '',
    modifiedBy: '',
    cashflows: [],
    payback: 0,
    currency: 'PLN',
    precision: 'full',
}

export const createBusinessCase = createAsyncThunk<BusinessCaseWithDetails, BusinessCaseWithDetails, AppThunkAPIType>('businessCase/create', async (businessCase, api) => {
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(postWithAuth({
        url: "api/businessCase",
        payload: businessCase
    }))
    const { payload } = result
    if (postWithAuth.fulfilled.match(result)) {
        return payload as BusinessCaseWithDetails
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const updateBusinessCase = createAsyncThunk<BusinessCaseWithDetails, BusinessCaseWithDetails, AppThunkAPIType>('businessCase/update', async (businessCase, api) => {
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(putWithAuth({
        url: `api/businessCase/${businessCase.id}`,
        payload: businessCase
    }))
    const { payload } = result
    if (putWithAuth.fulfilled.match(result)) {
        return payload as BusinessCaseWithDetails
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const deleteBusinessCase = createAsyncThunk<void, AppId, AppThunkAPIType>('businessCase/delete', async (id, api) => {
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(deleteWithAuth({
        url: `api/businessCase/${id}`,
    }))
    if (deleteWithAuth.fulfilled.match(result)) {
        return
    } else {
        return rejectWithValue(result.payload ?? { kind: 'unknown' })
    }
})

export const businessCaseSlice = createSlice({
    name: "businessCases",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loadBusinessCases.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addCase(loadBusinessCases.fulfilled, (state, action) => {
            if (isSameRequestId(state.state, action.meta.requestId)) {
                adapter.setAll(state, action.payload)
                state.state = { type: "loaded" }
            }
        })
        builder.addCase(loadBusinessCases.rejected, (state, action) => {
            if (isSameRequestId(state.state, action.meta.requestId)) {
                state.state = { 
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
        builder.addCase(createBusinessCase.fulfilled, (state, action) => {
            adapter.addOne(state, action.payload)
        })
        builder.addCase(updateBusinessCase.fulfilled, (state, action) => {
            adapter.upsertOne(state, action.payload)
        })
        builder.addCase(deleteBusinessCase.fulfilled, (state, action) => {
            adapter.removeOne(state, action.meta.arg)
        })
    }
})

export const selectBusinessCases = (state: RootState) => state.businessCases

export const
    { selectAll: selectAllBusinessCases
    , selectById: selectBusinessCaseById
    } = adapter.getSelectors(selectBusinessCases)

export default businessCaseSlice.reducer
