import { Localized, useLocalization } from '@fluent/react'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { ButtonGroup, Dialog, FormControl, IconButton, InputLabel, MenuItem, Select, Stack } from '@mui/material'
import { FlexDiv } from '../../app/Utils'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { loadMailTemplates, selectAllMailTemplates, selectMailTemplates } from './mailTemplatesSlice'
import { MailTemplateDialog } from './MailTemplateDialog'
import { showSuccess } from '../notifications/notificationsSlice'
import { AppId } from '../../app/appTypes'
import { selectModule } from '../modules/moduleSlice'
import { RestoreTemplateDialog } from './RestoreTemplateDialog'
import { If } from '../../app/If'
import { LoadingContainer } from '../../app/LoadingContainer'
import { ErrorContainer } from '../../app/ErrorContainer'
import { selectSettings } from '../settings/settingsSlice'
import { enUS, plPL } from '@mui/x-data-grid/locales'
import SyncIcon from '@mui/icons-material/Sync';

type DialogState =
    | { type: "none" }
    | { type: "updateTemplate", templateId: AppId | undefined, editable: boolean }
    | { type: "newTemplate", editable: boolean }
    | { type: "restoreTemplate", templateId: AppId }

   


const DialogContentDispatcher = ({ state, setter }: { state: DialogState, setter: Dispatch<SetStateAction<DialogState>> }) => {
    const dispatch = useAppDispatch()
    const onSuccess = useCallback(() => {
        setter({ type: "none" })
        dispatch(showSuccess("saved"))
    }, [setter, dispatch])
    const onCancel = useCallback(() => {
        setter({ type: "none" })
    }, [setter])

    switch (state.type) {
        case "none":
            return null

        case "updateTemplate":
            return <MailTemplateDialog
                id={state.templateId}
                editable={state.editable}
                onSaveSuccess={onSuccess}
                onCancel={onCancel}
            />

        case "restoreTemplate":
            return <RestoreTemplateDialog
            id={state.templateId}
            onSaveSuccess={onSuccess}
            onCancel={onCancel}
            />

        case "newTemplate":
            return <MailTemplateDialog
            id={undefined}
            editable={state.editable}
            onSaveSuccess={onSuccess}
            onCancel={onCancel}
            />
    }
}

export const MailTemplates = () => {
    const { locale } = useAppSelector(selectSettings);
    const { l10n } = useLocalization()
    const dispatch = useAppDispatch()
    const { state: mailTemplatesState } = useAppSelector(selectMailTemplates)
    const rows = useAppSelector(state => selectAllMailTemplates(state))
    const [context, setContext] = useState<string>("all");
    const currentModule = useAppSelector(state => selectModule(state).currentModule)
    const isSuper = currentModule?.code === "super"

    const [dialog, setDialog] = useState<DialogState>({ type: "none" })

    const handleContextChange = (e) => setContext(e.target.value);

    const handleNewTemplate = useCallback(() => {
        setDialog({
            type: "newTemplate",
            editable: isSuper
        })
    }, [setDialog])

    const handleEditTemplate = useCallback((templateId: AppId) => {
        setDialog({
            type: "updateTemplate",
            templateId,
            editable: isSuper
        })
    }, [setDialog])

    const handleRestoreTemplate = useCallback((templateId: AppId) => {
        setDialog({
            type: "restoreTemplate",
            templateId
        })
    }, [setDialog])


    useEffect(() => {
        if (mailTemplatesState.type === "empty") {
            dispatch(loadMailTemplates())
        }
    }, [dispatch, mailTemplatesState])
    
    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const handleRefreshClick = () => {
        dispatch(loadMailTemplates(context));
    }


    const columns: GridColDef[] =
        [
            {
                field: 'type',
                headerName: l10n.getString("mail-type", null, "Typ"),
                flex: 1
            },
            {
                field: 'name',
                headerName: l10n.getString("mail-name", null, "Nazwa"),
                flex: 1,
                valueGetter: (_, row) => {
                    return l10n.getString(`mail-type-${row.type}`, null, row.type)
                }
            },
            {
                field: 'context',
                headerName: l10n.getString("mail-context", null, "Kontekst"),
                flex: 1
            },
            {
                field: 'isCompanyTemplate',
                headerName: l10n.getString("mail-template-overwritten", null, "Czy nadpisano systemowy szablon?"),
                flex: 1,
                valueGetter: (_, row) => {
                    return row.companyId ? l10n.getString(`yes`, null, "Tak") : l10n.getString("no", null, "Nie");
                }
            },
            {
                field: 'actions', type: 'actions', width: 350,
                renderCell: (params) => {
                    return (
                        <ButtonGroup>
                            { params.row.companyId ?
                                (<Button color="primary" onClick={() => handleRestoreTemplate(params.row.id)} >
                                <Localized id="mail-restore-system">
                                    <span>Przywróć systemowy</span>
                                </Localized>
                            </Button>) : null
                            }
                            <Button color="primary" onClick={() => handleEditTemplate(params.row.id)} >
                                <Localized id="edit">
                                    <span>Edytuj</span>
                                </Localized>
                            </Button>
                        </ButtonGroup>
                    )
                }
            }
        ];


    switch (mailTemplatesState.type) {
        case "empty":
        case "loading":
            return <LoadingContainer/>
        case "error":
            return <ErrorContainer error={mailTemplatesState.error}/>;
        case "loaded":
            return (
                <>
                    <If condition={isSuper}>
                        <div>
                            <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                                <FormControl>
                                    <InputLabel id="select-mail-conxtext-label"><Localized id="mail-context">Kontekst</Localized></InputLabel>
                                    <Select
                                        value={context}
                                        onChange={handleContextChange}
                                        labelId="select-mail-context-label"
                                        label={<Localized id="mail-context">Kontekst</Localized>}
                                        sx={{ width: '200px' }}
                                    >
                                        <MenuItem value={'all'}>
                                            <Localized id="all">All</Localized>
                                        </MenuItem>
                                        <MenuItem value={'DOCUMENT'}>
                                            <Localized id="mail-document">Dokument</Localized>
                                        </MenuItem>
                                        <MenuItem value={'SYSTEM'}>System</MenuItem>
                                    </Select>
                                </FormControl>
                                <IconButton
                                    onClick={handleRefreshClick}>
                                    <SyncIcon />
                                </IconButton>
                            </Stack>
                        </div>
                    </If>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid style={{ minHeight: 100, height: isSuper ? 'calc(100vh - 375px)' : 'calc(100vh - 250px)' }} localeText={getCustomLocaleText()} rows={rows} columns={columns} onRowDoubleClick={({ row }) => handleEditTemplate(row.id)} />
                        </div>
                    </div>
                    <If condition={isSuper}>
                        <FlexDiv>
                            <Fab color="secondary" sx={{ marginTop: 2 }} onClick={() => handleNewTemplate()}>
                                <AddIcon />
                            </Fab>
                        </FlexDiv>
                    </If>
                    <Dialog open={dialog.type !== "none"} fullWidth maxWidth="lg" keepMounted={false}>
                        <DialogContentDispatcher state={dialog} setter={setDialog} />
                    </Dialog>
                </>
            )
    }
}
