import { Localized, useLocalization } from '@fluent/react'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { useEffect, useState } from 'react'
import { ButtonGroup } from '@mui/material'
import { FlexDiv } from '../../app/Utils';
import { selectSettings } from '../settings/settingsSlice'
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { CompanyToRegister, loadCompaniesToRegister } from './companiesToRegisterApi'
import { unwrapResult } from '@reduxjs/toolkit'
import { ErrorContainer } from '../../app/ErrorContainer'
import { LoadingContainer } from '../../app/LoadingContainer'
import { QuickSearchToolbar } from '../../app/QuickSearchToolbar'

export const CompaniesToRegister = () => {
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const [companies, setCompanies] = useState<CompanyToRegister[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);
    const dispatch = useAppDispatch()

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadCompaniesToRegister())
        .then(unwrapResult)
        .then(setCompanies)
        .catch((error) => { console.error(error); setIsError(true); setError(error) })
        .finally(() => setIsLoading(false));
    }, [])

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'companyName',
            headerName: l10n.getString("companies-short-name", null, "Nazwa skrócona"),
            width: 400
        },
        {
            field: 'user',
            headerName: l10n.getString("user", null, "Użytkownik"),
            flex: 1,
            valueGetter: (_, row) => {
                return row.firstName + ' ' + row.lastName;
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            valueGetter: (_, row) => {
                return row.companyId ? l10n.getString("companies-registered", null, "Zarejestrowana") : l10n.getString("companies-unregistered", null, "Niezarejestrowana");
            }
        },
        {
            field: 'actions', type: 'actions', width: 150,
            renderCell: (params) => {
                return (
                    <ButtonGroup size="small">
                        <Button
                            style={{ width: 115 }}
                            component={Link}
                            to={`/company/create?id=${params.row.id}`}
                            color="secondary"
                            variant="outlined"
                            disabled={params.row.companyId} >
                            <Localized id="register">
                                <span>Zarejestruj</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ]
    
    if (isError) {
        return <>
            <ErrorContainer error={error}/>
        </>
    }

    if (isLoading) {
        return <>
            <LoadingContainer/>
        </>
    }

    return (
        <>
            <TableContainer component={Paper}>
                <DataGrid localeText={getCustomLocaleText()}
                    style={{ minHeight: 100, height: 'calc(100vh - 200px)' }}
                    rows={companies}
                    columns={columns}
                    initialState={{
                        sorting: { sortModel: [{ field: 'shortName', sort: 'asc' }] }
                    }}
                    slots={{ toolbar: QuickSearchToolbar }}
                />
            </TableContainer>
            <FlexDiv>
                <Fab sx={{
                        marginTop: 2,
                    }} 
                    component={Link} 
                    to="/company/create" 
                    color="secondary" 
                >
                    <AddIcon />
                </Fab>
            </FlexDiv>
        </>
    );
}
