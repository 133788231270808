import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, isAnyOf } from "@reduxjs/toolkit";
import { AppId, AppThunkAPIType } from "../../app/appTypes";
import { RootState } from "../../app/store";
import { deleteWithAuth, deleteWithAuthAndBody, getWithAuth, post, postWithAuth, putWithAuth } from "../../http";
import { RequirementNote, UrsOfferStatus } from "../ursOffer/ursOfferSlice";
import { logout } from "../user/userSlice";
import { ValidationStage } from "../validations/validationsSlice";
import { RequirementType } from '../requirements/RequirementsTypesSlice';
import { loadURSComments } from "./URSCommentSlice";
import { loadManyBlobs } from "./ursAttachementsSlice";
import { loadUrsTemplate, updateUrsTemplate } from "../urstemplates/ursTemplatesSlice";
import { useEffect, useState } from "react";
import { CommentKind } from "../../app/Discuss";
import { DocumentTeamMemberRole } from "../documents/components/teamMemberApi";

export const AppURSChangedEvent = 'app:URS:changed'

const dispatchURSChanged = (urs: Urs) => {
    window.dispatchEvent(new CustomEvent<Urs>(AppURSChangedEvent, { detail: urs }))
}

export function useUrsState(initialState: Urs) {
    const [urs, setUrs] = useState(initialState)

    useEffect(() => {
        function handleUrsChanged(event: CustomEvent<Urs>) {
            console.log("URS changed", event.detail)
            setUrs(event.detail)
        }
        window.addEventListener(AppURSChangedEvent, handleUrsChanged as EventListener)

        return () => {
            window.removeEventListener(AppURSChangedEvent, handleUrsChanged as EventListener)
        }
    }, [setUrs])

    return urs
}

export type UrsStatus = "N" | "C" | "A" | "F" | "P" | "I" | "O" | "D" | "E"

export interface ActionLink {
    href: string
    type: string
    authorize: boolean
    hasParams: boolean
}

export type UrsActionType 
    = "approve" 
    | "reject" 
    | "finishOpinion" 
    | "finishEdition" 
    | "sendToOpinion" 
    | "sendToApprove" 
    | "save" 
    | "del" 
    | "report" 
    | "publish" 
    | "newversion" 
    | "changedeadline" 
    | "substituteMember" 
    | "deleteMember" 
    | "changeursnumber"
    | "comment"
    | "closeOpinionStage"
    | "changeurstitle"
    | "resolvingcomment"
    | "addChildRequirement"

export interface UrsHeader {
   id              : AppId
   ursNo           : string
   ursTemplateId   : string | null
   deviceTypeId    : AppId
   title           : string
   purpose         : string
   description     : string
   docDesignation  : string
   area            : string
   createDate      : string
   companyId       : AppId
   createdBy       : string
   createdByFullName: string
   status          : UrsStatus
   limit           : number
   version         : number
   supplierId      : AppId | null
   internalSupplierId : AppId | null
   isTemplate      : boolean
   isSystem        : boolean
   guid            : string
   language        : string | null
}

export interface UrsGroup {
    id: AppId
    name: string
    numeration: string
    requirements: URSRequirement[]
    groups: UrsGroup[]
}

export interface UrsRequirementType {
    id: AppId
    code: string
    name: string
    name_EN: string
    order: number
    numeration: string
    requirements: URSRequirement[]
    groups: UrsGroup[]
    sysSectionId: AppId | null // ID of requirement type in system
}

export type Urs = UrsHeader & {
   ursRequirements : URSRequirement[]
   types           : UrsRequirementType[]
   teamMembers     : TeamMember[]
   links           : Partial<Record<UrsActionType, ActionLink>>
}

export interface URSReqTypeView {
    requirementType: RequirementType
    groups: EntityState<URSGroupView>
}

export interface URSGroupView {
    name: string
    requirements: EntityState<URSRequirement>
}

/*
"offerMesages": [
              {
                "id": 0,
                "ursOfferId": 0,
                "ursRequirementId": 0,
                "message": "string",
                "author": "string",
                "userId": "string",
                "companyName": "string",
                "type": "string",
                "guid": "string",
                "date": "2024-05-21T18:45:54.515Z",
                "status": "string",
                "parentMessageId": 0,
                "subMessages": [
                  "string"
                ]
              }
            ] 
 */
export interface OfferMessage {
    id: AppId
    ursOfferId: AppId
    ursRequirementId: AppId
    message: string
    author: string
    userId: string
    companyName: string
    type: CommentKind
    guid: string
    date: string
    status: string
    parentMessageId: AppId | null
    subMessages: string[]
}

export interface URSRequirement {
    id: string
    code: string
    name: string
    description: string
    criticality: string | null
    typeId: string | null
    ursId: string
    severity: number 
    frequency:number 
    detection: number
    potentialDefect: string
    consequence: string
    reason: string
    comment: string | null
    note: RequirementNote | null
    testDefinitionId: string | null
    requirementId: string | null
    stage: ValidationStage | null
    group: string | null
    order: number
    guid: string
    sectionGuid: string
    parentRequirementId: number | null
    numeration : string | null
    offerMessages: OfferMessage[]
    childRequirements: URSRequirement[]
    modifyDate: string | null
}

export type ChangeType = "add" | "delete" | "modify"
export interface Change {
    changeType: ChangeType
    old : Pick<URSRequirement, "description"> | null
    current: Pick<URSRequirement, "description"> | null
    id: AppId
}
export const changeLogAdapter = createEntityAdapter<Change>({
    selectId: x => x.id,
})

export interface TeamMember {
    id: AppId
    userName: string
    firstName: string
    surname: string
    companyId: AppId
    companyName: string
    position: string
    role: DocumentTeamMemberRole
    substitutionName: string
    documentId: AppId
    approvementDate: string | null
    opinionDate: string | null
    finishEditionDate: string | null
    opinionAction: TeamMemberAction | null
    approveAction: TeamMemberAction | null
    opinionClosed: TeamMemberAction | null
    finishEditionAction: TeamMemberAction | null
}

export interface TeamMemberAction {
    deadline    : Date
    execDate    : Date
    status : string

}

export interface Offers {
    id: AppId 
    supplierId: AppId  
    supplierName: string
    ursNo: string   
    ursId: AppId
    deviceTypeId: AppId
    deviceTypeName: string
    companyId: AppId
    companyName: string
    status: UrsOfferStatus  
    createDate: string   
    responseDate: string   
    comment: string
    purpose: string 
    hasUnfunfilledRequirements: boolean
    description: string     
 }

export interface CreateURSPayload {
    createFrom    : string
    numberingMode    : string
    deviceTypeId  : AppId
    ursNumber     : string
    title         : string
    ursTemplateId : AppId | undefined
    ursId         : AppId | undefined
    purpose       : string
    area          : string
    description   : string
    language      : string
    processId     : string | null
}

export interface ChangeDeadlinePayload {
    ursId: AppId
    deadline: string
    teamMemberId: AppId
    forAllMembers : boolean
}

export interface SubstitutionPayload {
    ursId: AppId
    userName: string
    substitution: string
}

export interface UrsState {
    data       : Urs
    anyChanges : boolean
    status     : 'idle' | 'loading'
    changeLog  : EntityState<Change>
}

export interface UrsHistory {
    id: AppId
    ursNo: string
    title: string
    types: UrsHistoryRequirementType[]
}
export interface UrsHistoryRequirementType {
    id: AppId
    code: string
    name: string
    name_EN: string
    order: number
    sysSectionId: AppId | null
    groups: UrsHistoryGroup[]
    requirements: UrsHistoryRequirement[]
    numeration: string
}
export interface UrsHistoryGroup {
    id: AppId
    name: string
    numeration: string
    requirements: UrsHistoryRequirement[]
}
export interface UrsHistoryRequirement {
    id: AppId
    name: string
    name_orig: string
    guid: string
    criticality: string
    criticality_orig: string
    sectionGuid: string
    numeration: string | null
    numeration_orig: string | null
    status: string
}

export const emptyUrs: Urs = {
   id              : "",
   ursNo           : "",
   ursTemplateId   : null,
   deviceTypeId    : "",
   title           : "",
   purpose         : "",
   description     : "",
   docDesignation  : "",
   area            : "",
   createDate      : "",
   companyId       : "",
   createdBy       : "",
   createdByFullName: "",
   status          : 'N',
   limit           : 0,
   version         : 0,
   supplierId      : null,
   internalSupplierId: null,
   ursRequirements : [],
   teamMembers     : [],
   links           : {},
   isTemplate      : false,
   isSystem        : false,
   guid            : "",
   language        : null,
   types           : [],
}

export interface UrsVersion {
    versionId: number
    subVersionId: number | null
    mainVersion: number
    name: string
    date: string
    syntheticKey: string
}

export const loadUrsVersions = createAsyncThunk<UrsVersion[], AppId, AppThunkAPIType>("urs/loadVersions", async (ursId, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({
    url: `api/UrsHistory/versions/${ursId}`,
        }))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return (payload as UrsVersion[]).map(x => {
            return {...x, syntheticKey: `${x.versionId}-${x.subVersionId ?? 0}`}
        })
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

interface Diff {
    prev: string
    curr: string
}
export const getDiff = createAsyncThunk<Diff, Diff, AppThunkAPIType>("html/diff", async (args, { dispatch, rejectWithValue }) => {
    try {
        const response = await fetch(process.env.REACT_APP_BLOB_API + '/diff', {
            method: "POST",
            body: JSON.stringify(args),
        })
        if (response.ok) {
            return await response.json()
        } else {
            return args
        }
    } catch (error) {
        return rejectWithValue({
            kind: "connection",
        })
    }
})

export interface UrsComparePayload {
    versionId: number
    subVersionId: number | null
    origVersionId: number
    origSubVersionId: number | null
}
export const compareUrsVersions = createAsyncThunk<UrsHistory, UrsComparePayload, AppThunkAPIType>("urs/compareVersions", async (data, { dispatch, rejectWithValue }) => {
    const result = await dispatch(postWithAuth({
        url: `api/UrsHistory/compare`,
        payload: data,
    }))
    const { payload } = result
    if (postWithAuth.fulfilled.match(result)) {
        const data = payload as UrsHistory
        // for (const type of data.types) {
        //     for (const req of type.requirements) {
        //         if (req.status === 'MODIFIED') {
        //             const { prev: from, curr: to } = await dispatch(getDiff({ prev: req.name_orig, curr: req.name })).unwrap()
        //             req.name = to
        //             req.name_orig = from
        //         }
        //     }
        //     for (const group of type.groups) {
        //         for (const req of group.requirements) {
        //             if (req.status === 'MODIFIED') {
        //                 const { prev: from, curr: to } = await dispatch(getDiff({ prev: req.name_orig, curr: req.name })).unwrap()
        //                 req.name = to
        //                 req.name_orig = from
        //             }
        //         }
        //     }
        // }
        return data
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export function isUrsLinkAvail(action: UrsActionType, urs: Urs) {
    const link = urs.links[action]
    if (!link) {
        return false
    }
    return true
}

export function* allURSRequirements(urs: Urs) {
    for (const type of urs.types) {
        for (const req of type.requirements) {
            yield req
        }
        for (const group of type.groups) {
            for (const req of group.requirements) {
                yield req
                for (const child of req.childRequirements) {
                    yield child
                }
            }
        }
    }
}

export function* allURSRequirementsWithType(urs: Urs): Generator<[URSRequirement, UrsRequirementType]> {
    for (const type of urs.types) {
        for (const req of type.requirements) {
            yield [req, type]
        }
        for (const group of type.groups) {
            for (const req of group.requirements) {
                yield [req, type]
            }
        }
    }
}

export function* allRequirementsConditionaly(urs: Urs, condition: (req: URSRequirement) => boolean) {
    for (const req of allURSRequirements(urs)) {
        if (condition(req)) {
            yield req
        }
    }
}

export function* allURSRequirementsForever(urs: Urs, condition: (req: URSRequirement) => boolean) {
    const items: URSRequirement[] = []
    for (const req of allURSRequirements(urs)) {
        if (condition(req)) {
            items.push(req)
            yield req
        }
    }
    while (items.length > 0) {
        for (const req of items) {
            yield req
        }
    }
    yield null
}

export function findSuccesor(urs: Urs, requirementGuid: string, condition: (req: URSRequirement) => boolean) {
    const items = Array.from(allURSRequirementsWithType(urs))
    const index = items.findIndex(x => x[0].guid === requirementGuid)
    if (index === -1) {
        return null
    }
    for (let i = index + 1; i < items.length; i++) {
        if (condition(items[i][0])) {
            return items[i]
        }
    }
    // if not found, start from the beginning
    for (let i = 0; i < index; i++) {
        if (condition(items[i][0])) {
            return items[i]
        }
    }
    return null
}

export const findPredecessor = (urs: Urs, requirementGuid: string, condition: (req: URSRequirement) => boolean) => {
    const items = Array.from(allURSRequirementsWithType(urs))
    const index = items.findIndex(x => x[0].guid === requirementGuid)
    if (index === -1) {
        return null
    }
    for (let i = index - 1; i >= 0; i--) {
        if (condition(items[i][0])) {
            return items[i]
        }
    }
    // if not found, start from the end
    for (let i = items.length - 1; i > index; i--) {
        if (condition(items[i][0])) {
            return items[i]
        }
    }
    return null
}

const initialState: UrsState = { 
    data       : emptyUrs,
    anyChanges : false ,
    status     : 'idle',
    changeLog  : changeLogAdapter.getInitialState(),
}

export const createURS = createAsyncThunk<Urs, CreateURSPayload, AppThunkAPIType>("urs/create", async (data, {
    dispatch, 
    rejectWithValue,
}) => {
    const result = await dispatch(postWithAuth({
        url: "api/Urs/create-from-template",
        payload: data,
    }))
    const { payload } = result 
    if (postWithAuth.fulfilled.match(result)) {
        return payload 
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const createURSRequirement = createAsyncThunk<Urs, URSRequirement, AppThunkAPIType>("urs/createRequirement", async (data, { dispatch, rejectWithValue }) => {
    const result = await dispatch(postWithAuth({
        url: "api/Urs/document/requirement",
        payload: data,
    }))
    const { payload } = result
    if (postWithAuth.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const addUrsRequirementsFromCatalog = createAsyncThunk<Urs, { ursId: AppId, sectionGuid: string, catalogIds: Set<AppId> }, AppThunkAPIType>("urs/addRequirementsFromCatalog", async (data, { dispatch, rejectWithValue }) => {
    const result = await dispatch(postWithAuth({
        url: `api/urs/document/requirement/add-from-cataloque`,
        payload: { 
            ursId: data.ursId,
            sectionGuid: data.sectionGuid,
            catalogIds: Array.from(data.catalogIds).map(x => {
                return {
                    id: x
                }
            })
        },
    }))
    const { payload } = result
    if (postWithAuth.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export interface URSActionArgs {
    url: string 
    args: { password: string , deadline: string }
}

export const postURSAction = createAsyncThunk<Urs, URSActionArgs, AppThunkAPIType>("urs/postAction", async ({ url, args }, { dispatch, rejectWithValue }) => {
    const result = await dispatch(postWithAuth({ 
        url: url.startsWith("/") ? url.substring(1) : url, 
        payload: args ?? {},
    }))
    const { payload } = result 
    if (postWithAuth.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const deleteUrs = createAsyncThunk<AppId, URSActionArgs, AppThunkAPIType>("urs/delete", async ({ url, args }, { dispatch, rejectWithValue }) => {
    const result = await dispatch(postWithAuth({ 
        url: url.startsWith("/") ? url.substring(1) : url, 
        payload: args ?? {},
    }))
    const { payload } = result 
    if (postWithAuth.fulfilled.match(result)) {
        return payload
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
}
)

export interface PublishActionArgs {
    url: string 
    companiesIds: Array<AppId>
    suppliersIds: Array<AppId>
}

export const publishAction = createAsyncThunk<Urs, PublishActionArgs, AppThunkAPIType>("urs/publishAction", async ({ url, companiesIds, suppliersIds }, { dispatch, rejectWithValue }) => {

    const result = await dispatch(postWithAuth({ 
        url: url.startsWith("/") ? url.substring(1) : url, 
        payload: {
            companiesIds: Array.from(companiesIds), 
            suppliersIds: Array.from(suppliersIds)
        },
    }))
    const { payload } = result 
    if (postWithAuth.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})


export const changeDeadline = createAsyncThunk<Urs, ChangeDeadlinePayload, AppThunkAPIType>("urs/changeDeadline",
 async (data, { dispatch, rejectWithValue }) => {

    const result = await dispatch(postWithAuth({ 
        url: `api/Urs/${data.ursId}/change-deadline`,
        payload: data,
    }))

    const { payload } = result 
    if (postWithAuth.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const sendReminder = createAsyncThunk<Urs, AppId, AppThunkAPIType>("urs/sendReminder",
 async (ursId, { dispatch, rejectWithValue }) => {

    const result = await dispatch(postWithAuth({ 
        url: `api/Urs/send-reminder/${ursId}`,
        payload: {},
    }))

    const { payload } = result 
    if (postWithAuth.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const deleteUrsTeamMember = createAsyncThunk<Urs, AppId, AppThunkAPIType>("urs/deleteTeamMember",
async (id, { dispatch, rejectWithValue }) => {

    const result = await dispatch(deleteWithAuthAndBody({
        url: `api/Urs/team-member/${id}`,
    }))
    const { payload } = result 

    if (deleteWithAuthAndBody.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const addUrsTeamMember = createAsyncThunk<Urs, { ursId: AppId, userName: string, role: string }, AppThunkAPIType>("urs/addTeamMember", async (data, { dispatch, rejectWithValue }) => {
    const result = await dispatch(postWithAuth({
        url: `api/urs/team-member`,
        payload: { 
            documentId: data.ursId, 
            userName: data.userName,
            role: data.role
        },
    }))
    const { payload } = result
    if (postWithAuth.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const inviteTeamMember = createAsyncThunk<Urs, { ursId: AppId, userEmail: string, role: string }, AppThunkAPIType>("urs/inviteTeamMember", async (data, { dispatch, rejectWithValue }) => {
    const result = await dispatch(postWithAuth({
        url: `api/urs/team-member/invite`,
        payload: { 
            documentId: data.ursId, 
            userEmail: data.userEmail,
            role: data.role
        },
    }))
    const { payload } = result
    if (postWithAuth.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const substituteUrsTeamMember = createAsyncThunk<Urs, SubstitutionPayload, AppThunkAPIType>("urs/substituteTm",
 async (data, { dispatch, rejectWithValue }) => {

    const result = await dispatch(postWithAuth({ 
        url: `api/Urs/${data.ursId}/team-member/substitute`,
        payload: data,
    }))

    const { payload } = result 
    if (postWithAuth.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const updateURS = createAsyncThunk<Urs, Urs, AppThunkAPIType>("urs/update", async (data, { dispatch, rejectWithValue, }) => {
    const result = await dispatch(putWithAuth({
        url: `api/Urs/${data.id}`,
        payload: data,
    }))
    const { payload } = result
    if (putWithAuth.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const updateURSRequirement = createAsyncThunk<Urs, URSRequirement, AppThunkAPIType>("urs/updateRequirement", async (data, { dispatch, getState, rejectWithValue }) => {
    const { id, typeId, group } = data
    const result = await dispatch(putWithAuth({
        url: `api/Urs/document/requirement/${data.id}`,
        payload: data,
    }))
    const { payload } = result
    if (putWithAuth.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const updateUrsNo = createAsyncThunk<Urs, { ursId: AppId, ursNo: string }, AppThunkAPIType>("urs/updateUrsNo", async (data, { dispatch, getState, rejectWithValue }) => {
    const { ursId, ursNo } = data
    const result = await dispatch(postWithAuth({
        url: `api/Urs/${ursId}/change-number`,
        payload: { 
            id: ursId,
            ursNo,
        },
    }))
    const { payload } = result
    if (postWithAuth.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export type DescriptionField = "purpose" | "description" | "area" | "title"
export const updateDescriptionField = createAsyncThunk<Urs, { ursId: AppId, fieldName: DescriptionField, value: string }, AppThunkAPIType>("urs/updateDescriptionField", async (data, { dispatch, getState, rejectWithValue }) => {
    const { ursId, fieldName, value } = data
    const result = await dispatch(putWithAuth({
        url: `api/Urs/document/${ursId}/descriptionfields`,
        payload: { fieldName, text: value },
    }))
    const { payload } = result
    if (putWithAuth.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const deleteURSRequirement = createAsyncThunk<Urs, URSRequirement, AppThunkAPIType>("urs/deleteRequirement", async (entity, { dispatch, rejectWithValue }) => {
    const { id, group, typeId } = entity
    const result = await dispatch(deleteWithAuthAndBody({
        url: `api/Urs/document/requirement/${id}`,
    }))
    const { payload } = result
    if (deleteWithAuth.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const loadUrs = createAsyncThunk<Urs, AppId, AppThunkAPIType>("urs/loadDocument", async (id, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({
        url: `api/Urs/${id}`,
    }))
    const { payload } = result 
    if (getWithAuth.fulfilled.match(result)) {
        const urs = payload as Urs
        await dispatch(loadURSComments(id))
        const allRequirements = Array.from(allURSRequirements(urs))
        await dispatch(loadManyBlobs([{ docType: 'urs', docId: id }, allRequirements.map(x => x.guid).concat([urs.guid])]))

        return urs
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
}, {
    condition: (_, { getState }) => {
        const { status } = getState().urs 
        if (status === 'loading') {
            return false
        } 
    }
})

export const selectSupplierOfferWihtNewVersion = createAsyncThunk<Urs, AppId, AppThunkAPIType>("urs/selectSupplierOfferNewVersion", async (id, { dispatch, rejectWithValue }) => {
    const result = await dispatch(postWithAuth({
        url: `api/Urs/select-offer/${id}?createversion=true`,        
        payload: {}
    }))
    const { payload } = result 
    if (postWithAuth.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload 
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const selectSupplierOffer = createAsyncThunk<Urs, AppId, AppThunkAPIType>("urs/selectSupplierOffer", async (id, { dispatch, rejectWithValue }) => {
    const result = await dispatch(postWithAuth({
        url: `api/Urs/select-offer/${id}?createversion=false`,        
        payload: {}
    }))
    const { payload } = result 
    if (postWithAuth.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload 
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export interface CreateUrsMainGroupPayload {
    ursId: AppId
    name: string
    code: string
    systemTypeId: AppId | null
}

export const createUrsMainGroup = createAsyncThunk<Urs, CreateUrsMainGroupPayload, AppThunkAPIType>("urs/createMainGroup", async (data, { dispatch, rejectWithValue }) => {
    const result = await dispatch(postWithAuth({
        url: `api/Urs/document/sections/add-main-group`,
        payload: data,
    }))
    const { payload } = result 
    if (postWithAuth.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload 
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export interface CreateUrsSubGroupPayload {
    ursId: AppId
    name: string
    code: string
    parentGroupGuid: AppId
}

export const createUrsSubGroup = createAsyncThunk<Urs, CreateUrsSubGroupPayload, AppThunkAPIType>("urs/createSubGroup", async (data, { dispatch, rejectWithValue }) => {
    const result = await dispatch(postWithAuth({
        url: `api/Urs/document/sections/add-sub-group`,
        payload: data,
    }))
    const { payload } = result
    if (postWithAuth.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload 
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export interface EditGroupPayload {
    id: AppId
    name: string
    code: string
}

export const updateUrsGroup = createAsyncThunk<Urs, EditGroupPayload, AppThunkAPIType>("urs/updateGroup", async (data, { dispatch, rejectWithValue }) => {
    const result = await dispatch(postWithAuth({
        url: `api/Urs/document/sections/edit-group`,
        payload: data,
    }))
    const { payload } = result
    if (postWithAuth.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload 
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const deleteGroup = createAsyncThunk<Urs, AppId, AppThunkAPIType>("urs/deleteGroup", async (id, { dispatch, rejectWithValue }) => {
    const result = await dispatch(deleteWithAuthAndBody({
        url: `api/Urs/document/sections/${id}`,
    }))
    const { payload } = result
    if (deleteWithAuth.fulfilled.match(result)) {
        dispatchURSChanged(payload)
        return payload 
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const loadAllUrsOffers = createAsyncThunk<Offers[], AppId, AppThunkAPIType>("urs/loadAllOffers", async (id, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({
        url: `api/UrsOffers/find-for-urs/${id}`,
    }))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as Offers[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const ursSlice = createSlice({
    name: "urs",
    initialState,
    reducers: {
        clearUrsSlice: (state) => { 
            state.data = emptyUrs 
            state.anyChanges = false 
            state.changeLog = changeLogAdapter.getInitialState()
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadUrs.pending, state => {
            state.status = 'loading'
        })
        builder.addCase(loadUrs.rejected, state => {
            state.status = 'idle'
        })
        builder.addMatcher(isAnyOf(
            loadUrs.fulfilled,
            loadUrsTemplate.fulfilled,
            createURS.fulfilled,
            updateURS.fulfilled,
            postURSAction.fulfilled,
            publishAction.fulfilled,
            selectSupplierOffer.fulfilled,
            updateUrsTemplate.fulfilled,
            changeDeadline.fulfilled,
            substituteUrsTeamMember.fulfilled,
            deleteUrsTeamMember.fulfilled,
            updateURSRequirement.fulfilled,
        ), (state, action) => {
            state.data = action.payload
            state.anyChanges = false
            state.status = 'idle'
        })
        builder.addMatcher(isAnyOf(
            logout.fulfilled,
            deleteUrs.fulfilled,
            selectSupplierOfferWihtNewVersion.fulfilled,
        ), (state) => { 
            state.data = emptyUrs 
            state.anyChanges = false
        })
    },
})

export const selectUrs = (state: RootState): UrsState => state.urs

export const { selectAll: selectAllURSChages } = changeLogAdapter.getSelectors<RootState>(state => selectUrs(state).changeLog)

export default ursSlice.reducer;
