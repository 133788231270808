import React from 'react';
import { ButtonGroup, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { AlertFeatureInProgress } from '../utils/AlertFeatureInProgress';

export const SupplierBudgetOffer = () => {

    // Dane dla głównego ekranu
    const mainRow = {
        id: 1,
        client: 'Company ABC',
        generationDate: '2024-02-25',
        sentDate: '2024-02-26',
        preparedBy: 'John Kowolski',
        ursNumber: 'URS-12345'
    };

    // Dane dla gridu podrzędnego
    const subRows = [
        { id: 1, equipmentNumber: 1, elementName: 'Base machine', price: 500 },
        { id: 2, equipmentNumber: 2, elementName: 'Rotation Sieve', price: 200 },
        { id: 3, equipmentNumber: 3, elementName: 'Rasping sieve, mesh 1,0 mm', price: 100 },
        { id: 4, equipmentNumber: 4, elementName: 'Rasping sieve, mesh 3,0 mm', price: 150 },
        { id: 5, equipmentNumber: 5, elementName: 'Documentation', price: 100 },
        // Dodaj więcej danych urządzeń w razie potrzeby
    ];

    const subColumns = [
        { field: 'equipmentNumber', headerName: 'No', width: 150 },
        { field: 'elementName', headerName: 'Device component', width: 200 },
        { field: 'price', headerName: 'Price', width: 150 },
        
    ];

    const sumPrices = subRows.reduce((sum, { price }) => sum + price, 0);
    return <>
        <AlertFeatureInProgress/>

        <div>
            <Typography variant="h6">Offer details</Typography>
            <Typography>Offer number: {mainRow.id}</Typography>
            <Typography>Customer: {mainRow.client}</Typography>
            <Typography>Budget offer create date: {mainRow.generationDate}</Typography>
            <Typography>Budget offer send date: {mainRow.sentDate}</Typography>
            <Typography>Prepared by: {mainRow.preparedBy}</Typography>
            <Typography variant="h6">Budget Offer Details</Typography>
            <div style={{ height: 300, width: '100%' }}>
                <DataGrid
                    rows={subRows}
                    columns={subColumns}
                    autoHeight
                    slots={{
                        footer: () => (
                          <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: '16px' }}>
                            Total: {sumPrices}
                          </div>
                        ),
                      }}
                />
            </div>
        </div>
    </>;
}