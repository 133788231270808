import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectSupplierDeviceById, updateSupplierDevice } from "./supplierDeviceSlice"
import { Localized } from "@fluent/react"
import { Alert, Button, ButtonGroup, Card, CardActions, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, Grid,  Skeleton, Stack, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import {  SupplierDeviceDetail, createSupplierDeviceDetail, deleteSupplierDeviceDetail, selectAllSupplierDeviceDetail, updateSupplierDeviceDetail } from "./supplierDeviceDetailsSlice"
import { LoadSupplierDeviceDetails } from "../../app/AppDataLoader"
import { FlexDiv } from "../../app/Utils"
import AddIcon from '@mui/icons-material/Add'
import { useCallback, useState } from "react"
import { AppId, AppTextFieldHandler } from "../../app/appTypes"
import { LoadingButton }  from "@mui/lab"
import {  showError, showSuccess } from "../notifications/notificationsSlice"
import { StructureName } from "../device/Structure"

type FormTabs = "description" | "parameters" 

export const EditCostEstimationComponents = () => {
    return <LoadSupplierDeviceDetails component={<Skeleton />}>
            <Edit />
    </LoadSupplierDeviceDetails>
}

const Edit = () => {
    const dispatch = useAppDispatch()
    const { id: paramId } = useParams(); const id = paramId ?? ''
    const device = useAppSelector(state => selectSupplierDeviceById(state, id))
    const deviceDetails = useAppSelector(state => selectAllSupplierDeviceDetail(state).filter(({ supplierDeviceId }) => supplierDeviceId == id))

    const [name, setName] = useState<string>(device?.name ?? "");    
    const [description, setDescription] = useState<string>(device?.description?? "");

    const [detailId, setDetailId] = useState("")
    const [detailName, setDetailName] = useState("")
    const [detailDescription, setDetailDescription] = useState("")
    const [detailOrder, setDetailOrder] = useState("")
    const [detailPrice, setDetailPrice] = useState(0)

    const [showParamDialog, setShowParamDialog] = useState(false)
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [saving, setSaving] = useState(false)
    const [itemToDelete, setItemToDelete] = useState<AppId | undefined>(undefined)
    
    const handleNameChange: AppTextFieldHandler = useCallback(e => {
        setName(e.target.value)
    }, [])

    const handleDescriptionChange: AppTextFieldHandler = useCallback(e => {
        setDescription(e.target.value)
    }, [])

    const handleDetailOrderChange: AppTextFieldHandler = useCallback(e => {
        setDetailOrder(e.target.value)
    }, [])

    const handleDetailNameChange: AppTextFieldHandler = useCallback(e => {
        setDetailName(e.target.value)
    }, [])

    const handleDetailPriceChange: AppTextFieldHandler = useCallback(e => {
        setDetailPrice(Number.parseFloat(e.target.value))
    }, [])
    
    const handleDetailDescriptionChange: AppTextFieldHandler = useCallback(e => {
        setDetailDescription(e.target.value)
    }, [])
    
    const handleEditClick = useCallback(() => {
        setShowEditDialog(true)
    }, [])

    const handleAddDetail = useCallback(() => {
        setDetailId("")
        setDetailName("")
        setDetailDescription("")
        setDetailOrder("")
        setSaving(false)
        setShowParamDialog(true)
    }, [])

    
    const handleConfirmSaveClick = async () => {
        setSaving(true)
        try {
            if (detailId) {
                await dispatch(updateSupplierDeviceDetail({
                    id: detailId,
                    name: detailName,
                    description: detailDescription,
                    supplierDeviceId: id,
                    position: detailOrder,
                    price: detailPrice
                })).unwrap();

                dispatch(showSuccess("saved"))
                setShowParamDialog(false)
            }else{
                await dispatch(createSupplierDeviceDetail({
                    name: detailName,
                    description: detailDescription,
                    supplierDeviceId: id,
                    position: detailOrder,
                    price: detailPrice
                })).unwrap();

                dispatch(showSuccess("saved"))
                setShowParamDialog(false)
            }
        }
        catch (error) {
            dispatch(showError("error"));
        }
        finally {
            setSaving(false)
        }
    }

    
    const handleConfirmMainSaveClick = async () => {
        setSaving(true)
        try {
            if (device && name && description ) {

                await dispatch(updateSupplierDevice(
                    {...device, 
                        name, 
                        description,
                        productionYear:0,
                        structureId : device.structureId,
                        id: device?.id
                })).unwrap();

                dispatch(showSuccess("saved"))
                setShowEditDialog(false)
            }
        }
        catch (error) {
            dispatch(showError("error"));
        }
        finally {
            setSaving(false)
            setShowEditDialog(false)
        }
    }

    const handleCancelEditClick = useCallback(() => {
        setShowEditDialog(false)
    }, [])

    const handleCancelClick = useCallback(() => {
        setDetailId("")
        setDetailName("")
        setDetailDescription("")
        setDetailOrder("")
        setShowParamDialog(false)
    }, [])

    
    const handleEditParameterClick = useCallback((row: SupplierDeviceDetail) => {
        setDetailId(row.id)
        setDetailName(row.name)
        setDetailDescription(row.description)
        setDetailOrder(row.position)
        setShowParamDialog(true)

    }, [])

    const handleDeleteClick = useCallback((id: AppId) => {
        setItemToDelete(id)
    }, [setItemToDelete])

    const handleCancelDelClick = useCallback(() => {
        setItemToDelete(undefined)
    }, [setItemToDelete])

    const handleConfirmDelClick = async () => {
        if (itemToDelete) {
            setSaving(true)
            try {
                await dispatch(deleteSupplierDeviceDetail(itemToDelete)).unwrap()
                dispatch(showSuccess("deleted"))
            } catch (error) {
                dispatch(showError("error"))
            }

            setSaving(false)
            setItemToDelete(undefined)
        }
    }

    if (!device) return <Alert severity="warning">
        <Localized id="error-entity-not-exists">
            <div>Obiekt nie istnieje</div>
        </Localized>
    </Alert>

    return <>
        <Container maxWidth={false}>
            <Stack spacing={2}> 
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <Typography component="div" color="text.secondary" gutterBottom>
                                    <Localized id="devices-name">
                                        <span>Nazwa urządzenia</span>
                                    </Localized>
                                </Typography>
                                <Typography variant="h5">{device?.name}</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography component="div" color="text.secondary" gutterBottom>
                                    <Localized id="devices-type">
                                        <span>Typ urządzenia</span>
                                    </Localized>
                                </Typography>
                                <Typography variant="h5"><StructureName structureId={device.structureId} /></Typography>
                            </Grid>
                        </Grid>
                        <Divider sx={{
                            marginTop: 2,
                        }}> 
                            <Localized id="devices-description">
                                <span>Opis</span>
                            </Localized>
                        </Divider>
                        <Typography variant="body2">{device?.description}</Typography>
                    </CardContent>
                    <CardActions>
                        <Button  variant="text" onClick={handleEditClick}>
                            <Localized id="edit">Edytuj</Localized>
                        </Button>
                    </CardActions>
                </Card>
                <Card>
                    <Divider sx={{
                            marginTop: 1,
                        }}>
                        <Localized id="">
                            <span>Składniki kosztorysu</span>
                        </Localized>
                    </Divider>
                    <Typography color="text.secondary" gutterBottom>
                        
                    </Typography>
                    <TableContainer>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: "60px" }}>
                                    <Localized id="lp">
                                        <span>L.p.</span>
                                    </Localized>
                                </TableCell>
                                <TableCell>
                                    <Localized id="devices-parameter-name">
                                        <span>Nazwa</span>
                                    </Localized>
                                </TableCell>
                                <TableCell>  
                                    <Localized id="devices-parameter-description">
                                        <span>Opis</span>
                                    </Localized>
                                </TableCell>
                                <TableCell>  
                                    <Localized id="">
                                        <span>Koszt</span>
                                    </Localized>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {deviceDetails.sort((a, b) => a.position.localeCompare(b.position)).map(row => 
                                <TableRow key={row.id}>     
                                    <TableCell>{row.position}</TableCell>
                                    <TableCell style={{ width: '100%' }}>{row.name}</TableCell>
                                    <TableCell 
                                      sx={{
                                        maxWidth: 1000,
                                        whiteSpace: 'nowrap', 
                                        overflow: 'hidden',  
                                        textOverflow: 'ellipsis'
                                      }}
                                    >{row.description}</TableCell>
                                    <TableCell>{row.price}</TableCell>
                                    <TableCell align="right">
                                        <ButtonGroup>                                              
                                            <Button color="primary" onClick={() => handleEditParameterClick(row)} >
                                                <Localized id="edit">
                                                    <span>Edytuj</span>
                                                </Localized>
                                            </Button>
                                            <Button onClick={() => handleDeleteClick(row.id)} >
                                                <Localized id="delete">
                                                    <span>Usuń</span>
                                                </Localized>
                                            </Button>
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </TableContainer>
                    <FlexDiv>
                        <Fab color="secondary" onClick={handleAddDetail} sx={{marginTop:2, marginLeft:2, marginBottom:2 }}>
                            <AddIcon />
                        </Fab>
                    </FlexDiv>
                </Card>
            </Stack>
            <Dialog open={showEditDialog} fullWidth maxWidth="lg">
                <DialogTitle>
                    <Localized id="edit">
                            <span>Edytuj</span>
                    </Localized>
                </DialogTitle>
                <DialogContent>
                    <Stack direction="column" spacing={2} sx={{marginTop: 2}}>
                    <TextField      
                            fullWidth    
                            required   
                            inputProps={{ maxLength: 200 }}                                 
                            value={name}   
                            onChange={handleNameChange}                        
                            label={<Localized id="devices-name">Nazwa urzadzenia</Localized>}>
                        </TextField> 

                        <TextField               
                            fullWidth              
                            value={description}                 
                            onChange={handleDescriptionChange}                 
                            multiline     
                            minRows={4}                     
                            label={<Localized id="devices-description">Opis</Localized>}>
                        </TextField>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleConfirmMainSaveClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </LoadingButton>
                    <LoadingButton loading={saving} onClick={handleCancelEditClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog open={showParamDialog} fullWidth maxWidth="lg">
                <DialogTitle>
                    <Localized id="">
                            <span>Składnik kosztorysu</span>
                    </Localized>
                </DialogTitle>
                <DialogContent>
                    <Stack direction="column" spacing={2} sx={{marginTop: 2}}>
                        <TextField 
                            type="number"
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9,.]*', // Dozwolone cyfry, kropka i przecinek
                              }}
                            label={<Localized id="lp">
                                <span>L.p.</span>
                            </Localized>}
                            value={detailOrder}
                            onChange={handleDetailOrderChange}
                        />
                        <TextField 
                            fullWidth
                            label={<Localized id="devices-parameter-name">
                                <span>Nazwa</span>
                            </Localized>}
                            value={detailName}
                            onChange={handleDetailNameChange}
                        />
                         <TextField 
                            fullWidth
                            type="number"
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '^[0-9]*([.,][0-9]{0,2})?$', // Dozwolone cyfry, kropka lub przecinek i do dwóch miejsc po przecinku
                              }}
                            label={<Localized id="">
                                <span>Koszt</span>
                            </Localized>}
                            value={detailPrice}
                            onChange={handleDetailPriceChange}
                        />
                        <TextField 
                            fullWidth
                            label={<Localized id="devices-parameter-description">
                                <span>Nazwa</span>
                            </Localized>}
                            multiline={true}
                            minRows={8}
                            value={detailDescription}
                            onChange={handleDetailDescriptionChange}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleConfirmSaveClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </LoadingButton>
                    <LoadingButton loading={saving} onClick={handleCancelClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog open={itemToDelete !== undefined}>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="confirm-delete">
                            <span>Czy napewno chcesz usunąć?</span>
                        </Localized>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmDelClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </Button>
                    <Button onClick={handleCancelDelClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    </>
}
