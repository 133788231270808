import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppId, AppThunkAPIType } from "../../../app/appTypes";
import { getWithAuth } from "../../../http";

export type CompanySettingType
    = "DEFAULT_LANGUAGE"

export type SupplierEventType
    = "NEW_URS_REQUEST"

export type WorkflowRoleType
    = "OWNER"
    | "PROCESS_MANAGER"
    | "COLLABORATOR"
    | "APPROVER"


export type NotificationMethodType
    = "EMAIL"

export interface CompanySetting {
    id: AppId
    companyId: number
    settingType: CompanySettingType
    value: string
    isActive: boolean
    modifiedBy: string
    modifyDate: Date
}

export interface CompanySupplierAssigmentSetting {
    id: AppId;
    user: string;
    companyId: number;
    event: SupplierEventType
    role: WorkflowRoleType
    isActive: boolean
    notifyUser: boolean
    notificationMethod: boolean
    modifiedBy: string
    modifyDate: Date
}

export const loadCompanySettings = createAsyncThunk<CompanySetting[], void, AppThunkAPIType>("/company-settings/load-main-settings",
    async (_, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/company-settings` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as CompanySetting[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const loadSupplierAssigmentsSettings = createAsyncThunk<CompanySupplierAssigmentSetting[], void, AppThunkAPIType>("/company-settings/supplier-assigments",
    async (_, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/company-settings/supplier-assigments` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as CompanySupplierAssigmentSetting[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })