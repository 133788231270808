import React from 'react';
import { Alert, AlertTitle, ButtonGroup, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { AlertFeatureInProgress } from '../utils/AlertFeatureInProgress';

export const SupplierOfferForm = () => {

    // Dane dla głównego ekranu
    const mainRow = {
        id: 1,
        client: 'Company ABC',
        generationDate: '2024-02-25',
        sentDate: '2024-02-26',
        preparedBy: 'John Kozak',
        ursNumber: 'URZ-12345'
    };

    // Dane dla gridu podrzędnego
    const subRows = [
        { id: 1, equipmentNumber: 1, elementName: 'Base machine', price: 780 },
        { id: 2, equipmentNumber: 2, elementName: 'Component name 1', price: 100 },
        { id: 3, equipmentNumber: 3, elementName: 'Component name 2', price: 150 },
        { id: 4, equipmentNumber: 4, elementName: 'IQ validation', price: 100 },
        { id: 5, equipmentNumber: 5, elementName: 'Year maitenance', price: 50 },
        // Dodaj więcej danych urządzeń w razie potrzeby
    ];

    const subColumns = [
        { field: 'equipmentNumber', headerName: 'Device number', width: 150 },
        { field: 'elementName', headerName: 'Device komponent', width: 200 },
        { field: 'price', headerName: 'Price', width: 150 },

    ];

    const getRowId = (row) => row.id;

    const sumPrices = subRows.reduce((sum, { price }) => sum + price, 0);


    return <>
        <AlertFeatureInProgress/>

        <div>
            <Typography variant="h6">Offer details</Typography>
            <Typography>Offer number: {mainRow.id}</Typography>
            <Typography>Customer: {mainRow.client}</Typography>
            <Typography>Offer create date: {mainRow.generationDate}</Typography>
            <Typography>Offer send date {mainRow.sentDate}</Typography>
            <Typography>Prepared by: {mainRow.preparedBy}</Typography>
            <Typography>URS number: {mainRow.ursNumber}</Typography>
            <Typography variant="h6">Offer Details</Typography>
            <div style={{ height: 300, width: '100%' }}>
                <DataGrid
                    rows={subRows}
                    columns={subColumns}
                    autoHeight
                    getRowId={getRowId}
                    slots={{
                        footer: () => (
                            <div style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: '16px' }}>
                                Total: {sumPrices}
                            </div>
                        ),
                    }}

                />
            </div>
        </div>
    </>;
}