import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppThunkAPIType } from "../../app/appTypes"
import { getWithAuth } from "../../http"

export type NotificationType
    = "MENTION"
    | "COMMENT"

export interface UserNotification {
    id: string
    message: string
    link: string
    isRead: boolean
    createdAt: string
    notificationType: NotificationType
}

export const loadUserNotifications = createAsyncThunk<UserNotification[], void, AppThunkAPIType>("/user-notifications/load",
    async (_, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/user-notifications` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as UserNotification[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })
