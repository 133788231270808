import { Box, Button, ButtonGroup, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material"
import { AppId } from "../../../app/appTypes"
import { useCallback, useState } from "react"
import { Localized } from "@fluent/react"
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { ManagedTeamMember } from "../../utils/managedObject"
import { OfferTeamMemberRoleLabel } from "./OfferTeamMemberRoleLabel"
import { AddEditOfferTeamMemberDialog } from "./AddEditOfferTeamMemberDialog"
import { If } from "../../../app/If";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { ChangeOfferOwnerDialog } from "./ChangeOfferOwnerDialog";
import { UrsOffer } from "../ursOfferSlice";

type DialogState
    = "none"
    | "addEditTeamMember"
    | "changeProcessOwner"
    | "changeTMDeadline"
    | "substituteTM"
    | "inviteTeamMember"
    | "sendReminder"
    | "deleteTeamMember"
    | { tag: "confirmTeamMemberDelete", offerId: AppId, teamMemberId: AppId, user: string }


export interface OfferTeamMemberPanelProps {
    offerId: AppId
    teamMembers: ManagedTeamMember[]
    editable: boolean
    onSuccessfullAddTeamMember: (teamMember: ManagedTeamMember) => void
    onSuccessfullChangeOwner: (updatedOffer: UrsOffer) => void
}

export const OfferTeamMemberPanel = ({
    offerId,
    teamMembers,
    editable,
    onSuccessfullAddTeamMember,
    onSuccessfullChangeOwner }: OfferTeamMemberPanelProps) => {
    const [dialog, setDialog] = useState<DialogState>("none")

    const handleChangeTMDeadlineClick = useCallback((entity: ManagedTeamMember) => {

    }, [])

    const handleAddTeamMemberClick = useCallback(() => {
        setDialog("addEditTeamMember")
    }, [])

    const handleChangeProcessOwnerClick = useCallback(() => {
        setDialog("changeProcessOwner")
    }, [])

    const handleDeleteTeamMemberClick = useCallback((entity: ManagedTeamMember, user: string) => {
        setDialog({ tag: "confirmTeamMemberDelete", offerId: offerId, teamMemberId: entity.id, user })

    }, [])

    const handleDialogCancelClick = useCallback(() => {
        setDialog("none")
    }, [])

    const handleDeleteTMClick = useCallback((entity: ManagedTeamMember, user: string) => {
    }, [])



    const onChangeOfferSuccessfulSave = (updatedOffer: UrsOffer) => {
        onSuccessfullChangeOwner(updatedOffer)
        setDialog("none")
    };

    const onTeamMemberSuccessfulSave = (teamMember: ManagedTeamMember) => {
        onSuccessfullAddTeamMember(teamMember)
        setDialog("none")
    };

    return <>
        <TableContainer component={Paper} sx={{
            maxHeight: 'calc(100vh - 400px)',
        }}>

            <Table>
                <TableHead>
                    <TableRow >
                        <TableCell sx={{
                            minWidth: 200,
                        }}>
                            <Localized id="user-first-name">
                                <span>Imię</span>
                            </Localized>
                        </TableCell>
                        <TableCell sx={{
                            minWidth: 200,
                        }}>
                            <Localized id="user-last-name">
                                <span>Nazwisko</span>
                            </Localized>
                        </TableCell>
                        <TableCell>
                            <Localized id="user-position">
                                <span>Stanowisko</span>
                            </Localized>
                        </TableCell>
                        <TableCell>
                            <Localized id="user-company">Firma</Localized>
                        </TableCell>
                        <TableCell>
                            <Localized id="user-role">
                                <span>Rola</span>
                            </Localized>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{teamMembers?.map(tm => <TeamMemberRow
                    editable={editable}
                    entity={tm}
                    key={tm.userName}
                    onChangeDeadlineClick={handleChangeTMDeadlineClick}
                    onDeleteClick={handleDeleteTMClick}
                />)}</TableBody>
            </Table>

        </TableContainer>
        <Box sx={{
            padding: 1,
        }}>
            <If condition={editable}>
                <Button variant="contained" color="secondary" size="small" startIcon={<AddIcon />} onClick={handleAddTeamMemberClick}>
                    <Localized id="add">
                        <span>_Dodaj</span>
                    </Localized>
                </Button>
                <Button variant="contained" color="error" size="small" startIcon={<AdminPanelSettingsIcon />}
                    onClick={handleChangeProcessOwnerClick}>
                    <Localized id="team-change-process-owner">
                        <span>_change process owner</span>
                    </Localized>
                </Button>
            </If>

        </Box>
        {
            dialog === "addEditTeamMember" &&
            <AddEditOfferTeamMemberDialog
                offerId={offerId}
                teamMembers={teamMembers}
                mode={"create"}
                userToSubstitute={""}
                onCancelClick={handleDialogCancelClick}
                onSuccessfulSave={onTeamMemberSuccessfulSave}
            />
        },
        {
            dialog === "changeProcessOwner" &&
            <ChangeOfferOwnerDialog
                offerId={offerId}
                teamMembers={teamMembers}
                onCancelClick={handleDialogCancelClick}
                onSuccessfulSave={onChangeOfferSuccessfulSave}
            />
        }

    </>
}

interface TeamMemberRowProps {
    entity: ManagedTeamMember
    onChangeDeadlineClick: (entity: ManagedTeamMember) => void
    onDeleteClick: (entity: ManagedTeamMember, user: string) => void
    editable: boolean
}
const TeamMemberRow = ({ entity, onChangeDeadlineClick, onDeleteClick, editable }: TeamMemberRowProps) => {
    const { firstName, surname, position, companyName } = entity

    let substitutionRowColor = {};

    const handleDeleteClick = useCallback(() => {
        onDeleteClick(entity, `${firstName} ${surname}`)
    }, [entity, onDeleteClick])

    return <TableRow style={substitutionRowColor} key={entity.id}>
        <TableCell>{firstName}</TableCell>
        <TableCell>{surname}</TableCell>
        <TableCell>{position}</TableCell>
        <TableCell>{companyName}</TableCell>
        <TableCell><OfferTeamMemberRoleLabel role={entity.role}></OfferTeamMemberRoleLabel></TableCell>

        <TableCell>
            <ButtonGroup>
                <If condition={editable}>
                    <Tooltip title={<Localized id="delete">Usuń</Localized>}>
                        <IconButton size="small" onClick={handleDeleteClick}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </If>
            </ButtonGroup>
        </TableCell>
    </TableRow>
}

