import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { Box, Button, ButtonGroup, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Skeleton, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { ChangeEvent, DragEvent, useCallback, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { If } from "../../app/If"
import { BlobMeta } from "../urs/ursAttachementsSlice"
import { LoadDeviceTypes } from "../../app/AppDataLoader"
import { selectAllDeviceTypes } from "../device/deviceTypesSlice"
import { selectBearerToken } from "../user/userSlice"
import { showSuccess } from "../notifications/notificationsSlice"

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>


export interface ImportPanelProps {
    onSucces: () => void
}

export const ImportPanel = (props: ImportPanelProps) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const formRef = useRef<HTMLFormElement | null>(null)
    const dropboxRef = useRef<HTMLDivElement | null>(null)
    const [uploading, setUploading] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState<FileList | never[] | null>(null)
    const [metadata, setMetadata] = useState<{ key: string; value: string }[]>([]);
    const importDefinitionId = 'f459968d-4467-469f-83fb-7ce8b4d2cc87';
    const dispatch = useAppDispatch()

    const anyFiles: boolean = selectedFiles !== null && selectedFiles.length > 0 ? true : false
    const allDeviceTypes = useAppSelector(selectAllDeviceTypes)
    const [structureId, setStructureId] = useState<string>("");
    const [validationErrors, setValidationErrors] = useState(false)

    const deviceTypeIdError = validationErrors && structureId === ""


    const handleStructureChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setStructureId(e.target.value)
        let newMetadataObject = { key: 'structureId', value: e.target.value };

        setMetadata(prevMetadata => [...prevMetadata, newMetadataObject]);

    }, [setStructureId])


    const handleSelectFilesClick = useCallback(() => {
        if (fileInputRef) {
            fileInputRef.current?.click()
        }
    }, [fileInputRef])

    const handleSelectedFilesChange = useCallback(() => {
        if (fileInputRef) {
            setSelectedFiles(fileInputRef.current?.files ?? null)
        }
    }, [fileInputRef, setSelectedFiles])

    const handleCancelUploadClick = useCallback(() => {
        if (formRef) {
            formRef.current?.reset()
            setSelectedFiles(null)
        }
    }, [formRef, setSelectedFiles])


    const uploadFiles = (files: File[], importDefinitionId: string, metadata: any) => async (dispatch: any, getState: any) => {
        const fd = new FormData();
        files.forEach(file => {
            fd.append("file", file);
            fd.append('importDefinitionId', importDefinitionId);
            fd.append('metadata', JSON.stringify(metadata));
        });
    
        try {
            const authHeader = selectBearerToken(getState());
    
            const response = await fetch(`${process.env.REACT_APP_REST_API}api/import/from-definition`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    ...authHeader,
                },
                body: fd,
            });

            return response.ok
        } catch (error) {
            console.log(error);
        }
    };

    
    const handleUploadClick = async () => {
        setValidationErrors(true)

        
        if (selectedFiles && selectedFiles.length > 0 && structureId) {
            setUploading(true)
            let files: File[] = []
            for (const file of selectedFiles) {
                files.push(file)
            }
            try {

           
                try {
                    const result = await dispatch(uploadFiles(files, importDefinitionId, metadata));

                   if(result){
                        dispatch(showSuccess("saved"))
                        props.onSucces()
                   }
                   else{
                    dispatch(showSuccess("error"))

                   }
                   
                } catch (error) {
                    console.log(error)
                }

                if (formRef) {
                    formRef.current?.reset()
                }
                setSelectedFiles(null)
            } catch (error) {
                console.log(error)
            } finally {
                setUploading(false)
            }
        }
    }

    const handleDragEnter = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
    }, [])
    const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
    }, [])
    const handleDrop = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
        const dt = e.dataTransfer
        if (dt) {
            setSelectedFiles(dt.files)
        }
    }, [])


    return <Stack spacing={2}>

        <Box>
            <LoadDeviceTypes component={<Skeleton variant="rectangular" />}>
                <TextField
                    select
                    required
                    error={deviceTypeIdError}
                    helperText={deviceTypeIdError && <RequiredError />}
                    fullWidth
                    value={structureId}
                    onChange={handleStructureChange}
                    name="structureId"
                    label={<Localized id="devices-type">Typ</Localized>}>
                    {allDeviceTypes.map((devType) => <MenuItem key={devType.id} value={devType.id}>{devType.name}</MenuItem>)}
                </TextField>
            </LoadDeviceTypes>

            <form ref={formRef}>
                <input
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    type="file"
                    onChange={handleSelectedFilesChange}
                    accept="image/*,.xlsx,"
                />
            </form>
            <Button size="large" variant="contained" onClick={handleSelectFilesClick}>
                <Localized id="select-file">Wybierz plik</Localized>
            </Button>
        </Box>
        <Box sx={{
            width: '100%',
            minHeight: 60,
            border: 'dashed grey',
            marginTop: 2,
            padding: 1,
        }} ref={dropboxRef} component="div" onDragEnter={handleDragEnter} onDragOver={handleDragOver} onDrop={handleDrop}>
            <Typography variant="body1" color="GrayText" gutterBottom>
                <Localized id="drop-files">or drop here...</Localized>
            </Typography>
            <If condition={anyFiles}>
                <FileList files={selectedFiles} />
            </If>
        </Box>
        <If condition={anyFiles}>
            <ButtonGroup>
                <LoadingButton loading={uploading} size="large" variant="contained" onClick={handleUploadClick}>
                    <Localized id="import">Import</Localized>
                </LoadingButton>
                <LoadingButton loading={uploading} size="large" variant="contained" onClick={handleCancelUploadClick}>
                    <Localized id="cancel">Anuluj</Localized>
                </LoadingButton>
            </ButtonGroup>
        </If>
    </Stack>
}

const FileList = ({ files }: { files: FileList | never[] | null }) => {
    if (files === null) {
        return <span>No files</span>
    }

    let idx: number[] = []
    for (let i = 0; i < files.length; i++) {
        idx.push(i)
    }

    return <List>{idx.map(j => {
        const file = files[j]
        const url = URL.createObjectURL(file)
        return <ListItem>
            <ListItemAvatar>
                <img
                    style={{ width: '90px' }}
                    src={url}
                    onLoad={() => URL.revokeObjectURL(url)}
                />
            </ListItemAvatar>
            <ListItemText primary={file.name} secondary={`size: ${file.size}B`}></ListItemText>
        </ListItem>
    })}</List>
}

