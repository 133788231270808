import React, { useContext, useState } from 'react';
import { Box, TextField, Typography, Grid, Button, Paper, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { updateDocumentSetting } from '../documentsApi';
import { useAppDispatch } from '../../../app/hooks';
import { AppId } from '../../../app/appTypes';
import { showError, showSuccess } from '../../notifications/notificationsSlice';
import { DataSetterContext } from '../riskAnalysis/RiskAnalysisForm';
import { unwrapResult } from '@reduxjs/toolkit';
import { loadSettingsForCompany } from '../../systemSettings/systemDocumentSettings/SystemDocumentSettingsApi';

interface DocumentLimitEditorProps {
  documentId: AppId,
  initialGoodThreshold: number;
  initialWarningThreshold: number;
  onLimitsChange?: (goodThreshold: number, warningThreshold: number) => void;
}

export const DocumentLimitEditor: React.FC<DocumentLimitEditorProps> = ({ documentId, initialGoodThreshold, initialWarningThreshold }) => {
  const [goodThreshold, setGoodThreshold] = useState<number>(initialGoodThreshold);
  const [warningThreshold, setWarningThreshold] = useState<number>(initialWarningThreshold);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const dispatch = useAppDispatch()
  
  const documentDataSetter = useContext(DataSetterContext)

  const handleGoodThresholdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    setGoodThreshold(value);
  };

  const handleWarningThresholdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    setWarningThreshold(value);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    if(goodThreshold === 0 || warningThreshold === 0) {
      dispatch(loadSettingsForCompany(`?documentCode=RiskAnalysis`))
        .then(unwrapResult)
        .then((response) => {
            const gT = response.find(f => f.name === "GOOD_THRESHOLD");

            if(gT !== undefined && goodThreshold === 0) {
              setGoodThreshold(Number(gT.value));
            }

            const wT = response.find(f => f.name === "WARNING_THRESHOLD");

            if(wT !== undefined && warningThreshold === 0) {
              setWarningThreshold(Number(wT.value));
            }
        })
        .catch((error) => console.error(error))
    }
  };

  const handleSaveClick = async () => {
    setIsEditing(false);
    if (goodThreshold && warningThreshold) {
      try {
        const goodUpdateResult = await dispatch(updateDocumentSetting({
          documentId: documentId,
          name: 'GOOD_THRESHOLD',
          value: goodThreshold.toString(),
        }))
      
        if (updateDocumentSetting.fulfilled.match(goodUpdateResult)) {
          const warningUpdateResult = await dispatch(updateDocumentSetting({
            documentId: documentId,
            name: 'WARNING_THRESHOLD',
            value: warningThreshold.toString(),
          }))

          if (updateDocumentSetting.fulfilled.match(warningUpdateResult)) {
            
            dispatch(showSuccess("saved"))

            documentDataSetter(doc=>{
              const goodIndx = doc.settings.findIndex(x => x.name === 'GOOD_THRESHOLD');
              doc.settings[goodIndx].value = goodThreshold.toString()

              const warningIndx = doc.settings.findIndex(x => x.name === 'WARNING_THRESHOLD');
              doc.settings[warningIndx].value = warningThreshold.toString()
            })
          }
        }

      }
      catch (error) {
        dispatch(showError("error"))
      }
      finally {
        setIsEditing(false)
      }

    }
  };

  // Obliczenia szerokości kolorowych przedziałów dla wizualizacji
  const minErrorPercentage = 30;
  let goodPercentage = (goodThreshold / warningThreshold) * (100 - minErrorPercentage);
  let warningPercentage = ((warningThreshold - goodThreshold) / warningThreshold) * (100 - minErrorPercentage);
  let errorPercentage = minErrorPercentage;

  return (
    <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h6" gutterBottom>
          Limity
        </Typography>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={5}>
            <TextField
              label="Good Threshold"
              type="number"
              variant="outlined"
              disabled={!isEditing}
              value={goodThreshold}
              onChange={handleGoodThresholdChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Warning Threshold"
              type="number"
              variant="outlined"
              disabled={!isEditing}
              value={warningThreshold}
              onChange={handleWarningThresholdChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            {!isEditing ? (
              <IconButton onClick={handleEditClick} color="primary">
                <EditIcon />
              </IconButton>
            ) : (
              <Button variant="contained" color="success" onClick={handleSaveClick} startIcon={<SaveIcon />}>
                Save
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: 3, position: 'relative' }}>
          <Box sx={{ display: 'flex', height: 20, width: '100%', borderRadius: 1, overflow: 'hidden', position: 'relative' }}>
            <Box sx={{ width: `${goodPercentage}%`, backgroundColor: 'green', position: 'relative' }}>
              <Typography variant="caption" color="white" sx={{ position: 'absolute', left: '50%', top: '-2px', transform: 'translateX(-50%)' }}>
                OK
              </Typography>
            </Box>
            <Box sx={{ width: `${warningPercentage}%`, backgroundColor: 'orange', position: 'relative' }}>
              <Typography variant="caption" color="white" sx={{ position: 'absolute', left: '50%', top: '-2px', transform: 'translateX(-50%)' }}>
                Warning
              </Typography>
            </Box>
            <Box sx={{ width: `${errorPercentage}%`, backgroundColor: 'red', position: 'relative' }}>
              <Typography variant="caption" color="white" sx={{ position: 'absolute', left: '50%', top: '-2px', transform: 'translateX(-50%)' }}>
                Error
              </Typography>
            </Box>
          </Box>

          <Box sx={{ position: 'absolute', top: '-20px', left: `${goodPercentage}%`, transform: 'translateX(-50%)' }}>
            <Typography variant="body2" color="textSecondary">
              {goodThreshold}
            </Typography>
          </Box>
          <Box sx={{ position: 'absolute', top: '-20px', left: `${goodPercentage + warningPercentage}%`, transform: 'translateX(-50%)' }}>
            <Typography variant="body2" color="textSecondary">
              {warningThreshold}
            </Typography>
          </Box>
        </Grid>
      </Box>
    </Paper>
  );
}