import { Localized } from "@fluent/react"
import { MenuItem, TextField } from "@mui/material"
import { ChangeEvent } from "react"
import { ManagementRoleType } from "../../utils/managedObject"

export interface OfferTeamMemberRoleMenuFieldProps {
    value: ManagementRoleType | undefined
    onChange: (e: ChangeEvent<HTMLInputElement>) => void,
}

export const OfferTeamMemberRoleMenuField = ({ value, onChange }: OfferTeamMemberRoleMenuFieldProps) => {
    return <>
        <TextField
            sx={{ marginTop: 2 }}
            value={value}
            fullWidth
            required={true}
            select
            label={<Localized id="document-user-role"><span>Rola</span></Localized>}
            onChange={onChange}
        >
            <MenuItem value={"EDITOR"}><Localized id=""><span>Editor</span></Localized></MenuItem>
        </TextField>
    </>
}