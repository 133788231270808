import { deleteSupplier, loadSuppliers, selectAllSuppliers, selectSuppliers, Supplier } from "./suppliersSlice";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useCallback, useEffect, useState } from 'react';
import { AppId } from "../../app/appTypes";
import { unwrapResult } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../notifications/notificationsSlice";
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { Box, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { Localized, useLocalization } from '@fluent/react';
import { AppLoader } from '../../app/AppLoader';
import { FlexDiv } from '../../app/Utils';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { LoadingContainer } from "../../app/LoadingContainer";
import { selectSettings } from "../settings/settingsSlice";
import { enUS, plPL } from "@mui/x-data-grid/locales";
import { QuickSearchToolbar } from "../../app/QuickSearchToolbar";

export const Suppliers = () => {
    const { loaded } = useAppSelector(selectSuppliers)
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const rows = useAppSelector(state => selectAllSuppliers(state))
    const [deleting, setDeleting] = useState(false)
    const [itemToDelete, setItemToDelete] = useState<AppId | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleDeleteClick = useCallback((id: AppId) => {
        setItemToDelete(id)
    }, [setItemToDelete])

    const handleCancelDelClick = useCallback(() => {
        setItemToDelete(undefined)
    }, [setItemToDelete])
    
    const handleConfirmDelClick = async () => {
        if (itemToDelete) { 
            setDeleting(true)           
            try {
                unwrapResult(await dispatch(deleteSupplier(itemToDelete)))
                dispatch(showSuccess("deleted"))
            } catch (error) {
                dispatch(showError("error"))
            }
            setItemToDelete(undefined)
            setDeleting(false)
        }
    }

    useEffect(() => {
        if (!loaded) {
            setIsLoading(true);
            dispatch(loadSuppliers())
        } else {
            setIsLoading(false)
        }
    }, [dispatch, loaded])
    
    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const handleGoToClick = (id) => {
        navigate(`/suppliers/edit/${id}`)
    };

    const columns: GridColDef<Supplier, any, any>[] = 
    [
        { 
            field: 'shortName', 
            headerName: l10n.getString('supplier-shortName',null,'Nazwa skrócona'),   
            flex:1 
        },
        { 
            field: 'name', 
            headerName:l10n.getString('supplier-name',null,'Nazwa'),  
            flex:1
        },
        { 
            field: 'actions',
            type: "actions",
            width: 200,
            renderCell: (params) => {
                return(
                <ButtonGroup>
                    <Button onClick={() => handleGoToClick(params.row.id)} color="secondary" variant="outlined">
                            <Localized id="edit">
                            <span>Edytuj</span> 
                        </Localized>
                    </Button>
                    <Button color="primary" onClick={() => handleDeleteClick(params.row.id)}>
                        <Localized id="delete">
                            <span>Usuń</span>
                        </Localized>
                    </Button>
                </ButtonGroup>
                )
            }
        }
    ];

    if (isLoading) {
        return <>
            <LoadingContainer/>
        </>
    }
        return (
            <>
            <Box sx={{ width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                <DataGrid style={{ minHeight: 100, height: 'calc(100vh - 200px)' }} localeText={getCustomLocaleText()} rows={rows} columns={columns} slots={{ toolbar: QuickSearchToolbar }} onRowDoubleClick={({ row }) => handleGoToClick(row.id)} />
                </div>
            </div>
                <FlexDiv>
                    <Fab component={Link} to="/suppliers/create" color="secondary" sx={{marginTop:2}}>
                        <AddIcon />
                    </Fab>
                </FlexDiv>
                <Dialog open={itemToDelete !== undefined}>
                    <DialogContent>
                        <DialogContentText>
                            <Localized id="confirm-delete">
                                <span>Czy napewno chcesz usunąć?</span>
                            </Localized>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirmDelClick}>
                            <Localized id="yes"><span>Tak</span></Localized>
                        </Button>
                        <Button onClick={handleCancelDelClick}>
                            <Localized id="no"><span>Nie</span></Localized>
                        </Button>
                    </DialogActions>
                </Dialog>
                <AppLoader open={deleting} />
                </Box>
            </>
        );
}
